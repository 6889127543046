import {
  EDIT_BLOCK,
  EDIT_BLOCK_SUCCESS,
  EDIT_BLOCK_CHANGE_TEXT,
  EDIT_BLOCK_CLEAN,
  EDIT_BLOCK_ERROR,
} from "./actionTypes"

export const editBlock = form => ({
  type: EDIT_BLOCK,
  payload: { form },
})

export const editBlockSuccess = block => {
  return {
    type: EDIT_BLOCK_SUCCESS,
    payload: { block },
  }
}

export const editBlockClean = () => {
  return {
    type: EDIT_BLOCK_CLEAN,
    payload: {},
  }
}

export const editBlockText = (idBlock, text) => {
  return {
    type: EDIT_BLOCK_CHANGE_TEXT,
    payload: { idBlock, text },
  }
}

export const apiError = error => {
  return {
    type: EDIT_BLOCK_ERROR,
    payload: error,
  }
}
