import {
  GET_COURSE_KNOWLEDGES,
  GET_COURSE_KNOWLEDGES_SUCCESS,
  GET_COURSE_KNOWLEDGES_ERROR,
} from "./actionTypes"

export const getCourseKnowledges = () => ({
  type: GET_COURSE_KNOWLEDGES,
  payload: {},
})

export const getCourseKnowledgesSuccess = courseKnowledges => {
  return {
    type: GET_COURSE_KNOWLEDGES_SUCCESS,
    payload: { courseKnowledges },
  }
}

export const apiError = error => {
  return {
    type: GET_COURSE_KNOWLEDGES_ERROR,
    payload: error,
  }
}
