/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_CUSTOMER } from "./actionTypes"
import { getCustomerError, getCustomerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getCustomerNewApi } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(getCustomerNewApi, action.payload.id)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al guardar al cliente")
    yield put(getCustomerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(getCustomerError(error.message))
  }
}

function* customerGetSaga() {
  yield takeEvery(GET_CUSTOMER, get)
}

export default customerGetSaga
