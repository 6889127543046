import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_CLEAN,
  ADD_CUSTOMER_ERROR,
} from "./actionTypes"

export const addCustomer = form => ({
  type: ADD_CUSTOMER,
  payload: { form },
})

export const addCustomerSuccess = course => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: { course },
  }
}

export const addCustomerClean = () => {
  return {
    type: ADD_CUSTOMER_CLEAN,
    payload: {},
  }
}

export const addCustomerError = error => {
  return {
    type: ADD_CUSTOMER_ERROR,
    payload: error,
  }
}
