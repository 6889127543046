import { GET_LEVELS, GET_LEVELS_SUCCESS, API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  levels: [],
}

const LevelsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEVELS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LEVELS_SUCCESS:
      state = {
        ...state,
        levels: action.payload.levels,
        loading: false,
        done: true,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LevelsList
