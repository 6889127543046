/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_COURSE_KNOWLEDGE } from "./actionTypes"
import { apiError, addCourseKnowledgeSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addCourseKnowledge } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addCourseKnowledge, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success)
      throw new Error("Error al guardar el conocimiento adquirido")
    yield put(addCourseKnowledgeSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* courseKnowledgeAddSaga() {
  yield takeEvery(ADD_COURSE_KNOWLEDGE, add)
}

export default courseKnowledgeAddSaga
