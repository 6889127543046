import {
  ADD_ANSWERS,
  ADD_ANSWERS_SUCCESS,
  ADD_ANSWERS_CLEAN,
  ADD_ANSWERS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  question: undefined,
}

const AnswersAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ANSWERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_ANSWERS_SUCCESS:
      state = {
        ...state,
        question: action.payload.question,
        loading: false,
        done: true,
      }
      break
    case ADD_ANSWERS_CLEAN:
      state = initialState
      break
    case ADD_ANSWERS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AnswersAdd
