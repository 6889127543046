import {
  GET_KNOWLEDGE_CATEGORIES,
  GET_KNOWLEDGE_CATEGORIES_ALL,
  GET_KNOWLEDGE_CATEGORIES_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getKnowledgeCategories = () => {
  return { type: GET_KNOWLEDGE_CATEGORIES, payload: {} }
}

export const getAllKnowledgeCategories = () => {
  return { type: GET_KNOWLEDGE_CATEGORIES_ALL, payload: {} }
}

export const getKnowledgeCategoriesSuccess = knowledgeCategories => {
  return {
    type: GET_KNOWLEDGE_CATEGORIES_SUCCESS,
    payload: { knowledgeCategories },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
