import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { Row, Col, Modal } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"
import { getPeriodsParticipant } from "store/actions"

const GammificationPeriodsParticipantListTable = props => {
  const dispatch = useDispatch()
  const [finalPeriods, setFinalPeriods] = useState([])

  const { periods, loading } = useSelector(state => ({
    periods: state.GamificationPeriods.periodsParticipant,
    loading: state.GamificationPeriods.loading,
  }))

  useEffect(() => {
    dispatch(getPeriodsParticipant(props.userId))
  }, [props.userId])

  useEffect(() => {
    const dummyPeriods = periods
    const preFinalPeriods = []
    periods
      .filter(period => {
        if (props.periodId !== null) {
          return period.periodId === props.periodId
        }
        return true
      })
      .sort((a, b) => {
        return b.createdAt - a.createdAt
      })

    for (let i = 0; i < dummyPeriods.length; i++) {
      const preFinalPreiod = {
        points: dummyPeriods[i].points,
        createdAt: dummyPeriods[i].createdAt,
        periodName:
          dummyPeriods[i].period !== null
            ? dummyPeriods[i].period.periodName
            : "-",
        ruleName:
          dummyPeriods[i].rule !== null ? dummyPeriods[i].rule.ruleName : "-",
        courseName:
          dummyPeriods[i].course !== null
            ? dummyPeriods[i].course.courseName
            : "-",
      }

      preFinalPeriods.push(preFinalPreiod)
    }

    setFinalPeriods(preFinalPeriods)
  }, [periods])

  const { SearchBar } = Search
  const columns = [
    {
      dataField: "points",
      text: "Puntos",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="text-center">
            <strong>{row.points}</strong>
          </div>
        )
      },
    },
    {
      dataField: "createdAt",
      text: "Fecha",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <Fragment>
            <div className="text-center">
              {moment(row.createdAt).format("DD/MM/YYYY")}
            </div>
          </Fragment>
        )
      },
    },
    {
      dataField: "courseName",
      text: "Curso",
      sort: true,
    },
    {
      dataField: "ruleName",
      text: "Regla aplicada",
      sort: true,
    },
    {
      dataField: "periodName",
      text: "Periodo",
      sort: true,
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: finalPeriods?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "createdAt", order: "desc" }]

  return loading || finalPeriods === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="IdUsuario"
        columns={columns}
        data={finalPeriods}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="IdUsuario"
            columns={columns}
            data={finalPeriods}
            search
          >
            {toolkitProps => (
              <Fragment>
                <Row className="mb-2 pt-3">
                  <Col>
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"IdUsuario"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {finalPeriods?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <style jsx>
        {`
          th {
            text-align: center;
          }
        `}
      </style>
    </Fragment>
  )
}

GammificationPeriodsParticipantListTable.propTypes = {
  loading: PropTypes.bool,
  userId: PropTypes.number,
  periodId: PropTypes.number,
}

export default GammificationPeriodsParticipantListTable
