/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_POLL_ANSWER } from "./actionTypes"
import { listPollAnswerError, listPollAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getPollAnswers } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(getPollAnswers, request.payload.id)
    if (response == undefined) throw new Error("API error")
    yield put(listPollAnswerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(listPollAnswerError(error.message))
  }
}

function* pollAnswerListSaga() {
  yield takeEvery(LIST_POLL_ANSWER, list)
}

export default pollAnswerListSaga
