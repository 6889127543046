import {
  EDIT_EXAM,
  EDIT_EXAM_SUCCESS,
  EDIT_EXAM_CLEAN,
  EDIT_EXAM_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  exam: undefined,
}

const ExamEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_EXAM:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_EXAM_SUCCESS:
      state = {
        ...state,
        exam: action.payload.exam,
        loading: false,
        done: true,
      }
      break
    case EDIT_EXAM_CLEAN:
      state = initialState
      break
    case EDIT_EXAM_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ExamEdit
