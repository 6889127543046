import React, { useState } from "react"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import PropTypes from "prop-types"
import { Modal, Row, Col } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const ImageViewerModal = props => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  toastr.options = {
    positionClass: "toast-bottom-center",
    closeButton: true,
    debug: false,
    progressBar: false,
    preventDuplicates: true,
    newestOnTop: true,
  }

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size={"lg"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {props.title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setPageNumber(1)
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <Row className="pt-3">
          <Col>
            <img src={props.path} alt={props.title} className="img-fluid" />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col className="d-flex justify-content-end text-right">
            {!props.readOnly && (
              <button
                className={`btn btn-light`}
                onClick={() => {
                  toastr.success(
                    "La ruta del archivo fue copiada al portapapeles",
                    "Ruta copiada"
                  )
                  navigator.clipboard.writeText(props.path)
                }}
                type="button"
              >
                <i className="fa fa-clipboard"></i>
              </button>
            )}
            <span>&nbsp;&nbsp;&nbsp;</span>
            <a
              className={`btn btn-light`}
              href={props.path}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-file-image"></i>
            </a>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

ImageViewerModal.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  readOnly: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default ImageViewerModal
