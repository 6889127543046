/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_QUIZZ_ANSWER } from "./actionTypes"
import { apiError, editQuizzAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  updateQuizzAnswer,
  updateQuizzAnswerNewApi,
} from "../../../helpers/backend_helper"

function* update(request) {
  try {
    const response = yield call(updateQuizzAnswerNewApi, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(editQuizzAnswerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* quizzAnswerEditSaga() {
  yield takeEvery(EDIT_QUIZZ_ANSWER, update)
}

export default quizzAnswerEditSaga
