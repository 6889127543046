/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_KNOWLEDGE_CATEGORY } from "./actionTypes"
import { apiError, addKnowledgeCategorySuccess } from "./actions"

//Include Both Helper File with needed methods
import { addKnowledgeCategoryNewApi } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addKnowledgeCategoryNewApi, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (response.message) throw new Error(response.message)
    yield put(addKnowledgeCategorySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* knowledgeCategoryAddSaga() {
  yield takeEvery(ADD_KNOWLEDGE_CATEGORY, add)
}

export default knowledgeCategoryAddSaga
