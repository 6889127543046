/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_POLL_ANSWER } from "./actionTypes"
import { addPollAnswerError, addPollAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { insertMassivePollAnswer } from "../../../helpers/backend_helper"

function* add(request) {
  try {
    const response = yield call(insertMassivePollAnswer, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(addPollAnswerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(addPollAnswerError(error.message))
  }
}

function* pollAnswerAddSaga() {
  yield takeEvery(ADD_POLL_ANSWER, add)
}

export default pollAnswerAddSaga
