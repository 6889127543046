import {
  LIST_QUIZZ,
  LIST_QUIZZ_SUCCESS,
  LIST_QUIZZ_CLEAN,
  LIST_QUIZZ_ERROR,
} from "./actionTypes"

export const listQuizz = (lessonId, userId) => ({
  type: LIST_QUIZZ,
  payload: { lessonId, userId },
})

export const listQuizzSuccess = quizz => {
  return {
    type: LIST_QUIZZ_SUCCESS,
    payload: { quizz },
  }
}

export const listQuizzClean = () => {
  return {
    type: LIST_QUIZZ_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_QUIZZ_ERROR,
    payload: error,
  }
}
