/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_QUIZZ_ANSWERS } from "./actionTypes"
import { listQuizzAnswersError, listQuizzAnswersSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getQuizzAnswerNewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(getQuizzAnswerNewApi, {
      quizzId: request.payload.quizzId,
      userId: request.payload.userId,
    })
    if (response == undefined) throw new Error("API error")
    yield put(listQuizzAnswersSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(listQuizzAnswersError(error.message))
  }
}

function* quizzAnswersListSaga() {
  yield takeEvery(LIST_QUIZZ_ANSWERS, list)
}

export default quizzAnswersListSaga
