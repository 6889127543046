import {
  EDIT_COURSE,
  EDIT_COURSE_CLEAN,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_ERROR,
  EDIT_COURSE_STATUS,
  EDIT_COURSE_STATUS_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  course: undefined,
}

const CourseEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_COURSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_COURSE_STATUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_COURSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        course: action.payload.course,
      }
      break
    case EDIT_COURSE_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        course: action.payload.course,
      }
      break
    case EDIT_COURSE_CLEAN:
      state = initialState
      break
    case EDIT_COURSE_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CourseEdit
