import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { numberWithCommas } from "common/strings"
import "../../assets/scss/datatables.scss"

const Report6ListTable = props => {
  const report = props.report
  const loading = props.loading
  const total = report.internal + report.external

  return (
    <table className="table table-striped">
      <thead className="alert-primary">
        <tr>
          <th>Nombre</th>
          <th>Cantidad</th>
          <th>Porcentaje</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Usuarios internos</td>
          <td align="center" className="text-center">
            {report.internal}
          </td>
          <td align="center" className="text-center">
            {Math.round((report.internal / total) * 100 * 10) / 10}%
          </td>
        </tr>
        <tr>
          <td>Usuarios externos</td>
          <td align="center" className="text-center">
            {report.external}
          </td>
          <td align="center" className="text-center">
            {Math.round((report.external / total) * 100 * 10) / 10}%
          </td>
        </tr>
        <tr>
          <td>Total usuarios</td>
          <td align="center" className="text-center">
            {total}
          </td>
          <td align="center" className="text-center">
            100%
          </td>
        </tr>
      </tbody>
    </table>
  )
}

Report6ListTable.propTypes = {
  report: PropTypes.array,
  loading: PropTypes.bool,
}

export default Report6ListTable
