import {
  EDIT_EXAM,
  EDIT_EXAM_SUCCESS,
  EDIT_EXAM_CLEAN,
  EDIT_EXAM_ERROR,
} from "./actionTypes"

export const editExam = form => ({
  type: EDIT_EXAM,
  payload: { form },
})

export const editExamSuccess = exam => {
  return {
    type: EDIT_EXAM_SUCCESS,
    payload: { exam },
  }
}

export const editExamClean = () => {
  return {
    type: EDIT_EXAM_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_EXAM_ERROR,
    payload: error,
  }
}
