import {
  EDIT_POLL,
  EDIT_POLL_SUCCESS,
  EDIT_POLL_CLEAN,
  EDIT_POLL_ERROR,
} from "./actionTypes"

export const editPoll = form => ({
  type: EDIT_POLL,
  payload: { form },
})

export const editPollSuccess = poll => {
  return {
    type: EDIT_POLL_SUCCESS,
    payload: { poll },
  }
}

export const editPollClean = () => {
  return {
    type: EDIT_POLL_CLEAN,
    payload: {},
  }
}

export const editPollError = error => {
  return {
    type: EDIT_POLL_ERROR,
    payload: error,
  }
}
