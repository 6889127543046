import { GET_LEVELS, GET_LEVELS_SUCCESS, API_ERROR } from "./actionTypes"

export const getLevels = () => ({
  type: GET_LEVELS,
  payload: {},
})

export const getLevelsSuccess = levels => {
  return {
    type: GET_LEVELS_SUCCESS,
    payload: { levels },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
