import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { numberWithCommas } from "common/strings"
import "../../assets/scss/datatables.scss"

const Report5ListTable = props => {
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const report = props.report
  const loading = props.loading

  const cursos = []

  report.map((element, i) => {
    element.courses.map((course, j) => {
      if (
        cursos.filter(object => object.clave_curso === course.clave_curso)
          .length < 1
      ) {
        cursos.push(course)
      }
    })
  })

  const columns = [
    {
      dataField: "nombre_usuario",
      text: "Nombre",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span>
            {row.nombre_usuario} {row.apellido_usuario}
          </span>
        )
      },
    },
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "nombre_grupo",
      text: "Grupo",
      sort: true,
    },
    ...cursos.map(curso => {
      return {
        dataField: `porcentaje_avance_${curso.clave_curso}`,
        text: `% avance - Curso ${curso.clave_curso}`,
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, row, rowIndex, extraData) => {
          const selected = row.courses.find(
            course => course.clave_curso === curso.clave_curso
          )
          return selected !== undefined
            ? selected.porcentaje_avance === null
              ? 0
              : selected.porcentaje_avance
            : -1
        },
      }
    }),
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: report.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setPage(1)
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "IdCurso", order: "asc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return loading && report === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField="IdCurso"
      columns={columns}
      data={report}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider keyField="IdCurso" columns={columns} data={report}>
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  <p>
                    <span>
                      Mostrando{" "}
                      <strong>{numberWithCommas(report.length)}</strong>{" "}
                      elementos
                    </span>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"IdCurso"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                    {report?.length <= 0 && (
                      <Row>
                        <Col md={12} className="text-center">
                          -- No hay resultados --
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                </Col>
              </Row>

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}

Report5ListTable.propTypes = {
  report: PropTypes.array,
  loading: PropTypes.bool,
}

export default Report5ListTable
