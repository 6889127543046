import {
  RESET_GROUP_USER,
  RESET_GROUP_USER_SUCCESS,
  RESET_GROUP_USER_CLEAN,
  RESET_GROUP_USER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  groupUser: {},
}

const GroupUserReset = (state = initialState, action) => {
  switch (action.type) {
    case RESET_GROUP_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESET_GROUP_USER_SUCCESS:
      state = {
        ...state,
        groupUser: action.payload.groupUser,
        loading: false,
        done: true,
      }
      break
    case RESET_GROUP_USER_CLEAN:
      state = initialState
      break
    case RESET_GROUP_USER_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupUserReset
