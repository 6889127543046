/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_SHOPPING_AREA } from "./actionTypes"
import { apiError, editShoppingAreaSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateShoppingAreaNewApi } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateShoppingAreaNewApi, action.payload.form)
    yield put(editShoppingAreaSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* shoppingAreaEditSaga() {
  yield takeEvery(EDIT_SHOPPING_AREA, edit)
}

export default shoppingAreaEditSaga
