/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_GROUP_COURSE } from "./actionTypes"
import { apiError, addGroupCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import { insertGroupCourse } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(insertGroupCourse, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al agregar el curso")
    yield put(addGroupCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* groupCourseAddSaga() {
  yield takeEvery(ADD_GROUP_COURSE, add)
}

export default groupCourseAddSaga
