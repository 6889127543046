import PropTypes from "prop-types"
import React, { useState, useEffect, Fragment } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect, useSelector } from "react-redux"
import { toggleLeftmenu } from "../../store/actions"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [courses, setcourses] = useState(false)
  const [groups, setgroups] = useState(false)
  const [utility, setutility] = useState(false)
  const [gamification, setgamification] = useState(false)
  const [analytics, setanalytics] = useState(false)
  const [facebook, setfacebook] = useState(false)
  const [youtube, setyoutube] = useState(false)
  const [instagram, setinstagram] = useState(false)
  const [website, setwebsite] = useState(false)
  const [profile, setprofile] = useState(false)

  const { whoIAm } = useSelector(state => ({
    whoIAm: state.Profile.user,
  }))

  const idRol = whoIAm?.profileId ?? 0

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul !== null ? ul.getElementsByTagName("a") : []
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
      const splitPathname = props.location.pathname.split("/")
      if (splitPathname.length > 1) {
        if (items[i].pathname.includes(`/${splitPathname[1]}`)) {
          matchingMenuItem = items[i]
          break
        }
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const getSocialNetworks = () => (
    <Fragment>
      <li className="nav-item">
        <div className="nav-item-separator"></div>
      </li>
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle arrow-none"
          draggable="false"
          to="#"
          onClick={e => {
            setfacebook(!facebook)
          }}
        >
          <span className="d-none d-lg-block">
            <i className="fab fa-facebook"></i>
          </span>
          <span className="d-lg-none">
            <i className="fab fa-facebook"></i> Facebook
          </span>
        </Link>
        <div
          className={classname("dropdown-menu", {
            show: facebook,
          })}
        >
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.facebook.com/ResistolMexico"
          >
            Resistol
          </a>
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.facebook.com/PrittMX"
          >
            Pritt
          </a>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle arrow-none"
          draggable="false"
          to="#"
          onClick={e => {
            setyoutube(!youtube)
          }}
        >
          <span className="d-none d-lg-block">
            <i className="fab fa-youtube"></i>
          </span>
          <span className="d-lg-none">
            <i className="fab fa-youtube"></i> YouTube
          </span>
        </Link>
        <div
          className={classname("dropdown-menu", {
            show: youtube,
          })}
        >
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.youtube.com/channel/UC9erl2KYR2dsVChc_3kUWFw/featured"
          >
            ACC Henkel MX
          </a>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle arrow-none"
          draggable="false"
          to="#"
          onClick={e => {
            setinstagram(!instagram)
          }}
        >
          <span className="d-none d-lg-block">
            <i className="fab fa-instagram"></i>
          </span>
          <span className="d-lg-none">
            <i className="fab fa-instagram"></i> Instagram
          </span>
        </Link>
        <div
          className={classname("dropdown-menu", {
            show: instagram,
          })}
        >
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.facebook.com"
          >
            Resistol
          </a>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle arrow-none"
          draggable="false"
          to="#"
          onClick={e => {
            setwebsite(!website)
          }}
        >
          <span className="d-none d-lg-block">
            <i className="fa fa-globe"></i>
          </span>
          <span className="d-lg-none">
            <i className="fa fa-globe"></i> Sitio web
          </span>
        </Link>
        <div
          className={classname("dropdown-menu", {
            show: website,
          })}
        >
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.resistol.com.mx/es.html"
          >
            Resistol
          </a>
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.sista-selladores.com/es.html"
          >
            Sista
          </a>
          <a
            className="dropdown-item"
            draggable="false"
            target="_blank"
            rel="noreferrer"
            onClick={e => {
              props.toggleLeftmenu(false)
            }}
            href="https://www.prittworld.com.mx/es.html"
          >
            Pritt
          </a>
        </div>
      </li>
    </Fragment>
  )

  return (
    <React.Fragment>
      <div className="topnav main-celf-color">
        <div className="container-fluid container-menu">
          {idRol !== 0 && (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              {idRol === 8 ? (
                <Collapse
                  isOpen={props.leftMenu}
                  className="navbar-collapse"
                  id="topnav-menu-content"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setdashboard(!dashboard)
                        }}
                        to="/dashboard"
                        draggable="false"
                      >
                        <i className="fa fa-home me-2"></i>
                        {props.t("Home")}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setcourses(!courses)
                        }}
                        to="/my-courses"
                        draggable="false"
                      >
                        <i className="fa fa-book"></i> Mis cursos
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/gammification"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setgamification(!gamification)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                        draggable="false"
                      >
                        <i className="fa fa-certificate"></i> Aprende y gana
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setcourses(!courses)
                        }}
                        to="#"
                      >
                        <i className="fa fa-question"></i> Centro de ayuda
                      </Link>
                    </li> */}
                    {getSocialNetworks()}
                  </ul>
                </Collapse>
              ) : (
                <Collapse
                  isOpen={props.leftMenu}
                  className="navbar-collapse"
                  id="topnav-menu-content"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setdashboard(!dashboard)
                        }}
                        to="/dashboard"
                        draggable="false"
                      >
                        <i className="fa fa-home me-2"></i>
                        {props.t("Home")}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setcourses(!courses)
                        }}
                        to="/my-courses"
                        draggable="false"
                      >
                        <i className="fa fa-book"></i> Mis cursos
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setgroups(!groups)
                        }}
                        to="/groups"
                        draggable="false"
                      >
                        <i className="fa fa-vector-square me-2"></i>
                        Grupos
                      </Link>
                    </li>
                    {idRol !== 8 && (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle arrow-none"
                          onClick={e => {
                            setanalytics(!analytics)
                          }}
                          draggable="false"
                        >
                          <i className="fa fa-chart-line me-2"></i>
                          Analítica <div className="arrow-down"></div>
                        </a>
                        <div
                          className={classname("dropdown-menu", {
                            show: analytics,
                          })}
                        >
                          <Link
                            to="/reports/report1"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Avance por
                            cliente
                          </Link>
                          <Link
                            to="/reports/report2"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Progreso de
                            usuarios por curso
                          </Link>
                          <Link
                            to="/reports/report3"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Cursos/Usuario
                          </Link>
                          <Link
                            to="/reports/report4"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i>{" "}
                            Avance/Aprobación
                          </Link>
                          <Link
                            to="/reports/report5"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Avance por
                            curso
                          </Link>
                          <Link
                            to="/reports/report6"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Split/Usuario
                          </Link>
                          <Link
                            to="/reports/reportA"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Cursos/Perfil
                          </Link>
                          <Link
                            to="/reports/reportB"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Tabla de
                            aprobación
                          </Link>
                          <Link
                            to="/reports/report9"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Avance Global
                          </Link>
                          <Link
                            to="/reports/report10"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-chart-line"></i> Participación
                          </Link>
                          {(idRol === 1 || idRol === 2) && (
                            <Link
                              to="/reports/reportSurvey"
                              className="dropdown-item"
                              draggable="false"
                            >
                              <i className="fa fa-chart-line"></i> Reporte
                              encuesta
                            </Link>
                          )}
                        </div>
                      </li>
                    )}
                    {idRol !== 3 && idRol !== 4 && (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle arrow-none"
                          onClick={e => {
                            setutility(!utility)
                          }}
                          draggable="false"
                        >
                          <i className="fa fa-cogs me-2"></i>
                          Configurar <div className="arrow-down"></div>
                        </a>
                        <div
                          className={classname("dropdown-menu", {
                            show: utility,
                          })}
                        >
                          {(idRol === 1 || idRol === 2) && (
                            <Link
                              to="/courses"
                              onClick={e => {
                                props.toggleLeftmenu(false)
                              }}
                              className="dropdown-item"
                              draggable="false"
                            >
                              <i className="fa fa-book"></i> Cursos
                            </Link>
                          )}
                          {(idRol === 1 || idRol === 2) && (
                            <Link
                              to="/customers"
                              onClick={e => {
                                props.toggleLeftmenu(false)
                              }}
                              className="dropdown-item"
                              draggable="false"
                            >
                              <i className="fa fa-briefcase"></i> Clientes
                            </Link>
                          )}
                          <Link
                            to="/users"
                            onClick={e => {
                              props.toggleLeftmenu(false)
                            }}
                            className="dropdown-item"
                            draggable="false"
                          >
                            <i className="fa fa-users"></i> Usuarios
                          </Link>
                          {(idRol === 1 || idRol === 2) && (
                            <Link
                              to="/catalog"
                              onClick={e => {
                                props.toggleLeftmenu(false)
                              }}
                              className="dropdown-item"
                              draggable="false"
                            >
                              <i className="fa fa-th-list"></i> Catálogos
                            </Link>
                          )}
                          {(idRol === 1 || idRol === 2) && (
                            <Link
                              to="/carousel"
                              onClick={e => {
                                props.toggleLeftmenu(false)
                              }}
                              className="dropdown-item"
                              draggable="false"
                            >
                              <i className="fa fa-compress"></i> Carrusel
                            </Link>
                          )}
                          {/* <Link to="#" className="dropdown-item">
                          <i className="fa fa-question"></i> Centro de ayuda
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <i className="fa fa-clipboard-list"></i> Bitácora
                        </Link> */}
                        </div>
                      </li>
                    )}
                    <li className="nav-item dropdown">
                      <Link
                        to="/gammification"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setgamification(!gamification)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                        draggable="false"
                      >
                        <i className="fa fa-certificate"></i> Aprende y gana
                      </Link>
                    </li>
                    <li className="nav-item dropdown d-lg-none d-xl-none">
                      <Link
                        to="/profile"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setgamification(!gamification)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                        draggable="false"
                      >
                        <i className="fa fa-user"></i> Perfil
                      </Link>
                    </li>
                    <li className="nav-item dropdown d-lg-none d-xl-none">
                      <Link
                        to="/logout"
                        onClick={e => {
                          props.toggleLeftmenu(false)
                          setgamification(!gamification)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                        draggable="false"
                      >
                        <i className="fa fa-door-closed"></i> Cerrar sesión
                      </Link>
                    </li>
                    {/*<li className="nav-item">
                      <Link
                        to="#"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fa fa-question"></i> Ayuda
                      </Link>
                      </li>*/}
                    {getSocialNetworks()}
                  </ul>
                </Collapse>
              )}
            </nav>
          )}
        </div>
      </div>
      <style jsx="true">{`
        .container-menu {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .nav-item-separator {
          border: 1px solid #fff;
          height: 60%;
          width: 1px;
          background: rgba(255, 255, 255, 0.6);
          margin: 10px 5px;
        }
        .nav-social {
          position: absolute;
          right: 0;
        }
        .nav-social i {
          font-size: 20px !important;
        }
        .nav-social-1 {
          right: 0 !important;
        }
        .nav-social-2 {
          right: 36px !important;
        }
        .nav-social-3 {
          right: 72px !important;
        }
        @media (max-width: 991px) {
          .nav-item-separator {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar))
)
