import CarouselListTable from "components/Carousel/CarouselListTable"
import { Fragment } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"

const CarouselList = props => {
  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Carrusel | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Gestión del carrusel</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "carousel", title: "Carrusel" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CarouselListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default CarouselList
