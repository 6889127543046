/* ADD TRIES */
export const START_EXAM = "START_EXAM"
export const START_EXAM_SUCCESS = "START_EXAM_SUCCESS"
export const START_EXAM_CLEAN = "START_EXAM_CLEAN"
export const START_EXAM_ERROR = "START_EXAM_ERROR"

export const FINISH_EXAM = "FINISH_EXAM"
export const FINISH_EXAM_SUCCESS = "FINISH_EXAM_SUCCESS"
export const FINISH_EXAM_CLEAN = "FINISH_EXAM_CLEAN"
export const FINISH_EXAM_ERROR = "FINISH_EXAM_ERROR"

export const GENERATE_SCORE = "GENERATE_SCORE"
export const GENERATE_SCORE_SUCCESS = "GENERATE_SCORE_SUCCESS"
export const GENERATE_SCORE_CLEAN = "GENERATE_SCORE_CLEAN"
export const GENERATE_SCORE_ERROR = "GENERATE_SCORE_ERROR"

export const ANSWER_QUESTION_EXAM = "ANSWER_QUESTION_EXAM"
export const ANSWER_QUESTION_EXAM_SUCCESS = "ANSWER_QUESTION_EXAM_SUCCESS"
export const ANSWER_QUESTION_EXAM_CLEAN = "ANSWER_QUESTION_EXAM_CLEAN"
export const ANSWER_QUESTION_EXAM_ERROR = "ANSWER_QUESTION_EXAM_ERROR"
