import {
  ADD_NOTE,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_CLEAN,
  ADD_NOTE_ERROR,
} from "./actionTypes"

export const addNote = form => ({
  type: ADD_NOTE,
  payload: { form },
})

export const addNoteSuccess = note => {
  return {
    type: ADD_NOTE_SUCCESS,
    payload: { note },
  }
}

export const addNoteClean = () => {
  return {
    type: ADD_NOTE_CLEAN,
    payload: {},
  }
}

export const addNoteError = error => {
  return {
    type: ADD_NOTE_ERROR,
    payload: error,
  }
}
