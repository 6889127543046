import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import QRCode from "qrcode"
import { PropTypes } from "prop-types"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
} from "reactstrap"
import academiaFester from "assets/images/logo_proacademy_header.png"
import moment from "moment"
import { createHash } from "crypto"

import academyLogo from "../../assets/images/logo_proacademy_header.png"
import resistolLogo from "../../assets/images/logo_resistol.png"
import prittLogo from "../../assets/images/logo_pritt.png"
import sistaLogo from "../../assets/images/logo_sista.png"
import tangitLogo from "../../assets/images/logo_tangit.png"
import plecaAcademy from "../../assets/images/pleca_academy.png"
import medal from "../../assets/images/green_certified_medall.png"
import { useEffect, useState } from "react"

// eslint-disable-next-line no-undef
const APP_URL = process.env.REACT_APP_URL

const ModalCertificatePdf = props => {
  const styles = StyleSheet.create({
    page: {},
    section: {
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "start",
      paddingLeft: "100px",
      paddingRight: "50px",
    },
  })

  const [QRCodeString, setQRCodeString] = useState("")
  const [url, setUrl] = useState("")

  const sello =
    props.exam === undefined
      ? ""
      : createHash("sha256")
          .update(`||${props.exam.examId}|${props.course.courseId}||`)
          .digest("hex")

  useEffect(() => {
    if (props.exam === undefined || sello === "") return
    setUrl(
      `${APP_URL}certificate-validation/${props.exam.examId}/${props.course.courseId}/${props.user.userId}`
    )
  }, [props.exam, sello])

  useEffect(() => {
    if (url === "") return
    QRCode.toDataURL(`${url}/${sello}`).then(data => {
      setQRCodeString(data)
    })
  }, [url])

  const getSelloMultirow = text => {
    const fixedSize = 16
    const length = text.length
    const rows = Math.ceil(length / fixedSize)
    let multirow = ""
    for (let i = 0; i < rows; i++) {
      multirow += text.substring(i * fixedSize, (i + 1) * fixedSize) + "\n"
    }

    return multirow
  }

  return (
    <Modal isOpen={props.open} backdrop={"static"} size="xl">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Ver constancia
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onClose()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody>
        <PDFViewer width={"100%"} height={"560px"}>
          <Document>
            <Page size="A4" style={styles.page} orientation={"landscape"}>
              <View
                style={{
                  ...styles.section,
                }}
              >
                <div
                  style={{
                    height: "900px",
                    backgroundColor: "#ff000f",
                    width: "100px",
                    position: "absolute",
                    top: -20,
                    left: 0,
                  }}
                >
                  <Image
                    src={QRCodeString}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "10px",
                      marginTop: "450px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "9px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "80px",
                      marginTop: "4px",
                      color: "#cccccc",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                    }}
                  >
                    Sello digital único: {getSelloMultirow(sello)}
                  </Text>
                </div>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Image
                    src={academiaFester}
                    style={{
                      height: "60px",
                      width: "260px",
                      marginTop: "20px",
                      marginLeft: "20px",
                    }}
                  />
                  <Image
                    src={medal}
                    style={{
                      width: "100px",
                      position: "absolute",
                      right: 0,
                      top: "20px",
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: "16px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  OTORGA LA PRESENTE
                </Text>
                <Text
                  style={{
                    fontSize: "24px",
                    marginTop: "12px",
                    marginLeft: "20px",
                    fontWeight: "light",
                  }}
                >
                  CONSTANCIA DE APROBACIÓN A
                </Text>
                <Text
                  style={{
                    fontSize: "24px",
                    marginTop: "20px",
                    marginLeft: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {props.user.firstName} {props.user.lastName}
                </Text>
                <div
                  style={{
                    height: "1px",
                    border: "1px solid #000",
                    marginLeft: "20px",
                    marginRight: "240px",
                    marginTop: "12px",
                  }}
                ></div>
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: "24px",
                    marginLeft: "20px",
                  }}
                >
                  POR CONCLUIR SATISFACTORIAMENTE EL CURSO EN LÍNEA EN NIVEL{" "}
                  {props.course.courseLevelId == 1
                    ? "BÁSICO"
                    : props.course.courseLevelId == 2
                    ? "INTERMEDIO"
                    : "AVANZADO"}{" "}
                  DE:
                </Text>
                <Text
                  style={{
                    fontSize: "20px",
                    marginTop: "24px",
                    marginLeft: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {props.course.courseName}
                </Text>
                <div
                  style={{
                    height: "1px",
                    border: "1px solid #000",
                    marginLeft: "20px",
                    marginRight: "240px",
                    marginTop: "12px",
                  }}
                ></div>
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: "24px",
                    marginLeft: "20px",
                    color: "rgb(0, 0, 0)",
                    fontWeight: "normal",
                  }}
                >
                  PRESENTANDO UNA CALIFICACIÓN DE{" "}
                  <span>
                    {props.course === undefined
                      ? 0
                      : props.course.courseParticipant.result}
                  </span>
                </Text>
                {/*<Text
                  style={{
                    fontSize: "18px",
                    marginTop: "48px",
                    marginLeft: "20px",
                  }}
                >
                  Efectuado el día{" "}
                  {props.exam === undefined
                    ? ""
                    : moment(props.exam.finishDate).format(
                        "DD [de] MMMM [de] YYYY"
                      )}
                </Text>
                    */}
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "start",
                  position: "absolute",
                  bottom: 140,
                  right: 20,
                  left: 100,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    marginLeft: "20px",
                  }}
                >
                  <Image
                    src={resistolLogo}
                    style={{
                      height: "75px",
                      width: "55px",
                      marginTop: "20px",
                    }}
                  />
                  <span style={{ marginLeft: "20px" }}></span>
                  <Image
                    src={sistaLogo}
                    style={{
                      height: "75px",
                      width: "55px",
                      marginTop: "20px",
                    }}
                  />
                  <span style={{ marginLeft: "20px" }}></span>
                  <Image
                    src={tangitLogo}
                    style={{
                      height: "75px",
                      width: "55px",
                      marginTop: "20px",
                    }}
                  />
                  <span style={{ marginLeft: "20px" }}></span>
                  <Image
                    src={prittLogo}
                    style={{
                      height: "75px",
                      width: "55px",
                      marginTop: "20px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "12px",
                      marginTop: "48px",
                      marginLeft: "20px",
                      width: "400px",
                      textAlign: "right",
                    }}
                  >
                    Fecha de término:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {props.exam === undefined
                        ? ""
                        : moment(props.exam.finishDate).format(
                            "DD / MMMM / YYYY"
                          )}
                    </span>
                  </Text>
                </View>
              </View>
              <div
                style={{
                  height: "150px",
                  backgroundColor: "rgb(244, 244, 244)",
                  width: "100%",
                  position: "absolute",
                  bottom: "-20px",
                  left: "100px",
                }}
              >
                <Image src={plecaAcademy} style={{ width: "100%" }} />
                <Text
                  style={{
                    fontSize: "9px",
                    color: "#cccccc",
                    bottom: "32px",
                    right: "110px",
                    position: "relative",
                    textAlign: "right",
                  }}
                >
                  Para verificar la autenticidad de este documento escanea el
                  código QR o dirígete a
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    color: "#cccccc",
                    bottom: "30px",
                    position: "relative",
                    right: "110px",
                    textAlign: "right",
                  }}
                >
                  {url}
                </Text>
              </div>
            </Page>
          </Document>
        </PDFViewer>
      </ModalBody>
    </Modal>
  )
}

ModalCertificatePdf.propTypes = {
  course: PropTypes.object,
  exam: PropTypes.object,
  open: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default ModalCertificatePdf
