import React, { Fragment, useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  Modal,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  Media,
  Card,
  CardBody,
  Table,
} from "reactstrap"
import {
  getCourse,
  getLessons,
  getLevels,
  getUsers,
  getBlocks,
} from "store/actions"

const CoursePreviewModal = props => {
  const courseId = props.course

  const [position, setPosition] = useState(0)

  const ref = useRef(null)

  const dispatch = useDispatch()

  const { course, users, levels, lessons, blocks, blocksLoading } = useSelector(
    store => ({
      blocks: store.BlocksList.blocks,
      blocksLoading: store.BlocksList.loading,
      course: store.CourseGet.course,
      users: store.UsersList.users,
      levels: store.LevelsList.levels,
      lessons: store.LessonsList.lessons,
    })
  )

  useEffect(() => {
    if (courseId !== undefined) {
      dispatch(getCourse(courseId))
      dispatch(getUsers())
      dispatch(getLevels())
      dispatch(getLessons(courseId))
    }
  }, [courseId])

  useEffect(() => {
    if (position - 2 >= 0) dispatch(getBlocks(lessons[position - 2].lessonId))
    if (ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
      })
  }, [position])

  const resposibles = []
  let level = ""

  if (
    course !== undefined &&
    course.responsibles !== undefined &&
    course.courseLevelId !== undefined
  ) {
    course.responsibles.forEach(respCourse => {
      const user = users.find(user => user.idUsuario === respCourse.userId)
      if (user !== undefined)
        resposibles.push(`${user.firstName} ${user.lastName}`)
    })

    levels.forEach(lvl => {
      if (lvl.levelId === course.courseLevelId) level = lvl.levelName
    })
  }

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size={"xl"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Vista previa del curso
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setPosition(0)
            props.onClose()
          }}
          aria-label="Close"
        ></button>
      </div>
      {course !== undefined && (
        <div className="modal-body">
          <Fragment>
            <div
              style={{ position: "absolute", top: -50, left: 0, right: 0 }}
              ref={ref}
            ></div>
            <Row>
              <Col md="3">
                <ul className="verti-timeline list-unstyled">
                  <li
                    key={`lesson-${0}`}
                    className="event-list"
                    style={{ cursor: "pointer" }}
                    onClick={() => setPosition(0)}
                  >
                    <div className="event-timeline-dot">
                      <i
                        className={`fa fa-circle font-size-18 ${
                          0 == position
                            ? "alt-celf-color"
                            : "alt-celf-color-with-opacity"
                        }`}
                      />
                    </div>
                    <Media>
                      <Media body>
                        <h3
                          className={
                            0 == position
                              ? "alt-celf-color"
                              : "alt-celf-color-with-opacity"
                          }
                        >
                          Portada
                        </h3>
                      </Media>
                    </Media>
                  </li>
                  <li
                    key={`lesson-${1}`}
                    className="event-list"
                    style={{ cursor: "pointer" }}
                    onClick={() => setPosition(1)}
                  >
                    <div className="event-timeline-dot">
                      <i
                        className={`fa fa-circle font-size-18 ${
                          1 == position
                            ? "alt-celf-color"
                            : 1 < position
                            ? "alt-celf-color-with-opacity"
                            : "with-opacity"
                        }`}
                      />
                    </div>
                    <Media>
                      <Media body>
                        <h3
                          className={
                            1 == position
                              ? "alt-celf-color"
                              : 1 < position
                              ? "alt-celf-color-with-opacity"
                              : "with-opacity"
                          }
                        >
                          Acerca del curso
                        </h3>
                      </Media>
                    </Media>
                  </li>
                  {lessons.map((lesson, index) => (
                    <li
                      key={`lesson-${index + 2}`}
                      className="event-list"
                      style={{ cursor: "pointer" }}
                      onClick={() => setPosition(index + 2)}
                    >
                      <div className="event-timeline-dot">
                        <i
                          className={`fa fa-circle font-size-18 ${
                            index == position - 2
                              ? "alt-celf-color"
                              : index <= position - 2
                              ? "alt-celf-color-with-opacity"
                              : "with-opacity"
                          }`}
                        />
                      </div>
                      <Media>
                        <Media body>
                          <h3
                            className={
                              index == position - 2
                                ? "alt-celf-color"
                                : index <= position - 2
                                ? "alt-celf-color-with-opacity"
                                : "with-opacity"
                            }
                          >
                            {lesson.title}
                          </h3>
                          <span>Lección {index + 1}</span>
                        </Media>
                      </Media>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md="9">
                {position === 0 && (
                  <Row>
                    <Col md={12}>
                      <h3>Portada</h3>
                    </Col>
                    <Card>
                      <CardBody>
                        <img
                          className="w-100"
                          src={course.backgroundImage}
                          alt={course.courseName}
                        />
                      </CardBody>
                    </Card>
                  </Row>
                )}
                {position === 1 && (
                  <Row>
                    <Col md={12}>
                      <h3>Acerca de este curso</h3>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <Table className="table table-striped">
                            <tbody>
                              <tr>
                                <td>Categoría</td>
                                <td>
                                  {course.knowledgeCategories
                                    .map(category =>
                                      category.knowledgeCategoryName.trim()
                                    )
                                    .join(", ")}
                                </td>
                              </tr>
                              <tr>
                                <td>Clave curso</td>
                                <td>{course.claveCurso}</td>
                              </tr>
                              <tr>
                                <td>Curso previo</td>
                                <td>
                                  {course.previousCourses.length <= 0
                                    ? "Ninguno"
                                    : course.previousCourses
                                        .map(course => course.courseName.trim())
                                        .join(", ")}
                                </td>
                              </tr>
                              <tr>
                                <td>Responsable</td>
                                <td>{resposibles.join(", ")}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <Table className="table table-striped">
                            <tbody>
                              <tr>
                                <td>Nivel</td>
                                <td>{level}</td>
                              </tr>
                              <tr>
                                <td>Dedicación</td>
                                <td>{course.dedicationTime} minutos</td>
                              </tr>
                              <tr>
                                <td>Mínimo aprobatorio:</td>
                                <td>{course.minApproval} %</td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {position > 1 && (
                  <Row className=" border pt-3 pb-3">
                    <Col>
                      {blocks !== undefined &&
                        blocks.map((block, index) => {
                          if (block.type == "text") {
                            return (
                              <Row
                                key={`block-${index}`}
                                className="border pt-3 pb-3"
                              >
                                <Col>
                                  <h3>{lessons[position - 2].title}</h3>
                                  <hr />
                                  {blocksLoading && (
                                    <div style={{ height: "320px" }}>
                                      <div className="d-flex justify-content-center align-items-center h-100">
                                        <span style={{ fontSize: "24px" }}>
                                          <i className="fa fa-spinner fa-spin"></i>{" "}
                                          Cargando contenido...
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {blocks !== undefined &&
                                    !blocksLoading &&
                                    blocks.map((block, index2) => {
                                      if (block.type == "text") {
                                        return (
                                          <Card
                                            key={`block-card-${index}-${index2}`}
                                            className="card"
                                          >
                                            <CardBody>
                                              <div
                                                className="table-responsive"
                                                dangerouslySetInnerHTML={{
                                                  __html: block.value,
                                                }}
                                              ></div>
                                            </CardBody>
                                          </Card>
                                        )
                                      }
                                    })}
                                </Col>
                              </Row>
                            )
                          }
                          if (block.tyoe == "youtube") {
                            var video_id = block.value.split("v=")[1]
                            var ampersandPosition = video_id.indexOf("&")
                            if (ampersandPosition != -1) {
                              video_id = video_id.substring(
                                0,
                                ampersandPosition
                              )
                            }
                            return (
                              <iframe
                                key={`block-${index}`}
                                width="100%"
                                height="480"
                                src={`https://www.youtube.com/embed/${video_id}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                              />
                            )
                          }
                        })}
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => {
                        if (position > 0) setPosition(position - 1)
                      }}
                      className={`btn btn-secondary ${
                        position <= 0 ? "disabled" : ""
                      }`}
                    >
                      <i className="fa fa-chevron-left"></i> Lección anterior
                    </button>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <button
                      type="button"
                      onClick={() => {
                        if (position <= lessons.length)
                          setPosition(position + 1)
                      }}
                      className={`btn btn-secondary ${
                        position > lessons.length ? "disabled" : ""
                      }`}
                    >
                      Lección siguiente <i className="fa fa-chevron-right"></i>
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        </div>
      )}
    </Modal>
  )
}

CoursePreviewModal.propTypes = {
  show: PropTypes.bool,
  course: PropTypes.number,
  onClose: PropTypes.func,
}

export default CoursePreviewModal
