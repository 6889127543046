import {
  GET_POSITIONS,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  positions: [],
}

const PositionsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSITIONS:
      state = {
        ...state,
        loading: true,
        positions: [],
      }
      break
    case GET_POSITIONS_SUCCESS:
      state = {
        ...state,
        positions: action.payload.positions,
        loading: false,
        done: true,
      }
      break
    case GET_POSITIONS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PositionsList
