import { Card, CardBody } from "reactstrap"
import { PropTypes } from "prop-types"

const CourseExamUserIntro = props => {
  return (
    <Card>
      <CardBody>
        <h2 className="alt-celf-color">Examen</h2>
        <hr />
        <h4>Consideraciones</h4>
        <ol className="lista">
          <li>
            La calificación aprobatoria para este curso es de{" "}
            <strong>{props.course?.minApproval ?? 0}</strong>.
          </li>
          <li>
            Es altamente recomendable haber revisado el contenido del curso de
            forma completa.
          </li>
          <li>
            El examen está disponible desde el momento que se abre el curso
            hasta que finaliza, dentro del periodo de fechas del curso, pero una
            vez que lo inicia deberá terminarlo en una sola sesión.
          </li>
          <li>
            Este examen tiene un tiempo de realización aproximado de{" "}
            <strong>{props.exam?.examTime ?? 0}</strong> minutos, en los cuales
            usted deberá finalizar el examen.
            {props.exam?.finishOnTimeout && (
              <span>
                A los <strong>{props.exam?.examTime ?? 0}</strong> minutos de
                haber iniciado el curso se cerrará.
              </span>
            )}
          </li>
          <li>
            El examen cuenta con un número diferente de preguntas que se toman
            de forma aleatoria.
          </li>
          <li>
            Al finalizar, se mostrará un resumen del examen por respuesta,
            indicando cual fue correcta y cual incorrecta, si hubiera
          </li>
          <li>No tiene un límite de oportunidades para aprobar el examen.</li>
          <li>
            Para iniciar el examen, de clic en &quot;Iniciar examen&quot;.
          </li>
        </ol>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            {!props.startExamLoading && (
              <button
                className="btn btn-secondary"
                onClick={() => props.handleStartExam()}
              >
                Iniciar examen
              </button>
            )}
            {props.startExamLoading && (
              <button className="btn btn-secondary disabled">
                Iniciando examen...
              </button>
            )}
          </div>
        </div>
      </CardBody>
      <style jsx="true">{`
        ol.lista li {
          margin-bottom: 8px;
        }
      `}</style>
    </Card>
  )
}

CourseExamUserIntro.propTypes = {
  startExamLoading: PropTypes.bool,
  handleStartExam: PropTypes.func,
  exam: PropTypes.object,
  course: PropTypes.object,
}

export default CourseExamUserIntro
