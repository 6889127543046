/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  GET_COURSES,
  GET_COURSES_BY_USER,
  GET_COURSES_BY_USER_AND_GROUP,
  SEND_COURSE_TRASH,
} from "./actionTypes"
import {
  apiError,
  getCoursesSuccess,
  getCoursesByUserSuccess,
  getCoursesByUserAndGroupSuccess,
  getCoursesByUserAndGroupError,
  sendCourseToTrashSuccess,
  sendCourseToTrashError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCoursesNewApi,
  getCoursesByUser,
  coursesWithGroup,
  courseToTrash,
} from "../../../helpers/backend_helper"

function* courses() {
  try {
    const response = yield call(getCoursesNewApi)
    yield put(getCoursesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* coursesByUser(action) {
  try {
    const response = yield call(getCoursesByUser, action.payload.idUser)
    if (response.message) throw new Error(response.message)
    yield put(getCoursesByUserSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* coursesByUserAndGroup(request) {
  try {
    const response = yield call(coursesWithGroup, {
      groupId: request.payload.groupId,
      userId: request.payload.userId,
    })
    if (response.message) throw new Error(response.message)
    yield put(getCoursesByUserAndGroupSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(getCoursesByUserAndGroupError(error.message))
  }
}

function* trash(request) {
  try {
    const response = yield call(courseToTrash, request.payload)
    if (response.message) throw new Error(response)
    yield put(sendCourseToTrashSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(sendCourseToTrashError(error.message))
  }
}

function* coursesSaga() {
  yield takeEvery(GET_COURSES, courses)
  yield takeEvery(GET_COURSES_BY_USER, coursesByUser)
  yield takeEvery(GET_COURSES_BY_USER_AND_GROUP, coursesByUserAndGroup)
  yield takeEvery(SEND_COURSE_TRASH, trash)
}

export default coursesSaga
