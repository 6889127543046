import {
  EDIT_BUSINESS,
  EDIT_BUSINESS_SUCCESS,
  EDIT_BUSINESS_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const editBusiness = form => ({
  type: EDIT_BUSINESS,
  payload: { form },
})

export const editBusinessSuccess = business => {
  return {
    type: EDIT_BUSINESS_SUCCESS,
    payload: { business },
  }
}

export const editBusinessClean = () => {
  return {
    type: EDIT_BUSINESS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
