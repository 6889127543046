import {
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_CLEAN,
  EDIT_CUSTOMER_ERROR,
} from "./actionTypes"

export const editCustomer = form => ({
  type: EDIT_CUSTOMER,
  payload: { form },
})

export const editCustomerSuccess = course => {
  return {
    type: EDIT_CUSTOMER_SUCCESS,
    payload: { course },
  }
}

export const editCustomerClean = () => {
  return {
    type: EDIT_CUSTOMER_CLEAN,
    payload: {},
  }
}

export const editCustomerError = error => {
  return {
    type: EDIT_CUSTOMER_ERROR,
    payload: error,
  }
}
