import {
  ADD_QUIZZ_ANSWER_USER,
  ADD_QUIZZ_ANSWER_USER_SUCCESS,
  ADD_QUIZZ_ANSWER_USER_CLEAN,
  ADD_QUIZZ_ANSWER_USER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  answerUser: undefined,
}

const QuizzAnswerUserAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUIZZ_ANSWER_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_QUIZZ_ANSWER_USER_SUCCESS:
      state = {
        ...state,
        answerUser: action.payload.answerUser,
        loading: false,
        done: true,
      }
      break
    case ADD_QUIZZ_ANSWER_USER_CLEAN:
      state = initialState
      break
    case ADD_QUIZZ_ANSWER_USER_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        answerUser: undefined,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuizzAnswerUserAdd
