import {
  EDIT_LEVEL,
  EDIT_LEVEL_SUCCESS,
  EDIT_LEVEL_CLEAN,
  EDIT_LEVEL_ERROR,
} from "./actionTypes"

export const editLevel = form => ({
  type: EDIT_LEVEL,
  payload: { form },
})

export const editLevelSuccess = level => {
  return {
    type: EDIT_LEVEL_SUCCESS,
    payload: { level },
  }
}

export const editLevelClean = () => {
  return {
    type: EDIT_LEVEL_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_LEVEL_ERROR,
    payload: error,
  }
}
