/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_BUSINESS } from "./actionTypes"
import { apiError, getBusinessSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getBusiness } from "../../../helpers/backend_helper"

function* get(params) {
  try {
    const response = yield call(getBusiness, params.payload.id)
    yield put(getBusinessSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* businessGetSaga() {
  yield takeEvery(GET_BUSINESS, get)
}

export default businessGetSaga
