import {
  LIST_QUESTIONS,
  LIST_QUESTIONS_SUCCESS,
  LIST_QUESTIONS_CLEAN,
  LIST_QUESTIONS_ERROR,
} from "./actionTypes"

export const listQuestions = idExam => ({
  type: LIST_QUESTIONS,
  payload: { idExam },
})

export const listQuestionsSuccess = questions => {
  return {
    type: LIST_QUESTIONS_SUCCESS,
    payload: { questions },
  }
}

export const listQuestionsClean = () => {
  return {
    type: LIST_QUESTIONS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_QUESTIONS_ERROR,
    payload: error,
  }
}
