/* USERS */
export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_CLEAN = "EDIT_USER_CLEAN"

export const API_ERROR = "API_ERROR"
export const ACTIVATE_DEACTIVATE_USER = "ACTIVATE_DEACTIVATE_USER"
export const ACTIVATE_DEACTIVATE_USER_SUCCESS =
  "ACTIVATE_DEACTIVATE_USER_SUCCESS"
export const ACTIVATE_DEACTIVATE_USER_ERROR = "ACTIVATE_DEACTIVATE_USER_ERROR"
export const ACTIVATE_DEACTIVATE_USER_CLEAN = "ACTIVATE_DEACTIVATE_USER_CLEAN"

export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE"
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS"
export const UPDATE_PROFILE_PICTURE_ERROR = "UPDATE_PROFILE_PICTURE_ERROR"
export const UPDATE_PROFILE_PICTURE_CLEAN = "UPDATE_PROFILE_PICTURE_CLEAN"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR"
export const UPDATE_PASSWORD_CLEAN = "UPDATE_PASSWORD_CLEAN"

export const UPDATE_POSITION = "UPDATE_POSITION"
export const UPDATE_POSITION_SUCCESS = "UPDATE_POSITION_SUCCESS"
export const UPDATE_POSITION_ERROR = "UPDATE_POSITION_ERROR"
export const UPDATE_POSITION_CLEAN = "UPDATE_POSITION_CLEAN"

export const UPDATE_ABOUT_ME = "UPDATE_ABOUT_ME"
export const UPDATE_ABOUT_ME_SUCCESS = "UPDATE_ABOUT_ME_SUCCESS"
export const UPDATE_ABOUT_ME_ERROR = "UPDATE_ABOUT_ME_ERROR"
export const UPDATE_ABOUT_ME_CLEAN = "UPDATE_ABOUT_ME_CLEAN"
