import {
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_CLEAN,
  GET_QUESTION_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  exam: undefined,
}

const QuestionGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_QUESTION_SUCCESS:
      state = {
        ...state,
        question: action.payload.question,
        loading: false,
        done: true,
      }
      break
    case GET_QUESTION_CLEAN:
      state = initialState
      break
    case GET_QUESTION_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuestionGet
