/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_SUPPORT_MATERIAL_TYPE } from "./actionTypes"
import { apiError, editSupportMaterialTypeSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateSupportMaterialTypeNewApi } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(
      updateSupportMaterialTypeNewApi,
      action.payload.form
    )
    if (response.message) throw new Error(response.message)
    else yield put(editSupportMaterialTypeSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* supportMaterialTypeEditSaga() {
  yield takeEvery(EDIT_SUPPORT_MATERIAL_TYPE, edit)
}

export default supportMaterialTypeEditSaga
