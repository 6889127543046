import {
  RESET_GROUP_USER,
  RESET_GROUP_USER_SUCCESS,
  RESET_GROUP_USER_CLEAN,
  RESET_GROUP_USER_ERROR,
} from "./actionTypes"

export const resetGroupUser = form => ({
  type: RESET_GROUP_USER,
  payload: { form },
})

export const resetGroupUserSuccess = groupUser => {
  return {
    type: RESET_GROUP_USER_SUCCESS,
    payload: { groupUser },
  }
}

export const resetGroupUserClean = () => {
  return {
    type: RESET_GROUP_USER_CLEAN,
    payload: {},
  }
}

export const resetGroupUserError = error => {
  return {
    type: RESET_GROUP_USER_ERROR,
    payload: error,
  }
}
