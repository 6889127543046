/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  LIST_SUPPORT_MATERIAL_TYPES,
  LIST_SUPPORT_MATERIAL_TYPES_ALL,
} from "./actionTypes"
import { apiError, listSupportMaterialTypesSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getSupportMaterialTypes,
  getAllSupportMaterialTypes,
} from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(getSupportMaterialTypes, action.payload.idCurso)
    yield put(listSupportMaterialTypesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getAll(action) {
  try {
    const response = yield call(getAllSupportMaterialTypes)
    yield put(listSupportMaterialTypesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* supportMaterialTypesListSaga() {
  yield takeEvery(LIST_SUPPORT_MATERIAL_TYPES, get)
  yield takeEvery(LIST_SUPPORT_MATERIAL_TYPES_ALL, getAll)
}

export default supportMaterialTypesListSaga
