import {
  RESET_GROUP_PARTICIPANT_COURSE,
  RESET_GROUP_PARTICIPANT_COURSE_SUCCESS,
  RESET_GROUP_PARTICIPANT_COURSE_CLEAN,
  RESET_GROUP_PARTICIPANT_COURSE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  groupParticipantCourse: undefined,
}

const GroupParticipantCourseReset = (state = initialState, action) => {
  switch (action.type) {
    case RESET_GROUP_PARTICIPANT_COURSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESET_GROUP_PARTICIPANT_COURSE_SUCCESS:
      state = {
        ...state,
        groupParticipantCourse: action.payload.groupParticipantCourse,
        loading: false,
        done: true,
      }
      break
    case RESET_GROUP_PARTICIPANT_COURSE_CLEAN:
      state = initialState
      break
    case RESET_GROUP_PARTICIPANT_COURSE_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        groupParticipantCourse: undefined,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupParticipantCourseReset
