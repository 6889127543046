/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_ANSWER } from "./actionTypes"
import { apiError, getAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getAnswer } from "../../../helpers/backend_helper"

function* get(request) {
  try {
    const response = yield call(getAnswer, request.payload.idAnswer)
    if (response == undefined) throw new Error("API error")
    yield put(getAnswerSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* answerGetSaga() {
  yield takeEvery(GET_ANSWER, get)
}

export default answerGetSaga
