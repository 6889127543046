import CarouselForm from "components/Carousel/CarouselForm"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { MetaTags } from "react-meta-tags"
import { Breadcrumb, Card, CardBody, Col, Container, Row } from "reactstrap"

const AddCarousel = props => {
  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Carrusel | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Agregar nuevo carrusel</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumb
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "carousel", title: "Carrusel", link: "/carousels" },
                  {
                    key: "carousel-add",
                    title: "Agregar carrusel",
                    link: "/carousels/add",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card className="p-3">
                <CarouselForm id={0} insert={true} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default AddCarousel
