import { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Row,
  Col,
  CardImg,
  Progress,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Button,
} from "reactstrap"
import fester from "../../assets/images/logo_fester_header.png"
import moment from "common/moment_i18n"

import {
  addFav,
  addFavClean,
  removeFav,
  removeFavClean,
  getCoursesByUser,
} from "store/actions"

const CoursesWidget = props => {
  const [dropDowns, setDropDowns] = useState(false)
  const [modal, setModal] = useState(false)

  const { addFavDone, removeFavDone, user } = useSelector(store => ({
    addFavDone: store.FavAdd.done,
    removeFavDone: store.FavRemove.done,
    user: store.Profile.user,
  }))

  const idUser = user?.idUsuario ?? 0

  const dispatch = useDispatch()

  const handleAddFav = idCourse => {
    dispatch(addFav(idCourse))
  }

  const handleRemoveFav = idCourse => {
    dispatch(removeFav(idCourse))
  }

  if (addFavDone) {
    dispatch(getCoursesByUser(idUser))
    dispatch(addFavClean())
  }

  if (removeFavDone) {
    dispatch(getCoursesByUser(idUser))
    dispatch(removeFavClean())
  }

  const secondsToHms = d => {
    d = Number(d)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos, ") : ""
    var sDisplay = s + (s == 1 ? " second" : " segundos")
    return hDisplay + mDisplay + sDisplay
  }

  const minutesToHm = d => {
    d = Number(d)
    var h = Math.floor((d % 3600) / 60)
    var m = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos") : ""
    return hDisplay + mDisplay
  }

  console.log("Disabled", props.disabled)
  console.log(props.title, props.disabled === true ? "course-disabled" : "")

  return (
    <Fragment>
      <Card className={props.disabled === true ? "course-disabled" : ""}>
        <CardBody>
          {props.disabled === true && (
            <div className="alert alert-danger mb-2">
              Curso bloqueado. Apruebe el curso{" "}
              <strong>{props.disabledBy}</strong> para desbloquear.
            </div>
          )}
          <Row className="no-gutters align-items-center">
            <Col md={3} className="align-self-start">
              <CardImg
                className="img-fluid"
                src={props.image == "" ? fester : props.image}
                alt="Imagen"
              />
              {props.finished && false && (
                <div className="text-center mt-1">
                  <button className="btn btn-link">
                    <h5>
                      <i className="fa fa-comment"></i> Comentarios
                    </h5>
                  </button>
                </div>
              )}
            </Col>
            <Col md={9} className="align-self-start">
              <div className="d-flex justify-content-between">
                <h3>{props.title}</h3>
                {props.finished && (
                  <div>
                    <span
                      className="badge bg-pill bg-success"
                      style={{ fontSize: "16px", borderRadius: "14px" }}
                    >
                      {" "}
                      <i className="fa fa-flag-checkered"></i> Aprobado
                    </span>
                  </div>
                )}
              </div>
              <h5 className="alt-celf-color">{props.category}</h5>
              <hr />
              {props.started && props.dateStart && (
                <p>
                  * {props.finished ? "Inició" : "Inicio"}:{" "}
                  {moment(props.dateStart)
                    .locale("es")
                    .format("D [de] MMM [de] YYYY")}
                </p>
              )}
              {props.finished && props.dateFinish && (
                <p>
                  * Finalizó:{" "}
                  {moment(props.dateFinish)
                    .locale("es")
                    .format("D [de] MMM [de] YYYY")}
                </p>
              )}
              {/* !props.started && (
              <p>
                El objetivo del curso es brindar al alumno la información
                necesaria para poder realizar la reparación de estructuras con
                ayuda de productos enfocados al concreto.
              </p>
            ) */}
              <Row>
                {props.started && (
                  <Col md={props.finished ? 4 : 7}>
                    <div className="">
                      <Progress multi>
                        <Progress
                          bar
                          color="primary"
                          value={Math.round(props.percentage)}
                          style={{
                            width: `${Math.round(props.percentage)}%`,
                            height: "20px",
                          }}
                        />
                        <Progress
                          bar
                          color="light"
                          value={100 - Math.round(props.percentage)}
                          style={{
                            width: `${100 - Math.round(props.percentage)}%`,
                            height: "20px",
                          }}
                        />
                      </Progress>
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                      {!props.finished && (
                        <p>
                          {Math.round(props.percentage)}% avance -{" "}
                          {minutesToHm(props.minutes)} dedicados
                        </p>
                      )}
                      {props.finished && (
                        <p>{minutesToHm(props.minutes)} dedicados</p>
                      )}
                    </div>
                  </Col>
                )}
                {!props.started && (
                  <Col lg={7} md={6} sm={8} className="pt-2">
                    <i className="fa fa-clock" /> Duración del curso:{" "}
                    <strong>{minutesToHm(props.minutes)}</strong>
                  </Col>
                )}
                {props.finished && false && (
                  <Col md={4}>
                    <div className="text-center">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="far fa-star" />
                    </div>
                    <p className="text-center">Valoración</p>
                  </Col>
                )}
                <Col lg={5} md={6} sm={4}>
                  <Link
                    draggable="false"
                    to={`/my-courses/info/${props.id}`}
                    className={`btn btn-primary float-end`}
                  >
                    Ir al curso <i className="fa fa-arrow-right"></i>
                  </Link>
                  <div className="float-end" style={{ width: "20px" }}>
                    &nbsp;
                  </div>
                  <Dropdown
                    className="float-end"
                    isOpen={dropDowns}
                    toggle={() => {
                      setDropDowns(!dropDowns)
                    }}
                  >
                    <DropdownToggle color="light" className="btn btn-light">
                      <i className="fa fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="white-celf-background">
                      <DropdownItem
                        onClick={() => {
                          props.fav
                            ? handleRemoveFav(props.id)
                            : handleAddFav(props.id)
                        }}
                      >
                        <i className={`fa${props.fav ? "r" : ""} fa-star`} />{" "}
                        {props.fav
                          ? "Remover de favoritos"
                          : "Añadir a favoritos"}
                      </DropdownItem>
                      {/*  <DropdownItem onClick={() => setModal(true)}>
                      <i className="fa fa-share-alt" /> Compartir
                    </DropdownItem>
                    {props.started && (
                      <DropdownItem>
                        <i className="fa fa-sync-alt" /> Resetear cursos
                      </DropdownItem>
                    )} */}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
              {props.finished && false && (
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-link">
                      Habilidades adquiridas
                    </button>
                  </Col>
                  <Col className="text-center">
                    <button className="btn btn-link">
                      Conocimientos adquiridos
                    </button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </CardBody>
        <Modal isOpen={modal} backdrop={"static"} id="staticBackdrop">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Compartir este curso
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModal(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>
              ¿Deseas compartir este curso con tus compañeros de la Capacitación
              en Línea Fester?
            </p>
            <Row>
              <Col md={1}>&nbsp;</Col>
              <Col md={2} className="text-center">
                <i className="fab fa-facebook" style={{ fontSize: 32 }}></i>
                <p>Facebook</p>
              </Col>
              <Col md={2} className="text-center">
                <i className="fab fa-linkedin" style={{ fontSize: 32 }}></i>
                <p>LinkedIn</p>
              </Col>
              <Col md={2} className="text-center">
                <i className="fab fa-twitter" style={{ fontSize: 32 }}></i>
                <p>Twitter</p>
              </Col>
              <Col md={2} className="text-center">
                <i className="fab fa-whatsapp" style={{ fontSize: 32 }}></i>
                <p>WhatsApp</p>
              </Col>
              <Col md={2} className="text-center">
                <i className="fa fa-envelope" style={{ fontSize: 32 }}></i>
                <p>
                  Correo
                  <br />
                  electrónico
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <button
                  className="btn btn-link btn-block"
                  style={{ background: "#eee" }}
                >
                  {`/my-courses/info/${props.id}`}
                </button>
              </Col>
              <Col md={3}>
                <button className="btn btn-primary btn-block">
                  <i className="fa fa-copy"></i> Copiar
                </button>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModal(false)
              }}
            >
              Cancelar
            </button>
          </div>
        </Modal>
      </Card>
      <style jsx>{`
        .course-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      `}</style>
    </Fragment>
  )
}

CoursesWidget.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.string,
  percentage: PropTypes.number,
  minutes: PropTypes.number,
  fav: PropTypes.bool,
  started: PropTypes.bool,
  finished: PropTypes.bool,
  dateStart: PropTypes.string,
  dateFinish: PropTypes.string,
  disabled: PropTypes.bool,
  disabledBy: PropTypes.string,
}

export default CoursesWidget
