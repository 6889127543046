import { createStore, applyMiddleware, compose } from "redux"
import * as Sentry from "@sentry/react"
import createSagaMiddleware from "redux-saga"

import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
)
sagaMiddleware.run(rootSaga)

export default store
