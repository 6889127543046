import {
  RESET_GROUP_PARTICIPANT_COURSE,
  RESET_GROUP_PARTICIPANT_COURSE_SUCCESS,
  RESET_GROUP_PARTICIPANT_COURSE_CLEAN,
  RESET_GROUP_PARTICIPANT_COURSE_ERROR,
} from "./actionTypes"

export const resetGroupParticipantCourse = form => ({
  type: RESET_GROUP_PARTICIPANT_COURSE,
  payload: { form },
})

export const resetGroupParticipantCourseSuccess = groupParticipantCourse => {
  return {
    type: RESET_GROUP_PARTICIPANT_COURSE_SUCCESS,
    payload: { groupParticipantCourse },
  }
}

export const resetGroupParticipantCourseClean = () => {
  return {
    type: RESET_GROUP_PARTICIPANT_COURSE_CLEAN,
    payload: {},
  }
}

export const resetGroupParticipantCourseError = error => {
  return {
    type: RESET_GROUP_PARTICIPANT_COURSE_ERROR,
    payload: error,
  }
}
