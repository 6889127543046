import {
  ADD_COURSE_KNOWLEDGE,
  ADD_COURSE_KNOWLEDGE_SUCCESS,
  ADD_COURSE_KNOWLEDGE_CLEAN,
  ADD_COURSE_KNOWLEDGE_ERROR,
} from "./actionTypes"

export const addCourseKnowledge = form => ({
  type: ADD_COURSE_KNOWLEDGE,
  payload: { form },
})

export const addCourseKnowledgeSuccess = courseKnowledge => {
  return {
    type: ADD_COURSE_KNOWLEDGE_SUCCESS,
    payload: { courseKnowledge },
  }
}

export const addCourseKnowledgeClean = () => {
  return {
    type: ADD_COURSE_KNOWLEDGE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_COURSE_KNOWLEDGE_ERROR,
    payload: error,
  }
}
