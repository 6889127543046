import {
  LIST_SUPPORT_MATERIALS,
  LIST_SUPPORT_MATERIALS_SUCCESS,
  LIST_SUPPORT_MATERIALS_ERROR,
  LIST_SUPPORT_MATERIALS_CLEAN,
} from "./actionTypes"

export const getSupportMaterials = idCurso => ({
  type: LIST_SUPPORT_MATERIALS,
  payload: { idCurso },
})

export const getSupportMaterialsSuccess = supportMaterials => {
  return {
    type: LIST_SUPPORT_MATERIALS_SUCCESS,
    payload: { supportMaterials },
  }
}

export const getSupportMaterialsClean = () => {
  return {
    type: LIST_SUPPORT_MATERIALS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_SUPPORT_MATERIALS_ERROR,
    payload: error,
  }
}
