import {
  SEND_GROUPS_TRASH,
  SEND_GROUPS_TRASH_SUCCESS,
  SEND_GROUPS_TRASH_ERROR,
  SEND_GROUPS_TRASH_CLEAN,
} from "./actionTypes"

export const sendGroupsToTrash = (id, trash) => {
  return {
    type: SEND_GROUPS_TRASH,
    payload: { id, trash },
  }
}

export const sendGroupsToTrashSuccess = group => {
  return {
    type: SEND_GROUPS_TRASH_SUCCESS,
    payload: {},
  }
}

export const sendGroupsToTrashError = error => {
  return {
    type: SEND_GROUPS_TRASH_ERROR,
    payload: error,
  }
}

export const sendGroupsToTrashClean = () => {
  return {
    type: SEND_GROUPS_TRASH_CLEAN,
    payload: {},
  }
}
