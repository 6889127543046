/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { EDIT_COURSE, EDIT_COURSE_STATUS } from "./actionTypes"
import { apiError, editCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  updateCourse,
  updateCourseStatus,
} from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateCourse, action.payload.form)
    if (response.statusCode !== undefined) throw new Error(response.message)
    yield put(editCourseSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* editStatus(action) {
  try {
    const response = yield call(updateCourseStatus, action.payload)
    if (response.statusCode !== undefined) throw new Error(response.message)
    yield put(editCourseSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* courseEditSaga() {
  yield takeEvery(EDIT_COURSE, edit)
  yield takeEvery(EDIT_COURSE_STATUS, editStatus)
}

export default courseEditSaga
