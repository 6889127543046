import {
  SEND_GROUPS_TRASH,
  SEND_GROUPS_TRASH_SUCCESS,
  SEND_GROUPS_TRASH_CLEAN,
  SEND_GROUPS_TRASH_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  doneTrash: false,
  groups: undefined,
}

const GroupsTrash = (state = initialState, action) => {
  switch (action.type) {
    case SEND_GROUPS_TRASH:
      state = {
        ...state,
        loading: true,
        trashDone: false,
      }
      break
    case SEND_GROUPS_TRASH_SUCCESS:
      state = {
        ...state,
        loading: false,
        trashDone: true,
      }
      break
    case SEND_GROUPS_TRASH_ERROR:
      state = {
        ...state,
        loading: false,
        trashDone: false,
      }
      break
    case SEND_GROUPS_TRASH_CLEAN:
      state = {
        ...state,
        loading: false,
        trashDone: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupsTrash
