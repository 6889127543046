import {
  GET_POLL,
  GET_POLL_SUCCESS,
  GET_POLL_CLEAN,
  GET_POLL_ERROR,
  TRY_POLL,
  TRY_POLL_SUCCESS,
  TRY_POLL_CLEAN,
  TRY_POLL_ERROR,
} from "./actionTypes"

export const getPoll = form => ({
  type: GET_POLL,
  payload: { form },
})

export const getPollSuccess = polls => {
  return {
    type: GET_POLL_SUCCESS,
    payload: { polls },
  }
}

export const getPollClean = () => {
  return {
    type: GET_POLL_CLEAN,
    payload: {},
  }
}

export const getPollError = error => {
  return {
    type: GET_POLL_ERROR,
    payload: error,
  }
}

export const tryPoll = form => ({
  type: TRY_POLL,
  payload: { form },
})

export const tryPollSuccess = poll => {
  return {
    type: TRY_POLL_SUCCESS,
    payload: { poll },
  }
}

export const tryPollClean = () => {
  return {
    type: TRY_POLL_CLEAN,
    payload: {},
  }
}

export const tryPollError = error => {
  return {
    type: TRY_POLL_ERROR,
    payload: error,
  }
}
