import {
  LIST_GROUP_PARTICIPANTS,
  LIST_GROUP_PARTICIPANTS_SUCCESS,
  LIST_GROUP_PARTICIPANTS_CLEAN,
  LIST_GROUP_PARTICIPANTS_ERROR,
} from "./actionTypes"

export const listGroupParticipants = id => ({
  type: LIST_GROUP_PARTICIPANTS,
  payload: { id },
})

export const listGroupParticipantsSuccess = groupParticipants => {
  return {
    type: LIST_GROUP_PARTICIPANTS_SUCCESS,
    payload: { groupParticipants },
  }
}

export const listGroupParticipantsClean = () => {
  return {
    type: LIST_GROUP_PARTICIPANTS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_GROUP_PARTICIPANTS_ERROR,
    payload: error,
  }
}
