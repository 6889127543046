/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_CUSTOMER } from "./actionTypes"
import { addCustomerError, addCustomerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addCustomerNewApi } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addCustomerNewApi, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (response.message) throw new Error(response.message)
    yield put(addCustomerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(addCustomerError(error.message))
  }
}

function* customerAddSaga() {
  yield takeEvery(ADD_CUSTOMER, add)
}

export default customerAddSaga
