import {
  EDIT_COURSE_KNOWLEDGE,
  EDIT_COURSE_KNOWLEDGE_CLEAN,
  EDIT_COURSE_KNOWLEDGE_SUCCESS,
  EDIT_COURSE_KNOWLEDGE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  courseKnowledge: undefined,
}

const CourseKnowledgeEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_COURSE_KNOWLEDGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_COURSE_KNOWLEDGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        courseKnowledge: action.payload.courseKnowledge,
      }
      break
    case EDIT_COURSE_KNOWLEDGE_CLEAN:
      state = initialState
      break
    case EDIT_COURSE_KNOWLEDGE_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CourseKnowledgeEdit
