import {
  EDIT_SKILL,
  EDIT_SKILL_SUCCESS,
  EDIT_SKILL_CLEAN,
  EDIT_SKILL_ERROR,
} from "./actionTypes"

export const editSkill = form => ({
  type: EDIT_SKILL,
  payload: { form },
})

export const editSkillSuccess = skill => {
  return {
    type: EDIT_SKILL_SUCCESS,
    payload: { skill },
  }
}

export const editSkillClean = () => {
  return {
    type: EDIT_SKILL_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_SKILL_ERROR,
    payload: error,
  }
}
