import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, withRouter } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import {
  createCarouselClean,
  editCarouselClean,
  getSingleCarousel,
  getCarouselDone,
  createCarousel,
  editCarousel,
  getCourses,
  getSingleCarouselClean,
} from "store/actions"
import UploadFileModal from "./UploadFileModal"
import { set } from "lodash"

const CarouselForm = props => {
  const id = props.id ?? 0
  const insert = props.insert ?? true

  const dispatch = useDispatch()

  const [formError, setFormError] = useState("")
  const [image, setImage] = useState("")
  const [resourceType, setResourceType] = useState("")
  const [pdf, setPdf] = useState("")
  const [link, setLink] = useState("")
  const [courseId, setCourseId] = useState(0)
  const [uploadImage, setUploadImage] = useState(false)
  const [uploadPdf, setUploadPdf] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const emptyCarousel = {
    carouselName: "",
    carouselType: "",
    resourceType: "Archivo",
    status: true,
    startDate: new Date(),
    endDate: new Date(),
    imagePath: "",
    pdfPath: "",
    linkPath: "",
    courseId: 0,
  }

  const {
    loading,
    done,
    carousel,
    insertedCarouselError,
    getDone,
    getError,
    editCarouselError,
    createDone,
    editDone,
    coursesList,
  } = useSelector(state => ({
    carousel: state.CarouselsAll.carousel,
    loading: state.CarouselsAll.loading,
    getDone: state.CarouselsAll.getDone,
    getError: state.CarouselsAll.getError,
    createDone: state.CarouselsAll.createDone,
    editDone: state.CarouselsAll.editDone,
    insertedCarouselError: state.CarouselsAll.createError,
    editCarouselError: state.CarouselsAll.editError,
    coursesList: state.CoursesList.coursesForAdmin,
  }))

  const [form, setForm] = useState(emptyCarousel)

  useEffect(() => {
    if (!insert) {
      dispatch(getSingleCarousel(id))
    } else {
      dispatch(getSingleCarouselClean())
    }
  }, [id])

  useEffect(() => {
    dispatch(getCourses())
  }, [])

  useEffect(() => {
    console.log(carousel)
    if (carousel !== undefined) {
      setStartDate(carousel.startDate)
      setEndDate(carousel.endDate)
      setImage(carousel.imagePath)
      setPdf(carousel.pdfPath)
      setCourseId(carousel.courseId ?? 0)
      setLink(carousel.linkPath)
      setResourceType(carousel.resourceType ?? "Archivo")
    } else {
      setStartDate(new Date())
      setEndDate(new Date())
      setImage("")
      setPdf("")
      setCourseId(0)
      setLink("")
      setResourceType("Archivo")
    }
    return () => {
      setStartDate(new Date())
      setEndDate(new Date())
      setImage("")
      setPdf("")
      setCourseId(0)
      setLink("")
      setResourceType("Archivo")
    }
  }, [carousel])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(
        "Existen errores en el formulario. Por favor corrígelos para continuar."
      )
      return
    }

    if (startDate > endDate) {
      setFormError(
        "La fecha final del periodo no puede ser menor que la fecha inicial."
      )
      return
    }

    if (image === "") {
      setFormError("Debe cargar una imagen.")
      return
    }

    if (pdf === "" && resourceType === "Archivo") {
      setFormError("Debe cargar un archivo PDF.")
      return
    }

    if (link === "" && resourceType === "Link") {
      setFormError("Debe agregar un link.")
      return
    }

    if (parseInt(courseId) === 0 && resourceType === "Curso") {
      setFormError("Debe seleccionar un curso.")
    }

    setFormError("")

    const data = {
      name: values.name,
      type: values.type,
      status: parseInt(values.estatus),
      image: image,
      pdf: pdf,
      link: link,
      courseId: parseInt(courseId),
      resourceType: resourceType,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    }

    if (insert) {
      dispatch(createCarousel(data))
    } else {
      dispatch(editCarousel({ ...data, carouselId: carousel.carouselId }))
    }
  }

  const carouselInfo =
    !insert && carousel !== undefined ? { ...carousel } : emptyCarousel

  let courses = []

  if (coursesList !== undefined) {
    coursesList.forEach(course => {
      if (course.status === 2) {
        courses.push(course)
      }
    })
  }

  return (
    <Fragment>
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        {formError !== "" && (
          <div className="alert alert-danger">{formError}</div>
        )}
        {insert && insertedCarouselError !== "" && (
          <div className="alert alert-danger">
            Ocurrió un error al registrar usuario: {insertedCarouselError}
          </div>
        )}
        {!insert && editCarouselError !== "" && (
          <div className="alert alert-danger">
            Ocurrió un error al editar usuario: {editCarouselError}
          </div>
        )}
        <Row className="mb-3">
          <Col md={3}>* Nombre:</Col>
          <Col md={9}>
            <AvField
              name="name"
              placeholder="Nombre del carrusel"
              value={carouselInfo.carouselName}
              className="form-control"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>* Tipo de carrusel:</Col>
          <Col md={9}>
            <AvField
              name="type"
              value={carouselInfo.carouselType}
              className="form-control"
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option>-- Seleccione tipo --</option>
              <option value={"Aviso general"}>Aviso general</option>
              <option value={"Publicación de resultados"}>
                Publicación de resultados
              </option>
            </AvField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>* Tipo de recurso:</Col>
          <Col md={9}>
            <AvField
              name="resourceType"
              value={carouselInfo.resourceType}
              className="form-control"
              onChange={e => setResourceType(e.target.value)}
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option>-- Seleccione tipo de recurso --</option>
              <option value={"Archivo"}>Archivo</option>
              <option value={"Curso"}>Curso</option>
              <option value={"Link"}>Link</option>
            </AvField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>* Fecha del:</Col>
          <Col md={3}>
            <Flatpickr
              name="startDate"
              onChange={date => setStartDate(date[0].toISOString())}
              value={moment(startDate).format("DD/MM/YYYY")}
              className="form-control d-block"
              placeholder="dd/mm/yyyy"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "d/m/Y",
              }}
            />
          </Col>
          <Col md={1} className="pt-1">
            al:
          </Col>
          <Col md={3}>
            <Flatpickr
              name="endDate"
              onChange={date => setEndDate(date[0].toISOString())}
              value={moment(endDate).format("DD/MM/YYYY")}
              className="form-control d-block"
              placeholder="dd/mm/yyyy"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "d/m/Y",
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>* Estatus:</Col>
          <Col md={9}>
            <AvField
              name="estatus"
              value={carouselInfo.status === true ? "1" : "0"}
              className="form-control"
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option>-- Seleccione estatus --</option>
              <option value={1}>Activado</option>
              <option value={0}>Desactivado</option>
            </AvField>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>* Imagen de carrusel:</Col>
          <Col md={9}>
            {image !== "" ? (
              <a
                href={image}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                <i className="fa fa-file-image"></i> Ver imagen
              </a>
            ) : carouselInfo.imagePath !== "" ? (
              <a
                href={carouselInfo.imagePath}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                <i className="fa fa-file-image"></i> Ver imagen
              </a>
            ) : (
              <button type="button" className="btn btn-light" disabled={true}>
                <i className="fa fa-file-image"></i> Sin imagen
              </button>
            )}{" "}
            <button
              className="btn btn-dark"
              type="button"
              onClick={() => setUploadImage(true)}
            >
              <i className="fa fa-upload"></i> Subir imagen
            </button>
          </Col>
        </Row>
        {resourceType === "Archivo" && (
          <Row className="mb-3">
            <Col md={3}>* PDF de carrusel:</Col>
            <Col md={9}>
              {pdf !== "" ? (
                <a
                  href={pdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  <i className="fa fa-file-image"></i> Ver PDF
                </a>
              ) : carouselInfo.pdfPath !== "" ? (
                <a
                  href={carouselInfo.pdfPath}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  <i className="fa fa-file-image"></i> Ver PDF
                </a>
              ) : (
                <button type="button" className="btn btn-light" disabled={true}>
                  <i className="fa fa-file-image"></i> Sin PDF
                </button>
              )}{" "}
              <button
                className="btn btn-dark"
                type="button"
                onClick={() => setUploadPdf(true)}
              >
                <i className="fa fa-upload"></i> Subir PDF
              </button>
            </Col>
          </Row>
        )}
        {resourceType === "Link" && (
          <Row className="mb-3">
            <Col md={3}>* Link:</Col>
            <Col md={9}>
              <AvField
                name="link"
                placeholder="Link del carrusel"
                value={carouselInfo.linkPath}
                onChange={e => setLink(e.target.value)}
                className="form-control"
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
        )}
        {resourceType === "Curso" && (
          <Row className="mb-3">
            <Col md={3}>* Curso:</Col>
            <Col md={9}>
              <AvField
                name="profile"
                value={carouselInfo.courseId}
                className="form-control"
                placeholder=""
                type="select"
                onChange={event => {
                  setCourseId(event.target.value)
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option key={0} value="0">
                  -- Seleccione curso --
                </option>
                {coursesList.map((element, index) => (
                  <option value={element.courseId} key={element.courseId}>
                    {element.courseName}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <Button
              className={`float-right ${loading ? "disabled" : ""} btn-block`}
              type="submit"
            >
              {!loading && <span>Guardar</span>}
              {loading && <span>Guardando...</span>}
            </Button>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <Link
              to="/carousel"
              className="btn btn-light btn-block"
              draggable="false"
            >
              Cancelar
            </Link>
          </Col>
        </Row>
      </AvForm>
      {createDone && insert && insertedCarouselError === "" && (
        <SweetAlert
          title="Carrusel registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(createCarouselClean())
            props.history.push("/carousel")
          }}
        ></SweetAlert>
      )}

      {editDone && !insert && editCarouselError === "" && (
        <SweetAlert
          title="Carrusel modificado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editCarouselClean())
            props.history.push("/carousel")
          }}
        ></SweetAlert>
      )}
      {uploadImage && (
        <UploadFileModal
          show={true}
          onCancel={() => setUploadImage(false)}
          onConfirm={file => {
            setUploadImage(false)
            setImage(file)
          }}
          image={true}
        />
      )}
      {uploadPdf && (
        <UploadFileModal
          show={true}
          onCancel={() => setUploadPdf(false)}
          onConfirm={file => {
            setUploadPdf(false)
            setPdf(file)
          }}
          image={false}
        />
      )}
    </Fragment>
  )
}

CarouselForm.propTypes = {
  id: PropTypes.number,
  insert: PropTypes.bool,
  history: PropTypes.object,
}

export default withRouter(CarouselForm)
