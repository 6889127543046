/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_LESSON } from "./actionTypes"
import { apiError, addLessonSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addLesson } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addLesson, action.payload.form)
    if (response === undefined) {
      throw new Error("API error")
    }
    if (response.statusCode !== undefined) {
      throw new Error(response.message)
    }
    yield put(addLessonSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* lessonAddSaga() {
  yield takeEvery(ADD_LESSON, add)
}

export default lessonAddSaga
