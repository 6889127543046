import { GET_SKILLS, GET_SKILLS_SUCCESS, GET_SKILLS_ERROR } from "./actionTypes"

export const getSkills = () => ({
  type: GET_SKILLS,
  payload: {},
})

export const getSkillsSuccess = skills => {
  return {
    type: GET_SKILLS_SUCCESS,
    payload: { skills },
  }
}

export const apiError = error => {
  return {
    type: GET_SKILLS_ERROR,
    payload: error,
  }
}
