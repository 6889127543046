import {
  GET_BUSINESS,
  GET_BUSINESS_SUCCESS,
  GET_BUSINESS_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const getBusiness = id => {
  return {
    type: GET_BUSINESS,
    payload: { id },
  }
}

export const getBusinessSuccess = business => {
  return {
    type: GET_BUSINESS_SUCCESS,
    payload: { business },
  }
}

export const getBusinessClean = () => {
  return {
    type: GET_BUSINESS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
