import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"

const ChangePasswordModal = props => {
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [currentPasswordValidation, setCurrentPasswordValidation] =
    useState(undefined)
  const [passwordValidation, setPasswordValidation] = useState(undefined)
  const pattern = /()^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}).*$/

  const changePassword = event => {
    setPassword(event.target.value)
    setCurrentPasswordValidation(
      event.target.value.length > 0 ? true : undefined
    )
  }

  const changeNewPassword = event => {
    setNewPassword(event.target.value)
    setPasswordValidation(
      pattern.test(newPassword) && newPassword.length >= 12 ? true : undefined
    )
  }

  const handleSubmit = () => {
    if (password.length < 1) {
      setCurrentPasswordValidation(false)
      return
    }
    if (!pattern.test(newPassword) && newPassword.length < 12) {
      setPasswordValidation(false)
      return
    }
    setPasswordValidation(true)
    setCurrentPasswordValidation(true)
    props.onConfirm(password, newPassword)
  }

  const beforeClose = () => {
    setPassword("")
    setNewPassword("")
  }

  if (
    props.show === false &&
    (passwordValidation !== undefined ||
      currentPasswordValidation !== undefined)
  ) {
    setPasswordValidation(undefined)
    setCurrentPasswordValidation(undefined)
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Cambiar contraseña
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            beforeClose()
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <FormGroup check>
          <Row>
            <Col xs={12}>
              {props.error !== "" && (
                <div className="alert alert-danger">{props.error}</div>
              )}
            </Col>
            <Col xs={12} className="mb-3">
              <Input
                name="password"
                id="password"
                type="password"
                placeholder="Escribe aquí tu contraseña actual"
                onChange={changePassword}
              />
              {currentPasswordValidation !== undefined && (
                <div
                  className={
                    currentPasswordValidation === true
                      ? "valid text-success mt-1"
                      : "invalid text-danger mt-1"
                  }
                  name="validate"
                  id="validate1"
                >
                  {currentPasswordValidation === true
                    ? "Validaremos que esta contraseña sea la correcta"
                    : "La contraseña actual es obligatoria para realizar el cambio"}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <Input
                name="newPassword"
                id="newPassword"
                type="password"
                placeholder="Escriba aquí la nueva contraseña"
                onChange={changeNewPassword}
              />
              {passwordValidation !== undefined && (
                <div
                  className={
                    passwordValidation === true
                      ? "valid text-success mt-1"
                      : "invalid text-danger mt-1"
                  }
                  name="validate"
                  id="validate1"
                >
                  {passwordValidation === true
                    ? "¡Esa contraseña luce genial!"
                    : "La contraseña debe tener al menos 12 caracteres, al menos una minúscula, una mayúscula y un número"}
                </div>
              )}
            </Col>
            <Col xs={12}>
              <p>
                Debe tener al menos 12 caracteres, al menos una minúscula, una
                mayúscula y un número
              </p>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            beforeClose()
            props.onCancel()
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => handleSubmit()}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default ChangePasswordModal
