import {
  START_COURSE,
  START_COURSE_SUCCESS,
  START_COURSE_CLEAN,
  START_COURSE_ERROR,
} from "./actionTypes"

export const startCourse = form => ({
  type: START_COURSE,
  payload: { form },
})

export const startCourseSuccess = response => {
  return {
    type: START_COURSE_SUCCESS,
    payload: { response },
  }
}

export const startCourseClean = () => {
  return {
    type: START_COURSE_CLEAN,
    payload: {},
  }
}

export const startCourseError = error => {
  return {
    type: START_COURSE_ERROR,
    payload: error,
  }
}
