import {
  LIST_POLLS,
  LIST_POLLS_SUCCESS,
  LIST_POLLS_CLEAN,
  LIST_POLLS_ERROR,
} from "./actionTypes"

export const listPolls = idCourse => ({
  type: LIST_POLLS,
  payload: { idCourse },
})

export const listPollsSuccess = polls => {
  return {
    type: LIST_POLLS_SUCCESS,
    payload: { polls },
  }
}

export const listPollsClean = () => {
  return {
    type: LIST_POLLS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_POLLS_ERROR,
    payload: error,
  }
}
