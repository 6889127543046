/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { RESET_GROUP_COURSE } from "./actionTypes"
import { resetGroupCourseError, resetGroupCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import { resetGroupCourseApi } from "../../../helpers/backend_helper"

function* reset(action) {
  try {
    const response = yield call(resetGroupCourseApi, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al agregar el curso")
    yield put(resetGroupCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(resetGroupCourseError(error.message))
  }
}

function* groupCourseResetSaga() {
  yield takeEvery(RESET_GROUP_COURSE, reset)
}

export default groupCourseResetSaga
