import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import CourseLessonIntro from "components/Courses/CourseLessonIntro"

const CourseIntro = props => {
  const { id } = useParams()
  const course =
    id !== undefined
      ? {
          id: id,
          nombre: "Technical Customer Service",
          claveCurso: "ABC-123",
          dedication: 14,
          minimoAprobatorio: 80,
        }
      : {
          id: 0,
          nombre: "",
          claveCurso: "",
          dedication: "",
          minimoAprobatorio: "",
        }
  const [activeTab, setActiveTab] = useState("1")

  const lessons =
    id !== undefined
      ? [
          {
            id: 1,
            title: "Lección 1",
            averageTime: 15,
            quizzes: 0,
            blocks: [
              {
                type: "text",
                value: "<h2>Información de la lección 1</h2>",
              },
              {
                type: "youtube",
                value: "https://www.youtube.com/watch?v=hJ5vppsvZ7s",
              },
            ],
          },
        ]
      : []

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {course.id == 0 ? "Nuevo curso" : `Curso: ${course.nombre}`} |
            Professional&apos;s Academy E-Learning
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>
                {course.id == 0 ? "Nuevo curso" : `Curso: ${course.nombre}`}
              </h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Home",
                    link: "/dashboard",
                  },
                  { key: "courses", title: "Cursos", link: "/courses" },
                  {
                    key: "edit_group",
                    title:
                      course.id == 0 ? "Nuevo curso" : `Curso: ${course.name}`,
                  },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-list"></i> Información
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-book-open"></i> Contenido
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-book-open"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleIconCustom("6")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-medal"></i> Calificación
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-medal"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleIconCustom("3")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-sticky-note"></i> Notas
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-sticky-note"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleIconCustom("4")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-question-circle"></i> Ayuda
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleIconCustom("5")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-people-arrows"></i> Foros
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-people-arrows"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted mt-4"
                  >
                    <TabPane tabId="1">
                      <CourseLessonIntro />
                    </TabPane>
                    <TabPane tabId="2"></TabPane>
                    <TabPane tabId="3"></TabPane>
                    <TabPane tabId="4"></TabPane>
                    <TabPane tabId="5"></TabPane>
                    <TabPane tabId="6"></TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CourseIntro)
