/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_COURSE_KNOWLEDGE } from "./actionTypes"
import { apiError, editCourseKnowledgeSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateCourseKnowledge } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateCourseKnowledge, action.payload.form)
    console.log(response)
    if (response === undefined)
      yield put(apiError("Error al editar el conocimiento adquirido"))
    if (!response.success)
      yield put(
        apiError(`Error al editar el conocimiento adquirido: ${response.error}`)
      )
    else yield put(editCourseKnowledgeSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* courseKnowledgeEditSaga() {
  yield takeEvery(EDIT_COURSE_KNOWLEDGE, edit)
}

export default courseKnowledgeEditSaga
