import React, { Component, Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import { getUsers, apiError } from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import GroupsListTable from "components/Groups/GroupsListTable"

const GroupsList = props => {
  const loading = false

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Grupos | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Gestión de grupos</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "groups", title: "Gestión de grupos" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="mt-3 mb-3">
                      <div className="spinner-chase">
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                      </div>
                    </div>
                  ) : (
                    <GroupsListTable />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GroupsList
