import {
  LIST_QUIZZ_ANSWERS,
  LIST_QUIZZ_ANSWERS_SUCCESS,
  LIST_QUIZZ_ANSWERS_CLEAN,
  LIST_QUIZZ_ANSWERS_ERROR,
} from "./actionTypes"

export const listQuizzAnswers = (quizzId, userId) => ({
  type: LIST_QUIZZ_ANSWERS,
  payload: { quizzId, userId },
})

export const listQuizzAnswersSuccess = quizzAnswers => {
  return {
    type: LIST_QUIZZ_ANSWERS_SUCCESS,
    payload: { quizzAnswers },
  }
}

export const listQuizzAnswersClean = () => {
  return {
    type: LIST_QUIZZ_ANSWERS_CLEAN,
    payload: {},
  }
}

export const listQuizzAnswersError = error => {
  return {
    type: LIST_QUIZZ_ANSWERS_ERROR,
    payload: error,
  }
}
