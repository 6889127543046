import {
  LIST_NOTES,
  LIST_NOTES_SUCCESS,
  LIST_NOTES_ERROR,
  LIST_NOTES_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  notes: [],
}

const NotesList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NOTES:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_NOTES_SUCCESS:
      state = {
        ...state,
        notes: action.payload.notes,
        loading: false,
        done: true,
      }
      break
    case LIST_NOTES_CLEAN:
      state = initialState
      break
    case LIST_NOTES_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default NotesList
