/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_BUSINESS } from "./actionTypes"
import { apiError, addBusinessSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addBusiness } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addBusiness, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al guardar el negocio")
    yield put(addBusinessSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* businessAddSaga() {
  yield takeEvery(ADD_BUSINESS, add)
}

export default businessAddSaga
