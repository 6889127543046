import {
  ADD_LESSON,
  ADD_LESSON_SUCCESS,
  ADD_LESSON_CLEAN,
  ADD_LESSON_ERROR,
} from "./actionTypes"

export const addLesson = form => ({
  type: ADD_LESSON,
  payload: { form },
})

export const addLessonSuccess = lesson => {
  return {
    type: ADD_LESSON_SUCCESS,
    payload: { lesson },
  }
}

export const addLessonClean = () => {
  return {
    type: ADD_LESSON_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_LESSON_ERROR,
    payload: error,
  }
}
