/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { SEND_GROUPS_TRASH } from "./actionTypes"
import { sendGroupsToTrashSuccess, sendGroupsToTrashError } from "./actions"

//Include Both Helper File with needed methods
import { groupToTrash } from "../../../helpers/backend_helper"

function* trash(request) {
  try {
    const response = yield call(groupToTrash, request.payload)
    if (response.message) throw new Error(response)
    yield put(sendGroupsToTrashSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(sendGroupsToTrashError(error.message))
  }
}

function* groupsTrashSaga() {
  yield takeEvery(SEND_GROUPS_TRASH, trash)
}

export default groupsTrashSaga
