import {
  LIST_REPORT1,
  LIST_REPORT1_SUCCESS,
  LIST_REPORT1_CLEAN,
  LIST_REPORT1_ERROR,
} from "./actionTypes"

export const listReport1 = form => ({
  type: LIST_REPORT1,
  payload: { form },
})

export const listReport1Success = report => {
  return {
    type: LIST_REPORT1_SUCCESS,
    payload: { report },
  }
}

export const listReport1Clean = () => {
  return {
    type: LIST_REPORT1_CLEAN,
    payload: {},
  }
}

export const listReport1Error = error => {
  return {
    type: LIST_REPORT1_ERROR,
    payload: error,
  }
}
