import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Table } from "reactstrap"
import avatar1 from "../../assets/images/user_without_pic.png"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const GamificationTable = props => {
  return props.loading === true ? (
    <div className="text-center">
      <LoadingIndicator />
    </div>
  ) : (
    <Fragment>
      <div className="text-center">
        <h2 style={{ fontWeight: "bold" }} className="mb-4">
          Tabla general{" "}
          {props.participants.length > 0
            ? props.participants[0].nombre_periodo
            : ""}
        </h2>
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th className="text-center">Posición</th>
            <th className="text-center" colSpan={2}>
              Nombre
            </th>
            <th className="text-center">Puntos</th>
            <th className="text-center">Grupo</th>
            <th className="text-center">Cliente</th>
          </tr>
        </thead>
        <tbody>
          {props.participants.length === 0 && (
            <tr>
              <td colSpan={6} className="text-center">
                -- Ningún participante ha generado puntos aún --
              </td>
            </tr>
          )}
          {props.participants.map((participant, index) => {
            return index >= 10 ? (
              <Fragment></Fragment>
            ) : (
              <tr
                key={index}
                className={index >= 5 ? `tr-gray` : `tr-position-${index}`}
              >
                <td className="text-center">
                  <div className={`pt-2 pb-2 pl-4 pr-4 badge bg-light`}>
                    {participant.ranking}
                  </div>
                </td>
                <td>
                  <div style={{}}>
                    <div
                      style={{
                        backgroundImage:
                          participant.avatar == null
                            ? `url(${avatar1})`
                            : `url(${participant.avatar})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        borderRadius: "50%",
                      }}
                    >
                      &nbsp;
                    </div>
                  </div>
                </td>
                <td>
                  {participant.nombre_usuario} {participant.apellido_usuario}
                </td>
                <td>
                  <h3 className="text-center">
                    {participant.puntos_totales ?? 0}
                  </h3>
                </td>
                <td>{participant.nombre_grupo}</td>
                <td>{participant.nombre_distribuidor ?? "N/A"}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <style jsx>{`
        .tr-position-0,
        .tr-position-0 td,
        .tr-position-0 td h3 {
          background-color: #34c38f !important;
          color: white;
        }
        .tr-position-1,
        .tr-position-1 td,
        .tr-position-1 td h3,
        .tr-position-2,
        .tr-position-2 td,
        .tr-position-2 td h3,
        .tr-position-3,
        .tr-position-3 td,
        .tr-position-3 td h3,
        .tr-position-4,
        .tr-position-4 td,
        .tr-position-4 td h3 {
          background-color: #f1b44c;
          color: black;
        }
        .tr-gray {
          background-color: #f2f2f5;
        }
      `}</style>
    </Fragment>
  )
}

GamificationTable.propTypes = {
  participants: PropTypes.array,
  loading: PropTypes.bool,
}

export default GamificationTable
