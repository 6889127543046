import {
  LIST_QUESTIONS,
  LIST_QUESTIONS_SUCCESS,
  LIST_QUESTIONS_CLEAN,
  LIST_QUESTIONS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  questions: [],
}

const QuestionList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_QUESTIONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_QUESTIONS_SUCCESS:
      state = {
        ...state,
        questions: action.payload.questions,
        loading: false,
        done: true,
      }
      break
    case LIST_QUESTIONS_CLEAN:
      state = initialState
      break
    case LIST_QUESTIONS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuestionList
