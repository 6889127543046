import {
  LIST_REPORT6,
  LIST_REPORT6_SUCCESS,
  LIST_REPORT6_CLEAN,
  LIST_REPORT6_ERROR,
} from "./actionTypes"

export const listReport6 = form => ({
  type: LIST_REPORT6,
  payload: { form },
})

export const listReport6Success = report => {
  return {
    type: LIST_REPORT6_SUCCESS,
    payload: { report },
  }
}

export const listReport6Clean = () => {
  return {
    type: LIST_REPORT6_CLEAN,
    payload: {},
  }
}

export const listReport6Error = error => {
  return {
    type: LIST_REPORT6_ERROR,
    payload: error,
  }
}
