import {
  ADD_POLL_ANSWER,
  ADD_POLL_ANSWER_SUCCESS,
  ADD_POLL_ANSWER_CLEAN,
  ADD_POLL_ANSWER_ERROR,
} from "./actionTypes"

export const addPollAnswer = form => ({
  type: ADD_POLL_ANSWER,
  payload: { form },
})

export const addPollAnswerSuccess = pollAnswer => {
  return {
    type: ADD_POLL_ANSWER_SUCCESS,
    payload: { pollAnswer },
  }
}

export const addPollAnswerClean = () => {
  return {
    type: ADD_POLL_ANSWER_CLEAN,
    payload: {},
  }
}

export const addPollAnswerError = error => {
  return {
    type: ADD_POLL_ANSWER_ERROR,
    payload: error,
  }
}
