import {
  EXTEND_SESSION,
  EXTEND_SESSION_SUCCESS,
  EXTEND_SESSION_CLEAN,
  EXTEND_SESSION_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  question: undefined,
}

const SessionExtend = (state = initialState, action) => {
  switch (action.type) {
    case EXTEND_SESSION:
      state = {
        ...state,
        loading: true,
      }
      break
    case EXTEND_SESSION_SUCCESS:
      state = {
        ...state,
        token: action.payload.token,
        loading: false,
        done: true,
      }
      break
    case EXTEND_SESSION_CLEAN:
      state = initialState
      break
    case EXTEND_SESSION_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SessionExtend
