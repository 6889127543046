import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"

import CourseLessonsPreview from "./CourseLessonsPreview"
import {
  getCourses,
  getLessons,
  getLevels,
  getUsers,
  sendCourseToTrash,
  sendCourseToTrashClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const CoursesListTable = props => {
  const dispatch = useDispatch()

  const [dropDown, setDropDown] = useState(0)
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const [showPreview, setShowPreview] = useState(false)
  const [courseId, setCourseId] = useState(undefined)
  const [coursePreview, setCoursePreview] = useState(undefined)
  const [trash, setTrash] = useState(undefined)

  const {
    coursesPrev,
    loading,
    error,
    users,
    levels,
    lessons,
    whoIAm,
    trashDone,
  } = useSelector(state => ({
    coursesPrev: state.CoursesList.coursesForAdmin,
    loading: state.CoursesList.loading,
    error: state.CoursesList.error,
    lessons: state.LessonsList.lessons,
    levels: state.LevelsList.levels,
    users: state.UsersList.users,
    whoIAm: state.Profile.user,
    trashDone: state.CoursesList.trashDone,
  }))

  const idRol = whoIAm?.idRol ?? 0
  const idUsuario = whoIAm?.idUsuario ?? 0

  useEffect(() => {
    dispatch(getCourses())
  }, [])

  const courses = []

  if (coursesPrev !== undefined) {
    coursesPrev.forEach(course => {
      course.claveCurso = parseInt(course.courseCode)
      courses.push(course)
    })
  }

  if (trashDone) {
    dispatch(getCourses())
    dispatch(sendCourseToTrashClean())
  }

  useEffect(() => {
    if (courseId !== undefined) {
      dispatch(getLessons(courseId))
      dispatch(getLevels())
      dispatch(getUsers())
      const course = courses.find(course => course.courseId == courseId)
      if (course !== undefined) {
        setCoursePreview(course)
        setShowPreview(true)
      }
    }
  }, [courseId])

  const { SearchBar } = Search

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.courseId}`}
        isOpen={row.courseId === active}
        toggle={() => {
          setDropDown(row.courseId === active ? 0 : row.courseId)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem
            onClick={() => props.history.push(`courses/detail/${row.courseId}`)}
          >
            Editar
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setCourseId(row.courseId)
            }}
          >
            Previsualizar
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setTrash(row.courseId)
            }}
          >
            Enviar a papelera
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "courseId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "courseCode",
      text: "ID",
      sort: true,
    },
    {
      dataField: "courseName",
      text: "Nombre del curso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => (
        <Link draggable="false" to={`courses/detail/${row.courseId}`}>
          {row.courseName}
        </Link>
      ),
    },
    {
      dataField: "categoria",
      text: "Categoría(s) de conocimiento",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        let knowledgeCategories = []
        row.knowledgeCategories.forEach(object => {
          knowledgeCategories.push(object.knowledgeCategoryName)
        })
        let string = knowledgeCategories.join(", ")
        return (
          <div key={`knowledge-categories-${row.courseId}`}>
            {string === ""
              ? "-- Sin categorías de conocimiento asignadas --"
              : string}
          </div>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Actualización",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.courseId}`}>
            {moment(row.lastActivity).format("DD/MM/YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.courseId}`}>
            {row.status == 2 && (
              <span className="pt-2 badge bg-success">Publicado</span>
            )}
            {row.status == 3 && (
              <span className="pt-2 badge bg-dark">Inactivo</span>
            )}
            {row.status == 4 && (
              <span className="pt-2 badge bg-warning">Obsoleto</span>
            )}
            {row.status == 1 && (
              <span className="pt-2 badge bg-light">Edición</span>
            )}
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: courses?.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const { ExportCSVButton } = CSVExport

  const defaultSorted = [{ dataField: "nombreCurso", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  return loading ? (
    <div className="text-center">
      <LoadingIndicator />
    </div>
  ) : (
    <Fragment>
      {!showPreview ? (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="courseId"
          columns={columns}
          data={courses}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="courseId"
              columns={columns}
              data={courses}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2 pt-3">
                    <Col lg={2} md={3} sm={4} xs={6}>
                      <div className="d-grid">
                        <Link
                          draggable="false"
                          to="/courses/add"
                          className="btn btn-primary btn-rounded"
                        >
                          <i className="fa fa-plus"></i> Agregar curso
                        </Link>
                        <button className="btn btn-link mt-2">
                          <i className="fa fa-file-excel"></i> Exportar a Excel
                        </button>
                      </div>
                    </Col>
                    <Col
                      lg={{ size: 2, offset: 8 }}
                      md={{ size: 3, offset: 6 }}
                      sm={{ size: 4, offset: 4 }}
                      xs={6}
                    >
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder="Buscar"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"courseId"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {courses.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      ) : (
        <Row>
          <div className="d-flex justify-content-end mb-3">
            <button
              className="btn btn-light"
              onClick={() => {
                setShowPreview(false)
                setCourseId(undefined)
                setCoursePreview(undefined)
              }}
            >
              <i className="fa fa-times"></i> Cerrar vista previa
            </button>
          </div>
          <CourseLessonsPreview
            responsibles={users}
            levels={levels}
            lessons={lessons}
            course={coursePreview}
          />
        </Row>
      )}
      {trash !== undefined && (
        <SweetAlert
          title="Enviar a papelera"
          warning
          confirmBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No, cancelar"
          onConfirm={() => {
            dispatch(sendCourseToTrash(trash, true))
            setTrash(undefined)
          }}
          onCancel={() => {
            setTrash(undefined)
          }}
        >
          <p>¿Confirmas que deseas enviar este curso a papelera?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CoursesListTable.propTypes = {
  group: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(CoursesListTable)
