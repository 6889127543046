import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Input } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { removeDiacritics } from "common/strings"

import {
  getProfiles,
  getCustomers,
  addUser,
  addUserClean,
  editUser,
  getGroups,
  getPositions,
  getUser,
  getUsers,
  getUsersByRole,
  editUserClean,
} from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"

const UsersForm = props => {
  const insert = props.insert ?? true
  const id = props.id ?? 0

  const [formError, setFormError] = useState(false)
  const [n4Field, setN4Field] = useState(false)
  const [n5Field, setN5Field] = useState(false)
  const [n6Field, setN6Field] = useState(false)
  const [n7Field, setN7Field] = useState(false)
  const [showGroup, setShowGroup] = useState(false)
  const [profile, setProfile] = useState(0)
  const [group, setGroup] = useState(0)
  const [filteredPositions, setFilteredPositions] = useState([])

  const changeProfile = evt => {
    const profileId = parseInt(evt.target.value)
    setVisibleFields(profileId)
  }

  const changePositions = profileId => {
    const positionsByProfile = positions.filter(
      position => position.profileId === profileId
    )
    setFilteredPositions(positionsByProfile)
  }

  const setVisibleFields = profileId => {
    setProfile(profileId)
    changePositions(profileId)
    switch (profileId) {
      case 1:
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setShowGroup(true)
        break
      case 2:
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setShowGroup(true)
        break
      case 3:
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setShowGroup(true)
        break
      case 4:
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setShowGroup(true)
        break
      case 5:
        setN4Field(true)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setShowGroup(true)
        dispatch(getUsersByRole(4))
        break
      case 6:
        setN4Field(false)
        setN5Field(true)
        setN6Field(false)
        setN7Field(false)
        setShowGroup(false)
        dispatch(getUsersByRole(5))
        break
      case 7:
        setN4Field(false)
        setN5Field(false)
        setN6Field(true)
        setN7Field(false)
        setShowGroup(false)
        dispatch(getCustomers())
        dispatch(getUsersByRole(6))
        break
      case 8:
        setN4Field(false)
        setN5Field(false)
        setN6Field(true)
        setN7Field(true)
        setShowGroup(false)
        dispatch(getCustomers())
        dispatch(getUsersByRole(6))
        break
    }
  }

  const dispatch = useDispatch()

  const {
    users,
    usersByRole,
    profiles,
    groups,
    positions,
    clients,
    loading,
    done,
    user,
    insertedUser,
    insertedUserError,
    getUserLoading,
    getUserDone,
    editUserLoading,
    editUserError,
    editUserDone,
    whoIAm,
  } = useSelector(state => ({
    users: state.UsersList.users,
    usersByRole: state.UsersList.usersByRole,
    profiles: state.ProfilesList.profiles,
    groups: state.GroupsList.groups,
    positions: state.PositionsList.positions,
    clients: state.CustomersList.customers,
    loading: state.UserAdd.loading,
    done: state.UserAdd.done,
    insertedUser: state.UserAdd.user,
    insertedUserError: state.UserAdd.error,
    user: state.UserEdit.user,
    getUserLoading: state.UserEdit.loading,
    getUserDone: state.UserEdit.done,
    editUserLoading: state.UserEdit.updateLoading,
    editUserDone: state.UserEdit.updateDone,
    editUserError: state.UserEdit.error,
    whoIAm: state.Profile.user,
  }))

  const userId = whoIAm?.userId ?? 0

  const userInfo =
    !insert && user !== undefined
      ? { ...user, name: user.nombreCliente }
      : {
          firstName: "",
          lastName: "",
          email: "",
          positionId: 0,
          groupId: group,
          profileId: 0,
          idh: 0,
          clientName: "",
          n4Assigned: 0,
          n5Assigned: 0,
          n6Assigned: 0,
        }

  const idRol = userInfo.profileId

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profile: 0,
    occupation: 0,
    managerId: 0,
    customerId: 0,
    customerName: "",
  })

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getUsers(userId))
    }
  }, [userId])

  useEffect(() => {
    dispatch(getProfiles())
    dispatch(getGroups())
    dispatch(getPositions())
    if (!insert) {
      dispatch(getUser(id))
      setVisibleFields(idRol)
    }
    return () => {
      dispatch(addUserClean())
    }
  }, [id, idRol])

  useEffect(() => {
    changePositions(idRol)
  }, [idRol, positions])

  useEffect(() => {
    if (userInfo !== undefined && userInfo.groupId !== undefined) {
      setGroup(userInfo.groupId)
    }
  }, [userInfo.groupId])

  const handleSubmit = (event, errors, values) => {
    //dispatch(editUserClean())
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    const profileId = parseInt(values.profile)
    const occupation = parseInt(values.occupation)
    const userByRole =
      profileId == 5 || profileId == 6 || profileId == 7
        ? parseInt(values.userByRole)
        : null

    let idhCustomer =
      profileId == 7 || profileId == 8 ? values.idhCustomer : null

    let request = {
      nombre: values.firstName,
      apellido: values.lastName,
      email: values.email,
      idRol: profileId,
      idGrupo: showGroup && insert ? parseInt(values.group) : 0,
      returnUri: `${process.env.REACT_APP_URL}/restore-password`,
    }

    if (occupation !== null) {
      request = { ...request, idPosicion: occupation }
    }
    if (userByRole !== null && profileId == 5 && userByRole !== 0) {
      request = { ...request, idUserN4assigned: userByRole }
    }
    if (userByRole !== null && profileId == 6) {
      request = { ...request, idUserN5assigned: userByRole }
    }
    if (userByRole !== null && (profileId == 7 || profileId == 8)) {
      request = { ...request, idUserN6assigned: userByRole }
    }
    if (idhCustomer !== null) {
      request = { ...request, idhCliente: parseInt(idhCustomer) }
    }

    if (insert) {
      dispatch(addUser(request))
    } else {
      request = {
        ...request,
        idUsuario: id,
        idStatus: userInfo.idStatus,
      }
      dispatch(editUser(request))
    }
  }

  const assignedGroup = groups
    ? groups.find(groupElement => groupElement.idGrupo === group)
    : 0

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {insert && insertedUserError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar usuario: {insertedUserError}
        </div>
      )}
      {!insert && editUserError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al editar usuario: {editUserError}
        </div>
      )}
      <Row className="mb-3">
        <Col md={6}>
          <AvField
            name="firstName"
            value={userInfo.firstName}
            label="* Nombre:"
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
        <Col md={6}>
          <AvField
            name="lastName"
            value={userInfo.lastName}
            label="* Apellido:"
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <AvField
            name="email"
            value={userInfo.email}
            label="* Correo electrónico de la compañia:"
            className="form-control"
            placeholder=""
            type="email"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
              email: {
                value: true,
                errorMessage:
                  "Este campo debe contener un correo electrónico válido",
              },
            }}
          />
        </Col>
        <Col md={6}>
          {usersByRole !== undefined && (
            <AvField
              name="profile"
              value={userInfo.profileId}
              label="* Perfil:"
              className="form-control"
              placeholder=""
              type="select"
              onChange={event => {
                changeProfile(event)
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option key={0} value="">
                -- Seleccione perfil --
              </option>
              {profiles.map((element, index) => (
                <option value={element.profileId} key={element.profileId}>
                  {element.name} / {element.description}
                </option>
              ))}
            </AvField>
          )}
        </Col>
      </Row>
      {(n4Field || n5Field || n6Field) && (
        <Row className="mb-3">
          <Col md={12}>
            {usersByRole !== undefined && (
              <AvField
                name="userByRole"
                value={
                  idRol === 5
                    ? userInfo.n4Assigned
                    : idRol === 6
                    ? userInfo.n5Assigned
                    : userInfo.n6Assigned
                }
                label={`* Usuario responsable`}
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option>-- Seleccione usuario --</option>
                {usersByRole
                  .sort((a, b) =>
                    removeDiacritics(a.firstName.toLowerCase()) >
                    removeDiacritics(b.firstName.toLowerCase())
                      ? 1
                      : -1
                  )
                  .map((element, index) => {
                    if (element.groupId === 0 || !element.status) {
                      return
                    }
                    return (
                      <option value={element.userId} key={element.userId}>
                        {element.firstName} {element.lastName}
                      </option>
                    )
                  })}
                {profile === 5 && (
                  <option value={0} key={0}>
                    Ninguno
                  </option>
                )}
              </AvField>
            )}
          </Col>
        </Row>
      )}
      {(n6Field || n7Field) && (
        <Row className="mb-3">
          <Col md={12}>
            {clients !== undefined && (
              <AvField
                name="idhCustomer"
                value={userInfo.idh}
                label={`* Razón social`}
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option>-- Seleccione razón social del cliente --</option>
                {clients
                  .sort((a, b) =>
                    removeDiacritics(a.name.toLowerCase()) >
                    removeDiacritics(b.name.toLowerCase())
                      ? 1
                      : -1
                  )
                  .map((element, index) => (
                    <option value={element.idh} key={element.idh}>
                      {element.name}
                    </option>
                  ))}
              </AvField>
            )}
          </Col>
        </Row>
      )}

      <Row className="mb-3">
        <Col md={12}>
          {positions !== undefined && (
            <AvField
              name="occupation"
              value={userInfo.positionId}
              label="* Ocupación:"
              className="form-control"
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option>-- Seleccione ocupación --</option>
              {filteredPositions
                .sort((a, b) =>
                  removeDiacritics(a.name.toLowerCase()) >
                  removeDiacritics(b.name.toLowerCase())
                    ? 1
                    : -1
                )
                .map((element, index) => (
                  <option value={element.positionId} key={element.positionId}>
                    {element.name}
                  </option>
                ))}
            </AvField>
          )}
        </Col>
      </Row>
      {!insert && (
        <Row className="mb-3">
          <Col md={6}>
            Grupo:{" "}
            <strong>
              {assignedGroup ? assignedGroup.nombreGrupo : "Ninguno"}
            </strong>
          </Col>
          <p>
            <small>
              Para cambiar el grupo, por favor vaya al módulo de Grupos.
            </small>
          </p>
        </Row>
      )}
      {showGroup && insert && (
        <Row className="mb-3">
          <Col md={6}>
            {groups !== undefined && (
              <AvField
                name="group"
                value={group}
                defaultValue={group}
                label="* Grupo:"
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option value="">-- Seleccione grupo --</option>
                <option value={0} key={0}>
                  Ninguno
                </option>
                {groups
                  .sort((a, b) =>
                    removeDiacritics(a.nombreGrupo.toLowerCase()) >
                    removeDiacritics(b.nombreGrupo.toLowerCase())
                      ? 1
                      : -1
                  )
                  .map((element, index) => (
                    <option value={element.idGrupo} key={element.idGrupo}>
                      {element.nombreGrupo}
                    </option>
                  ))}
              </AvField>
            )}
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Button
            className={`float-right ${loading ? "disabled" : ""} btn-block`}
            type="submit"
          >
            {!loading && <span>Guardar</span>}
            {loading && <span>Guardando...</span>}
          </Button>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Link
            to="/users"
            className="btn btn-light btn-block"
            draggable="false"
          >
            Cancelar
          </Link>
        </Col>
      </Row>
      {done && insert && insertedUserError === "" && (
        <SweetAlert
          title="Usuario registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(addUserClean())
            props.history.push("/users")
          }}
        >
          <p>
            Hemos enviado un correo electrónico al usuario con las instrucciones
            para acceder a la plataforma.
          </p>
        </SweetAlert>
      )}

      {editUserDone && !insert && editUserError === "" && (
        <SweetAlert
          title="Usuario modificado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editUserClean())
            props.history.push("/users")
          }}
        >
          <p>El usuario ha sido modificado exitosamente.</p>
        </SweetAlert>
      )}
    </AvForm>
  )
}

UsersForm.propTypes = {
  id: PropTypes.number,
  insert: PropTypes.bool,
  history: PropTypes.object,
}

export default withRouter(UsersForm)
