import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"

import {
  deleteCarousel,
  deleteCarouselClean,
  listCarousels,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import CarouselOrderModal from "./CarouselOrderModal"

const CarouselListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [remove, handleRemove] = useState(undefined)
  const [orderScreen, setOrderScreen] = useState(false)
  const [carousels, setCarousels] = useState([])

  const { error, loading, done, preCarousels, deleteDone } = useSelector(
    state => ({
      error: state.CarouselsAll.listError,
      loading: state.CarouselsAll.loading,
      preCarousels: state.CarouselsAll.carousels,
      done: state.CarouselsAll.listDone,
      deleteDone: state.CarouselsAll.deleteDone,
    })
  )

  useEffect(() => {
    dispatch(listCarousels(false))
  }, [])

  useEffect(() => {
    const finalCarousel = preCarousels.map(carousel => {
      const carouselDate = moment(carousel.startDate).unix()
      return {
        ...carousel,
        carouselOrder: `${carousel.status ? "B" : "A"}-${carouselDate}`,
      }
    })
    setCarousels(finalCarousel)
  }, [preCarousels])

  console.log(carousels)

  const editAction = (cell, row, rowIndex, extraData) => {
    return (
      <Link
        key={`edit-${row.carouselId}`}
        to={`/carousel/edit/${row.carouselId}`}
        className="btn btn-primary"
        draggable="false"
      >
        <i className="fa fa-edit"></i>
      </Link>
    )
  }

  const deleteAction = (cell, row, rowIndex, extraData) => {
    return (
      <button
        key={`delete-${row.carouselId}`}
        onClick={() => handleRemove(row.carouselId)}
        className="btn btn-primary"
      >
        <i className="fa fa-trash"></i>
      </button>
    )
  }

  if (deleteDone) {
    handleRemove(undefined)
    dispatch(deleteCarouselClean())
    dispatch(listCarousels(false))
  }

  const columns = [
    {
      dataField: "carouselId",
      text: "ID",
      sort: false,
      hidden: true,
    },
    {
      dataField: "carouselOrder",
      text: "Order",
      sort: true,
      hidden: true,
    },
    {
      dataField: "carouselName",
      text: "Nombre del carrusel",
      sort: true,
    },
    {
      dataField: "carouselType",
      text: "Tipo",
      sort: true,
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.carouselId}`}>
            <span
              className={`pt-2 badge bg-${row.status ? "success" : "warning"}`}
            >
              {row.status ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "startDate",
      text: "Periodo",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div>
            Del {moment(row.startDate).format("DD/MM/YYYY")} al{" "}
            {moment(row.endDate).format("DD/MM/YYYY")}
          </div>
        )
      },
    },
    {
      dataField: "editAction",
      text: "Editar",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: editAction,
    },
    {
      dataField: "deleteAction",
      text: "Eliminar",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: deleteAction,
    },
  ]

  const pageOptions = {
    sizePerPage: 25,
    totalSize: carousels?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [
    {
      dataField: "carouselOrder",
      order: "desc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  return (
    <Fragment>
      {loading || carousels === undefined ? (
        <div className="mt-3 mb-3">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      ) : (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="carouselId"
          columns={columns}
          data={carousels}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="carouselId"
              columns={columns}
              data={carousels}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2 pt-3">
                    <Col lg={2} md={3} sm={4} xs={6}>
                      <div className="d-grid">
                        <Link
                          className="btn btn-primary btn-rounded"
                          to={`/carousel/add`}
                          draggable="false"
                        >
                          <i className="fa fa-plus"></i> Agregar carrusel
                        </Link>
                      </div>
                    </Col>
                    <Col lg={2} md={3} sm={4} xs={6}>
                      <div className="d-grid">
                        <button
                          className="btn btn-secondary btn-rounded"
                          onClick={() => setOrderScreen(true)}
                        >
                          <i className="fa fa-sort"></i> Ordenar carrusel
                        </button>
                      </div>
                    </Col>
                    <Col
                      lg={{ size: 2, offset: 8 }}
                      md={{ size: 3, offset: 6 }}
                      sm={{ size: 4, offset: 4 }}
                      xs={6}
                    >
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder="Buscar"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <Fragment>
                        <BootstrapTable
                          keyField={"carouselId"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {carousels?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}
      <CarouselOrderModal
        carousels={carousels}
        show={orderScreen}
        onConfirm={() => {
          setOrderScreen(false)
          dispatch(listCarousels(false))
        }}
      />
      {remove !== undefined && (
        <SweetAlert
          title="Eliminar carrusel"
          danger
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={() => dispatch(deleteCarousel(remove))}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => handleRemove(undefined)}
        >
          <p>¿Confirmas la eliminación de este elemento de carrusel?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CarouselListTable.propTypes = {
  carousels: PropTypes.array,
}

export default CarouselListTable
