import {
  EDIT_QUIZZ,
  EDIT_QUIZZ_SUCCESS,
  EDIT_QUIZZ_CLEAN,
  EDIT_QUIZZ_ERROR,
} from "./actionTypes"

export const editQuizz = form => ({
  type: EDIT_QUIZZ,
  payload: { form },
})

export const editQuizzSuccess = quizz => {
  return {
    type: EDIT_QUIZZ_SUCCESS,
    payload: { quizz },
  }
}

export const editQuizzClean = () => {
  return {
    type: EDIT_QUIZZ_CLEAN,
    payload: {},
  }
}

export const editQuizzError = error => {
  return {
    type: EDIT_QUIZZ_ERROR,
    payload: error,
  }
}
