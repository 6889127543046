export const MY_PROFILE = "MY_PROFILE"
export const MY_PROFILE_SUCCESS = "MY_PROFILE_SUCCESS"
export const MY_PROFILE_ERROR = "MY_PROFILE_ERROR"
export const MY_PROFILE_RESET = "MY_PROFILE_RESET"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"
export const ACCEPT_PRIVACY_TYC = "ACCEPT_PRIVACY_TYC"
export const ACCEPT_PRIVACY_TYC_SUCCESS = "ACCEPT_PRIVACY_TYC_SUCCESS"
export const ACCEPT_PRIVACY_TYC_ERROR = "ACCEPT_PRIVACY_TYC_ERROR"
export const ACCEPT_PRIVACY_TYC_RESET = "ACCEPT_PRIVACY_TYC_RESET"
