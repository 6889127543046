/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_IMAGE } from "./actionTypes"
import { apiError, addImageSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addImage } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addImage, action.payload.form)
    if (response.statusCode !== undefined)
      throw new Error("No se realizó la carga correctamente")
    yield put(addImageSuccess(response.image))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* imageAddSaga() {
  yield takeEvery(ADD_IMAGE, add)
}

export default imageAddSaga
