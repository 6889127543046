import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = ({ periodData }) => {
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Juan Juárez",
        "Fernanda Ramírez",
        "Ana Luisa Castillo",
        "Sebastián Lemus",
        "Andrea Guerrero",
        "Judith López",
        "Marcos García",
      ],
      labels: {
        show: true,
      },
    },
    colors: ["#34c38f", "#f1b44c", "#f46a6a"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
