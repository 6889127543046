import {
  ADD_KNOWLEDGE_CATEGORY,
  ADD_KNOWLEDGE_CATEGORY_SUCCESS,
  ADD_KNOWLEDGE_CATEGORY_CLEAN,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  group: {},
}

const KnowledgeCategoryAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_KNOWLEDGE_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_KNOWLEDGE_CATEGORY_SUCCESS:
      state = {
        ...state,
        knowledgeCategory: action.payload.knowledgeCategory,
        loading: false,
        done: true,
      }
      break
    case ADD_KNOWLEDGE_CATEGORY_CLEAN:
      state = initialState
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default KnowledgeCategoryAdd
