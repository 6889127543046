import {
  EDIT_LESSON,
  EDIT_LESSON_SUCCESS,
  EDIT_LESSON_CLEAN,
  EDIT_LESSON_ERROR,
  EDIT_LESSON_POSITIONS,
  EDIT_LESSON_POSITIONS_SUCCESS,
  EDIT_LESSON_POSITIONS_CLEAN,
  EDIT_LESSON_POSITIONS_ERROR,
  LESSON_START,
  LESSON_START_SUCCESS,
  LESSON_START_CLEAN,
  LESSON_START_ERROR,
  LESSON_FINISH,
  LESSON_FINISH_SUCCESS,
  LESSON_FINISH_CLEAN,
  LESSON_FINISH_ERROR,
} from "./actionTypes"

export const editLesson = form => ({
  type: EDIT_LESSON,
  payload: { form },
})

export const editLessonSuccess = lesson => {
  return {
    type: EDIT_LESSON_SUCCESS,
    payload: { lesson },
  }
}

export const editLessonClean = () => {
  return {
    type: EDIT_LESSON_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_LESSON_ERROR,
    payload: error,
  }
}

export const editLessonPositions = form => ({
  type: EDIT_LESSON_POSITIONS,
  payload: { form },
})

export const editLessonPositionsSuccess = lesson => {
  return {
    type: EDIT_LESSON_POSITIONS_SUCCESS,
    payload: { lesson },
  }
}

export const editLessonPositionsClean = () => {
  return {
    type: EDIT_LESSON_POSITIONS_CLEAN,
    payload: {},
  }
}

export const editLessonPositionsError = error => {
  return {
    type: EDIT_LESSON_POSITIONS_ERROR,
    payload: error,
  }
}

export const lessonStart = form => ({
  type: LESSON_START,
  payload: { form },
})

export const lessonStartSuccess = lesson => {
  return {
    type: LESSON_START_SUCCESS,
    payload: { lesson },
  }
}

export const lessonStartClean = () => {
  return {
    type: LESSON_START_CLEAN,
    payload: {},
  }
}

export const lessonStartError = error => {
  return {
    type: LESSON_START_ERROR,
    payload: error,
  }
}

export const lessonFinish = form => ({
  type: LESSON_FINISH,
  payload: { form },
})

export const lessonFinishSuccess = lesson => {
  return {
    type: LESSON_FINISH_SUCCESS,
    payload: { lesson },
  }
}

export const lessonFinishClean = () => {
  return {
    type: LESSON_FINISH_CLEAN,
    payload: {},
  }
}

export const lessonFinishError = error => {
  return {
    type: LESSON_FINISH_ERROR,
    payload: error,
  }
}