/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_BLOCKS } from "./actionTypes"
import { apiError, getBlocksSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getBlocks } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(getBlocks, action.payload.idLesson)
    yield put(getBlocksSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* blocksListSaga() {
  yield takeEvery(GET_BLOCKS, get)
}

export default blocksListSaga
