import {
  EDIT_POLL_ANSWER,
  EDIT_POLL_ANSWER_SUCCESS,
  EDIT_POLL_ANSWER_CLEAN,
  EDIT_POLL_ANSWER_ERROR,
} from "./actionTypes"

export const editPollAnswer = form => ({
  type: EDIT_POLL_ANSWER,
  payload: { form },
})

export const editPollAnswerSuccess = pollAnswer => {
  return {
    type: EDIT_POLL_ANSWER_SUCCESS,
    payload: { pollAnswer },
  }
}

export const editPollAnswerClean = () => {
  return {
    type: EDIT_POLL_ANSWER_CLEAN,
    payload: {},
  }
}

export const editPollAnswerError = error => {
  return {
    type: EDIT_POLL_ANSWER_ERROR,
    payload: error,
  }
}
