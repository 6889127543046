import {
  ADD_SHOPPING_AREA,
  ADD_SHOPPING_AREA_SUCCESS,
  ADD_SHOPPING_AREA_CLEAN,
  ADD_SHOPPING_AREA_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  shoppingArea: {},
}

const ShoppingAreaAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SHOPPING_AREA:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_SHOPPING_AREA_SUCCESS:
      state = {
        ...state,
        shoppingArea: action.payload.shoppingArea,
        loading: false,
        done: true,
      }
      break
    case ADD_SHOPPING_AREA_CLEAN:
      state = initialState
      break
    case ADD_SHOPPING_AREA_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ShoppingAreaAdd
