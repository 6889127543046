/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_REPORT10 } from "./actionTypes"
import { listReport10Error, listReport10Success } from "./actions"

//Include Both Helper File with needed methods
import { report10NewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(report10NewApi, request.payload.form)
    if (response === undefined) throw new Error("API error")
    yield put(listReport10Success(response))
  } catch (error) {
    console.log(error.message)
    yield put(listReport10Error(error.message))
  }
}

function* report10ListSaga() {
  yield takeEvery(LIST_REPORT10, list)
}

export default report10ListSaga
