import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_CLEAN,
  GET_CUSTOMER_ERROR,
} from "./actionTypes"

export const getCustomer = form => ({
  type: GET_CUSTOMER,
  payload: { form },
})

export const getCustomerSuccess = course => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: { course },
  }
}

export const getCustomerClean = () => {
  return {
    type: GET_CUSTOMER_CLEAN,
    payload: {},
  }
}

export const getCustomerError = error => {
  return {
    type: GET_CUSTOMER_ERROR,
    payload: error,
  }
}
