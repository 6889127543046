import {
  EDIT_SHOPPING_AREA,
  EDIT_SHOPPING_AREA_SUCCESS,
  EDIT_SHOPPING_AREA_CLEAN,
  EDIT_SHOPPING_AREA_ERROR,
} from "./actionTypes"

export const editShoppingArea = form => ({
  type: EDIT_SHOPPING_AREA,
  payload: { form },
})

export const editShoppingAreaSuccess = shoppingArea => {
  return {
    type: EDIT_SHOPPING_AREA_SUCCESS,
    payload: { shoppingArea },
  }
}

export const editShoppingAreaClean = () => {
  return {
    type: EDIT_SHOPPING_AREA_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_SHOPPING_AREA_ERROR,
    payload: error,
  }
}
