import { GET_PROFILES, GET_PROFILES_SUCCESS, API_ERROR } from "./actionTypes"

export const getProfiles = () => ({
  type: GET_PROFILES,
  payload: {},
})

export const getProfilesSuccess = profiles => {
  return {
    type: GET_PROFILES_SUCCESS,
    payload: { profiles },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
