import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { listCarousels } from "store/actions"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import moment from "moment"
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap"
import { set } from "lodash"

const DashboardCarousel = props => {
  const dispatch = useDispatch()
  const [loadedCarousel, setLoadedCarousel] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const { error, loading, done, carousels } = useSelector(state => ({
    error: state.CarouselsAll.listError,
    loading: state.CarouselsAll.loading,
    carousels: state.CarouselsAll.carousels,
    done: state.CarouselsAll.listDone,
  }))

  useEffect(() => {
    if (!loadedCarousel) {
      dispatch(listCarousels(true))
    }
  }, [loadedCarousel])

  carousels.sort((a, b) => (a.order < b.order ? -1 : 1))

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === carousels.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? carousels.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  return (
    <Fragment>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {carousels.map((carousel, index) => {
          return (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={carousel.carouselId}
            >
              <div className="row">
                <div className="col-12">
                  <div
                    onClick={() => {
                      const resourceType = carousel.resourceType ?? "Archivo"
                      if (resourceType == "Archivo") {
                        window.open(carousel.pdfPath, "_blank")
                      }
                      if (resourceType == "Link") {
                        window.open(carousel.linkPath, "_blank")
                      }
                      if (resourceType == "Curso") {
                        props.history.push(
                          `/my-courses/info/${carousel.courseId}`
                        )
                      }
                    }}
                    className="carousel-image-div"
                    style={{
                      backgroundImage: `url(${carousel.imagePath})`,
                    }}
                  ></div>
                </div>
              </div>
            </CarouselItem>
          )
        })}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
      <style jsx>
        {`
          .carousel-image-div {
            width: 100%;
            height: 400px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
          }
          @media (max-width: 480px) and (orientation: portrait) {
            .carousel-image-div {
              background-size: contain;
              background-position: center;
              height: 200px;
              width: 100%;
            }
          }
          @media (min-width: 481px) and (max-width: 960px) and (orientation: portrait) {
            .carousel-image-div {
              background-size: cover;
              background-position: left;
              height: 320px;
            }
          }
          @media (max-width: 960px) and (orientation: landscape) {
            .carousel-image-div {
              background-size: cover;
              background-position: left;
              height: 240px;
            }
          }
        `}
      </style>
      <div className="mt-2 mb-4"></div>
    </Fragment>
  )
}

DashboardCarousel.propTypes = {
  history: PropTypes.object,
}

export default withRouter(DashboardCarousel)
