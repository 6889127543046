import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import "../../assets/scss/datatables.scss"
import { numberWithCommas } from "common/strings"

const ReportAListTable = props => {
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const report = props.report
  const loading = props.loading

  const columns = [
    {
      dataField: "clave_curso",
      text: "ID Curso Henkel",
      sort: true,
    },
    {
      dataField: "nombre_curso",
      text: "Nombre curso",
      sort: true,
    },
    {
      dataField: "total_aprobados_x_curso",
      text: "Total aprobados por curso",
      sort: true,
    },
    {
      dataField: "total_veces_tomaron_curso_para_aprobar",
      text: "Total de veces cursado para aprobar",
      sort: true,
    },
    {
      dataField: "N1",
      text: "Usuarios N1",
      sort: true,
    },
    {
      dataField: "N2",
      text: "Usuarios N2",
      sort: true,
    },
    {
      dataField: "N3",
      text: "Usuarios N3",
      sort: true,
    },
    {
      dataField: "N4",
      text: "Usuarios N4",
      sort: true,
    },
    {
      dataField: "N5",
      text: "Usuarios N5",
      sort: true,
    },
    {
      dataField: "N6",
      text: "Usuarios N6",
      sort: true,
    },
    {
      dataField: "N7",
      text: "Usuarios N7",
      sort: true,
    },
    {
      dataField: "N8",
      text: "Usuarios N8",
      sort: true,
    },
    {
      dataField: "N1",
      text: "Total de usuarios",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span>
            {row.N1 +
              row.N2 +
              row.N3 +
              row.N4 +
              row.N5 +
              row.N6 +
              row.N7 +
              row.N8}
          </span>
        )
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const totalRowA =
          rowA.N1 +
          rowA.N2 +
          rowA.N3 +
          rowA.N4 +
          rowA.N5 +
          rowA.N6 +
          rowA.N7 +
          rowA.N8
        const totalRowB =
          rowB.N1 +
          rowB.N2 +
          rowB.N3 +
          rowB.N4 +
          rowB.N5 +
          rowB.N6 +
          rowB.N7 +
          rowB.N8
        if (order === "asc") {
          return totalRowB - totalRowA
        }
        return totalRowA - totalRowB // desc
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: report.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setPage(1)
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "clave_curso", order: "asc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return loading && report === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField="clave_curso"
      columns={columns}
      data={report}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider keyField="clave_curso" columns={columns} data={report}>
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  <p>
                    <span>
                      Mostrando{" "}
                      <strong>{numberWithCommas(report.length)}</strong>{" "}
                      elementos
                    </span>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"clave_curso"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                    {report?.length <= 0 && (
                      <Row>
                        <Col md={12} className="text-center">
                          -- No hay resultados --
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                </Col>
              </Row>

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}

ReportAListTable.propTypes = {
  report: PropTypes.array,
  loading: PropTypes.bool,
}

export default ReportAListTable
