/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_REPORT1 } from "./actionTypes"
import { listReport1Error, listReport1Success } from "./actions"

//Include Both Helper File with needed methods
import { report1NewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(report1NewApi, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (response.length === undefined) throw new Error(response)
    yield put(listReport1Success(response))
  } catch (error) {
    console.log(error.message)
    yield put(listReport1Error(error.message))
  }
}

function* report1ListSaga() {
  yield takeEvery(LIST_REPORT1, list)
}

export default report1ListSaga
