import {
  REMOVE_QUIZZ_ANSWER,
  REMOVE_QUIZZ_ANSWER_SUCCESS,
  REMOVE_QUIZZ_ANSWER_CLEAN,
  REMOVE_QUIZZ_ANSWER_ERROR,
} from "./actionTypes"

export const removeQuizzAnswer = form => ({
  type: REMOVE_QUIZZ_ANSWER,
  payload: { form },
})

export const removeQuizzAnswerSuccess = answer => {
  return {
    type: REMOVE_QUIZZ_ANSWER_SUCCESS,
    payload: { answer },
  }
}

export const removeQuizzAnswerClean = () => {
  return {
    type: REMOVE_QUIZZ_ANSWER_CLEAN,
    payload: {},
  }
}

export const removeQuizzAnswerError = error => {
  return {
    type: REMOVE_QUIZZ_ANSWER_ERROR,
    payload: error,
  }
}
