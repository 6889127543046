import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Col, Row, Table } from "reactstrap"
import GammificationDetailModal from "./GammificationDetailModal"
import GammificationParticipantsListTable from "./GammificationParticipantsListTable"
import GammificationClientsListTable from "./GammificationClientsListTable"

const GammificationBeneficiaries = () => {
  const [toggle, setToggle] = useState(0)
  const [detailModal, showDetailModal] = useState(undefined)

  const { rules, periods } = useSelector(state => ({
    rules: state.GamificationRules.rules,
    periods: state.GamificationPeriods.periods,
  }))

  const handleToggle = periodId => {
    if (toggle === periodId) {
      setToggle(0)
      return
    }
    setToggle(periodId)

    // CALL LIST OF BENEFICIARIES
  }

  return (
    <div id="accordion">
      {periods.map((period, index) => {
        return (
          <div className="card" key={index}>
            <div className="card-header p-0" id={`heading-${period.periodId}`}>
              <h5 className="mb-0">
                <button
                  className="btn btn-light btn-block text-left d-flex justify-content-start"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapse-${period.periodId}`}
                  aria-expanded="true"
                  aria-controls={`collapse-${period.periodId}`}
                  onClick={() => handleToggle(period.periodId)}
                  style={{
                    color: "#ff000f",
                    borderColor: "#ff000f",
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <i
                    className={`pt-1 fa fa-${
                      toggle === period.periodId ? "minus" : "plus"
                    }`}
                  ></i>{" "}
                  <div className="">&nbsp;&nbsp;{period.periodName}</div>
                </button>
              </h5>
            </div>

            <div
              id={`collapse-${period.periodId}`}
              className={`collapse ${toggle === period.periodId ? "show" : ""}`}
              aria-labelledby={`heading-${period.periodId}`}
              data-parent="#accordion"
              style={{
                borderColor: "#ff000f",
                borderWidth: "0 1px 1px 1px",
                borderStyle: "solid",
              }}
            >
              <div className="card-body">
                <Row>
                  <Col>
                    <h2 className="text-center mb-4">
                      Top 5 de usuarios con más puntos - {period.periodName}
                    </h2>
                    <p className="text-center">
                      Listado de usuarios que generaron más puntos en{" "}
                      <strong>{period.periodName}</strong>. Puede ordenar por
                      columnas y/o buscar por palabras clave
                    </p>
                  </Col>
                  <br />
                  {toggle === period.periodId && (
                    <GammificationParticipantsListTable
                      limit={true}
                      periodId={period.periodId}
                      periodName={period.periodName}
                      onShowDetail={user => showDetailModal(user)}
                    />
                  )}
                </Row>
                <Row className="mt-4">
                  <Col>
                    <h2 className="text-center mb-4">
                      Top 5 de clientes con más puntos - {period.periodName}
                    </h2>
                    <p className="text-center">
                      Listado de clientes que generaron más puntos en{" "}
                      <strong>{period.periodName}</strong>. Puede ordenar por
                      columnas y/o buscar por palabras clave
                    </p>
                  </Col>
                  <br />
                  {toggle === period.periodId && (
                    <GammificationClientsListTable
                      periodId={period.periodId}
                      limit={true}
                    />
                  )}
                </Row>
              </div>
            </div>
          </div>
        )
      })}
      {detailModal !== undefined && (
        <GammificationDetailModal
          user={detailModal}
          show={true}
          onClose={() => {
            showDetailModal(undefined)
          }}
        />
      )}
    </div>
  )
}

export default GammificationBeneficiaries
