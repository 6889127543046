/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SKILLS } from "./actionTypes"
import { apiError, getSkillsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getSkills } from "../../../helpers/backend_helper"

function* skills() {
  try {
    const response = yield call(getSkills)
    yield put(getSkillsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* skillsSaga() {
  yield takeEvery(GET_SKILLS, skills)
}

export default skillsSaga
