import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const ForumGroup = () => {
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const statuses = [
    {
      id: 1,
      stausTitle: "Dudas generales",
      iconClass: "fa-book-open",
      description: "¿Cómo aplicar los cursos de forma correcta?",
    },
    {
      id: 2,
      stausTitle: "Después de finalizar",
      iconClass: "fa-check-square",
      description: "¿Qué hacer después de haber finalizado el curso?",
    },
    {
      id: 3,
      stausTitle: "Problemas técnicos",
      iconClass: "fa-exclamation",
      description: "¿Prestas algún problema al ingresar a los cursos?",
    },
    {
      id: 4,
      stausTitle: "Sobre la plataforma",
      iconClass: "fa-laptop",
      description: "¿Tienes alguna duda sobre cómo utilizar la plataforma?",
    },
  ]

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-5">Foros de discusión</CardTitle>
            <div className="">
              <ul className="verti-timeline list-unstyled">
                {/* Render Horizontal Timeline Events */}
                {statuses.map((status, key) => (
                  <li key={key} className="event-list">
                    <div className="event-timeline-dot">
                      <i className={"bx bx-right-arrow-circle"} />
                    </div>
                    <div className="media">
                      <div className="me-3">
                        <i
                          className={
                            "fas " + status.iconClass + " alt-celf-color"
                          }
                        />
                      </div>
                      <div className="media-body">
                        <div>
                          <h5>{status.stausTitle}</h5>
                          <p className="text-muted">{status.description}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
      <style jsx="true">{`
        .event-list {
          font-size: 22px;
        }
      `}</style>
    </Row>
  )
}

export default ForumGroup
