import {
  LIST_GROUP_COURSES,
  LIST_GROUP_COURSES_SUCCESS,
  LIST_GROUP_COURSES_CLEAN,
  LIST_GROUP_COURSES_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  groupCourses: undefined,
}

const GroupCoursesList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_GROUP_COURSES:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_GROUP_COURSES_SUCCESS:
      state = {
        ...state,
        groupCourses: action.payload.groupCourses,
        loading: false,
        done: true,
      }
      break
    case LIST_GROUP_COURSES_CLEAN:
      state = initialState
      break
    case LIST_GROUP_COURSES_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupCoursesList
