/* RULES */
export const LIST_RULES = "LIST_RULES"
export const LIST_RULES_SUCCESS = "LIST_RULES_SUCCESS"
export const LIST_RULES_CLEAN = "LIST_RULES_CLEAN"
export const LIST_RULES_ERROR = "LIST_RULES_ERROR"

export const EDIT_RULE = "EDIT_RULE"
export const EDIT_RULE_SUCCESS = "EDIT_RULE_SUCCESS"
export const EDIT_RULE_CLEAN = "EDIT_RULE_CLEAN"
export const EDIT_RULE_ERROR = "EDIT_RULE_ERROR"
