import {
  ADD_BLOCK,
  ADD_BLOCK_SUCCESS,
  ADD_BLOCK_CLEAN,
  ADD_BLOCK_ERROR,
} from "./actionTypes"

export const addBlock = form => ({
  type: ADD_BLOCK,
  payload: { form },
})

export const addBlockSuccess = block => {
  return {
    type: ADD_BLOCK_SUCCESS,
    payload: { block },
  }
}

export const addBlockClean = () => {
  return {
    type: ADD_BLOCK_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_BLOCK_ERROR,
    payload: error,
  }
}
