import {
  ADD_BLOCK,
  ADD_BLOCK_SUCCESS,
  ADD_BLOCK_CLEAN,
  ADD_BLOCK_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  lesson: {},
}

const BlockAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BLOCK:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_BLOCK_SUCCESS:
      state = {
        ...state,
        block: action.payload.block,
        loading: false,
        done: true,
      }
      break
    case ADD_BLOCK_CLEAN:
      state = initialState
      break
    case ADD_BLOCK_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default BlockAdd
