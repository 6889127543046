/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_POLLS } from "./actionTypes"
import { apiError, listPollsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getPolls } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(getPolls, request.payload.idCourse)
    if (response == undefined) throw new Error("API error")
    yield put(listPollsSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* pollsListSaga() {
  yield takeEvery(LIST_POLLS, list)
}

export default pollsListSaga
