import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
} from "./actionTypes"

export const getCustomers = active => ({
  type: GET_CUSTOMERS,
  payload: { active: active == undefined },
})

export const getCustomersSuccess = customers => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload: { customers },
  }
}

export const apiError = error => {
  return {
    type: GET_CUSTOMERS_ERROR,
    payload: error,
  }
}
