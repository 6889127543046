import {
  ADD_QUIZZ_ANSWER_USER,
  ADD_QUIZZ_ANSWER_USER_SUCCESS,
  ADD_QUIZZ_ANSWER_USER_CLEAN,
  ADD_QUIZZ_ANSWER_USER_ERROR,
} from "./actionTypes"

export const addQuizzAnswerUser = form => ({
  type: ADD_QUIZZ_ANSWER_USER,
  payload: { form },
})

export const addQuizzAnswerUserSuccess = answerUser => {
  return {
    type: ADD_QUIZZ_ANSWER_USER_SUCCESS,
    payload: { answerUser },
  }
}

export const addQuizzAnswerUserClean = () => {
  return {
    type: ADD_QUIZZ_ANSWER_USER_CLEAN,
    payload: {},
  }
}

export const addQuizzAnswerUserError = error => {
  return {
    type: ADD_QUIZZ_ANSWER_USER_ERROR,
    payload: error,
  }
}
