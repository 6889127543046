import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { Row, Col, Modal } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"
import { getPeriodParticipants } from "store/actions"
import GammificationDetailModal from "./GammificationDetailModal"
import XlsExporter from "components/Common/XlsExporter"

const GammificationParticipantsListTable = props => {
  const limit = props.limit ?? false
  const dispatch = useDispatch()
  const [finalParticipants, setFinalParticipants] = useState([])

  const { participants, loading } = useSelector(state => ({
    participants: state.GamificationPeriods.participants,
    loading: state.GamificationPeriods.loading,
  }))

  useEffect(() => {
    dispatch(getPeriodParticipants(props.periodId))
  }, [props.periodId])

  useEffect(() => {
    const dummyParticipants = participants
    dummyParticipants.sort((a, b) => {
      const criteria1 = b.puntos_totales - a.puntos_totales
      if (criteria1 !== 0) return criteria1
      const criteria2 =
        moment(a.fecha_puntos).unix() - moment(b.fecha_puntos).unix()

      return criteria2
    })

    for (let i = 0; i < dummyParticipants.length; i++) {
      dummyParticipants[i].ranking = i + 1
    }

    const dummyParticipants2 = limit
      ? dummyParticipants.slice(0, 5).map((participant, index) => participant)
      : dummyParticipants

    setFinalParticipants(dummyParticipants2)
  }, [participants])

  const getColor = ranking => {
    if (ranking === 1) {
      return { background: "#34c38f", color: "white" }
    }
    if (ranking === 2 || ranking === 3 || ranking === 4 || ranking === 5) {
      return { background: "#f1b44c", color: "black" }
    }
  }

  const { SearchBar } = Search
  const columns = [
    {
      dataField: "ranking",
      text: "Ranking",
      sort: true,
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <Fragment>
            {row.nombre_usuario} {row.apellido_usuario}
          </Fragment>
        )
      },
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "nombre_grupo",
      text: "Grupo",
      sort: true,
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "nombre_distribuidor",
      text: "Cliente",
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
        return row.nombre_distribuidor ?? "N/A"
      },
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "puntos_totales",
      text: "Puntos",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, rowIndex, extraData) => {
        return row.puntos_totales == null ? "0" : row.puntos_totales
      },
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "fecha_puntos",
      text: "Última fecha de puntos",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return row.fecha_puntos == null ? (
          "-- Sin fecha --"
        ) : (
          <Fragment>
            {moment(row.fecha_puntos).format("DD/MM/YYYY HH:mm:ss")} hrs.
          </Fragment>
        )
      },
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "posicion",
      text: "Ocupación",
      sort: true,
      align: "center",
      headerAlign: "center",
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "nombre_periodo",
      text: "Periodo",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, rowIndex, extraData) => {
        return row.nombre_periodo == null
          ? props.periodName
          : row.nombre_periodo
      },
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
    {
      dataField: "userId",
      text: "Detalle",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <button
            className="btn btn-light"
            onClick={() => {
              console.log(props)
              props.onShowDetail(row)
            }}
          >
            <i className="fa fa-eye"></i> Ver detalle
          </button>
        )
      },
      style: function callback(cell, row, rowIndex, colIndex) {
        return getColor(row.ranking)
      },
    },
  ]

  const getXlsData = participants => {
    let data = participants.map((row, index) => {
      return {
        Ranking: row.ranking,
        Nombre: `${row.nombre_usuario} ${row.apellido_usuario}`,
        Grupo: row.nombre_grupo,
        Cliente: row.nombre_distribuidor ?? "N/A",
        Puntos: row.puntos_totales ?? 0,
        "Última fecha de puntos": row.fecha_puntos
          ? moment(row.fecha_puntos).format("DD/MM/YYYY HH:mm:ss")
          : "-- Sin fecha --",
        Ocupación: row.posicion,
        Periodo: row.nombre_periodo ?? props.periodName,
      }
    })

    if (limit) {
      return {
        Ganadores: data,
      }
    }

    return {
      Participantes: data,
    }
  }

  const pageOptions = {
    sizePerPage: 25,
    totalSize: finalParticipants?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "ranking", order: "asc" }]

  return loading || finalParticipants === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="IdUsuario"
        columns={columns}
        data={finalParticipants}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="IdUsuario"
            columns={columns}
            data={finalParticipants}
            search
          >
            {toolkitProps => (
              <Fragment>
                <Row className="mb-2 pt-3">
                  <Col>
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                {finalParticipants !== undefined && (
                  <Row>
                    <Col className="d-flex justify-content-end pr-4">
                      <XlsExporter
                        data={getXlsData(finalParticipants)}
                        dataName={limit ? "Ganadores" : "Participantes"}
                        name={limit ? "Ganadores" : "Participantes"}
                        label="Exportar lista"
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"IdUsuario"}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {finalParticipants?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <style jsx>
        {`
          .position-0 {
            background-color: #34c38f;
            color: white;
          }
          .position-1,
          .position-2,
          .position-3,
          .position-4 {
            background-color: #f1b44c;
            color: black;
          }
          .gray {
            background-color: #f2f2f5;
          }
        `}
      </style>
    </Fragment>
  )
}

GammificationParticipantsListTable.propTypes = {
  loading: PropTypes.bool,
  periodId: PropTypes.number,
  periodName: PropTypes.string,
  onShowDetail: PropTypes.func,
  limit: PropTypes.bool,
}

export default GammificationParticipantsListTable
