import {
  LIST_GROUP_COURSES,
  LIST_GROUP_COURSES_SUCCESS,
  LIST_GROUP_COURSES_CLEAN,
  LIST_GROUP_COURSES_ERROR,
} from "./actionTypes"

export const listGroupCourses = (idGroup, idCourse) => ({
  type: LIST_GROUP_COURSES,
  payload: { idGroup, idCourse },
})

export const listGroupCoursesSuccess = groupCourses => {
  return {
    type: LIST_GROUP_COURSES_SUCCESS,
    payload: { groupCourses },
  }
}

export const listGroupCoursesClean = () => {
  return {
    type: LIST_GROUP_COURSES_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_GROUP_COURSES_ERROR,
    payload: error,
  }
}
