/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_QUIZZ } from "./actionTypes"
import { removeQuizzError, removeQuizzSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deleteQuizzNewApi } from "../../../helpers/backend_helper"

function* remove(request) {
  try {
    const response = yield call(deleteQuizzNewApi, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(removeQuizzSuccess(response))
  } catch (error) {
    yield put(removeQuizzError(error.message))
  }
}

function* quizzRemoveSaga() {
  yield takeEvery(REMOVE_QUIZZ, remove)
}

export default quizzRemoveSaga
