import {
  LIST_GROUP_PARTICIPANTS,
  LIST_GROUP_PARTICIPANTS_SUCCESS,
  LIST_GROUP_PARTICIPANTS_CLEAN,
  LIST_GROUP_PARTICIPANTS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  groupParticipants: undefined,
}

const GroupParticipantsList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_GROUP_PARTICIPANTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_GROUP_PARTICIPANTS_SUCCESS:
      state = {
        ...state,
        groupParticipants: action.payload.groupParticipants,
        loading: false,
        done: true,
      }
      break
    case LIST_GROUP_PARTICIPANTS_CLEAN:
      state = initialState
      break
    case LIST_GROUP_PARTICIPANTS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupParticipantsList
