import {
  REMOVE_NOTE,
  REMOVE_NOTE_SUCCESS,
  REMOVE_NOTE_CLEAN,
  REMOVE_NOTE_ERROR,
} from "./actionTypes"

export const removeNote = id => ({
  type: REMOVE_NOTE,
  payload: { id },
})

export const removeNoteSuccess = note => {
  return {
    type: REMOVE_NOTE_SUCCESS,
    payload: { note },
  }
}

export const removeNoteClean = () => {
  return {
    type: REMOVE_NOTE_CLEAN,
    payload: {},
  }
}

export const removeNoteError = error => {
  return {
    type: REMOVE_NOTE_ERROR,
    payload: error,
  }
}
