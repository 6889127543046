import { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { createHash } from "crypto"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useParams } from "react-router-dom"
import * as Sentry from "@sentry/browser"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import {
  loginUser,
  loginClean,
  me,
  getCourse,
  getExam,
  getExamClean,
  getCourseClean,
  getUser,
  getCoursesByUser,
} from "../../store/actions"
import SessionFooter from "components/Common/SessionFooter"
import ModalCertificatePdf from "components/Courses/ModalCertificatePdf"

import resistolLogo from "../../assets/images/logo_resistol.png"
import prittLogo from "../../assets/images/logo_pritt.png"
import sistaLogo from "../../assets/images/logo_sista.png"
import tangitLogo from "../../assets/images/logo_tangit.png"

const CertificateValidation = props => {
  const dispatch = useDispatch()

  const { sello, courseId, examId, userId } = useParams()

  const [selloF, setSelloF] = useState(sello ?? "")

  const [formError, setFormError] = useState(false)
  const [validity, setValidity] = useState(false)
  const [showCertificate, setShowCertificate] = useState(false)

  const { error, loading, success, whoIAm } = useSelector(state => ({}))

  const { exam, user, courses } = useSelector(store => ({
    exam: store.ExamGet.exam,
    user: store.UserEdit.user,
    courses: store.CoursesList.courses,
  }))

  useEffect(() => {
    document.body.className = "main-celf-background"
    //localStorage.removeItem("academyUser")
    if (error) dispatch(loginClean())
    return () => {
      document.body.className = ""
    }
  }, [])

  useEffect(() => {
    dispatch(getExam(courseId))
    dispatch(getUser(userId))
    dispatch(getCoursesByUser(userId))
  }, [courseId, userId])
  const course = courses.find(course => course.courseId === parseInt(courseId))

  // handleValidSubmit
  const handleSubmit = (event, errors, values) => {
    setValidity(false)

    if (errors.length > 0) {
      setFormError(true)
      return
    }

    const valid = createHash("sha256")
      .update(`||${examId}|${courseId}||`)
      .digest("hex")

    if (valid !== values.sello) {
      setFormError(true)
      return
    }

    setFormError(false)
    setValidity(true)
  }

  const handleShowCertificate = () => {
    setShowCertificate(true)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Validación de certificado | Professional&apos;s Academy E-Learning
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark" draggable="false">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md={8} lg={6} xl={5}>
              <Row className="mb-2">
                <Col xs={{ size: 10, offset: 1 }}>
                  <div className="academy-logo-login"></div>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 1 }}></Col>
                <Col>
                  <img
                    src={resistolLogo}
                    alt="Resistol"
                    className="img-fluid"
                  />
                </Col>
                <Col>
                  <img src={sistaLogo} alt="Sista" className="img-fluid" />
                </Col>
                <Col>
                  <img src={tangitLogo} alt="Tangit" className="img-fluid" />
                </Col>
                <Col>
                  <img src={prittLogo} alt="Pritt" className="img-fluid" />
                </Col>
                <Col xs={{ size: 1 }}></Col>
              </Row>
              <Card>
                <CardBody className="alt-celf-background text-white mt-2 rounded-3x">
                  <h1 className="text-center text-white pb-3">
                    Validación de certificado
                  </h1>
                  <div className="p-2">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">{error.toString()}</Alert>
                      )}
                      {formError && (
                        <Alert color="danger" className="text-center">
                          El certificado no es válido
                        </Alert>
                      )}
                      {validity && (
                        <Alert color="success" className="text-center">
                          ¡El certificado es válido!
                        </Alert>
                      )}

                      <div className="mb-3 red-box">
                        <AvField
                          name="sello"
                          label="Sello"
                          value={selloF}
                          className="form-control"
                          placeholder="Sello"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Este campo es requerido",
                            },
                          }}
                        />
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className={`btn btn-light btn-block ${
                            loading ? "disabled" : ""
                          }`}
                          type="submit"
                        >
                          {!loading && <span>Validar</span>}
                          {loading && <span>Validando...</span>}
                        </button>
                      </div>
                      {validity && (
                        <Fragment>
                          <div className="mt-2 d-grid">
                            <button
                              onClick={() => handleShowCertificate()}
                              className={`btn btn-secondary btn-block`}
                              type="button"
                            >
                              Ver constancia
                            </button>
                          </div>
                        </Fragment>
                      )}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <SessionFooter />
          </Row>
        </Container>
      </div>
      {course !== undefined && user !== undefined && exam !== undefined && (
        <ModalCertificatePdf
          open={showCertificate}
          exam={exam}
          user={user}
          course={course}
          onClose={() => setShowCertificate(false)}
        />
      )}
    </React.Fragment>
  )
}

export default withRouter(CertificateValidation)

CertificateValidation.propTypes = {
  history: PropTypes.object,
}
