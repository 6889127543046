import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import XlsExporter from "components/Common/XlsExporter"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "common/moment_i18n"
import SweetAlert from "react-bootstrap-sweetalert"

import {
  listGroupParticipants,
  addGroupParticipant,
  addGroupParticipantClean,
  removeGroupParticipant,
  removeGroupParticipantClean,
  getUsers,
  resetGroupParticipantCourse,
  resetGroupParticipantCourseClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import AddUserToGroupModal from "./AddUserToGroupModal"
import DeleteUserFromGroupModal from "./DeleteUserFromGroupModal"
import ParticipantInfoModal from "components/Common/ParticipantInfoModal"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const UsersGroupListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [showAddParticipant, setShowAddParticipant] = useState(false)
  const [showDeleteParticipant, setShowDeleteParticipant] = useState(undefined)
  const [userForReset, setUserForReset] = useState({
    userId: 0,
  })
  const [participant, setParticipant] = useState(undefined)
  const [resetCourses, setResetCourses] = useState(false)

  const idGroup = props.group.idGrupo ?? 0

  const {
    groupParticipants,
    groupParticipantsLoading,
    addParticipantLoading,
    addParticipantError,
    addParticipantDone,
    removeParticipantLoading,
    removeParticipantError,
    removeParticipantDone,
    usersList,
    usersListLoading,
    participantCourseReset,
  } = useSelector(store => ({
    groupParticipants: store.GroupParticipantsList.groupParticipants,
    groupParticipantsLoading: store.GroupParticipantsList.loading,
    addParticipantLoading: store.GroupParticipantAdd.loading,
    addParticipantError: store.GroupParticipantAdd.error,
    addParticipantDone: store.GroupParticipantAdd.done,
    removeParticipantLoading: store.GroupParticipantRemove.loading,
    removeParticipantError: store.GroupParticipantRemove.error,
    removeParticipantDone: store.GroupParticipantRemove.done,
    usersList: store.UsersList.users,
    usersListLoading: store.UsersList.loading,
    participantCourseReset: store.GroupParticipantCourseReset.done,
  }))

  let users = []
  let availableUsers = []

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  useEffect(() => {
    if (usersList !== undefined && usersList.length > 0) {
      dispatch(listGroupParticipants(idGroup))
    }
  }, [idGroup, usersList])

  if (usersList !== undefined && groupParticipants !== undefined) {
    usersList.forEach(user => {
      if (user.status) {
        const exists = groupParticipants.find(
          participant => participant.idUser === user.userId
        )
        if (exists !== undefined) {
          user.groupUserPercentage = exists.groupUserPercentage
          user.completeCourses = exists.completeCourses
          user.totalCourses = exists.totalCourses
          users.push(user)
        } else {
          availableUsers.push(user)
        }
      }
    })
  }

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.userId}`}
        isOpen={row.userId === active}
        toggle={() => {
          setDropDown(row.userId === active ? 0 : row.userId)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem onClick={() => setParticipant(row)}>
            Tarjeta académica
          </DropdownItem>
          {!props.readonly && (
            <DropdownItem
              onClick={() => {
                setUserForReset(row)
                setResetCourses(true)
              }}
            >
              Resetear cursos
            </DropdownItem>
          )}
          {!props.readonly && (
            <DropdownItem onClick={() => setShowDeleteParticipant(row)}>
              Eliminar del grupo
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    )
  }

  const handleResetCourses = () => {
    setResetCourses(false)
    dispatch(
      resetGroupParticipantCourse({
        groupId: idGroup,
        userId: userForReset.userId,
      })
    )
  }

  if (participantCourseReset) {
    dispatch(resetGroupParticipantCourseClean())
    dispatch(getUsers())
  }

  const columns = [
    {
      dataField: "userId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "firstName",
      text: "Nombre completo y correo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`nombre-${row.userId}`}>
            <strong>
              <a onClick={() => setParticipant(row)}>
                {row.firstName} {row.lastName}
              </a>
            </strong>
            <br />
            <span>{row.email}</span>
          </div>
        )
      },
    },
    {
      dataField: "lastName",
      text: "Apellido",
      sort: true,
      hidden: true,
    },
    {
      dataField: "posicion",
      text: "Posición",
      sort: true,
    },
    {
      dataField: "clientName",
      text: "Cliente",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`cliente-${row.userId}`}>
            {row.clientName !== null ? row.clientName : "Ninguno"}
          </div>
        )
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      hidden: true,
    },
    {
      dataField: "progreso",
      text: "Progreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        const progreso = row.groupUserPercentage
        const restante = 100 - progreso
        const terminados = row.completeCourses
        const cursos = row.totalCourses
        return (
          <div key={`progress-${row.userId}`}>
            <Progress multi>
              <Progress
                bar
                value={progreso}
                color="secondary"
                style={{ width: `${progreso}%` }}
                animated
              />
              <Progress
                bar
                value={restante}
                color="light"
                style={{ width: `${restante}%` }}
              />
            </Progress>
            <div className="mt-1">{`${(
              Math.round((progreso + Number.EPSILON) * 100) / 100
            ).toFixed(2)}%`}</div>
            <div>
              {terminados} / {cursos}
            </div>
          </div>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.userId}`}>
            {moment(row.lastActivity)
              .locale("es")
              .format("D [de] MMMM [de] YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "profileId",
      text: "Perfil",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return <span>N{row.profileId}</span>
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: users?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "profileId", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  const handleAddUser = userId => {
    const request = {
      idGroup: props.group.idGrupo,
      idUser: userId,
    }

    dispatch(addGroupParticipant(request))
  }

  const handleDeleteUser = userId => {
    const request = {
      idGroup: props.group.idGrupo,
      idUser: userId,
    }

    dispatch(removeGroupParticipant(request))
  }

  const getXlsData = users => {
    let data = users.map((element, index) => {
      const progreso = element.groupUserPercentage
      const terminados = element.completeCourses
      const cursos = element.totalCourses
      const porcentaje = Math.round((progreso + Number.EPSILON) * 100) / 100
      return {
        Nombre: `${element.firstName} ${element.lastName}`,
        Email: element.email,
        Posición: element.posicion,
        Cliente: element.clientName !== null ? element.clientName : "Ninguno",
        "Cursos asignados": cursos,
        "Cursos completados": terminados,
        "% Avance": porcentaje,
        "Último ingreso": moment(element.lastActivity)
          .locale("es")
          .format("D [de] MMMM [de] YYYY"),
        Perfil: `N${element.profileId}`,
      }
    })

    return {
      Usuarios: data,
    }
  }

  if (addParticipantDone) {
    dispatch(listGroupParticipants(props.group.idGrupo))
    dispatch(addGroupParticipantClean())
  }

  if (removeParticipantDone) {
    setShowDeleteParticipant(undefined)
    dispatch(listGroupParticipants(props.group.idGrupo))
    dispatch(removeGroupParticipantClean())
  }

  return (
    <Fragment>
      {usersListLoading || groupParticipantsLoading ? (
        <LoadingIndicator />
      ) : (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="userId"
          columns={columns}
          data={users}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="userId"
              columns={columns}
              data={users}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col className="text-center">
                      <XlsExporter
                        classNames={`btn btn-link`}
                        data={getXlsData(users)}
                        dataName={"Usuarios"}
                        name="Usuarios"
                        label="Exportar a Excel"
                      />
                      {"   "}
                      {!props.readonly && (
                        <Link
                          to="/users"
                          className="btn btn-link"
                          draggable="false"
                        >
                          <i className="fa fa-users"></i> Ver todos los usuarios
                        </Link>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2 pt-3">
                    <Col xl={2} lg={3} md={4} sm={4} xs={12}>
                      <div className="d-grid">
                        {!props.readonly && (
                          <button
                            onClick={() => setShowAddParticipant(true)}
                            className="btn btn-primary btn-rounded"
                          >
                            <i className="fa fa-plus"></i> Agregar participante
                          </button>
                        )}
                      </div>
                    </Col>
                    <Col
                      xl={8}
                      lg={6}
                      md={4}
                      xs={12}
                      className="text-center pt-2"
                    >
                      Total de participantes: <strong>{users.length}</strong>
                    </Col>
                    <Col
                      xl={{ size: 2 }}
                      lg={{ size: 3 }}
                      md={{ size: 4 }}
                      sm={{ size: 4 }}
                      xs={12}
                    >
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder="Buscar"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <Fragment>
                          <BootstrapTable
                            keyField={"userId"}
                            bordered={false}
                            striped={true}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                          {users.length <= 0 && (
                            <Row>
                              <Col md={12} className="text-center">
                                -- No hay registros --
                              </Col>
                            </Row>
                          )}
                        </Fragment>
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}
      <AddUserToGroupModal
        show={showAddParticipant}
        loading={addParticipantLoading}
        users={availableUsers}
        onConfirm={userId => handleAddUser(userId)}
        onCancel={() => setShowAddParticipant(false)}
        error={addParticipantError}
      />
      <DeleteUserFromGroupModal
        show={showDeleteParticipant !== undefined}
        user={showDeleteParticipant}
        loading={removeParticipantLoading}
        onConfirm={userId => handleDeleteUser(userId)}
        onCancel={() => setShowDeleteParticipant(undefined)}
        error={removeParticipantError}
      />
      {participant !== undefined && (
        <ParticipantInfoModal
          show={true}
          user={participant}
          group={props.group}
          onClose={() => setParticipant(undefined)}
        />
      )}
      {resetCourses && (
        <SweetAlert
          title="Resetear cursos"
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={handleResetCourses}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => setResetCourses(false)}
        >
          <p>¿Confirmas el reseteo de todos los cursos del participante?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

UsersGroupListTable.propTypes = {
  group: PropTypes.object,
  readonly: PropTypes.bool,
}

export default UsersGroupListTable
