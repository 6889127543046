/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { EDIT_SKILL } from "./actionTypes"
import { apiError, editSkillSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateSkill } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateSkill, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if(response.success) yield put(editSkillSuccess(response))
    if (!response.success) throw new Error("Error al actualizar la habilidad")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* skillEditSaga() {
  yield takeEvery(EDIT_SKILL, edit)
}

export default skillEditSaga
