import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import {
  getCourses,
  listReport10,
  listReport10Clean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import Report10Chart from "components/Reports/Report10Chart"
import Report10ListTable from "components/Reports/Report10ListTable"
import moment from "moment"

const Report10 = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.Report10List.error,
    loading: state.Report10List.loading,
    report: state.Report10List.report,
    done: state.Report10List.done,
    courses: state.CoursesList.coursesForAdmin,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReport10({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    console.log(report)
    let data = report.map(element => {
      const date = moment(element.date).format("DD/MM/YYYY")
      let result = {
        Fecha: `${date}`,
        "Usuarios acumulados que han ingresado": element.accesos,
        "Usuarios que participaron en cursos": element.acceso_cursos,
        "Usuarios que concluyeron cursos": element.aprobaron,
        "% participación sobre usuarios disponibles":
          Math.round(
            (element.porcentaje_participacion + Number.EPSILON) * 100
          ) / 100,
        "% conclusión sobre usuarios disponibles":
          Math.round((element.porcentaje_aprobacion + Number.EPSILON) * 100) /
          100,
      }

      return result
    })

    return {
      Reporte10: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReport10Clean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 10 - Participación | Professional&apos;s Academy E-Learning
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 10 - Participación</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report10", title: "Participación" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 10"}
                    onSubmit={values => onSubmit(values)}
                    users={users}
                    courses={courses}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte10"}
                        name="Reporte 10"
                        label="Exportar"
                      />
                    }
                    customers={customers}
                    groups={groups}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <Report10Chart data={reportInfo} />
                      <br />
                      <Report10ListTable
                        loading={loading}
                        report={reportInfo}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Report10
