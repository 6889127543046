import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import {
  getSupportMaterials,
  getSupportMaterialsClean,
  removeSupportMaterial,
  removeSupportMaterialClean,
  addSupportMaterialClean,
  listSupportMaterialTypes,
} from "store/actions"
import SupportMaterialsListTable from "./SupportMaterialsListTable"
import SupportMaterialModal from "./SupportMaterialModal"
import RemoveSupportMaterialModal from "./RemoveSupportMaterialModal"

const CourseSupportMaterials = props => {
  const courseId = props.course.courseId
  const readOnly = props.readOnly ?? false

  const dispatch = useDispatch()
  const {
    supportMaterials,
    addSupportMaterialLoading,
    addSupportMaterialSuccess,
    addSupportMaterialError,
    removeSupportMaterialLoading,
    removeSupportMaterialSuccess,
    removeSupportMaterialError,
    supportMaterialTypes,
  } = useSelector(state => ({
    supportMaterials: state.SupportMaterialsList.supportMaterials,
    addSupportMaterialLoading: state.SupportMaterialAdd.loading,
    addSupportMaterialSuccess: state.SupportMaterialAdd.done,
    addSupportMaterialError: state.SupportMaterialAdd.error,
    removeSupportMaterialLoading: state.SupportMaterialRemove.loading,
    removeSupportMaterialSuccess: state.SupportMaterialRemove.done,
    removeSupportMaterialError: state.SupportMaterialRemove.error,
    supportMaterialTypes: state.SupportMaterialTypesList.supportMaterialTypes,
  }))
  const [form, setForm] = useState(undefined)
  const [materialToRemove, setMaterialToRemove] = useState(undefined)

  useEffect(() => {
    dispatch(getSupportMaterials(courseId))
    dispatch(listSupportMaterialTypes())
    return () => {
      dispatch(getSupportMaterialsClean())
    }
  }, [courseId])

  const handleRemove = material => {
    setMaterialToRemove(material)
  }

  if (removeSupportMaterialSuccess) {
    setMaterialToRemove(undefined)
    dispatch(getSupportMaterials(courseId))
    dispatch(removeSupportMaterialClean())
  }

  if (addSupportMaterialSuccess) {
    setForm(undefined)
    dispatch(getSupportMaterials(courseId))
    dispatch(addSupportMaterialClean())
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-3">Material de apoyo</CardTitle>
            {supportMaterials !== undefined && (
              <SupportMaterialsListTable
                course={props.course}
                readOnly={readOnly}
                supportMaterials={supportMaterials}
                supportMaterialTypes={supportMaterialTypes}
                onAdd={() => setForm({})}
                onDelete={id => handleRemove(id)}
                onEdit={supportMaterial => {
                  setForm(supportMaterial)
                }}
              />
            )}
          </CardBody>
        </Card>
      </Col>
      <SupportMaterialModal
        course={props.course}
        error={addSupportMaterialError}
        show={form !== undefined}
        supportMaterial={form}
        supportMaterialTypes={supportMaterialTypes}
        onCancel={() => setForm(undefined)}
      />
      <RemoveSupportMaterialModal
        onCancel={() => setMaterialToRemove(undefined)}
        error={removeSupportMaterialError}
        onConfirm={() =>
          dispatch(removeSupportMaterial(materialToRemove.supportMaterialId))
        }
        show={materialToRemove !== undefined}
      />
    </Row>
  )
}

CourseSupportMaterials.propTypes = {
  course: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default CourseSupportMaterials
