import {
  EDIT_POSITION,
  EDIT_POSITION_SUCCESS,
  EDIT_POSITION_CLEAN,
  EDIT_POSITION_ERROR,
} from "./actionTypes"

export const editPosition = form => ({
  type: EDIT_POSITION,
  payload: { form },
})

export const editPositionSuccess = position => {
  return {
    type: EDIT_POSITION_SUCCESS,
    payload: { position },
  }
}

export const editPositionClean = () => {
  return {
    type: EDIT_POSITION_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_POSITION_ERROR,
    payload: error,
  }
}
