import {
  ADD_COURSE,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_CLEAN,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  course: {},
}

const CourseAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_COURSE_SUCCESS:
      state = {
        ...state,
        course: action.payload.course,
        error: "",
        loading: false,
        done: true,
      }
      break
    case ADD_COURSE_CLEAN:
      state = initialState
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CourseAdd
