/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_QUIZZ_ANSWER_USER } from "./actionTypes"
import { getQuizzAnswerUserError, getQuizzAnswerUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getQuizzAnswerUserNewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(getQuizzAnswerUserNewApi, request.payload.form)
    if (response == undefined) throw new Error("API error")
    if (response.statusCode !== undefined && response.statusCode === 404) {
      yield put(getQuizzAnswerUserSuccess({ quizzAnswerId: 0 }))
    } else {
      yield put(getQuizzAnswerUserSuccess(response))
    }
  } catch (error) {
    console.log(error.message)
    yield put(getQuizzAnswerUserError(error.message))
  }
}

function* quizzAnswerUserGetSaga() {
  yield takeEvery(GET_QUIZZ_ANSWER_USER, list)
}

export default quizzAnswerUserGetSaga
