import {
  ADD_GROUP_TO_PERIOD,
  ADD_GROUP_TO_PERIOD_CLEAN,
  ADD_GROUP_TO_PERIOD_ERROR,
  ADD_GROUP_TO_PERIOD_SUCCESS,
  ADD_RULE_TO_PERIOD,
  ADD_RULE_TO_PERIOD_CLEAN,
  ADD_RULE_TO_PERIOD_ERROR,
  ADD_RULE_TO_PERIOD_SUCCESS,
  CREATE_PERIOD,
  CREATE_PERIOD_CLEAN,
  CREATE_PERIOD_ERROR,
  CREATE_PERIOD_SUCCESS,
  EDIT_PERIOD,
  EDIT_PERIOD_CLEAN,
  EDIT_PERIOD_ERROR,
  EDIT_PERIOD_SUCCESS,
  GET_SINGLE_PERIOD,
  GET_SINGLE_PERIOD_CLEAN,
  GET_SINGLE_PERIOD_ERROR,
  GET_SINGLE_PERIOD_SUCCESS,
  LIST_PERIODS,
  LIST_PERIODS_CLEAN,
  LIST_PERIODS_ERROR,
  LIST_PERIODS_SUCCESS,
  REMOVE_GROUP_TO_PERIOD,
  REMOVE_GROUP_TO_PERIOD_CLEAN,
  REMOVE_GROUP_TO_PERIOD_ERROR,
  REMOVE_GROUP_TO_PERIOD_SUCCESS,
  REMOVE_RULE_TO_PERIOD,
  REMOVE_RULE_TO_PERIOD_CLEAN,
  REMOVE_RULE_TO_PERIOD_ERROR,
  REMOVE_RULE_TO_PERIOD_SUCCESS,
  GET_PERIOD_PARTICIPANTS,
  GET_PERIOD_PARTICIPANTS_SUCCESS,
  GET_PERIOD_PARTICIPANTS_ERROR,
  GET_PERIOD_PARTICIPANTS_CLEAN,
  GET_PERIOD_CLIENTS,
  GET_PERIOD_CLIENTS_SUCCESS,
  GET_PERIOD_CLIENTS_ERROR,
  GET_PERIOD_CLIENTS_CLEAN,
  GET_PERIODS_PARTICIPANT,
  GET_PERIODS_PARTICIPANT_SUCCESS,
  GET_PERIODS_PARTICIPANT_ERROR,
  GET_PERIODS_PARTICIPANT_CLEAN,
} from "./actionTypes"

const initialState = {
  loading: false,
  listError: "",
  listDone: false,
  periods: [],
  getError: "",
  getDone: false,
  period: undefined,
  createDone: false,
  createError: "",
  editDone: false,
  editError: "",
  addRuleDone: false,
  addRuleError: "",
  removeRuleDone: false,
  removeRuleError: "",
  addGroupDone: false,
  addGroupError: "",
  removeGroupDone: false,
  removeGroupError: "",
  participantsDone: false,
  participantsError: "",
  participants: [],
  clientsDone: false,
  clientsError: "",
  clients: [],
  periodsParticipantDone: false,
  periodsParticipantError: "",
  periodsParticipant: [],
}

const GamificationPeriods = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PERIODS:
      state = {
        ...state,
        listError: "",
        loading: true,
      }
      break
    case LIST_PERIODS_SUCCESS:
      state = {
        ...state,
        periods: action.payload.periods,
        loading: false,
        listDone: true,
      }
      break
    case LIST_PERIODS_ERROR:
      state = {
        ...state,
        listError: action.payload.error,
        loading: false,
        listDone: false,
        periods: [],
      }
      break
    case LIST_PERIODS_CLEAN:
      state = {
        ...state,
        listError: "",
        loading: false,
        listDone: false,
        periods: [],
      }
      break
    case GET_SINGLE_PERIOD:
      state = {
        ...state,
        getError: "",
        loading: true,
      }
      break
    case GET_SINGLE_PERIOD_SUCCESS:
      state = {
        ...state,
        period: action.payload.period,
        loading: false,
        getDone: true,
      }
      break
    case GET_SINGLE_PERIOD_ERROR:
      state = {
        ...state,
        getError: action.payload.error,
        loading: false,
        getDone: false,
        period: undefined,
      }
      break
    case GET_SINGLE_PERIOD_CLEAN:
      state = {
        ...state,
        getError: "",
        loading: false,
        getDone: false,
        period: undefined,
      }
      break
    case CREATE_PERIOD:
      state = {
        ...state,
        createError: "",
        loading: true,
      }
      break
    case CREATE_PERIOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        createDone: true,
        period: action.payload.period,
      }
      break
    case CREATE_PERIOD_ERROR:
      state = {
        ...state,
        createError: action.payload.error,
        loading: false,
        createDone: false,
      }
      break
    case CREATE_PERIOD_CLEAN:
      state = {
        ...state,
        createError: "",
        loading: false,
        createDone: false,
      }
      break
    case EDIT_PERIOD:
      state = {
        ...state,
        editError: "",
        loading: true,
      }
      break
    case EDIT_PERIOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        editDone: true,
      }
      break
    case EDIT_PERIOD_ERROR:
      state = {
        ...state,
        editError: action.payload.error,
        loading: false,
        editDone: false,
      }
      break
    case EDIT_PERIOD_CLEAN:
      state = {
        ...state,
        editError: "",
        loading: false,
        editDone: false,
      }
      break
    case ADD_RULE_TO_PERIOD:
      state = {
        ...state,
        addRuleError: "",
        loading: true,
      }
      break
    case ADD_RULE_TO_PERIOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        addRuleDone: true,
      }
      break
    case ADD_RULE_TO_PERIOD_ERROR:
      state = {
        ...state,
        addRuleError: action.payload.error,
        loading: false,
        addRuleDone: false,
      }
      break
    case ADD_RULE_TO_PERIOD_CLEAN:
      state = {
        ...state,
        addRuleError: "",
        loading: false,
        addRuleDone: false,
      }
      break
    case REMOVE_RULE_TO_PERIOD:
      state = {
        ...state,
        removeRuleError: "",
        loading: true,
      }
      break
    case REMOVE_RULE_TO_PERIOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        removeRuleDone: true,
      }
      break
    case REMOVE_RULE_TO_PERIOD_ERROR:
      state = {
        ...state,
        removeRuleError: action.payload.error,
        loading: false,
        removeRuleDone: false,
      }
      break
    case REMOVE_RULE_TO_PERIOD_CLEAN:
      state = {
        ...state,
        removeRuleError: "",
        loading: false,
        removeRuleDone: false,
      }
      break
    case ADD_GROUP_TO_PERIOD:
      state = {
        ...state,
        addGroupError: "",
        loading: true,
      }
      break
    case ADD_GROUP_TO_PERIOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        addGroupDone: true,
      }
      break
    case ADD_GROUP_TO_PERIOD_ERROR:
      state = {
        ...state,
        addGroupError: action.payload.error,
        loading: false,
        addGroupDone: false,
      }
      break
    case ADD_GROUP_TO_PERIOD_CLEAN:
      state = {
        ...state,
        addGroupError: "",
        loading: false,
        addGroupDone: false,
      }
      break
    case REMOVE_GROUP_TO_PERIOD:
      state = {
        ...state,
        removeGroupError: "",
        loading: true,
      }
      break
    case REMOVE_GROUP_TO_PERIOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        removeGroupDone: true,
      }
      break
    case REMOVE_GROUP_TO_PERIOD_ERROR:
      state = {
        ...state,
        removeGroupError: action.payload.error,
        loading: false,
        removeGroupDone: false,
      }
      break
    case REMOVE_GROUP_TO_PERIOD_CLEAN:
      state = {
        ...state,
        removeGroupError: "",
        loading: false,
        removeGroupDone: false,
      }
      break

    case GET_PERIOD_PARTICIPANTS:
      state = {
        ...state,
        participantsError: "",
        loading: true,
      }
      break
    case GET_PERIOD_PARTICIPANTS_SUCCESS:
      state = {
        ...state,
        participants: action.payload.participants,
        loading: false,
        participantsDone: true,
      }
      break
    case GET_PERIOD_PARTICIPANTS_ERROR:
      state = {
        ...state,
        participantsError: action.payload.error,
        loading: false,
        participantsDone: false,
        participants: [],
      }
      break
    case GET_PERIOD_PARTICIPANTS_CLEAN:
      state = {
        ...state,
        participantsError: "",
        loading: false,
        participantsDone: false,
        participants: [],
      }
      break
    case GET_PERIOD_CLIENTS:
      state = {
        ...state,
        clientsError: "",
        loading: true,
      }
      break
    case GET_PERIOD_CLIENTS_SUCCESS:
      state = {
        ...state,
        clients: action.payload.clients,
        loading: false,
        clientsDone: true,
      }
      break
    case GET_PERIOD_CLIENTS_ERROR:
      state = {
        ...state,
        clientsError: action.payload.error,
        loading: false,
        clientsDone: false,
        clients: [],
      }
      break
    case GET_PERIOD_CLIENTS_CLEAN:
      state = {
        ...state,
        clientsError: "",
        loading: false,
        clientsDone: false,
        clients: [],
      }
      break
    case GET_PERIODS_PARTICIPANT:
      state = {
        ...state,
        periodsParticipantError: "",
        loading: true,
      }
      break
    case GET_PERIODS_PARTICIPANT_SUCCESS:
      state = {
        ...state,
        periodsParticipant: action.payload.periodsParticipant,
        loading: false,
        periodsParticipantDone: true,
      }
      break
    case GET_PERIODS_PARTICIPANT_ERROR:
      state = {
        ...state,
        periodsParticipantError: action.payload.error,
        loading: false,
        periodsParticipantDone: false,
        periodsParticipant: [],
      }
      break
    case GET_PERIODS_PARTICIPANT_CLEAN:
      state = {
        ...state,
        periodsParticipantError: "",
        loading: false,
        periodsParticipantDone: false,
        periodsParticipant: [],
      }
      break
  }
  return state
}

export default GamificationPeriods
