/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_POSITION } from "./actionTypes"
import { apiError, addPositionSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addPositionNewApi } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addPositionNewApi, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (response.message) throw new Error(response.message)
    yield put(addPositionSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* positionAddSaga() {
  yield takeEvery(ADD_POSITION, add)
}

export default positionAddSaga
