import {
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_CLEAN,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  group: undefined,
}

const GroupGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        group: action.payload.group,
      }
      break
    case GET_GROUP_CLEAN:
      state = initialState
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupGet
