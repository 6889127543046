/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_SUPPORT_MATERIALS } from "./actionTypes"
import { apiError, getSupportMaterialsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getSupportMaterials } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(getSupportMaterials, action.payload.idCurso)
    yield put(getSupportMaterialsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* supportMaterialsListSaga() {
  yield takeEvery(LIST_SUPPORT_MATERIALS, get)
}

export default supportMaterialsListSaga
