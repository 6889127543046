/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_QUESTION } from "./actionTypes"
import { apiError, removeQuestionSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deleteQuestion } from "../../../helpers/backend_helper"

function* remove(action) {
  try {
    const response = yield call(deleteQuestion, action.payload.id)
    if (response === undefined) throw new Error("Error al remover pregunta")
    if (response.statusCode !== undefined)
      throw new Error(`Error al remover pregunta: ${response.message}`)
    yield put(removeQuestionSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(apiError(`${error}`))
  }
}

function* questionRemoveSaga() {
  yield takeEvery(REMOVE_QUESTION, remove)
}

export default questionRemoveSaga
