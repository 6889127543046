/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_CUSTOMERS } from "./actionTypes"
import { apiError, getCustomersSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getCustomersNewApi } from "../../../helpers/backend_helper"

function* customers(action) {
  try {
    const response = yield call(getCustomersNewApi, action.payload.active)
    yield put(getCustomersSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* customersListSaga() {
  yield takeEvery(GET_CUSTOMERS, customers)
}

export default customersListSaga
