import React, { useEffect, useState, Fragment } from "react"
import classnames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import {
  getPeriodParticipants,
  getChartsData as onGetChartsData,
} from "../../store/actions"
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap"

import {
  getCourseKnowledges,
  getSkills,
  getCoursesByUser,
  getCustomers,
  getGroups,
  listReport9,
  listReport2,
  listReport3,
} from "../../store/actions"
import WelcomeN2to7 from "pages/Dashboard/WelcomeN2to7"
import CoursesByStatus from "./CoursesByStatus"
import GuageN8 from "components/Charts/GuageN8"
import DashboardFilters from "./DashboardFilters"
import Report2Chart from "components/Reports/Report2Chart"
import Report2ListTable from "components/Reports/Report2ListTable"
import Report3Chart from "components/Reports/Report3Chart"
import Report3ListTable from "components/Reports/Report3ListTable"
import Report9Chart from "components/Reports/Report9Chart"
import GamificationTable from "./GamificationTable"
import moment from "moment"

const N1to7 = props => {
  const [activeTabHab, setActiveTabHab] = useState("1")
  const [completedAvg, setCompletedAvg] = useState(0)
  const [finalParticipants, setFinalParticipants] = useState([])
  const [myRanking, setMyRanking] = useState({
    ranking: 0,
    puntos: 0,
  })

  const dispatch = useDispatch()

  const {
    knowledges,
    skills,
    user,
    courses,
    loading,
    customers,
    groups,
    report9,
    report2,
    report3,
    participants,
    participantsLoading,
  } = useSelector(store => ({
    knowledges: store.CourseKnowledgesList.courseKnowledges,
    skills: store.SkillsList.skills,
    user: store.Profile.user,
    courses: store.CoursesList.courses,
    loading: store.CoursesList.loading,
    customers: store.CustomersList.customers,
    groups: store.GroupsList.groups,
    report9: store.Report9List.report,
    report2: store.Report2List.report,
    report3: store.Report3List.report,
    participants: store.GamificationPeriods.participants,
    participantsLoading: store.GamificationPeriods.loading,
  }))

  const idUser = user?.userId ?? 0

  useEffect(() => {
    dispatch(getCourseKnowledges())
    dispatch(getSkills())
    dispatch(getCoursesByUser(idUser))
    dispatch(getCustomers())
    dispatch(getGroups())
    dispatch(getPeriodParticipants(0))
    onSubmit({
      courses: [],
      customers: [],
      groups: [],
      status: 1,
    })
  }, [idUser])

  useEffect(() => {
    const myParticipant = participants.find(
      participant => participant.IdUsuario === idUser
    )
    const dummyParticipants = participants
    dummyParticipants.sort((a, b) => {
      const criteria1 = b.puntos_totales - a.puntos_totales
      if (criteria1 !== 0) return criteria1
      const criteria2 =
        moment(a.fecha_puntos).unix() - moment(b.fecha_puntos).unix()

      return criteria2
    })

    let dummyMyParticipant = {
      ranking: 0,
      puntos: 0,
      diferenciaPrimerLugar: 0,
    }
    let firstPlacePoints = 0

    const myPoints =
      myParticipant === undefined ? 0 : myParticipant.puntos_totales

    for (let i = 0; i < dummyParticipants.length; i++) {
      if (firstPlacePoints === 0)
        firstPlacePoints = dummyParticipants[0].puntos_totales
      dummyParticipants[i].ranking = i + 1
      if (dummyParticipants[i].ranking === 1) {
        dummyMyParticipant = {
          ranking: 0,
          puntos: dummyParticipants[i].puntos_totales - myPoints,
        }
      }
      if (dummyParticipants[i].IdUsuario === idUser) {
        dummyMyParticipant = {
          ranking: dummyParticipants[i].ranking,
          puntos: dummyParticipants[i].puntos_totales,
          diferenciaPrimerLugar:
            firstPlacePoints - dummyParticipants[i].puntos_totales,
        }
      }
    }

    setMyRanking(dummyMyParticipant)

    setFinalParticipants(dummyParticipants)
  }, [participants])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const toggleIconCustomHab = tab => {
    if (activeTabHab !== tab) {
      setActiveTabHab(tab)
    }
  }

  const getColor = index => {
    if (index % 4 == 0) return "success"
    if (index % 4 == 1) return "warning"
    if (index % 4 == 2) return "dark"
    if (index % 4 == 3) return "info"
  }

  const getCompleteCourses = () => {
    const completed =
      courses !== undefined
        ? courses.filter(
            course => course.status === 2 && course.courseParticipant?.approved
          )
        : []

    return completed.length
  }

  useEffect(() => {
    const allCourses =
      courses !== undefined ? courses.filter(course => course.status === 2) : []
    const completedCourses = getCompleteCourses()
    if (allCourses.length <= 0 || completedCourses <= 0) {
      setCompletedAvg(0)
    }
    setCompletedAvg((completedCourses / allCourses.length) * 100)

    return () => {
      setCompletedAvg(0)
    }
  }, [courses])

  const onSubmit = data => {
    dispatch(listReport2({ ...data, status: true, userId: idUser }))
    dispatch(listReport9({ ...data, status: true, userId: idUser }))
    dispatch(listReport3({ ...data, status: true, userId: idUser }))
  }

  return (
    <Fragment>
      <Row>
        <Col xl={3} lg={6} md={6} sm={6} xs={12}>
          <Card className="overflow-hidden">
            <CardBody className="pt-0 pl-0">
              <WelcomeN2to7 completedAvg={completedAvg} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <GuageN8 value={completedAvg} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <h3>Ranking general</h3>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="mt-1 mb-3">
                  <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                    {myRanking.ranking === 0
                      ? "Sin posición"
                      : `Posición #${myRanking.ranking}`}
                  </span>
                </div>
                <div>
                  {myRanking.diferenciaPrimerLugar === 0 ? (
                    <div></div>
                  ) : (
                    <div>
                      Estás a{" "}
                      <span className={`pt-2 pb-2 pl-4 pr-4 badge bg-success`}>
                        <i className="fa fa-arrow-up"></i>{" "}
                        {myRanking.diferenciaPrimerLugar}
                      </span>{" "}
                      puntos para el liderato.
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={9} lg={6} md={6} sm={6} xs={12}>
          <Card>
            <CardBody className="card-medium">
              <GamificationTable
                participants={finalParticipants}
                loading={participantsLoading}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} sm={12}>
          <Card>
            <CardBody className="card-medium">
              <div className="d-flex justify-content-center">
                <h3>% de avance general</h3>
              </div>
              <DashboardFilters
                onSubmit={values => onSubmit(values)}
                courses={courses}
                groups={groups}
                customers={customers}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} sm={12}>
          <Card>
            <CardBody className="card-medium">
              <div className="d-flex justify-content-center">
                <h3>
                  Avance general de cursos terminados{" "}
                  <small style={{ color: "#898969" }}>
                    Usuarios activos:{" "}
                    {report3 !== undefined ? report3.length : 0}
                  </small>
                </h3>
              </div>
              <Report9Chart value={report9.porcentaje_avance} />
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-center">
                <h3>Cursos por porcentaje de avance</h3>
              </div>
              <Report2Chart data={report2} />
              <br />
              <Report2ListTable loading={loading} report={report2} />
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-center">
                <h3>Estatus de curso por usuario</h3>
              </div>
              <Report3Chart data={report3} />
              <br />
              <Report3ListTable loading={loading} report={report3} />
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <CoursesByStatus courses={courses} loading={loading} />
        </Col>
      </Row>
      <style jsx>{`
        .card-small {
          min-height: 280px;
        }
        .card-medium {
          min-height: 450px;
        }
      `}</style>
    </Fragment>
  )
}

export default N1to7
