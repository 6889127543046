import {
  ADD_SHOPPING_AREA,
  ADD_SHOPPING_AREA_SUCCESS,
  ADD_SHOPPING_AREA_CLEAN,
  ADD_SHOPPING_AREA_ERROR,
} from "./actionTypes"

export const addShoppingArea = form => ({
  type: ADD_SHOPPING_AREA,
  payload: { form },
})

export const addShoppingAreaSuccess = shoppingArea => {
  return {
    type: ADD_SHOPPING_AREA_SUCCESS,
    payload: { shoppingArea },
  }
}

export const addShoppingAreaClean = () => {
  return {
    type: ADD_SHOPPING_AREA_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_SHOPPING_AREA_ERROR,
    payload: error,
  }
}
