import {
  RESET_GROUP_COURSE,
  RESET_GROUP_COURSE_SUCCESS,
  RESET_GROUP_COURSE_CLEAN,
  RESET_GROUP_COURSE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  groupCourse: {},
}

const GroupCourseReset = (state = initialState, action) => {
  switch (action.type) {
    case RESET_GROUP_COURSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESET_GROUP_COURSE_SUCCESS:
      state = {
        ...state,
        groupCourse: action.payload.groupCourse,
        loading: false,
        done: true,
      }
      break
    case RESET_GROUP_COURSE_CLEAN:
      state = initialState
      break
    case RESET_GROUP_COURSE_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupCourseReset
