import {
  GET_ANSWER,
  GET_ANSWER_SUCCESS,
  GET_ANSWER_CLEAN,
  GET_ANSWER_ERROR,
} from "./actionTypes"

export const getAnswer = idAnswer => ({
  type: GET_ANSWER,
  payload: { idAnswer },
})

export const getAnswerSuccess = answer => {
  return {
    type: GET_ANSWER_SUCCESS,
    payload: { answer },
  }
}

export const getAnswerClean = () => {
  return {
    type: GET_ANSWER_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_ANSWER_ERROR,
    payload: error,
  }
}
