/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  GET_KNOWLEDGE_CATEGORIES,
  GET_KNOWLEDGE_CATEGORIES_ALL,
} from "./actionTypes"
import { apiError, getKnowledgeCategoriesSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getKnowledgeCategories,
  getAllKnowledgeCategories,
} from "../../../helpers/backend_helper"

function* knowledgeCategories() {
  try {
    const response = yield call(getKnowledgeCategories)
    yield put(getKnowledgeCategoriesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* knowledgeCategoriesAll() {
  try {
    const response = yield call(getAllKnowledgeCategories)
    yield put(getKnowledgeCategoriesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* knowledgeCategoriesSaga() {
  yield takeEvery(GET_KNOWLEDGE_CATEGORIES, knowledgeCategories)
  yield takeEvery(GET_KNOWLEDGE_CATEGORIES_ALL, knowledgeCategoriesAll)
}

export default knowledgeCategoriesSaga
