import {
  LIST_REPORTB,
  LIST_REPORTB_SUCCESS,
  LIST_REPORTB_CLEAN,
  LIST_REPORTB_ERROR,
} from "./actionTypes"

export const listReportB = form => ({
  type: LIST_REPORTB,
  payload: { form },
})

export const listReportBSuccess = report => {
  return {
    type: LIST_REPORTB_SUCCESS,
    payload: { report },
  }
}

export const listReportBClean = () => {
  return {
    type: LIST_REPORTB_CLEAN,
    payload: {},
  }
}

export const listReportBError = error => {
  return {
    type: LIST_REPORTB_ERROR,
    payload: error,
  }
}
