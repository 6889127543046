/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { TRY_POLL, GET_POLL } from "./actionTypes"
import {
  tryPollError,
  tryPollSuccess,
  getPollSuccess,
  getPollError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  tryPoll,
  getUserSurvey,
  answerUserSurvey,
} from "../../../helpers/backend_helper"

function* getPoll({ payload }) {
  try {
    const response = yield call(getUserSurvey, payload.form)
    if (response.statusCode)
      throw new Error(`Error al obtener encuesta: ${response.message}`)
    yield put(getPollSuccess(response))
  } catch (error) {
    yield put(getPollError(`${error}`))
  }
}

function* answerPoll(action) {
  try {
    const response = yield call(answerUserSurvey, action.payload.form)
    if (response.statusCode)
      throw new Error(`Error al guardar encuesta: ${response.message}`)
    yield put(tryPollSuccess(response))
  } catch (error) {
    yield put(tryPollError(`${error}`))
  }
}

function* pollTrySaga() {
  yield takeEvery(GET_POLL, getPoll)
  yield takeEvery(TRY_POLL, answerPoll)
}

export default pollTrySaga
