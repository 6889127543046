/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_QUIZZ } from "./actionTypes"
import { editQuizzError, editQuizzSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateQuizz, updateQuizzNewApi } from "../../../helpers/backend_helper"

function* edit(request) {
  try {
    const response = yield call(updateQuizzNewApi, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(editQuizzSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(editQuizzError(error.message))
  }
}

function* quizzEditSaga() {
  yield takeEvery(EDIT_QUIZZ, edit)
}

export default quizzEditSaga
