import { Fragment, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Table } from "reactstrap"
import ReactDragListView from "react-drag-listview/lib/index.js"
import PropTypes from "prop-types"
import moment from "moment"
import { editLessonPositions } from "store/actions"

const CoursesLessonsListTable = props => {
  const lessons = props.lessons

  const dispatch = useDispatch()

  const [data, SetData] = useState([])

  useEffect(() => {
    const orderedLessons = lessons.sort((a, b) => (a.order < b.order ? -1 : 1))
    SetData(orderedLessons)
    return () => {
      SetData([])
    }
  }, [lessons])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data]

      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      SetData(modifiedData)

      handleUpdatePosition(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const handleUpdatePosition = modifiedData => {
    let positions = modifiedData.map((lesson, index) => {
      return { position: index + 1, lessonId: lesson.lessonId }
    })

    const request = {
      idCourse: props.course.courseId,
      LessonPositions: positions,
    }

    dispatch(editLessonPositions(request))
  }

  return (
    <Fragment>
      {lessons !== undefined && lessons.length > 0 ? (
        <Fragment>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-light btn-rounded"
              onClick={props.onPreview}
            >
              <i className="fa fa-eye"></i> Vista previa
            </button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <button
              className="btn btn-primary btn-rounded"
              onClick={props.onAdd}
            >
              <i className="fa fa-plus"></i> Agregar lección
            </button>
          </div>
          <br />
          <div className="">
            <ReactDragListView {...dragProps}>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>No.</th>
                    <th>Lección</th>
                    <th>Tiempo aproximado</th>
                    <th>Quizz (preguntas)</th>
                    <th>Fecha de registro</th>
                    <th colSpan={2} align="center" className="text-center">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(data || [])?.map((lesson, index) => {
                    return (
                      <tr key={lesson.lessonId}>
                        <td>
                          <i className="fa fa-grip-vertical"></i>
                        </td>
                        <td scope="row" className="drag-pointer">
                          {index + 1}
                        </td>
                        <td>
                          <a onClick={() => props.onEdit(lesson)}>
                            {lesson.title}
                          </a>
                        </td>
                        <td className="text-center">
                          {lesson.relatedMinutes} minutos
                        </td>
                        <td className="text-center">{lesson.quizzes}</td>
                        <td>
                          {moment(lesson.registrationDate).format(
                            "D [de] MMM [de] YYYY [a las] HH:mm [hrs.]"
                          )}
                        </td>
                        <td>
                          <button
                            onClick={() => props.onEdit(lesson)}
                            className="btn btn-light"
                          >
                            <i className="fa fa-edit"></i> Editar
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => props.onDelete(lesson)}
                            className="btn btn-light"
                          >
                            <i className="fa fa-trash"></i> Remover
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </ReactDragListView>
          </div>
        </Fragment>
      ) : (
        <div className="text-center">
          No ha agregado lecciones.&nbsp;&nbsp;&nbsp;
          <button className="btn btn-primary btn-rounded" onClick={props.onAdd}>
            <i className="fa fa-plus"></i> Agregar lección
          </button>
        </div>
      )}
    </Fragment>
  )
}

CoursesLessonsListTable.propTypes = {
  course: PropTypes.object,
  lessons: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onPreview: PropTypes.func,
}

export default CoursesLessonsListTable
