import { call, put, takeEvery } from "redux-saga/effects"
import * as Sentry from "@sentry/browser"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import { postJwtLoginNewApi } from "../../../helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLoginNewApi, {
      username: user.email,
      password: user.password,
    })
    if (response.statusCode !== undefined) {
      yield put(apiError(response.message))
      return
    } else {
      localStorage.setItem("academyUser", response.token)
      yield put(loginSuccess(response.token))
      //history.push("/dashboard")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    Sentry.configureScope(scope => scope.setUser(null))
    localStorage.removeItem("academyUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
