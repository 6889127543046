import {
  LIST_NOTES,
  LIST_NOTES_SUCCESS,
  LIST_NOTES_ERROR,
  LIST_NOTES_CLEAN,
} from "./actionTypes"

export const listNotes = idCurso => ({
  type: LIST_NOTES,
  payload: { idCurso },
})

export const listNotesSuccess = notes => {
  return {
    type: LIST_NOTES_SUCCESS,
    payload: { notes },
  }
}

export const listNotesClean = () => {
  return {
    type: LIST_NOTES_CLEAN,
    payload: {},
  }
}

export const listNotesError = error => {
  return {
    type: LIST_NOTES_ERROR,
    payload: error,
  }
}
