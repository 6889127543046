export const removeDiacritics = str =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const numberWithCommas = x => {
  try {
    const value = x.toLocaleString()
    return value
  } catch (e) {
    return ""
  }
}
