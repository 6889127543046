import {
  REMOVE_QUIZZ,
  REMOVE_QUIZZ_SUCCESS,
  REMOVE_QUIZZ_CLEAN,
  REMOVE_QUIZZ_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  quizz: undefined,
}

const QuizzRemove = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_QUIZZ:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_QUIZZ_SUCCESS:
      state = {
        ...state,
        quizz: action.payload.quizz,
        loading: false,
        done: true,
      }
      break
    case REMOVE_QUIZZ_CLEAN:
      state = initialState
      break
    case REMOVE_QUIZZ_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        quizz: undefined,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuizzRemove
