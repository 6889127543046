import {
  LIST_ABILITIES_BY_USER_AND_GROUP,
  LIST_ABILITIES_BY_USER_AND_GROUP_SUCCESS,
  LIST_ABILITIES_BY_USER_AND_GROUP_CLEAN,
  LIST_ABILITIES_BY_USER_AND_GROUP_ERROR,
} from "./actionTypes"

export const listAbilitiesByUserAndGroup = (userId, groupId) => ({
  type: LIST_ABILITIES_BY_USER_AND_GROUP,
  payload: { userId, groupId },
})

export const listAbilitiesByUserAndGroupSuccess = abilities => {
  return {
    type: LIST_ABILITIES_BY_USER_AND_GROUP_SUCCESS,
    payload: { abilities },
  }
}

export const listAbilitiesByUserAndGroupClean = () => {
  return {
    type: LIST_ABILITIES_BY_USER_AND_GROUP_CLEAN,
    payload: {},
  }
}

export const listAbilitiesByUserAndGroupError = error => {
  return {
    type: LIST_ABILITIES_BY_USER_AND_GROUP_ERROR,
    payload: error,
  }
}
