/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_CUSTOMER } from "./actionTypes"
import { editCustomerError, editCustomerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updatecustomerNewApi } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updatecustomerNewApi, action.payload.form)
    console.log(response)
    if (response == undefined) throw new Error("API error")
    if (!response.customerId) throw new Error("Error al guardar al cliente")
    yield put(editCustomerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(editCustomerError(error.message))
  }
}

function* customerEditSaga() {
  yield takeEvery(EDIT_CUSTOMER, edit)
}

export default customerEditSaga
