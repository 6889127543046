/* PERIODS */
export const LIST_PERIODS = "LIST_PERIODS"
export const LIST_PERIODS_SUCCESS = "LIST_PERIODS_SUCCESS"
export const LIST_PERIODS_CLEAN = "LIST_PERIODS_CLEAN"
export const LIST_PERIODS_ERROR = "LIST_PERIODS_ERROR"

export const GET_SINGLE_PERIOD = "GET_SINGLE_PERIOD"
export const GET_SINGLE_PERIOD_SUCCESS = "GET_SINGLE_PERIOD_SUCCESS"
export const GET_SINGLE_PERIOD_CLEAN = "GET_SINGLE_PERIOD_CLEAN"
export const GET_SINGLE_PERIOD_ERROR = "GET_SINGLE_PERIOD_ERROR"

export const CREATE_PERIOD = "CREATE_PERIOD"
export const CREATE_PERIOD_SUCCESS = "CREATE_PERIOD_SUCCESS"
export const CREATE_PERIOD_CLEAN = "CREATE_PERIOD_CLEAN"
export const CREATE_PERIOD_ERROR = "CREATE_PERIOD_ERROR"

export const EDIT_PERIOD = "EDIT_PERIOD"
export const EDIT_PERIOD_SUCCESS = "EDIT_PERIOD_SUCCESS"
export const EDIT_PERIOD_CLEAN = "EDIT_PERIOD_CLEAN"
export const EDIT_PERIOD_ERROR = "EDIT_PERIOD_ERROR"

export const ADD_RULE_TO_PERIOD = "ADD_RULE_TO_PERIOD"
export const ADD_RULE_TO_PERIOD_SUCCESS = "ADD_RULE_TO_PERIOD_SUCCESS"
export const ADD_RULE_TO_PERIOD_CLEAN = "ADD_RULE_TO_PERIOD_CLEAN"
export const ADD_RULE_TO_PERIOD_ERROR = "ADD_RULE_TO_PERIOD_ERROR"

export const REMOVE_RULE_TO_PERIOD = "REMOVE_RULE_TO_PERIOD"
export const REMOVE_RULE_TO_PERIOD_SUCCESS = "REMOVE_RULE_TO_PERIOD_SUCCESS"
export const REMOVE_RULE_TO_PERIOD_CLEAN = "REMOVE_RULE_TO_PERIOD_CLEAN"
export const REMOVE_RULE_TO_PERIOD_ERROR = "REMOVE_RULE_TO_PERIOD_ERROR"

export const ADD_GROUP_TO_PERIOD = "ADD_GROUP_TO_PERIOD"
export const ADD_GROUP_TO_PERIOD_SUCCESS = "ADD_GROUP_TO_PERIOD_SUCCESS"
export const ADD_GROUP_TO_PERIOD_CLEAN = "ADD_GROUP_TO_PERIOD_CLEAN"
export const ADD_GROUP_TO_PERIOD_ERROR = "ADD_GROUP_TO_PERIOD_ERROR"

export const REMOVE_GROUP_TO_PERIOD = "REMOVE_GROUP_TO_PERIOD"
export const REMOVE_GROUP_TO_PERIOD_SUCCESS = "REMOVE_GROUP_TO_PERIOD_SUCCESS"
export const REMOVE_GROUP_TO_PERIOD_CLEAN = "REMOVE_GROUP_TO_PERIOD_CLEAN"
export const REMOVE_GROUP_TO_PERIOD_ERROR = "REMOVE_GROUP_TO_PERIOD_ERROR"

export const GET_PERIOD_PARTICIPANTS = "GET_PERIOD_PARTICIPANTS"
export const GET_PERIOD_PARTICIPANTS_SUCCESS = "GET_PERIOD_PARTICIPANTS_SUCCESS"
export const GET_PERIOD_PARTICIPANTS_CLEAN = "GET_PERIOD_PARTICIPANTS_CLEAN"
export const GET_PERIOD_PARTICIPANTS_ERROR = "GET_PERIOD_PARTICIPANTS_ERROR"

export const GET_PERIOD_CLIENTS = "GET_PERIOD_CLIENTS"
export const GET_PERIOD_CLIENTS_SUCCESS = "GET_PERIOD_CLIENTS_SUCCESS"
export const GET_PERIOD_CLIENTS_CLEAN = "GET_PERIOD_CLIENTS_CLEAN"
export const GET_PERIOD_CLIENTS_ERROR = "GET_PERIOD_CLIENTS_ERROR"

export const GET_PERIODS_PARTICIPANT = "GET_PERIODS_PARTICIPANT"
export const GET_PERIODS_PARTICIPANT_SUCCESS = "GET_PERIODS_PARTICIPANT_SUCCESS"
export const GET_PERIODS_PARTICIPANT_CLEAN = "GET_PERIODS_PARTICIPANT_CLEAN"
export const GET_PERIODS_PARTICIPANT_ERROR = "GET_PERIODS_PARTICIPANT_ERROR"
