/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  EDIT_LESSON,
  EDIT_LESSON_POSITIONS,
  LESSON_START,
  LESSON_FINISH,
} from "./actionTypes"
import {
  apiError,
  editLessonSuccess,
  editLessonPositionsSuccess,
  editLessonPositionsError,
  lessonStart,
  lessonStartError,
  lessonStartSuccess,
  lessonFinish,
  lessonFinishError,
  lessonFinishSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  updateLesson,
  updateLessonPositions,
  startLesson,
  finishLesson,
} from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateLesson, action.payload.form)
    if (response === undefined) yield put(apiError("Error al editar lección"))
    if (response.statusCode !== undefined) {
      throw new Error(response.message)
    }
    yield put(editLessonSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* positions(action) {
  try {
    const response = yield call(updateLessonPositions, action.payload.form)
    if (response === undefined)
      yield put(apiError("Error al editar la posición de las lecciones"))
    if (!response.success)
      yield put(
        apiError(
          `Error al editar la posición de las lecciones: ${response.error}`
        )
      )
    else yield put(editLessonPositionsSuccess(response))
  } catch (error) {
    yield put(editLessonPositionsError(error))
  }
}

function* start(action) {
  try {
    const response = yield call(startLesson, action.payload.form)
    if (response === undefined)
      yield put(apiError("Error al editar el inicio de la lección"))
    if (!response.success)
      yield put(
        apiError(`Error al editar el inicio de la lección: ${response.error}`)
      )
    else yield put(lessonStartSuccess(response))
  } catch (error) {
    yield put(lessonStartError(error))
  }
}

function* finish(action) {
  try {
    const response = yield call(finishLesson, action.payload.form)
    if (response === undefined)
      yield put(apiError("Error al editar el fin de la lección"))
    if (!response.success)
      yield put(
        apiError(`Error al editar el fin de la lección: ${response.error}`)
      )
    else yield put(lessonFinishSuccess(response))
  } catch (error) {
    yield put(lessonFinishError(error))
  }
}

function* lessonEditSaga() {
  yield takeEvery(EDIT_LESSON, edit)
  yield takeEvery(EDIT_LESSON_POSITIONS, positions)
  yield takeEvery(LESSON_START, start)
  yield takeEvery(LESSON_FINISH, finish)
}

export default lessonEditSaga
