import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label, Button } from "reactstrap"
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { listQuizzAnswers, listQuizzAnswersClean } from "store/actions"
import {
  getQuizzAnswerUser,
  getQuizzAnswerUserClean,
} from "store/quizzes/get_answer_user/actions"
import {
  addQuizzAnswerUser,
  addQuizzAnswerUserClean,
} from "store/quizzes/add_answer_user/actions"

const QuizzUserFormModal = props => {
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [saved, setSaved] = useState(false)
  const [value, setValue] = useState(undefined)
  const [correct, setCorrect] = useState(undefined)

  const {
    quizzAnswers,
    quizzAnswersDone,
    quizzAnswerUser,
    quizzAnswerUserDone,
    quizzAnswerUserAddLoading,
    quizzAnswerUserAddDone,
    whoIAm,
  } = useSelector(store => ({
    quizzAnswers: store.QuizzAnswersList.quizzAnswers,
    quizzAnswersDone: store.QuizzAnswersList.done,
    quizzAnswerUser: store.QuizzAnswerUserGet.quizzAnswerUser,
    quizzAnswerUserDone: store.QuizzAnswerUserGet.done,
    quizzAnswerUserAddLoading: store.QuizzAnswerUserAdd.loading,
    quizzAnswerUserAddDone: store.QuizzAnswerUserAdd.done,
    whoIAm: store.Profile.user,
  }))

  const quizz = props.quizz
  const answers = quizzAnswers ?? []
  const answerUser = quizzAnswerUser ?? { quizzAnswerId: 0 }
  const quizzId = quizz !== undefined ? quizz.quizzId : 0

  useEffect(() => {
    if (quizzId !== 0) {
      dispatch(listQuizzAnswers(quizzId, whoIAm.idUsuario))
      dispatch(
        getQuizzAnswerUser({
          quizzId: quizzId,
          userId: whoIAm.idUsuario,
        })
      )
    }
    return () => {
      dispatch(listQuizzAnswersClean())
      dispatch(getQuizzAnswerUserClean())
    }
  }, [quizzId])

  useEffect(() => {
    const answered =
      answerUser === undefined
        ? undefined
        : answers.find(
            answer => answer.quizzAnswerId === answerUser.quizzAnswerId
          )
    const correct =
      answerUser === undefined
        ? undefined
        : answers.find(
            answer =>
              answer.quizzAnswerId === answerUser.quizzAnswerId &&
              answer.correct
          )
    if (answered !== undefined) {
      setValue(answered.quizzAnswerId)
    }
    setCorrect(answered === undefined ? undefined : correct !== undefined)
    return () => {
      setValue(undefined)
      setCorrect(undefined)
    }
  }, [answers, answerUser])

  const handleSubmit = async (event, errors, values) => {
    setSaved(false)
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    dispatch(
      addQuizzAnswerUser({
        courseId: props.course.idCurso,
        quizzId: quizzId,
        userId: whoIAm.idUsuario,
        quizzAnswerId: values.private,
      })
    )
  }

  if (quizzAnswerUserAddDone) {
    dispatch(
      getQuizzAnswerUser({
        quizzId: quizzId,
        userId: whoIAm.idUsuario,
      })
    )
    dispatch(addQuizzAnswerUserClean())
    props.onAnswer()
  }

  return props.quizz === undefined ? (
    <div></div>
  ) : (
    <Modal isOpen={quizz !== undefined} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Responder pregunta
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onClose()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          {saved && (
            <div className="alert alert-success">
              La respuesta ha sido guardado exitosamente.
            </div>
          )}
          {correct !== undefined && (
            <div
              className={`text-center alert alert-${
                correct ? "success" : "danger"
              }`}
            >
              Tu respuesta fue {correct ? "correcta" : "incorrecta"}.
            </div>
          )}
          <Row className="mb-4">
            <Col md={12}>{quizz.question}</Col>
          </Row>
          {answers !== undefined && (
            <Row>
              <Col md={12}>
                <AvRadioGroup
                  name="private"
                  className="form-control noNorder"
                  value={value}
                  validate={{
                    required: {
                      value: true,
                      errorMessage:
                        "Agregue al menos una respuesta, marque la que sea correcta.",
                    },
                  }}
                >
                  {answers.map(answer => (
                    <div
                      key={`answer-${answer.quizzAnswerId}`}
                      className={`mb-2 ${
                        correct !== undefined && answer.correct
                          ? "text-success"
                          : ""
                      }`}
                    >
                      <AvRadio
                        disabled={correct !== undefined}
                        label={
                          <span className="noOpacity">{answer.answer}</span>
                        }
                        value={answer.quizzAnswerId}
                      />
                    </div>
                  ))}
                </AvRadioGroup>
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onClose()
            }}
          >
            {correct === undefined ? "Regresar" : "Continuar"}
          </button>
          {correct === undefined && (
            <button
              type="submit"
              className={`btn btn-secondary ${
                quizzAnswerUserAddLoading ? "disabled" : ""
              }`}
            >
              {quizzAnswerUserAddLoading ? "Guardando..." : "Responder"}
            </button>
          )}
        </div>
      </AvForm>
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
        .form-check-label {
          opacity: 1 !important;
        }
        .noOpacity {
          opacity: 1 !important;
        }
      `}</style>
    </Modal>
  )
}

QuizzUserFormModal.propTypes = {
  course: PropTypes.object,
  quizz: PropTypes.object,
  onClose: PropTypes.func,
  onAnswer: PropTypes.func,
}

export default QuizzUserFormModal
