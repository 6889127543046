import React, { Fragment } from "react"
import ReactSpeedometer from "react-d3-speedometer"
import PropTypes from "prop-types"

const Report9Chart = ({ value }) => {
  const number = isNaN(parseFloat(value)) ? 0 : parseFloat(value)

  return (
    <Fragment>
      <div className="speedometer d-flex justify-content-center">
        <div>
          <ReactSpeedometer
            width={600}
            height={320}
            className="ReactSpeedometer"
            maxSegmentLabels={5}
            segments={1000}
            value={number}
            ringWidth={100}
            minValue={0}
            maxValue={100}
            startColor="#ed1c2e"
            endColor="#34c38f"
            needleColor="#bb3f82"
            currentValueText={" "}
          />
          <h4 className="text-center w-100">
            Porcentaje de avance:
            <br />
            {Math.round((number + Number.EPSILON) * 100) / 100}%
          </h4>
        </div>
      </div>
      <style jsx="true">{`
        .ReactSpeedometer {
          width: 50%;
        }
        .speedometer {
          overflow: auto;
        }
        .speedometer h4 {
          width: 240px;
        }
      `}</style>
    </Fragment>
  )
}

Report9Chart.propTypes = {
  value: PropTypes.number,
}
export default Report9Chart
