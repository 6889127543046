import {
  LIST_ANSWERS,
  LIST_ANSWERS_SUCCESS,
  LIST_ANSWERS_CLEAN,
  LIST_ANSWERS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  answers: [],
}

const AnswersList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ANSWERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_ANSWERS_SUCCESS:
      state = {
        ...state,
        answers: action.payload.answers,
        loading: false,
        done: true,
      }
      break
    case LIST_ANSWERS_CLEAN:
      state = initialState
      break
    case LIST_ANSWERS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AnswersList
