import {
  EDIT_RULE,
  EDIT_RULE_CLEAN,
  EDIT_RULE_ERROR,
  EDIT_RULE_SUCCESS,
  LIST_RULES,
  LIST_RULES_CLEAN,
  LIST_RULES_ERROR,
  LIST_RULES_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  listError: "",
  listDone: false,
  rules: [],
  editDone: false,
  editError: "",
}

const GamificationRules = (state = initialState, action) => {
  switch (action.type) {
    case LIST_RULES:
      state = {
        ...state,
        listError: "",
        loading: true,
      }
      break
    case LIST_RULES_SUCCESS:
      state = {
        ...state,
        rules: action.payload.rules,
        loading: false,
        listDone: true,
      }
      break
    case LIST_RULES_ERROR:
      state = {
        ...state,
        listError: action.payload,
        loading: false,
        listDone: false,
        rules: [],
      }
      break
    case LIST_RULES_CLEAN:
      state = {
        ...state,
        listError: "",
        loading: false,
        listDone: false,
        rules: [],
      }
      break
    case EDIT_RULE:
      state = {
        ...state,
        editError: "",
        loading: true,
      }
      break
    case EDIT_RULE_SUCCESS:
      state = {
        ...state,
        loading: false,
        editDone: true,
      }
      break
    case EDIT_RULE_ERROR:
      state = {
        ...state,
        editError: action.payload,
        loading: false,
        editDone: false,
      }
      break
    case EDIT_RULE_CLEAN:
      state = {
        ...state,
        editError: "",
        loading: false,
        editDone: false,
      }
      break
  }
  return state
}

export default GamificationRules
