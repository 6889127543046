import {
  ADD_SKILL,
  ADD_SKILL_SUCCESS,
  ADD_SKILL_CLEAN,
  ADD_SKILL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  skill: {},
}

const SkillAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SKILL:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_SKILL_SUCCESS:
      state = {
        ...state,
        skill: action.payload.skill,
        loading: false,
        done: true,
      }
      break
    case ADD_SKILL_CLEAN:
      state = initialState
      break
    case ADD_SKILL_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SkillAdd
