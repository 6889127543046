import { Fragment, useEffect, useState } from "react"
import { Badge, Col, Row, Table } from "reactstrap"
import RulesFormModal from "./RulesFormModal"
import { useDispatch, useSelector } from "react-redux"
import { listPeriods, listRules } from "store/actions"
import PeriodFormModal from "./PeriodFormModal"
import moment from "moment"

const GammificationRulesAndConfig = () => {
  const dispatch = useDispatch()
  const [ruleForm, setRuleForm] = useState(undefined)
  const [periodForm, setPeriodForm] = useState(undefined)

  const { whoIAm, rules, periods } = useSelector(state => ({
    rules: state.GamificationRules.rules,
    periods: state.GamificationPeriods.periods,
    whoIAm: state.Profile.user,
  }))

  const user =
    whoIAm === undefined
      ? {
          profileId: 0,
        }
      : whoIAm

  return (
    <Fragment>
      <Row>
        <Col>
          <h2 className="text-center">Juegos generados</h2>
          <p className="text-center">
            Listado de juegos generados para configurar. Puede ordenar por
            columnas y/o buscar por palabras clave
          </p>
        </Col>
        <br />
        <table className="table table-striped mt-4">
          <thead>
            <tr>
              <th>No.</th>
              <th>Periodo</th>
              <th>Estatus</th>
              <th>Fecha inicio</th>
              <th>Fecha fin</th>
              <th>Info</th>
              {(user.profileId === 1 || user.profileId === 2) && (
                <th>Editar</th>
              )}
            </tr>
          </thead>
          <tbody>
            {periods.map((period, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{period.periodName}</td>
                <td className="text-center">
                  <span
                    className={`pt-2 pb-2 badge bg-${
                      period.status ? "success" : "light"
                    }`}
                  >
                    {period.status ? "Activo" : "Pendiente"}
                  </span>
                </td>
                <td>{moment(period.startDate).format("DD/MM/YYYY")}</td>
                <td>{moment(period.endDate).format("DD/MM/YYYY")}</td>
                <td className="text-center">
                  {period.pdfPath !== null ? (
                    <a
                      className="btn btn-primary"
                      href={period.pdfPath}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-file-pdf"></i> Ver
                    </a>
                  ) : (
                    <span className="text-muted">No disponible</span>
                  )}
                </td>
                {(user.profileId === 1 || user.profileId === 2) && (
                  <td className="text-center">
                    <button
                      className="btn btn-link"
                      onClick={() => setPeriodForm(period)}
                    >
                      <i className="fa fa-edit"></i> Editar
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        {(user.profileId === 1 || user.profileId === 2) && (
          <div className="d-flex justify-content-center mb-4 pt-4">
            <button
              onClick={() =>
                setPeriodForm({
                  periodId: 0,
                  periodName: "",
                  startDate: "",
                  endDate: "",
                  status: 0,
                })
              }
              className="btn btn-primary btn-lg"
            >
              <i className="fa fa-plus-circle"></i> Nuevo juego
            </button>
          </div>
        )}
        <hr />
        <br />
        <Col>
          <h2 className="text-center">Reglas</h2>
          <p className="text-center">
            Listado de reglas que puedes editar y utilizar en los juegos. Puede
            ordenar por columnas y/o buscar por palabras clave
          </p>
        </Col>
        <br />
        <table className="table table-striped mt-4">
          <thead>
            <tr>
              <th>No.</th>
              <th>Regla</th>
              <th>Valor en puntos</th>
              <th>Tipo de regla</th>
              {(user.profileId === 1 || user.profileId === 2) && (
                <th>Editar</th>
              )}
            </tr>
          </thead>
          <tbody>
            {rules.map((rule, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{rule.ruleName}</td>
                <td className="text-center">{rule.points}</td>
                <td>
                  {rule.ruleId > 4
                    ? "Puntos por completar curso en 1° mes"
                    : "Puntos por completar el curso con nivel"}
                </td>
                {(user.profileId === 1 || user.profileId === 2) && (
                  <td className="text-center">
                    <button
                      className="btn btn-link btn-sm"
                      onClick={() => setRuleForm(rule)}
                    >
                      <i className="fa fa-edit"></i> Editar
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
      <RulesFormModal
        show={ruleForm !== undefined}
        onCancel={() => setRuleForm(undefined)}
        onConfirm={() => {
          setRuleForm(undefined)
          dispatch(listRules())
        }}
        rule={ruleForm}
      />
      {periodForm !== undefined && (
        <PeriodFormModal
          show={true}
          onCancel={() => setPeriodForm(undefined)}
          onConfirm={() => {
            setPeriodForm(undefined)
            dispatch(listPeriods())
          }}
          period={periodForm}
        />
      )}
    </Fragment>
  )
}

export default GammificationRulesAndConfig
