import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"

const RemoveSupportMaterialModal = props => {
  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Remover material de apoyo
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        {props.error !== "" && (
          <div className="alert alert-danger">{props.error}</div>
        )}
        <p>¿Confirma que desea remover el material de apoyo seleccionado?</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            props.onCancel()
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onConfirm()}
        >
          Remover
        </button>
      </div>
    </Modal>
  )
}

RemoveSupportMaterialModal.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default RemoveSupportMaterialModal
