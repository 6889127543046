import {
  ADD_POLL,
  ADD_POLL_SUCCESS,
  ADD_POLL_CLEAN,
  ADD_POLL_ERROR,
} from "./actionTypes"

export const addPoll = form => ({
  type: ADD_POLL,
  payload: { form },
})

export const addPollSuccess = poll => {
  return {
    type: ADD_POLL_SUCCESS,
    payload: { poll },
  }
}

export const addPollClean = () => {
  return {
    type: ADD_POLL_CLEAN,
    payload: {},
  }
}

export const addPollError = error => {
  return {
    type: ADD_POLL_ERROR,
    payload: error,
  }
}
