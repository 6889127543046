import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import GammificationPeriodsParticipantListTable from "./GammificationPeriodsParticipantListTable"

const GammificationDetailModal = props => {
  const userId = props.user.IdUsuario

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size={"xl"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h3>
          Detalle de puntos obtenidos por {props.user.nombre_usuario}{" "}
          {props.user.apellido_usuario}
        </h3>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onClose()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <GammificationPeriodsParticipantListTable userId={userId} />
      </div>
    </Modal>
  )
}

GammificationDetailModal.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object,
  periodId: PropTypes.number,
  onClose: PropTypes.func,
}

export default GammificationDetailModal
