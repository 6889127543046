import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { numberWithCommas } from "common/strings"
import moment from "common/moment_i18n"
import "../../assets/scss/datatables.scss"

const ReportSurveyListTable = props => {
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const report = props.report
  const loading = props.loading
  const showComments = props.showComments ?? false

  const redondear = value => Math.round((value + Number.EPSILON) * 100) / 100

  const columns = [
    {
      dataField: "clave_curso",
      text: "ID Curso",
      sort: true,
    },
    {
      dataField: "nombre_curso",
      text: "Nombre del curso",
      sort: true,
    },
    {
      dataField: "aprobados",
      text: "Personas que han aprobado el curso",
      sort: true,
    },
    {
      dataField: "respuesta_01",
      text: "¿Consideras que la organización del temario fue adecuada?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_01)}</div>
      },
    },
    {
      dataField: "respuesta_02",
      text: "¿La explicación de los temas fue correcta?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_02)}</div>
      },
    },
    {
      dataField: "respuesta_03",
      text: "¿Qué te pareció el material presentado en el curso?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_03)}</div>
      },
    },
    {
      dataField: "respuesta_04",
      text: "¿Las actividades dentro del curso fueron útiles para reforzar el conocimiento?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_04)}</div>
      },
    },
    {
      dataField: "respuesta_05",
      text: "En cuanto a las herramientas mostradas dentro del curso, ¿Te fueron útiles?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_05)}</div>
      },
    },
    {
      dataField: "respuesta_06",
      text: "¿Como consideras que fue la experiencia dentro de la plataforma (conexión, navegación dentro de la misma)?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_06)}</div>
      },
    },
    {
      dataField: "respuesta_07",
      text: "¿La estructura y diseño de la plataforma le parecieron adecuados?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_07)}</div>
      },
    },
    {
      dataField: "respuesta_08",
      text: "¿En general, como valorarías la experiencia del curso?",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return <div className="text-center">{redondear(row.respuesta_08)}</div>
      },
    },
  ]

  if (showComments) {
    columns.push({
      dataField: "respuesta_09",
      text: "Comentarios",
      sort: true,
    })
  }

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: report.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setPage(1)
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "clave_curso", order: "asc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return loading && report === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField="clave_curso"
      columns={columns}
      data={report}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider keyField="clave_curso" columns={columns} data={report}>
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  <p>
                    <span>
                      Mostrando{" "}
                      <strong>{numberWithCommas(report.length)}</strong>{" "}
                      elementos
                    </span>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"clave_curso"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                    {report?.length <= 0 && (
                      <Row>
                        <Col md={12} className="text-center">
                          -- No hay resultados --
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                </Col>
              </Row>

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}

ReportSurveyListTable.propTypes = {
  report: PropTypes.array,
  loading: PropTypes.bool,
  showComments: PropTypes.bool,
}

export default ReportSurveyListTable
