import {
  LIST_REPORT5,
  LIST_REPORT5_SUCCESS,
  LIST_REPORT5_CLEAN,
  LIST_REPORT5_ERROR,
} from "./actionTypes"

export const listReport5 = form => ({
  type: LIST_REPORT5,
  payload: { form },
})

export const listReport5Success = report => {
  return {
    type: LIST_REPORT5_SUCCESS,
    payload: { report },
  }
}

export const listReport5Clean = () => {
  return {
    type: LIST_REPORT5_CLEAN,
    payload: {},
  }
}

export const listReport5Error = error => {
  return {
    type: LIST_REPORT5_ERROR,
    payload: error,
  }
}
