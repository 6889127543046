import {
  LIST_REPORT2,
  LIST_REPORT2_SUCCESS,
  LIST_REPORT2_CLEAN,
  LIST_REPORT2_ERROR,
} from "./actionTypes"

export const listReport2 = form => ({
  type: LIST_REPORT2,
  payload: { form },
})

export const listReport2Success = report => {
  return {
    type: LIST_REPORT2_SUCCESS,
    payload: { report },
  }
}

export const listReport2Clean = () => {
  return {
    type: LIST_REPORT2_CLEAN,
    payload: {},
  }
}

export const listReport2Error = error => {
  return {
    type: LIST_REPORT2_ERROR,
    payload: error,
  }
}
