import {
  LIST_REPORT10,
  LIST_REPORT10_SUCCESS,
  LIST_REPORT10_CLEAN,
  LIST_REPORT10_ERROR,
} from "./actionTypes"

export const listReport10 = form => ({
  type: LIST_REPORT10,
  payload: { form },
})

export const listReport10Success = report => {
  return {
    type: LIST_REPORT10_SUCCESS,
    payload: { report },
  }
}

export const listReport10Clean = () => {
  return {
    type: LIST_REPORT10_CLEAN,
    payload: {},
  }
}

export const listReport10Error = error => {
  return {
    type: LIST_REPORT10_ERROR,
    payload: error,
  }
}
