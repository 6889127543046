import {
  EDIT_ANSWER,
  EDIT_ANSWER_SUCCESS,
  EDIT_ANSWER_CLEAN,
  EDIT_ANSWER_ERROR,
} from "./actionTypes"

export const editAnswer = form => ({
  type: EDIT_ANSWER,
  payload: { form },
})

export const editAnswerSuccess = answer => {
  return {
    type: EDIT_ANSWER_SUCCESS,
    payload: { answer },
  }
}

export const editAnswerClean = () => {
  return {
    type: EDIT_ANSWER_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_ANSWER_ERROR,
    payload: error,
  }
}
