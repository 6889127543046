import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import {
  getLessons,
  addQuestion,
  editQuestion,
  addQuestionClean,
  editQuestionClean,
  editAnswer,
  removeQuestion,
  addAnsswers,
  editRule,
  editRuleClean,
} from "store/actions"
import ImageUploadModal from "components/Common/ImageUploadModal"

const RulesFormModal = props => {
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [ruleName, setRuleName] = useState("")

  const { loading, done, error } = useSelector(state => ({
    loading: state.GamificationRules.loading,
    done: state.GamificationRules.editDone,
    error: state.GamificationRules.editError,
  }))

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    dispatch(
      editRule({
        ruleId: props.rule.ruleId,
        name: values.rule,
        points: parseInt(values.points),
      })
    )
  }

  if (done) {
    dispatch(editRuleClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Editar regla
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          {error !== "" && <div className="alert alert-danger">error</div>}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="rule"
                label="Nombre de la regla"
                className="form-control"
                placeholder="Ingrese el nombre de la regla"
                value={props.rule !== undefined ? props.rule.ruleName : ""}
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="points"
                label="Valor en puntos"
                className="form-control"
                placeholder="0"
                value={props.rule !== undefined ? props.rule.points : "0"}
                type="number"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button type="submit" className={`btn btn-secondary`}>
            {"Guardar"}
          </button>
        </div>
      </AvForm>
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
      `}</style>
    </Modal>
  )
}

RulesFormModal.propTypes = {
  show: PropTypes.bool,
  rule: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default RulesFormModal
