import {
  EDIT_GROUP,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const editGroup = form => ({
  type: EDIT_GROUP,
  payload: { form },
})

export const editGroupSuccess = group => {
  return {
    type: EDIT_GROUP_SUCCESS,
    payload: { group },
  }
}

export const editGroupClean = () => {
  return {
    type: EDIT_GROUP_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
