import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report3Chart = ({ data }) => {
  const categories =
    data !== undefined
      ? data.map(
          element => `${element.nombre_usuario} ${element.apellido_usuario}`
        )
      : []

  let aprobados = []
  let enProceso = []
  let faltantes = []

  if (data !== undefined)
    data.forEach(element => {
      aprobados.push(element.cursos_aprobados)
      enProceso.push(element.cursos_en_proceso)
      faltantes.push(element.cursos_faltantes)
    })

  const series = [
    { name: "Aprobados", data: aprobados },
    { name: "En proceso", data: enProceso },
    { name: "Faltantes", data: faltantes },
  ]

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#34c38f", "#f1b44c", "#f46a6a"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report3Chart.propTypes = {
  data: PropTypes.array,
}
export default Report3Chart
