import {
  GET_EXAM,
  GET_EXAM_SUCCESS,
  GET_EXAM_CLEAN,
  GET_EXAM_ERROR,
} from "./actionTypes"

export const getExam = idCourse => ({
  type: GET_EXAM,
  payload: { idCourse },
})

export const getExamSuccess = exam => {
  return {
    type: GET_EXAM_SUCCESS,
    payload: { exam },
  }
}

export const getExamClean = () => {
  return {
    type: GET_EXAM_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_EXAM_ERROR,
    payload: error,
  }
}
