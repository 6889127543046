import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import GammificationDashboard from "components/Gammification/GammificationDashboard"
import GammificationBeneficiaries from "components/Gammification/GammificationBeneficiaries"
import GammificationRulesAndConfig from "components/Gammification/GammificationRulesAndConfig"
import { listPeriods, listRules } from "store/actions"

const GammificationStart = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("1")

  const { whoIAm } = useSelector(state => ({
    whoIAm: state.Profile.user,
  }))

  const profileId = whoIAm?.profileId ?? 0

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(listRules())
  }, [])

  useEffect(() => {
    dispatch(listPeriods())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Aprende y gana | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h2 className="main-celf-color">Aprende y gana</h2>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Home",
                    link: "/dashboard",
                  },
                  {
                    key: "gammification",
                    title: "Aprende y gana",
                    link: "/gammification",
                  },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-tachometer-alt"></i> Tableros
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-tachometer-alt"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-trophy"></i> Ganadores
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-trophy"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    {(profileId === 1 || profileId === 2) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleIconCustom("3")
                          }}
                        >
                          <span className="d-none d-sm-block">
                            <i className="fas fa-cogs"></i> Reglas
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cogs"></i>
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted mt-4"
                  >
                    <TabPane tabId="1">
                      <GammificationDashboard />
                    </TabPane>
                    <TabPane tabId="2">
                      <GammificationBeneficiaries />
                    </TabPane>
                    {(profileId === 1 || profileId === 2) && (
                      <TabPane tabId="3">
                        <GammificationRulesAndConfig />
                      </TabPane>
                    )}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GammificationStart
