import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_CLEAN,
  GET_CUSTOMER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  customer: {},
}

const CustomerGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customer: action.payload.customer,
        loading: false,
        done: true,
      }
      break
    case GET_CUSTOMER_CLEAN:
      state = initialState
      break
    case GET_CUSTOMER_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CustomerGet
