import {
  ADD_GROUP_PARTICIPANT,
  ADD_GROUP_PARTICIPANT_SUCCESS,
  ADD_GROUP_PARTICIPANT_CLEAN,
  ADD_GROUP_PARTICIPANT_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  groupParticipant: {},
}

const GroupParticipantAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GROUP_PARTICIPANT:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_GROUP_PARTICIPANT_SUCCESS:
      state = {
        ...state,
        groupParticipant: action.payload.groupParticipant,
        loading: false,
        done: true,
      }
      break
    case ADD_GROUP_PARTICIPANT_CLEAN:
      state = initialState
      break
    case ADD_GROUP_PARTICIPANT_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupParticipantAdd
