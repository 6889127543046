import {
  ADD_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_CLEAN,
  ADD_QUESTION_ERROR,
} from "./actionTypes"

export const addQuestion = form => ({
  type: ADD_QUESTION,
  payload: { form },
})

export const addQuestionSuccess = question => {
  return {
    type: ADD_QUESTION_SUCCESS,
    payload: { question },
  }
}

export const addQuestionClean = () => {
  return {
    type: ADD_QUESTION_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_QUESTION_ERROR,
    payload: error,
  }
}
