import {
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const getGroup = id => {
  return {
    type: GET_GROUP,
    payload: { id },
  }
}

export const getGroupSuccess = group => {
  return {
    type: GET_GROUP_SUCCESS,
    payload: { group },
  }
}

export const getGroupClean = () => {
  return {
    type: GET_GROUP_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
