import {
  REMOVE_POLL_ANSWER,
  REMOVE_POLL_ANSWER_SUCCESS,
  REMOVE_POLL_ANSWER_CLEAN,
  REMOVE_POLL_ANSWER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  pollAnswer: undefined,
}

const PollAnswerRemove = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_POLL_ANSWER:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_POLL_ANSWER_SUCCESS:
      state = {
        ...state,
        pollAnswer: action.payload.pollAnswer,
        loading: false,
        done: true,
      }
      break
    case REMOVE_POLL_ANSWER_CLEAN:
      state = initialState
      break
    case REMOVE_POLL_ANSWER_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PollAnswerRemove
