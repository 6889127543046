/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_EXAM } from "./actionTypes"
import { apiError, editExamSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateExam } from "../../../helpers/backend_helper"

function* edit(request) {
  try {
    const response = yield call(updateExam, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(editExamSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* examEditSaga() {
  yield takeEvery(EDIT_EXAM, edit)
}

export default examEditSaga
