import {
  LIST_REPORT9,
  LIST_REPORT9_SUCCESS,
  LIST_REPORT9_CLEAN,
  LIST_REPORT9_ERROR,
} from "./actionTypes"

export const listReport9 = form => ({
  type: LIST_REPORT9,
  payload: { form },
})

export const listReport9Success = report => {
  return {
    type: LIST_REPORT9_SUCCESS,
    payload: { report },
  }
}

export const listReport9Clean = () => {
  return {
    type: LIST_REPORT9_CLEAN,
    payload: {},
  }
}

export const listReport9Error = error => {
  return {
    type: LIST_REPORT9_ERROR,
    payload: error,
  }
}
