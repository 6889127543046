import {
  ADD_KNOWLEDGE_CATEGORY,
  ADD_KNOWLEDGE_CATEGORY_SUCCESS,
  ADD_KNOWLEDGE_CATEGORY_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const addKnowledgeCategory = form => ({
  type: ADD_KNOWLEDGE_CATEGORY,
  payload: { form },
})

export const addKnowledgeCategorySuccess = knowledgeCategory => {
  return {
    type: ADD_KNOWLEDGE_CATEGORY_SUCCESS,
    payload: { knowledgeCategory },
  }
}

export const addKnowledgeCategoryClean = () => {
  return {
    type: ADD_KNOWLEDGE_CATEGORY_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
