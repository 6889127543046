import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

import { map } from "lodash"

// Import Editor
import { Editor } from "react-draft-wysiwyg"

/* import {
  getInboxMails as onGetInboxMails,
  getStarredMails as onGetStarredMails,
  getImportantMails as onGetImportantMails,
  getDraftMails as onGetDraftMails,
  getSentMails as onGetSentMails,
  getTrashMails as onGetTrashMails,
} from "store/mails/actions" */

//Import images
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"

//redux
import { useSelector, useDispatch } from "react-redux"

const EmailInbox = props => {
  const dispatch = useDispatch()

  /* const {
    inboxmails,
    starredmails,
    importantmails,
    trashmails,
    draftmails,
    sentmails,
  } = useSelector(state => ({
    inboxmails: state.mails.inboxmails,
    starredmails: state.mails.starredmails,
    importantmails: state.mails.importantmails,
    trashmails: state.mails.trashmails,
    draftmails: state.mails.draftmails,
    sentmails: state.mails.sentmails,
  })) */

  const inboxmails = [
    {
      id: 1,
      name: "CELF",
      description:
        "<a href='#' class='subject'>Se agregó el curso Impermeabilizante híbrido base agua acrílico - poliuretano al grupo.</a>",
      read: false,
      fav: false,
      date: "6 ago 2021",
    },
    {
      id: 2,
      name: "CELF",
      description:
        "<a href='#' class='subject'>El usuario Atzin Rogel ha iniciado los cursos de este grupo.</a>",
      read: false,
      fav: false,
      date: "3 ago 2021",
    },
    {
      id: 3,
      name: "CELF",
      description:
        "<a href='#' class='subject'>El usuario Jesús Martínez ha superado el 100% de los cursos de este grupo.</span></a>",
      read: true,
      fav: false,
      date: "28 jul 2021",
    },
    {
      id: 4,
      name: "CELF",
      description:
        "<a href='#' class='subject'>Te informamos que el progreso del curso ha superado el 50%.</a>",
      read: true,
      fav: false,
      date: "27 jul 2021",
    },
  ]
  const starredmails = []
  const importantmails = []
  const trashmails = []
  const draftmails = []
  const sentmails = []

  const [activeTab, setactiveTab] = useState("1")
  const [modal, setmodal] = useState(false)

  useEffect(() => {
    /*     dispatch(onGetInboxMails())
    dispatch(onGetStarredMails())
    dispatch(onGetImportantMails())
    dispatch(onGetDraftMails())
    dispatch(onGetSentMails())
    dispatch(onGetTrashMails()) */
  }, [dispatch])

  return (
    <Row>
      <Col xs="12">
        <div className="mb-3">
          <Card>
            {/* Render Email Top Tool Bar */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ul className="message-list">
                  {map(inboxmails, (inbox, key) => (
                    <li key={key} className={inbox.read ? "" : "unread"}>
                      <div className="col-mail col-mail-2 col-mail-celf">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: inbox.description,
                          }}
                        ></div>
                        <div className="date">{inbox.date}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </TabPane>
            </TabContent>
          </Card>
          <Row>
            <Col xs="12">Tienes 2 mensajes sin leer</Col>
          </Row>
        </div>
      </Col>
      <style jsx="true">{`
        .col-mail-celf {
          left: 15px !important;
        }
      `}</style>
    </Row>
  )
}

EmailInbox.propTypes = {
  inboxmails: PropTypes.array,
  starredmails: PropTypes.array,
  onGetInboxMails: PropTypes.func,
  onGetStarredMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
}

export default withRouter(EmailInbox)
