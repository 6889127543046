import {
  REMOVE_POLL_ANSWER,
  REMOVE_POLL_ANSWER_SUCCESS,
  REMOVE_POLL_ANSWER_CLEAN,
  REMOVE_POLL_ANSWER_ERROR,
} from "./actionTypes"

export const removePollAnswer = form => ({
  type: REMOVE_POLL_ANSWER,
  payload: { form },
})

export const removePollAnswerSuccess = pollAnswer => {
  return {
    type: REMOVE_POLL_ANSWER_SUCCESS,
    payload: { pollAnswer },
  }
}

export const removePollAnswerClean = () => {
  return {
    type: REMOVE_POLL_ANSWER_CLEAN,
    payload: {},
  }
}

export const removePollAnswerError = error => {
  return {
    type: REMOVE_POLL_ANSWER_ERROR,
    payload: error,
  }
}
