import { Fragment } from "react"
import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import CoursesLessonsDetails from "components/UserCourses/CoursesLessonsDetail"
import {
  getCourseUser,
  getKnowledgeCategories,
  getSkills,
  getLevels,
  getCourseKnowledges,
  getUsers,
  getLessons,
  startCourse,
  progressLesson,
  progressLessonClean,
} from "store/actions"
import CourseLessonIntro from "components/Courses/CourseLessonIntro"
import CourseSupportMaterials from "components/Courses/CourseSupportMaterials"
import CourseNotes from "components/Courses/CourseNotes"
import CourseExamUser from "components/UserCourses/CourseExamUser"
import CoursePollForm from "components/UserCourses/CoursePollForm"

const CourseInfo = props => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState("5")
  const [approved, setApproved] = useState(false)
  const [lessonsProgress, setLessonsProgress] = useState(0)
  const [finishedLessons, setFinishedLessons] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(undefined)
  const [categoriesSelectedMulti, setCategoriesSelectedMulti] = useState([])
  const [previousCoursesSelectedMulti, setPreviousCoursesSelectedMulti] =
    useState([])
  const [responsiblesSelectedMulti, setResponsiblesSelectedMulti] = useState([])
  const [habilitiesSelectedMulti, setHabilitiesSelectedMulti] = useState([])
  const [knowledgesSelectedMulti, setKnowledgesSelectedMulti] = useState([])
  const [suggestedCoursesSelectedMulti, setSuggestedCoursesSelectedMulti] =
    useState([])

  const [courseParticipant, setCourseParticipant] = useState(undefined)

  const {
    course,
    knowledgeCategories,
    skills,
    levels,
    users,
    courseKnowledges,
    lessons,
    progressLessons,
    user,
  } = useSelector(store => ({
    user: store.Profile.user,
    course: store.CourseGet.course,
    knowledgeCategories: store.KnowledgeCategoriesList.knowledgeCategories,
    skills: store.SkillsList.skills,
    levels: store.LevelsList.levels,
    courseKnowledges: store.CourseKnowledgesList.courseKnowledges,
    users: store.UsersList.users,
    lessons: store.LessonsList.lessons,
    progressLessons: store.LessonProgress.lessons,
  }))

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getCourseUser(id, 0))
      dispatch(getLessons(id))
      dispatch(progressLesson(id))
      dispatch(startCourse({ idCourse: id }))
    }
    dispatch(getUsers())
    dispatch(getKnowledgeCategories())
    dispatch(getSkills())
    dispatch(getLevels())
    dispatch(getCourseKnowledges())
    return () => {
      dispatch(progressLessonClean())
    }
  }, [id, user])

  useEffect(() => {
    updateCourseParticipant()
  }, [course])

  const knowCat = course?.knowledgeCategories
  const prevCourses = course?.previousCourses
  const sugCourses = course?.suggestedCourses
  const respCourses = course?.responsibles
  const know = course?.knowledges
  const skl = course?.abilities
  const vc = course?.courseValoration

  useEffect(() => {
    let prevCoursesArray = []
    let sugCoursesArray = []
    let knowCatArray = []
    let knowArray = []
    let sklArray = []
    let rArray = []
    if (respCourses !== undefined)
      respCourses.forEach(respCourse => {
        rArray.push({
          label: ``,
          value: respCourses.userId,
        })
      })

    if (prevCourses !== undefined)
      prevCourses.forEach(prevCourse => {
        prevCoursesArray.push({
          label: `${prevCourse.courseName}`,
          value: prevCourse.courseId,
        })
      })

    if (sugCourses !== undefined)
      sugCourses.forEach(sugCourse => {
        sugCoursesArray.push({
          label: `${sugCourse.courseName}`,
          value: sugCourse.courseId,
        })
      })

    if (knowCat !== undefined)
      knowCat.forEach(knowC => {
        knowCatArray.push({
          label: `${knowC.knowledgeCategoryName}`,
          value: knowC.knowledgeCategoryId,
        })
      })

    if (know !== undefined)
      know.forEach(knowledge => {
        knowArray.push({
          label: `${knowledge.knowledgeName}`,
          value: knowledge.knowledgeId,
        })
      })

    if (skl !== undefined)
      skl.forEach(skill => {
        sklArray.push({
          label: `${skill.abilityName}`,
          value: skill.abilityId,
        })
      })

    setPreviousCoursesSelectedMulti(prevCoursesArray)
    setSuggestedCoursesSelectedMulti(sugCoursesArray)
    setCategoriesSelectedMulti(knowCatArray)
    setKnowledgesSelectedMulti(knowArray)
    setHabilitiesSelectedMulti(sklArray)
    setResponsiblesSelectedMulti(rArray)
  }, [prevCourses, sugCourses, knowCat, vc, users])

  useEffect(() => {
    const progress = getProgress()
    setLessonsProgress(Math.round(progress))
    return () => {
      setLessonsProgress(0)
    }
  }, [courseParticipant])

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleReloadProgress = () => {
    dispatch(getCourseUser(id))
  }

  const updateCourseParticipant = () => {
    const approved = course?.courseParticipant?.approved ?? false
    setCourseParticipant(course?.courseParticipant)
    setApproved(approved)
  }

  const getProgress = () => {
    if (!courseParticipant) return -1
    return !courseParticipant.approved
      ? lessonsProgress > courseParticipant.progress
        ? lessonsProgress
        : courseParticipant.progress
      : 100
  }

  return course !== undefined ? (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {`Curso: ${course.courseName}`} | Professional&apos;s Academy
            E-Learning
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Home",
                    link: "/dashboard",
                  },
                  {
                    key: "my_courses",
                    title: "Mis cursos",
                    link: "/my-courses",
                  },
                  {
                    key: "course_info",
                    title: course.courseName,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <div className="alert alert-info">
                  <Row>
                    <Col md={9}>
                      <h2 className="mt-4 mb-4" style={{ borderRadius: 0 }}>
                        {course.courseName}
                      </h2>
                    </Col>
                    <Col md={3}>
                      {/*lessonsProgress >= 0 && (
                        <Fragment>
                          <h5 className="text-center mt-3">
                            Progreso: {lessonsProgress}%
                          </h5>
                          <Progress multi>
                            <Progress
                              bar
                              color="primary"
                              value={lessonsProgress}
                              style={{
                                width: `${lessonsProgress}%`,
                              }}
                            />
                            <Progress
                              bar
                              value={100 - lessonsProgress}
                              color="light"
                              style={{
                                width: `${100 - lessonsProgress}%`,
                              }}
                            />
                          </Progress>
                        </Fragment>
                            )*/}
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleIconCustom("5")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-image"></i> Portada
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-image"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-list"></i> Información
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-list"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-book-open"></i> Contenido
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-book-open"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "7",
                        })}
                        onClick={() => {
                          toggleIconCustom("7")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-paperclip"></i> Adjuntos
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-paperclip"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleIconCustom("3")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-sticky-note"></i> Notas
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-sticky-note"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleIconCustom("6")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-medal"></i> Examen
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-medal"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleIconCustom("4")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-question-circle"></i> Valoración
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted mt-4"
                  >
                    <TabPane tabId="5">
                      <Row>
                        <Col md={12}>
                          <img
                            src={course.backgroundImage}
                            className="w-100"
                            draggable="false"
                          />
                          <br />
                          <div className="d-flex justify-content-center mt-4">
                            <button
                              onClick={() => setActiveTab("1")}
                              className="btn btn-secondary ml-2"
                            >
                              Siguiente <i className="fa fa-arrow-right"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="1">
                      <CourseLessonIntro
                        course={course}
                        knowledgeCategories={categoriesSelectedMulti}
                        previousCourses={previousCoursesSelectedMulti}
                        responsibles={responsiblesSelectedMulti}
                        goNext={() => setActiveTab("2")}
                        level={
                          levels.find(
                            level => level.levelId === course.courseLevelId
                          )?.levelName
                        }
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <CoursesLessonsDetails
                        course={course}
                        lessons={lessons}
                        approved={approved}
                        progressLessons={progressLessons}
                        activeTab={activeTab}
                        selectedLesson={selectedLesson}
                        cleanSelectedLesson={() => setSelectedLesson(undefined)}
                        reloadProgress={percentage => {
                          setLessonsProgress(percentage)
                          handleReloadProgress()
                        }}
                        goToExam={() => {
                          setFinishedLessons(true)
                          setActiveTab("6")
                        }}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <CourseNotes
                        course={course}
                        goToLesson={lessonId => {
                          setSelectedLesson(lessonId)
                          setActiveTab("2")
                        }}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      {!approved ? (
                        <div className="alert alert-warning">
                          <h3 className="text-center pt-2">
                            <i className="fa fa-exclamation-circle"></i> Para
                            poder valorar el curso es necesario haber aprobado
                            el examen.
                          </h3>
                        </div>
                      ) : (
                        <CoursePollForm course={course} />
                      )}
                    </TabPane>
                    <TabPane tabId="6">
                      <CourseExamUser
                        course={course}
                        approved={approved}
                        goToValoration={() => {
                          setActiveTab("4")
                        }}
                        canApplyExam={
                          (courseParticipant?.progress ?? 0) >= 70 ||
                          finishedLessons
                        }
                        reloadProgress={() => handleReloadProgress()}
                      />
                    </TabPane>
                    <TabPane tabId="7">
                      <CourseSupportMaterials course={course} readOnly={true} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div></div>
  )
}

export default withRouter(CourseInfo)
