import {
  PROGRESS_LESSON,
  PROGRESS_LESSON_SUCCESS,
  PROGRESS_LESSON_CLEAN,
  PROGRESS_LESSON_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  lessons: [],
}

const LessonProgress = (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS_LESSON:
      state = {
        ...state,
        loading: true,
      }
      break
    case PROGRESS_LESSON_SUCCESS:
      state = {
        ...state,
        lessons: action.payload.lessons,
        loading: false,
        done: true,
      }
      break
    case PROGRESS_LESSON_CLEAN:
      state = initialState
      break
    case PROGRESS_LESSON_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LessonProgress
