/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { EDIT_LEVEL } from "./actionTypes"
import { apiError, editLevelSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateLevel } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateLevel, action.payload.form)
    yield put(editLevelSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* levelEditSaga() {
  yield takeEvery(EDIT_LEVEL, edit)
}

export default levelEditSaga
