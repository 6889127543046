import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { useDispatch, useSelector } from "react-redux"
import { AvGroup, AvInput } from "availity-reactstrap-validation"
import ReportSurveyListTable from "components/Reports/ReportSurveyListTable"
import {
  getCourses,
  listReportSurvey,
  listReportSurveyClean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import { redondear } from "helpers/utilerias_helper"

const ReportSurvey = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.ReportSurveyList.error,
    loading: state.ReportSurveyList.loading,
    report: state.ReportSurveyList.report,
    done: state.ReportSurveyList.done,
    courses: state.CoursesList.coursesForAdmin,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(
      listReportSurvey({
        ...data,
        status: true,
        userId: whoIAm.idUsuario,
        showComments,
      })
    )
  }

  const getXlsData = report => {
    let data = report.map((element, index) => {
      const report = {
        "ID Curso": element.clave_curso,
        "Nombre del curso": element.nombre_curso,
        "Personas que han aprobado el curso": element.aprobados,
        "¿Consideras que la organización del temario fue adecuada?": redondear(
          element.respuesta_01
        ),
        "¿La explicación de los temas fue correcta?": redondear(
          element.respuesta_02
        ),
        "¿Qué te pareció el material presentado en el curso?": redondear(
          element.respuesta_03
        ),
        "¿Las actividades dentro del curso fueron útiles para reforzar el conocimiento?":
          redondear(element.respuesta_04),
        "En cuanto a las herramientas mostradas dentro del curso, ¿Te fueron útiles?":
          redondear(element.respuesta_05),
        "¿Como consideras que fue la experiencia dentro de la plataforma (conexión, navegación dentro de la misma)?":
          redondear(element.respuesta_06),
        "¿La estructura y diseño de la plataforma le parecieron adecuados?":
          redondear(element.respuesta_07),
        "¿En general, como valorarías la experiencia del curso?": redondear(
          element.respuesta_08
        ),
      }

      if (showComments) {
        report["Comentarios"] = element.respuesta_09
      }
      return report
    })

    return {
      ReporteEncuesta: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReportSurveyClean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte Encuesta | Professional&apos;s Academy E-Learning
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte Encuesta</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Home",
                    link: "/dashboard",
                  },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "ReportSurvey", title: "Reporte encuesta" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte Encuesta"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"ReporteEncuesta"}
                        name="Reporte encuesta"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                  />
                  <FormGroup check className="text-center my-4">
                    <Label check={true}>
                      <Input
                        type="checkbox"
                        name="comentarios"
                        onChange={() => setShowComments(!showComments)}
                      />
                      Incluir comentarios
                    </Label>
                  </FormGroup>
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <ReportSurveyListTable
                        loading={loading}
                        report={reportInfo}
                        showComments={showComments}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportSurvey
