import { Fragment, useState } from "react"
import { PropTypes } from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import CoursesWidget from "components/Dashboard/CoursesWidget"
import { Link } from "react-router-dom"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const CoursesByStatus = props => {
  const myCourses = props.courses ?? []
  const showAll = props.all ?? false

  myCourses.forEach(course => {
    const previousCourse =
      course.previousCourses?.length ?? 0 > 0 ? course.previousCourses[0] : null

    const previousCourseId =
      previousCourse === null ? 0 : previousCourse.courseId

    const previousCourseApproved = myCourses.find(myCourse => {
      console.log(
        myCourse.courseName,
        myCourse.courseId,
        previousCourseId,
        myCourse.courseParticipant?.approved
      )
      return (
        myCourse.courseId === previousCourseId &&
        myCourse.courseParticipant?.approved
      )
    })

    course.previosCourseName =
      previousCourse === null ? "" : previousCourse.courseName
    course.previosCourseId = previousCourseId
    course.previousCourseApproved =
      previousCourseId === 0 ? true : previousCourseApproved !== undefined
    return course
  })

  const coursesPending = myCourses
    .filter(course => !course.courseParticipant?.approved)
    .sort((a, b) => {
      return a.previosCourseId > b.previosCourseId ? 1 : -1
    })

  const coursesCompleted = myCourses.filter(
    course => course.courseParticipant?.approved
  )
  const coursesFav = myCourses.filter(
    course => course.courseParticipant?.favourite === true
  )
  const [activeTab, setActiveTab] = useState(props.initialTab ?? "2")

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Fragment>
      <Nav className="icon-tab nav-justified">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggleIconCustom("2")
            }}
          >
            <span className="d-none d-sm-block">
              Cursos pendientes ({coursesPending.length})
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-cogs"></i>
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              toggleIconCustom("3")
            }}
          >
            <span className="d-none d-sm-block">
              Cursos completados ({coursesCompleted.length})
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-cogs"></i>
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "4",
            })}
            onClick={() => {
              toggleIconCustom("4")
            }}
          >
            <span className="d-none d-sm-block">
              Cursos favoritos ({coursesFav.length})
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-cogs"></i>
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      {props.loading ? (
        <LoadingIndicator className="mt-4" />
      ) : (
        <TabContent activeTab={activeTab} className="p-3 text-muted mt-4">
          <TabPane tabId="2">
            <Row>
              {coursesPending.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos pendientes...</h5>
                </Col>
              )}
              {coursesPending.map((course, index) =>
                index >= 3 && !showAll ? (
                  <div key={`course-pending-${course.courseId}`}></div>
                ) : (
                  <Col
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    key={`course-pending-${course.courseId}`}
                    className="mb-4"
                  >
                    <div className="disabled">
                      <CoursesWidget
                        id={course.courseId}
                        title={course.courseName}
                        category={course.knowledgeCategories
                          .map(category =>
                            category.knowledgeCategoryName.trim()
                          )
                          .join(", ")}
                        percentage={course.courseParticipant.progress}
                        minutes={course.dedicationTime}
                        image={course.backgroundImage ?? ""}
                        fav={course.courseParticipant.favourite}
                        started={course.courseParticipant.progress > 0}
                        finished={course.courseParticipant.progress >= 100}
                        disabled={
                          course.previosCourseId !== 0 &&
                          !course.previousCourseApproved
                        }
                        disabledBy={course.previosCourseName}
                      />
                    </div>
                  </Col>
                )
              )}
            </Row>
            {coursesPending.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`my-courses/2`} draggable="false">
                    Ver más...
                  </Link>
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId="3">
            <Col lg={12}>
              <Row>
                {coursesCompleted.length <= 0 && (
                  <Col lg={12} className="text-center">
                    <h5 className="text-center">Sin cursos completados...</h5>
                  </Col>
                )}
                {coursesCompleted
                  .sort((a, b) => {
                    const courseCodeA = isNaN(a.courseCode)
                      ? 100000
                      : parseInt(a.courseCode)
                    const courseCodeB = isNaN(b.courseCode)
                      ? 100000
                      : parseInt(b.courseCode)
                    return courseCodeA > courseCodeB ? 1 : -1
                  })
                  .map((course, index) =>
                    index >= 3 && !showAll ? (
                      <div key={`course-completed-${course.courseId}`}></div>
                    ) : (
                      <Col
                        xl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        key={`course-completed-${course.courseId}`}
                        className="mb-4"
                      >
                        <CoursesWidget
                          id={course.courseId}
                          title={course.courseName}
                          category={course.knowledgeCategories
                            .map(category =>
                              category.knowledgeCategoryName.trim()
                            )
                            .join(", ")}
                          percentage={course.courseParticipant.progress}
                          minutes={course.dedicationTime}
                          image={course.backgroundImage ?? ""}
                          fav={course.courseParticipant.favourite}
                          started={course.courseParticipant.progress > 0}
                          finished={course.courseParticipant.progress >= 100}
                          dateStart={course.courseParticipant.startDate}
                          dateFinish={course.courseParticipant.endDate}
                        />
                      </Col>
                    )
                  )}
              </Row>
              {coursesCompleted.length > 3 && !showAll && (
                <Row>
                  <Col lg={12} className="text-center">
                    <Link to={`my-courses/3`} draggable="false">
                      Ver más...
                    </Link>
                  </Col>
                </Row>
              )}
            </Col>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              {coursesFav.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos favoritos...</h5>
                </Col>
              )}
              {coursesFav
                .sort((a, b) => {
                  const courseCodeA = isNaN(a.courseCode)
                    ? 100000
                    : parseInt(a.courseCode)
                  const courseCodeB = isNaN(b.courseCode)
                    ? 100000
                    : parseInt(b.courseCode)
                  return courseCodeA > courseCodeB ? 1 : -1
                })
                .map((course, index) =>
                  index >= 3 && !showAll ? (
                    <div key={`course-fav-${course.courseId}`}></div>
                  ) : (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      key={`course-fav-${course.courseId}`}
                      className="mb-4"
                    >
                      <CoursesWidget
                        id={course.courseId}
                        title={course.courseName}
                        category={course.knowledgeCategories
                          .map(category =>
                            category.knowledgeCategoryName.trim()
                          )
                          .join(", ")}
                        percentage={course.courseParticipant.progress}
                        minutes={course.dedicationTime}
                        image={course.backgroundImage ?? ""}
                        fav={course.courseParticipant.favourite}
                        started={course.courseParticipant.progress > 0}
                        finished={course.courseParticipant.progress >= 100}
                        dateStart={course.courseParticipant.startDate}
                        dateFinish={course.courseParticipant.endDate}
                      />
                    </Col>
                  )
                )}
            </Row>
            {coursesFav.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`my-courses/4`} draggable="false">
                    Ver más...
                  </Link>
                </Col>
              </Row>
            )}
          </TabPane>
        </TabContent>
      )}
    </Fragment>
  )
}

CoursesByStatus.propTypes = {
  courses: PropTypes.array,
  loading: PropTypes.bool,
  all: PropTypes.bool,
  initialTab: PropTypes.string,
}

export default CoursesByStatus
