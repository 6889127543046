import {
  ADD_LEVEL,
  ADD_LEVEL_SUCCESS,
  ADD_LEVEL_CLEAN,
  ADD_LEVEL_ERROR,
} from "./actionTypes"

export const addLevel = form => ({
  type: ADD_LEVEL,
  payload: { form },
})

export const addLevelSuccess = level => {
  return {
    type: ADD_LEVEL_SUCCESS,
    payload: { level },
  }
}

export const addLevelClean = () => {
  return {
    type: ADD_LEVEL_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_LEVEL_ERROR,
    payload: error,
  }
}
