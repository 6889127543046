import {
  LIST_PERIODS,
  LIST_PERIODS_SUCCESS,
  LIST_PERIODS_ERROR,
  LIST_PERIODS_CLEAN,
  GET_SINGLE_PERIOD,
  GET_SINGLE_PERIOD_SUCCESS,
  GET_SINGLE_PERIOD_ERROR,
  GET_SINGLE_PERIOD_CLEAN,
  EDIT_PERIOD,
  EDIT_PERIOD_SUCCESS,
  EDIT_PERIOD_ERROR,
  EDIT_PERIOD_CLEAN,
  CREATE_PERIOD,
  CREATE_PERIOD_SUCCESS,
  CREATE_PERIOD_ERROR,
  CREATE_PERIOD_CLEAN,
  ADD_RULE_TO_PERIOD,
  ADD_RULE_TO_PERIOD_SUCCESS,
  ADD_RULE_TO_PERIOD_ERROR,
  ADD_RULE_TO_PERIOD_CLEAN,
  ADD_GROUP_TO_PERIOD,
  ADD_GROUP_TO_PERIOD_SUCCESS,
  ADD_GROUP_TO_PERIOD_ERROR,
  ADD_GROUP_TO_PERIOD_CLEAN,
  REMOVE_RULE_TO_PERIOD,
  REMOVE_RULE_TO_PERIOD_SUCCESS,
  REMOVE_RULE_TO_PERIOD_ERROR,
  REMOVE_RULE_TO_PERIOD_CLEAN,
  REMOVE_GROUP_TO_PERIOD,
  REMOVE_GROUP_TO_PERIOD_SUCCESS,
  REMOVE_GROUP_TO_PERIOD_ERROR,
  REMOVE_GROUP_TO_PERIOD_CLEAN,
  GET_PERIOD_PARTICIPANTS,
  GET_PERIOD_PARTICIPANTS_SUCCESS,
  GET_PERIOD_PARTICIPANTS_ERROR,
  GET_PERIOD_PARTICIPANTS_CLEAN,
  GET_PERIOD_CLIENTS,
  GET_PERIOD_CLIENTS_SUCCESS,
  GET_PERIOD_CLIENTS_ERROR,
  GET_PERIOD_CLIENTS_CLEAN,
  GET_PERIODS_PARTICIPANT,
  GET_PERIODS_PARTICIPANT_SUCCESS,
  GET_PERIODS_PARTICIPANT_ERROR,
  GET_PERIODS_PARTICIPANT_CLEAN,
} from "./actionTypes"

export const listPeriods = () => ({
  type: LIST_PERIODS,
  payload: {},
})

export const listPeriodsSuccess = periods => ({
  type: LIST_PERIODS_SUCCESS,
  payload: { periods },
})

export const listPeriodsError = error => ({
  type: LIST_PERIODS_ERROR,
  payload: { error },
})

export const listPeriodsClean = () => ({
  type: LIST_PERIODS_CLEAN,
  payload: {},
})

export const getSinglePeriod = periodId => {
  return {
    type: GET_SINGLE_PERIOD,
    payload: { periodId },
  }
}

export const getSinglePeriodSuccess = period => ({
  type: GET_SINGLE_PERIOD_SUCCESS,
  payload: { period },
})

export const getSinglePeriodError = error => ({
  type: GET_SINGLE_PERIOD_ERROR,
  payload: { error },
})

export const getSinglePeriodClean = () => ({
  type: GET_SINGLE_PERIOD_CLEAN,
  payload: {},
})

export const createPeriod = form => ({
  type: CREATE_PERIOD,
  payload: { ...form },
})

export const createPeriodSuccess = period => ({
  type: CREATE_PERIOD_SUCCESS,
  payload: { period },
})

export const createPeriodError = error => ({
  type: CREATE_PERIOD_ERROR,
  payload: { error },
})

export const createPeriodClean = () => ({
  type: CREATE_PERIOD_CLEAN,
  payload: {},
})

export const editPeriod = form => ({
  type: EDIT_PERIOD,
  payload: { ...form },
})

export const editPeriodSuccess = () => ({
  type: EDIT_PERIOD_SUCCESS,
  payload: {},
})

export const editPeriodError = error => ({
  type: EDIT_PERIOD_ERROR,
  payload: { error },
})

export const editPeriodClean = () => ({
  type: EDIT_PERIOD_CLEAN,
  payload: {},
})

export const addRuleToPeriod = form => ({
  type: ADD_RULE_TO_PERIOD,
  payload: { ...form },
})

export const addRuleToPeriodSuccess = () => ({
  type: ADD_RULE_TO_PERIOD_SUCCESS,
  payload: {},
})

export const addRuleToPeriodError = error => ({
  type: ADD_RULE_TO_PERIOD_ERROR,
  payload: { error },
})

export const addRuleToPeriodClean = () => ({
  type: ADD_RULE_TO_PERIOD_CLEAN,
  payload: {},
})

export const removeRuleToPeriod = form => ({
  type: REMOVE_RULE_TO_PERIOD,
  payload: { ...form },
})

export const removeRuleToPeriodSuccess = () => ({
  type: REMOVE_RULE_TO_PERIOD_SUCCESS,
  payload: {},
})

export const removeRuleToPeriodError = error => ({
  type: REMOVE_RULE_TO_PERIOD_ERROR,
  payload: { error },
})

export const removeRuleToPeriodClean = () => ({
  type: REMOVE_RULE_TO_PERIOD_CLEAN,
  payload: {},
})

export const addGroupToPeriod = form => ({
  type: ADD_GROUP_TO_PERIOD,
  payload: { ...form },
})

export const addGroupToPeriodSuccess = () => ({
  type: ADD_GROUP_TO_PERIOD_SUCCESS,
  payload: {},
})

export const addGroupToPeriodError = error => ({
  type: ADD_GROUP_TO_PERIOD_ERROR,
  payload: { error },
})

export const addGroupToPeriodClean = () => ({
  type: ADD_GROUP_TO_PERIOD_CLEAN,
  payload: {},
})

export const removeGroupToPeriod = form => ({
  type: REMOVE_GROUP_TO_PERIOD,
  payload: { ...form },
})

export const removeGroupToPeriodSuccess = () => ({
  type: REMOVE_GROUP_TO_PERIOD_SUCCESS,
  payload: {},
})

export const removeGroupToPeriodError = error => ({
  type: REMOVE_GROUP_TO_PERIOD_ERROR,
  payload: { error },
})

export const removeGroupToPeriodClean = () => ({
  type: REMOVE_GROUP_TO_PERIOD_CLEAN,
  payload: {},
})

export const getPeriodParticipants = periodId => {
  return {
    type: GET_PERIOD_PARTICIPANTS,
    payload: { periodId },
  }
}

export const getPeriodParticipantsSuccess = participants => ({
  type: GET_PERIOD_PARTICIPANTS_SUCCESS,
  payload: { participants },
})

export const getPeriodParticipantsError = error => ({
  type: GET_PERIOD_PARTICIPANTS_ERROR,
  payload: { error },
})

export const getPeriodParticipantsClean = () => ({
  type: GET_PERIOD_PARTICIPANTS_CLEAN,
  payload: {},
})

export const getPeriodClients = periodId => {
  return {
    type: GET_PERIOD_CLIENTS,
    payload: { periodId },
  }
}

export const getPeriodClientsSuccess = clients => ({
  type: GET_PERIOD_CLIENTS_SUCCESS,
  payload: { clients },
})

export const getPeriodClientsError = error => ({
  type: GET_PERIOD_CLIENTS_ERROR,
  payload: { error },
})

export const getPeriodClientsClean = () => ({
  type: GET_PERIOD_CLIENTS_CLEAN,
  payload: {},
})

export const getPeriodsParticipant = userId => {
  return {
    type: GET_PERIODS_PARTICIPANT,
    payload: { userId },
  }
}

export const getPeriodsParticipantSuccess = periodsParticipant => ({
  type: GET_PERIODS_PARTICIPANT_SUCCESS,
  payload: { periodsParticipant },
})

export const getPeriodsParticipantError = error => ({
  type: GET_PERIODS_PARTICIPANT_ERROR,
  payload: { error },
})

export const getPeriodsParticipantClean = () => ({
  type: GET_PERIODS_PARTICIPANT_CLEAN,
  payload: {},
})
