import {
  REMOVE_GROUP_PARTICIPANT,
  REMOVE_GROUP_PARTICIPANT_SUCCESS,
  REMOVE_GROUP_PARTICIPANT_CLEAN,
  REMOVE_GROUP_PARTICIPANT_ERROR,
} from "./actionTypes"

export const removeGroupParticipant = form => ({
  type: REMOVE_GROUP_PARTICIPANT,
  payload: { form },
})

export const removeGroupParticipantSuccess = group => {
  return {
    type: REMOVE_GROUP_PARTICIPANT_SUCCESS,
    payload: { group },
  }
}

export const removeGroupParticipantClean = () => {
  return {
    type: REMOVE_GROUP_PARTICIPANT_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_GROUP_PARTICIPANT_ERROR,
    payload: error,
  }
}
