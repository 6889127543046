import {
  GET_BUSINESSES,
  GET_BUSINESSES_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getBusinesses = () => ({
  type: GET_BUSINESSES,
  payload: {},
})

export const getBusinessesSuccess = businesses => {
  return {
    type: GET_BUSINESSES_SUCCESS,
    payload: { businesses },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
