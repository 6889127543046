import {
  EDIT_COURSE_KNOWLEDGE,
  EDIT_COURSE_KNOWLEDGE_SUCCESS,
  EDIT_COURSE_KNOWLEDGE_CLEAN,
  EDIT_COURSE_KNOWLEDGE_ERROR,
} from "./actionTypes"

export const editCourseKnowledge = form => ({
  type: EDIT_COURSE_KNOWLEDGE,
  payload: { form },
})

export const editCourseKnowledgeSuccess = courseKnowledge => {
  return {
    type: EDIT_COURSE_KNOWLEDGE_SUCCESS,
    payload: { courseKnowledge },
  }
}

export const editCourseKnowledgeClean = () => {
  return {
    type: EDIT_COURSE_KNOWLEDGE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_COURSE_KNOWLEDGE_ERROR,
    payload: error,
  }
}
