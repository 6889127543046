import {
  EDIT_SHOPPING_AREA,
  EDIT_SHOPPING_AREA_CLEAN,
  EDIT_SHOPPING_AREA_SUCCESS,
  EDIT_SHOPPING_AREA_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  shoppingArea: undefined,
}

const ShoppingAreaEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_SHOPPING_AREA:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_SHOPPING_AREA_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        shoppingArea: action.payload.shoppingArea,
      }
      break
    case EDIT_SHOPPING_AREA_CLEAN:
      state = initialState
      break
    case EDIT_SHOPPING_AREA_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ShoppingAreaEdit
