/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_GROUPS } from "./actionTypes"
import { apiError, getGroupsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getGroupsNewApi } from "../../../helpers/backend_helper"

function* groups() {
  try {
    const response = yield call(getGroupsNewApi)
    yield put(getGroupsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* groupsListSaga() {
  yield takeEvery(GET_GROUPS, groups)
}

export default groupsListSaga
