import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"

import { Row, Col, CardBody, Card, Alert, Container, Label } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import {
  AvForm,
  AvField,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation"

import { userRestorePassword } from "../../store/actions"
import SessionFooter from "components/Common/SessionFooter"
import SessionLogo from "components/Common/SessionLogo"
import resistolLogo from "../../assets/images/logo_resistol.png"
import prittLogo from "../../assets/images/logo_pritt.png"
import sistaLogo from "../../assets/images/logo_sista.png"
import tangitLogo from "../../assets/images/logo_tangit.png"

const RestorePassword = props => {
  const { token } = useParams()

  const dispatch = useDispatch()

  const { success, error } = useSelector(state => ({
    success: state.ForgetPassword.restoreSuccessMsg,
    error: state.ForgetPassword.restoreError,
  }))

  const [formError, setFormError] = useState(false)

  useEffect(() => {
    document.body.className = "main-celf-background"
    localStorage.removeItem("authUser")
    return () => {
      document.body.className = ""
    }
  }, [])

  // handleValidSubmit
  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    dispatch(userRestorePassword(token, values.password))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Reestablecer contraseña | Professional&apos;s Academy E-Learning
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark" draggable="false">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <div className="academy-logo-login"></div>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 1 }}></Col>
                <Col>
                  <img
                    src={resistolLogo}
                    alt="Resistol"
                    className="img-fluid"
                  />
                </Col>
                <Col>
                  <img src={sistaLogo} alt="Sista" className="img-fluid" />
                </Col>
                <Col>
                  <img src={tangitLogo} alt="Tangit" className="img-fluid" />
                </Col>
                <Col>
                  <img src={prittLogo} alt="Pritt" className="img-fluid" />
                </Col>
                <Col xs={{ size: 1 }}></Col>
              </Row>
              <Card>
                <CardBody className="alt-celf-background text-white mt-2 rounded-3x">
                  <div className="p-2">
                    {formError && (
                      <div className="alert alert-danger">
                        Por favor ingresa la nueva contraseña que será asignada
                        a tu cuenta.
                      </div>
                    )}
                    {success && (
                      <div className="alert alert-success">
                        Contraseña asignada exitosamente. Ya puedes iniciar
                        sesión.
                      </div>
                    )}
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {error ? (
                        <Alert color="danger">{error.toString()}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvGroup>
                          <AvField
                            name="password"
                            value=""
                            label="Nueva contraseña"
                            className="form-control"
                            placeholder="Nueva contraseña"
                            type="password"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Este campo es requerido",
                              },
                              minLength: {
                                value: 12,
                                errorMessage:
                                  "La contraseña debe tener al menos 12 caracteres",
                              },
                              pattern: {
                                value:
                                  "()^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}).*$",
                                errorMessage:
                                  "La contraseña deba tener al menos una minúscula, una mayúscula y un número",
                              },
                            }}
                          />
                        </AvGroup>
                      </div>

                      <div className="mb-3">
                        <AvGroup>
                          <AvField
                            name="confirmPassword"
                            value=""
                            label="Confirmar contraseña"
                            className="form-control"
                            placeholder="Confirmar contraseña"
                            type="password"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Este campo es requerido",
                              },
                              match: {
                                value: "password",
                                errorMessage:
                                  "Este campo debe ser igual a Nueva contraseña",
                              },
                            }}
                          />
                        </AvGroup>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-light btn-block"
                          type="submit"
                        >
                          Asignar contraseña
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
                <div className="mt-4 mb-2 text-center">
                  <Link to="/login" className="text-muted" draggable="false">
                    <i className="mdi mdi-arrow-left me-1" />
                    Ir al login
                  </Link>
                </div>
              </Card>
            </Col>
            <SessionFooter />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RestorePassword
