import {
  REMOVE_GROUP_COURSE,
  REMOVE_GROUP_COURSE_SUCCESS,
  REMOVE_GROUP_COURSE_CLEAN,
  REMOVE_GROUP_COURSE_ERROR,
} from "./actionTypes"

export const removeGroupCourse = form => ({
  type: REMOVE_GROUP_COURSE,
  payload: { form },
})

export const removeGroupCourseSuccess = group => {
  return {
    type: REMOVE_GROUP_COURSE_SUCCESS,
    payload: { group },
  }
}

export const removeGroupCourseClean = () => {
  return {
    type: REMOVE_GROUP_COURSE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_GROUP_COURSE_ERROR,
    payload: error,
  }
}
