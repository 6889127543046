import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Report6ListTable from "components/Reports/Report6ListTable"
import {
  getCourses,
  listReport6,
  listReport6Clean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import Report6Chart from "components/Reports/Report6Chart"

const Report6 = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.Report6List.error,
    loading: state.Report6List.loading,
    report: state.Report6List.report,
    done: state.Report6List.done,
    courses: state.CoursesList.coursesForAdmin,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReport6({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    const data = [
      { "Usuarios internos": report.internal },
      { "Usuarios externos": report.externoal },
    ]

    return {
      Reporte6: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReport6Clean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 6 - Split/Usuario | Professional&apos;s Academy E-Learning
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 6 - Split/Usuario</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report6", title: "Split/Usuario" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 6"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte6"}
                        name="Reporte 6"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                  />
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <Report6ListTable
                            loading={loading}
                            report={reportInfo}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <Report6Chart data={reportInfo} />
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Report6
