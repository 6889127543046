import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import moment from "moment"

const Report10Chart = ({ data }) => {
  const categories =
    data !== undefined
      ? data.map(element => {
          const date = moment(element.date).format("DD/MM/YYYY")
          return `${date}`
        })
      : []

  let accesos = []
  let cursos = []
  let aprobados = []

  if (data !== undefined)
    data.forEach(element => {
      accesos.push(element.accesos)
      cursos.push(element.acceso_cursos)
      aprobados.push(element.aprobaron)
    })

  const series = [
    { name: "Ingresos", data: accesos },
    { name: "Participación en cursos", data: cursos },
    { name: "Concluyeron curso", data: aprobados },
  ]

  const options = {
    chart: {
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#34c38f", "#f1b44c", "#f46a6a"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="line"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report10Chart.propTypes = {
  data: PropTypes.array,
}
export default Report10Chart
