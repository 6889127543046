import {
  EDIT_KNOWLEDGE_CATEGORY,
  EDIT_KNOWLEDGE_CATEGORY_SUCCESS,
  EDIT_KNOWLEDGE_CATEGORY_CLEAN,
  EDIT_KNOWLEDGE_CATEGORY_ERROR,
} from "./actionTypes"

export const editKnowledgeCategory = form => ({
  type: EDIT_KNOWLEDGE_CATEGORY,
  payload: { form },
})

export const editKnowledgeCategorySuccess = knowledgeCategory => {
  return {
    type: EDIT_KNOWLEDGE_CATEGORY_SUCCESS,
    payload: { knowledgeCategory },
  }
}

export const editKnowledgeCategoryClean = () => {
  return {
    type: EDIT_KNOWLEDGE_CATEGORY_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_KNOWLEDGE_CATEGORY_ERROR,
    payload: error,
  }
}
