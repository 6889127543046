import {
  ADD_LEVEL,
  ADD_LEVEL_SUCCESS,
  ADD_LEVEL_CLEAN,
  ADD_LEVEL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  level: {},
}

const LevelAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LEVEL:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_LEVEL_SUCCESS:
      state = {
        ...state,
        level: action.payload.level,
        loading: false,
        done: true,
      }
      break
    case ADD_LEVEL_CLEAN:
      state = initialState
      break
    case ADD_LEVEL_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LevelAdd
