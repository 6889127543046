/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EXTEND_SESSION } from "./actionTypes"
import { extendSessionSuccess, extendSessionError } from "./actions"

//Include Both Helper File with needed methods
import { refreshToken } from "../../../helpers/backend_helper"

function* extend() {
  try {
    const response = yield call(refreshToken, {})
    if (response == undefined) throw new Error("API error")
    if (response.success) {
      localStorage.setItem("authUser", response.token)
      yield put(extendSessionSuccess(response))
    }
  } catch (error) {
    yield put(extendSessionError(error.message))
  }
}

function* sessionExtendSaga() {
  yield takeEvery(EXTEND_SESSION, extend)
}

export default sessionExtendSaga
