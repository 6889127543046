import {
  FINISH_LESSON,
  FINISH_LESSON_SUCCESS,
  FINISH_LESSON_CLEAN,
  FINISH_LESSON_ERROR,
} from "./actionTypes"

export const finishLesson = form => ({
  type: FINISH_LESSON,
  payload: { form },
})

export const finishLessonSuccess = lesson => {
  return {
    type: FINISH_LESSON_SUCCESS,
    payload: { lesson },
  }
}

export const finishLessonClean = () => {
  return {
    type: FINISH_LESSON_CLEAN,
    payload: {},
  }
}

export const finishLessonError = error => {
  return {
    type: FINISH_LESSON_ERROR,
    payload: error,
  }
}
