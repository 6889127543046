import {
  ADD_QUIZZ,
  ADD_QUIZZ_SUCCESS,
  ADD_QUIZZ_CLEAN,
  ADD_QUIZZ_ERROR,
} from "./actionTypes"

export const addQuizz = form => ({
  type: ADD_QUIZZ,
  payload: { form },
})

export const addQuizzSuccess = quizz => {
  return {
    type: ADD_QUIZZ_SUCCESS,
    payload: { quizz },
  }
}

export const addQuizzClean = () => {
  return {
    type: ADD_QUIZZ_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_QUIZZ_ERROR,
    payload: error,
  }
}
