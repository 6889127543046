import {
  EDIT_LEVEL,
  EDIT_LEVEL_CLEAN,
  EDIT_LEVEL_SUCCESS,
  EDIT_LEVEL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  level: undefined,
}

const LevelEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_LEVEL:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_LEVEL_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        level: action.payload.level,
      }
      break
    case EDIT_LEVEL_CLEAN:
      state = initialState
      break
    case EDIT_LEVEL_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LevelEdit
