import { AvField, AvForm } from "availity-reactstrap-validation"
import { Col, Label, Modal, Row } from "reactstrap"
import React, { useState, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import Select from "react-select"
import { addRuleToPeriod, addRuleToPeriodClean } from "store/actions"

const RuleToPeriodModal = props => {
  const dispatch = useDispatch()

  console.log(props.rule)

  const [rule, setRule] = useState(undefined)
  const [formError, setFormError] = useState("")
  const { rules, done, error } = useSelector(state => ({
    rules: state.GamificationRules.rules,
    done: state.GamificationPeriods.addRuleDone,
    error: state.GamificationPeriods.addRuleError,
  }))

  useEffect(() => {
    if (props.rule !== undefined) {
      const selectedRule = rules.find(rule => rule.ruleId === props.rule.ruleId)
      if (selectedRule !== undefined) {
        setRule({
          label: selectedRule.ruleName,
          value: selectedRule.ruleId,
        })
      }
    }
  }, [props.rule])

  const rulesOptionGroup =
    rules !== undefined
      ? rules.map(rule => {
          return {
            label: `${rule.ruleName}`,
            value: rule.ruleId,
          }
        })
      : []

  const handleRulesSelected = event => {
    setRule(event)
    // const selectedRule = rules.find(rule => rule.ruleId === event.value)
    // if (selectedRule !== undefined) {
    //   setValue(selectedRule.value)
    // }
  }

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(
        "Existen errores en el formulario. Por favor corrígelos para continuar"
      )
      return
    }
    if (rule === undefined) {
      setFormError("Seleccione una regla para continuar")
      return
    }

    setFormError("")

    dispatch(
      addRuleToPeriod({
        periodId: props.periodId,
        points: parseInt(values.value),
        ruleId: rule.value,
      })
    )
  }

  if (done) {
    dispatch(addRuleToPeriodClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Agregar regla al periodo
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError !== "" && (
            <div className="alert alert-danger">{formError}</div>
          )}
          {error !== "" && <div className="alert alert-danger">{error}</div>}
          <Row className="mb-3">
            <Col md={3}>
              <Label for="rule" className="pt-1">
                * Regla:
              </Label>
            </Col>
            <Col md={9}>
              <Select
                name="rule"
                value={rule}
                isMulti={false}
                onChange={event => {
                  handleRulesSelected(event)
                }}
                options={rulesOptionGroup}
                classNamePrefix="select2-selection"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <Label for="value" className="pt-1">
                * Valor:
              </Label>
            </Col>
            <Col md={9}>
              <AvField
                name="value"
                className="form-control"
                placeholder="Ingrese el valor de la regla"
                value={props.rule === undefined ? 0 : props.rule.points}
                type="number"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                  number: {
                    value: true,
                    errorMessage: "Este campo debe contener únicamente números",
                  },
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button type="submit" className={`btn btn-secondary`}>
            {"Agregar"}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

RuleToPeriodModal.propTypes = {
  periodId: PropTypes.number,
  rule: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
}

export default RuleToPeriodModal
