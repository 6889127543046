import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import * as Sentry from "@sentry/browser"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { withRouter, Link, Redirect } from "react-router-dom"
import { logoutUser, me, meReset } from "../../../store/actions"

// users
import user1 from "../../../assets/images/user_without_pic.png"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  const { whoIAm, profileError } = useSelector(state => ({
    whoIAm: state.Profile.user,
    profileError: state.Profile.profileError,
  }))

  useEffect(() => {
    if (whoIAm !== undefined)
      Sentry.setUser({
        id: whoIAm.userId,
        email: whoIAm.email,
        username: user.firstName,
      })
  }, [whoIAm])

  const user =
    whoIAm === undefined
      ? {
          userId: 0,
          firstName: "",
          lastName: "",
          profileId: 0,
          avatar: "",
        }
      : whoIAm

  if (profileError !== "") {
    dispatch(meReset())
    dispatch(logoutUser(history))
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block  d-none d-sm-block"
      >
        <DropdownToggle
          className="btn header-item  mt-2"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div
            style={{
              backgroundImage:
                user.avatar == null ? `url(${user1})` : `url(${user.avatar})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "36px",
              height: "36px",
              padding: "8px",
              borderRadius: "50%",
              display: "inline-block",
            }}
          >
            &nbsp;
          </div>
          <span className="d-none d-xl-inline-block ms-2">
            {user.firstName} {user.lastName} - N{user.profileId}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/*<DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Mi cuenta
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-info float-end pt-1">0</span>
            <i className="fa fa-trophy font-size-16 align-middle me-1" />
            Insignias
  </DropdownItem>
          <div className="dropdown-divider" />*/}
          <Link to="/profile" className="dropdown-item" draggable="false">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Mi cuenta
          </Link>
          <Link to="/logout" className="dropdown-item" draggable="false">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Cerrar sesión</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.object,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu))
