import {
  ADD_POSITION,
  ADD_POSITION_SUCCESS,
  ADD_POSITION_CLEAN,
  ADD_POSITION_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  position: {},
}

const PositionAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POSITION:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_POSITION_SUCCESS:
      state = {
        ...state,
        position: action.payload.position,
        loading: false,
        done: true,
      }
      break
    case ADD_POSITION_CLEAN:
      state = initialState
      break
    case ADD_POSITION_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PositionAdd
