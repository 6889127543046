import {
  PROGRESS_LESSON,
  PROGRESS_LESSON_SUCCESS,
  PROGRESS_LESSON_CLEAN,
  PROGRESS_LESSON_ERROR,
} from "./actionTypes"

export const progressLesson = idCourse => ({
  type: PROGRESS_LESSON,
  payload: { idCourse },
})

export const progressLessonSuccess = lessons => {
  return {
    type: PROGRESS_LESSON_SUCCESS,
    payload: { lessons },
  }
}

export const progressLessonClean = () => {
  return {
    type: PROGRESS_LESSON_CLEAN,
    payload: {},
  }
}

export const progressLessonError = error => {
  return {
    type: PROGRESS_LESSON_ERROR,
    payload: error,
  }
}
