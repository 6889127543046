import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"
import * as Sentry from "@sentry/browser"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, loginClean, me } from "../../store/actions"
import SessionFooter from "components/Common/SessionFooter"

import resistolLogo from "../../assets/images/logo_resistol.png"
import prittLogo from "../../assets/images/logo_pritt.png"
import sistaLogo from "../../assets/images/logo_sista.png"
import tangitLogo from "../../assets/images/logo_tangit.png"

const Login = props => {
  const dispatch = useDispatch()

  const [formError, setFormError] = useState(false)
  const [performLogin, setPerformLogin] = useState(false)

  const { error, loading, success, whoIAm } = useSelector(state => ({
    whoIAm: state.Profile.user,
    error: state.Login.error,
    loading: state.Login.loading,
    success: state.Login.success,
  }))

  useEffect(() => {
    document.body.className = "main-celf-background"
    //localStorage.removeItem("academyUser")
    if (error) dispatch(loginClean())
    return () => {
      document.body.className = ""
    }
  }, [])

  // handleValidSubmit
  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    dispatch(loginUser(values, props.history))
  }

  useEffect(() => {
    const token = localStorage.getItem("academyUser")
    if (whoIAm !== undefined && token !== null) {
      console.log(whoIAm)
      Sentry.setUser({
        id: whoIAm.userId,
        email: whoIAm.email,
        username: `${whoIAm.firstName} ${whoIAm.lastName}`,
      })
      props.history.push("/dashboard")
    }
  }, [whoIAm])

  if (success && !performLogin) {
    setPerformLogin(true)
    dispatch(me())
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Iniciar sesión | Professional&apos;s Academy E-Learning</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark" draggable="false">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md={8} lg={6} xl={5}>
              <Row className="mb-2">
                <Col xs={{ size: 10, offset: 1 }}>
                  <div className="academy-logo-login"></div>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 1 }}></Col>
                <Col>
                  <img
                    src={resistolLogo}
                    alt="Resistol"
                    className="img-fluid"
                  />
                </Col>
                <Col>
                  <img src={sistaLogo} alt="Sista" className="img-fluid" />
                </Col>
                <Col>
                  <img src={tangitLogo} alt="Tangit" className="img-fluid" />
                </Col>
                <Col>
                  <img src={prittLogo} alt="Pritt" className="img-fluid" />
                </Col>
                <Col xs={{ size: 1 }}></Col>
              </Row>
              <Card>
                <CardBody className="alt-celf-background text-white mt-2 rounded-3x">
                  <h1 className="text-center text-white pb-3">Bienvenidos</h1>
                  <div className="p-2">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">{error.toString()}</Alert>
                      )}
                      {formError && (
                        <Alert color="danger">
                          Por favor verifica los errores que se indican en el
                          formulario.
                        </Alert>
                      )}

                      <div className="mb-3 red-box">
                        <AvField
                          name="email"
                          label="Correo electrónico"
                          value=""
                          className="form-control"
                          placeholder="Correo electrónico"
                          type="email"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Este campo es requerido",
                            },
                            email: {
                              value: true,
                              errorMessage:
                                "Este campo debe ser un correo electrónico",
                            },
                          }}
                        />
                      </div>

                      <div className="mb-3 red-box">
                        <AvField
                          name="password"
                          label="Contraseña"
                          value=""
                          type="password"
                          className="form-control"
                          placeholder="Contraseña"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Este campo es requerido",
                            },
                          }}
                        />
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className={`btn btn-light btn-block ${
                            loading ? "disabled" : ""
                          }`}
                          type="submit"
                        >
                          {!loading && <span>Iniciar sesión</span>}
                          {loading && <span>Validando...</span>}
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
                <div className="mt-4 mb-2 text-center">
                  <Link
                    to="/forgot-password"
                    className="text-muted"
                    draggable="false"
                  >
                    <i className="mdi mdi-lock me-1" />
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
              </Card>
            </Col>
            <SessionFooter />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
