import {
  GET_USER,
  GET_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  API_ERROR,
  EDIT_USER_CLEAN,
  ACTIVATE_DEACTIVATE_USER,
  ACTIVATE_DEACTIVATE_USER_SUCCESS,
  ACTIVATE_DEACTIVATE_USER_ERROR,
  ACTIVATE_DEACTIVATE_USER_CLEAN,
  UPDATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  UPDATE_PROFILE_PICTURE_ERROR,
  UPDATE_PROFILE_PICTURE_CLEAN,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_CLEAN,
  UPDATE_POSITION,
  UPDATE_POSITION_SUCCESS,
  UPDATE_POSITION_ERROR,
  UPDATE_POSITION_CLEAN,
  UPDATE_ABOUT_ME,
  UPDATE_ABOUT_ME_SUCCESS,
  UPDATE_ABOUT_ME_ERROR,
  UPDATE_ABOUT_ME_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  updateLoading: false,
  updateDone: false,
  activateDeactivateError: "",
  activateDeactivateLoading: false,
  activateDeactivateSuccess: false,
  updateProfilePictureLoading: false,
  updateProfilePictureSuccess: false,
  updateProfilePictureError: "",
  updatePasswordLoading: false,
  updatePasswordSuccess: false,
  updatePasswordError: "",
  updatePositionLoading: false,
  updatePositionSuccess: false,
  updatePositionError: "",
  updateAboutMeLoading: false,
  updateAboutMeSuccess: false,
  updateAboutMeError: "",
  user: undefined,
}

const UserEdit = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        user: action.payload.user,
      }
      break
    case EDIT_USER:
      state = {
        ...state,
        updateLoading: true,
      }
      break
    case EDIT_USER_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        updateLoading: false,
        updateDone: true,
      }
      break
    case EDIT_USER_CLEAN:
      state = {
        ...state,
        user: undefined,
        updateLoading: false,
        updateDone: false,
        loading: false,
        done: false,
      }
      break
    case ACTIVATE_DEACTIVATE_USER:
      state = {
        ...state,
        activateDeactivateLoading: true,
        activateDeactivateError: "",
      }
      break
    case ACTIVATE_DEACTIVATE_USER_SUCCESS:
      state = {
        ...state,
        activateDeactivateLoading: false,
        activateDeactivateSuccess: true,
        activateDeactivateError: "",
      }
      break
    case ACTIVATE_DEACTIVATE_USER_ERROR:
      state = {
        ...state,
        activateDeactivateLoading: false,
        activateDeactivateSuccess: false,
        activateDeactivateError: action.payload,
      }
      break
    case ACTIVATE_DEACTIVATE_USER_CLEAN:
      state = {
        ...state,
        activateDeactivateLoading: false,
        activateDeactivateSuccess: false,
        activateDeactivateError: "",
      }
      break
    case UPDATE_PROFILE_PICTURE:
      state = {
        ...state,
        updateProfilePictureSuccess: false,
        updateProfilePictureLoading: true,
        updateProfilePictureError: "",
      }
      break
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      state = {
        ...state,
        updateProfilePictureLoading: false,
        updateProfilePictureSuccess: true,
        updateProfilePictureError: "",
      }
      break
    case UPDATE_PROFILE_PICTURE_ERROR:
      state = {
        ...state,
        updateProfilePictureLoading: false,
        updateProfilePictureSuccess: false,
        updateProfilePictureError: action.payload,
      }
      break
    case UPDATE_PROFILE_PICTURE_CLEAN:
      state = {
        ...state,
        updateProfilePictureLoading: false,
        updateProfilePictureSuccess: false,
        updateProfilePictureError: "",
      }
      break
    case UPDATE_PASSWORD:
      state = {
        ...state,
        updatePasswordSuccess: false,
        updatePasswordLoading: true,
        updatePasswordError: "",
      }
      break
    case UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        updatePasswordLoading: false,
        updatePasswordSuccess: true,
        updatePasswordError: "",
      }
      break
    case UPDATE_PASSWORD_ERROR:
      state = {
        ...state,
        updatePasswordLoading: false,
        updatePasswordSuccess: false,
        updatePasswordError: action.payload,
      }
      break
    case UPDATE_PASSWORD_CLEAN:
      state = {
        ...state,
        updatePasswordLoading: false,
        updatePasswordSuccess: false,
        updatePasswordError: "",
      }
      break
    case UPDATE_POSITION:
      state = {
        ...state,
        updatePositionSuccess: false,
        updatePositionLoading: true,
        updatePositionError: "",
      }
      break
    case UPDATE_POSITION_SUCCESS:
      state = {
        ...state,
        updatePositionLoading: false,
        updatePositionSuccess: true,
        updatePositionError: "",
      }
      break
    case UPDATE_POSITION_ERROR:
      state = {
        ...state,
        updatePositionLoading: false,
        updatePositionSuccess: false,
        updatePositionError: action.payload,
      }
      break
    case UPDATE_POSITION_CLEAN:
      state = {
        ...state,
        updatePositionLoading: false,
        updatePositionSuccess: false,
        updatePositionError: "",
      }
      break
    case UPDATE_ABOUT_ME:
      state = {
        ...state,
        updateAboutMeSuccess: false,
        updateAboutMeLoading: true,
        updateAboutMeError: "",
      }
      break
    case UPDATE_ABOUT_ME_SUCCESS:
      state = {
        ...state,
        updateAboutMeLoading: false,
        updateAboutMeSuccess: true,
        updateAboutMeError: "",
      }
      break
    case UPDATE_ABOUT_ME_ERROR:
      state = {
        ...state,
        updateAboutMeLoading: false,
        updateAboutMeSuccess: false,
        updateAboutMeError: action.payload,
      }
      break
    case UPDATE_ABOUT_ME_CLEAN:
      state = {
        ...state,
        updateAboutMeLoading: false,
        updateAboutMeSuccess: false,
        updateAboutMeError: "",
      }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        updateLoading: false,
        updateDone: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default UserEdit
