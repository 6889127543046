import {
  LIST_POLLS,
  LIST_POLLS_SUCCESS,
  LIST_POLLS_CLEAN,
  LIST_POLLS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  polls: [],
}

const PollsList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_POLLS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_POLLS_SUCCESS:
      state = {
        ...state,
        polls: action.payload.polls,
        loading: false,
        done: true,
      }
      break
    case LIST_POLLS_CLEAN:
      state = initialState
      break
    case LIST_POLLS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PollsList
