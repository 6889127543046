/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_REPORTA } from "./actionTypes"
import { listReportAError, listReportASuccess } from "./actions"

//Include Both Helper File with needed methods
import { reportANewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(reportANewApi, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (response.length === undefined) throw new Error(response)
    yield put(listReportASuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(listReportAError(error.message))
  }
}

function* reportAListSaga() {
  yield takeEvery(LIST_REPORTA, list)
}

export default reportAListSaga
