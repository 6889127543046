import {
  LIST_CAROUSELS,
  LIST_CAROUSELS_SUCCESS,
  LIST_CAROUSELS_ERROR,
  LIST_CAROUSELS_CLEAN,
  GET_SINGLE_CAROUSEL,
  GET_SINGLE_CAROUSEL_SUCCESS,
  GET_SINGLE_CAROUSEL_ERROR,
  GET_SINGLE_CAROUSEL_CLEAN,
  CREATE_CAROUSEL,
  CREATE_CAROUSEL_SUCCESS,
  CREATE_CAROUSEL_ERROR,
  CREATE_CAROUSEL_CLEAN,
  EDIT_CAROUSEL,
  EDIT_CAROUSEL_SUCCESS,
  EDIT_CAROUSEL_ERROR,
  EDIT_CAROUSEL_CLEAN,
  DELETE_CAROUSEL,
  DELETE_CAROUSEL_SUCCESS,
  DELETE_CAROUSEL_ERROR,
  DELETE_CAROUSEL_CLEAN,
  ORDER_CAROUSEL,
  ORDER_CAROUSEL_SUCCESS,
  ORDER_CAROUSEL_ERROR,
  ORDER_CAROUSEL_CLEAN,
} from "./actionTypes"

const initialState = {
  loading: false,
  orderLoading: false,
  listError: "",
  listDone: false,
  carousels: [],
  getError: "",
  getDone: false,
  carousel: undefined,
  createDone: false,
  createError: "",
  editDone: false,
  editError: "",
  deleteDone: false,
  deleteError: "",
  orderDone: false,
  orderError: "",
}

const CarouselsAll = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CAROUSELS:
      state = {
        ...state,
        listError: "",
        loading: true,
      }
      break
    case LIST_CAROUSELS_SUCCESS:
      state = {
        ...state,
        carousels: action.payload.carousels,
        loading: false,
        listDone: true,
      }
      break
    case LIST_CAROUSELS_ERROR:
      state = {
        ...state,
        listError: action.payload.error,
        loading: false,
        listDone: false,
        carousels: [],
      }
      break
    case LIST_CAROUSELS_CLEAN:
      state = {
        ...state,
        listError: "",
        loading: false,
        listDone: false,
        carousels: [],
      }
      break
    case GET_SINGLE_CAROUSEL:
      state = {
        ...state,
        getError: "",
        loading: true,
      }
      break
    case GET_SINGLE_CAROUSEL_SUCCESS:
      state = {
        ...state,
        carousel: action.payload.carousel,
        loading: false,
        getDone: true,
      }
      break
    case GET_SINGLE_CAROUSEL_ERROR:
      state = {
        ...state,
        getError: action.payload.error,
        loading: false,
        getDone: false,
        carousel: undefined,
      }
      break
    case GET_SINGLE_CAROUSEL_CLEAN:
      state = {
        ...state,
        getError: "",
        loading: false,
        getDone: false,
        carousel: undefined,
      }
      break
    case CREATE_CAROUSEL:
      state = {
        ...state,
        createError: "",
        loading: true,
      }
      break
    case CREATE_CAROUSEL_SUCCESS:
      state = {
        ...state,
        loading: false,
        createDone: true,
      }
      break
    case CREATE_CAROUSEL_ERROR:
      state = {
        ...state,
        createError: action.payload.error,
        loading: false,
        createDone: false,
      }
      break
    case CREATE_CAROUSEL_CLEAN:
      state = {
        ...state,
        createError: "",
        loading: false,
        createDone: false,
      }
      break
    case EDIT_CAROUSEL:
      state = {
        ...state,
        editError: "",
        loading: true,
      }
      break
    case EDIT_CAROUSEL_SUCCESS:
      state = {
        ...state,
        loading: false,
        editDone: true,
      }
      break
    case EDIT_CAROUSEL_ERROR:
      state = {
        ...state,
        editError: action.payload.error,
        loading: false,
        editDone: false,
      }
      break
    case EDIT_CAROUSEL_CLEAN:
      state = {
        ...state,
        editError: "",
        loading: false,
        editDone: false,
      }
      break
    case DELETE_CAROUSEL:
      state = {
        ...state,
        deleteError: "",
        loading: true,
      }
      break
    case DELETE_CAROUSEL_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteDone: true,
      }
      break
    case DELETE_CAROUSEL_ERROR:
      state = {
        ...state,
        deleteError: action.payload.error,
        loading: false,
        deleteDone: false,
      }
      break
    case DELETE_CAROUSEL_CLEAN:
      state = {
        ...state,
        deleteError: "",
        loading: false,
        deleteDone: false,
      }
      break
    case ORDER_CAROUSEL:
      state = {
        ...state,
        orderError: "",
        orderLoading: true,
      }
      break
    case ORDER_CAROUSEL_SUCCESS:
      state = {
        ...state,
        orderLoading: false,
        orderDone: true,
      }
      break
    case ORDER_CAROUSEL_ERROR:
      state = {
        ...state,
        orderError: action.payload.error,
        orderLoading: false,
        orderDone: false,
      }
      break
    case ORDER_CAROUSEL_CLEAN:
      state = {
        ...state,
        orderError: "",
        orderLoading: false,
        orderDone: false,
      }
      break
  }
  return state
}

export default CarouselsAll
