import {
  LIST_POLL_ANSWER,
  LIST_POLL_ANSWER_SUCCESS,
  LIST_POLL_ANSWER_CLEAN,
  LIST_POLL_ANSWER_ERROR,
} from "./actionTypes"

export const listPollAnswer = id => ({
  type: LIST_POLL_ANSWER,
  payload: { id },
})

export const listPollAnswerSuccess = pollAnswer => {
  return {
    type: LIST_POLL_ANSWER_SUCCESS,
    payload: { pollAnswer },
  }
}

export const listPollAnswerClean = () => {
  return {
    type: LIST_POLL_ANSWER_CLEAN,
    payload: {},
  }
}

export const listPollAnswerError = error => {
  return {
    type: LIST_POLL_ANSWER_ERROR,
    payload: error,
  }
}
