import React, { useState, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Input, Row, Col, Card, Table } from "reactstrap"
import { Link } from "react-router-dom"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import {
  addImage,
  addImageClean,
  orderCarousel,
  orderCarouselClean,
} from "store/actions"
import { info } from "toastr"
import ReactDragListView from "react-drag-listview/lib/index.js"

const CarouselOrderModal = props => {
  console.log(props)
  const carousels = props.carousels
  const [data, SetData] = useState([])

  const dispatch = useDispatch()

  const onConfirm = props.onConfirm

  const { loading, done } = useSelector(state => ({
    loading: state.CarouselsAll.orderLoading,
    done: state.CarouselsAll.orderDone,
  }))

  useEffect(() => {
    const orderedCarousels = carousels
      .filter(carousel => carousel.status === true)
      .sort((a, b) => (a.order < b.order ? -1 : 1))
    SetData(orderedCarousels)
    return () => {
      SetData([])
    }
  }, [carousels])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data]

      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      SetData(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const handleUpdatePosition = modifiedData => {
    let positions = modifiedData.map((carousel, index) => {
      return { position: index + 1, carouselId: carousel.carouselId }
    })

    const request = {
      positions: positions,
    }

    dispatch(orderCarousel(request))
  }

  if (done) {
    dispatch(orderCarouselClean())
    props.onConfirm()
  }

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      id="staticBackdrop"
      size={"lg"}
    >
      {loading ? (
        <div className="mt-4 mb-4">
          <h3 className="text-center mb-4">Guardando...</h3>
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Ordenar carrusel
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleUpdatePosition(data)
              }}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <ReactDragListView {...dragProps}>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>No.</th>
                    <th>Nombre</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((carousel, index) => (
                    <tr key={carousel.carouselId}>
                      <td>
                        <i className="fa fa-grip-vertical"></i>
                      </td>
                      <td scope="row" className="drag-pointer">
                        {index + 1}
                      </td>
                      <td className="text-center">{carousel.carouselName}</td>
                      <td className="text-center">{carousel.carouselType}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ReactDragListView>
          </div>
        </Fragment>
      )}
    </Modal>
  )
}

CarouselOrderModal.propTypes = {
  carousels: PropTypes.array,
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
}

export default CarouselOrderModal
