import CoursesByStatus from "components/Dashboard/CoursesByStatus"
import { Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { getCoursesByUser } from "../../store/actions"

const MyCourses = props => {
  const { tab } = useParams()

  const dispatch = useDispatch()
  const { user, courses, loading } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.CoursesList.courses,
    loading: store.CoursesList.loading,
  }))

  const idUser = user?.userId ?? 0

  useEffect(() => {
    dispatch(getCoursesByUser(idUser))
  }, [idUser])

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mis cursos | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Mis cursos</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "my-courses", title: "Mis cursos" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CoursesByStatus
                    courses={courses}
                    loading={loading}
                    all={true}
                    initialTab={tab}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default MyCourses
