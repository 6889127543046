/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_QUIZZ_ANSWER } from "./actionTypes"
import { removeQuizzAnswerError, removeQuizzAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  deleteQuizzAnswer,
  deleteQuizzAnswerNewApi,
} from "../../../helpers/backend_helper"

function* remove(request) {
  try {
    const response = yield call(deleteQuizzAnswerNewApi, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(removeQuizzAnswerSuccess(response))
  } catch (error) {
    yield put(removeQuizzAnswerError(error.message))
  }
}

function* quizzAnswerRemoveSaga() {
  yield takeEvery(REMOVE_QUIZZ_ANSWER, remove)
}

export default quizzAnswerRemoveSaga
