/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_REPORT2 } from "./actionTypes"
import { listReport2Error, listReport2Success } from "./actions"

//Include Both Helper File with needed methods
import { report2NewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(report2NewApi, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (response.length === undefined) throw new Error(response)
    yield put(listReport2Success(response))
  } catch (error) {
    console.log(error.message)
    yield put(listReport2Error(error.message))
  }
}

function* report2ListSaga() {
  yield takeEvery(LIST_REPORT2, list)
}

export default report2ListSaga
