import {
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_CLEAN,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  group: {},
}

const GroupAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GROUP:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_GROUP_SUCCESS:
      state = {
        ...state,
        group: action.payload.group,
        loading: false,
        done: true,
      }
      break
    case ADD_GROUP_CLEAN:
      state = initialState
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default GroupAdd
