/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_POSITIONS, GET_POSITIONS_ALL } from "./actionTypes"
import { apiError, getPositionsSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getPositionsNewApi,
  getAllPositionsNewApi,
} from "../../../helpers/backend_helper"

function* positions() {
  try {
    const response = yield call(getPositionsNewApi)
    yield put(getPositionsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* positionsAll() {
  try {
    const response = yield call(getAllPositionsNewApi)
    yield put(getPositionsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* positionsListSaga() {
  yield takeEvery(GET_POSITIONS, positions)
  yield takeEvery(GET_POSITIONS_ALL, positionsAll)
}

export default positionsListSaga
