import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { FirebaseAppProvider } from "reactfire"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import "./i18n"
import { Provider } from "react-redux"

import store from "./store"
import reportWebVitals from "./reportWebVitals"
import firebaseConfig from "./firebaseConfig"

Sentry.init({
  dsn: "https://c991434f1a534dc0a35dad90f2be92aa@o1032553.ingest.sentry.io/4504528130801664",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
})

const app = (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </FirebaseAppProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
