import {
  ADD_POSITION,
  ADD_POSITION_SUCCESS,
  ADD_POSITION_CLEAN,
  ADD_POSITION_ERROR,
} from "./actionTypes"

export const addPosition = form => ({
  type: ADD_POSITION,
  payload: { form },
})

export const addPositionSuccess = position => {
  return {
    type: ADD_POSITION_SUCCESS,
    payload: { position },
  }
}

export const addPositionClean = () => {
  return {
    type: ADD_POSITION_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_POSITION_ERROR,
    payload: error,
  }
}
