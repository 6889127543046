import { useExcelDownloder } from "react-xls"
import { PropTypes } from "prop-types"
import { Fragment, useEffect, useState } from "react"

const XlsExporter = props => {
  const { ExcelDownloder, Type, setData } = useExcelDownloder()

  const buttonClassName = props.classNames ?? "btn btn-link"

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  return (
    <Fragment>
      {props.data[props.dataName].length > 0 ? (
        <ExcelDownloder
          className={buttonClassName}
          data={props.data}
          filename={props.name}
          type={Type.Link} // or type={'button'}
        >
          <i className="fa fa-file-excel"></i> {props.label}
        </ExcelDownloder>
      ) : (
        <span></span>
      )}
    </Fragment>
  )
}

XlsExporter.propTypes = {
  data: PropTypes.object,
  dataName: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  classNames: PropTypes.string,
}

export default XlsExporter
