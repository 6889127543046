import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report1ChartNew = ({ data }) => {
  console.log(data)
  const categories = [
    "Usuarios sin avance",
    "Usuarios con avance sin completar (1% a 50%)",
    "Usuarios con avance sin completar (51% a 99%)",
    "Usuarios avance completo (100%)",
  ]

  let avance0 = 0
  let avance50 = 0
  let avance99 = 0
  let avance100 = 0

  if (data !== undefined) {
    data.forEach(element => {
      element.detail.forEach(detail => {
        if (detail.porcentaje_avance <= 0) {
          avance0 += 1
        }
        if (detail.porcentaje_avance > 0 && detail.porcentaje_avance < 50) {
          avance50 += 1
        }
        if (detail.porcentaje_avance >= 50 && detail.porcentaje_avance < 100) {
          avance99 += 1
        }
        if (detail.porcentaje_avance === 100) {
          avance100 += 1
        }
      })
    })
  }

  const series = [avance0, avance50, avance99, avance100]

  const options = {
    title: {
      text: "Gráfica 1: Avance por cliente",
    },
    chart: {
      type: "donut",
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },

    labels: categories,
    colors: ["#f46a6a", "#f1734f", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="donut"
        height="420"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report1ChartNew.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
}
export default Report1ChartNew
