/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_LEVELS } from "./actionTypes"
import { apiError, getLevelsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getLevels } from "../../../helpers/backend_helper"

function* levels() {
  try {
    const response = yield call(getLevels)
    yield put(getLevelsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* levelsSaga() {
  yield takeEvery(GET_LEVELS, levels)
}

export default levelsSaga
