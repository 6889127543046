import { call, put, takeEvery } from "redux-saga/effects"
import {
  LIST_PERIODS,
  GET_SINGLE_PERIOD,
  CREATE_PERIOD,
  EDIT_PERIOD,
  ADD_RULE_TO_PERIOD,
  REMOVE_RULE_TO_PERIOD,
  ADD_GROUP_TO_PERIOD,
  REMOVE_GROUP_TO_PERIOD,
  GET_PERIOD_PARTICIPANTS,
  GET_PERIOD_CLIENTS,
  GET_PERIODS_PARTICIPANT,
} from "./actionTypes"
import {
  addPeriod,
  addPeriodGroup,
  addPeriodRule,
  getActivePeriodParticipants,
  getAllPeriods,
  getPeriod,
  getPeriodClients,
  getPeriodsParticipant,
  getPeriodParticipants,
  removePeriodGroup,
  removePeriodRule,
  updatePeriod,
} from "helpers/backend_helper"
import {
  addGroupToPeriodError,
  addGroupToPeriodSuccess,
  addRuleToPeriodError,
  addRuleToPeriodSuccess,
  createPeriodError,
  createPeriodSuccess,
  editPeriodClean,
  editPeriodSuccess,
  getPeriodClientsError,
  getPeriodClientsSuccess,
  getPeriodParticipantsError,
  getPeriodParticipantsSuccess,
  getPeriodsParticipantError,
  getPeriodsParticipantSuccess,
  getSinglePeriodError,
  getSinglePeriodSuccess,
  listPeriodsError,
  listPeriodsSuccess,
  removeGroupToPeriodError,
  removeGroupToPeriodSuccess,
  removeRuleToPeriodError,
  removeRuleToPeriodSuccess,
} from "./actions"

function* periodsList() {
  try {
    const response = yield call(getAllPeriods, {})
    if (response.statusCode) throw new Error(response.message)
    yield put(listPeriodsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(listPeriodsError(error))
  }
}

function* periodGet({ payload }) {
  try {
    const response = yield call(getPeriod, payload.periodId)
    if (response.statusCode) throw new Error(response.message)
    yield put(getSinglePeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getSinglePeriodError(error))
  }
}

function* periodAdd({ payload }) {
  try {
    const response = yield call(addPeriod, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(createPeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(createPeriodError(error))
  }
}

function* periodEdit({ payload }) {
  try {
    const response = yield call(updatePeriod, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(editPeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(editPeriodClean(error))
  }
}

function* periodRuleAdd({ payload }) {
  try {
    const response = yield call(addPeriodRule, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(addRuleToPeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(addRuleToPeriodError(error))
  }
}

function* periodRuleRemove({ payload }) {
  console.log(payload)
  try {
    const response = yield call(removePeriodRule, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(removeRuleToPeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(removeRuleToPeriodError(error))
  }
}

function* periodGroupAdd({ payload }) {
  try {
    const response = yield call(addPeriodGroup, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(addGroupToPeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(addGroupToPeriodError(error))
  }
}

function* periodGroupRemove({ payload: { groupId, periodId } }) {
  try {
    const response = yield call(removePeriodGroup, { groupId, periodId })
    if (response.statusCode) throw new Error(response.message)
    yield put(removeGroupToPeriodSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(removeGroupToPeriodError(error))
  }
}

function* participantsGet({ payload }) {
  try {
    const response =
      payload.periodId !== 0
        ? yield call(getPeriodParticipants, payload.periodId)
        : yield call(getActivePeriodParticipants, payload.periodId)
    if (response.statusCode) throw new Error(response.message)
    yield put(getPeriodParticipantsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getPeriodParticipantsError(error))
  }
}

function* clientsGet({ payload }) {
  try {
    const response = yield call(getPeriodClients, payload.periodId)
    if (response.statusCode) throw new Error(response.message)
    yield put(getPeriodClientsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getPeriodClientsError(error))
  }
}

function* periodsParticipantGet({ payload }) {
  try {
    const response = yield call(getPeriodsParticipant, payload.userId)
    if (response.statusCode) throw new Error(response.message)
    yield put(getPeriodsParticipantSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getPeriodsParticipantError(error))
  }
}

function* gamificationPeriodsSaga() {
  yield takeEvery(LIST_PERIODS, periodsList)
  yield takeEvery(GET_SINGLE_PERIOD, periodGet)
  yield takeEvery(CREATE_PERIOD, periodAdd)
  yield takeEvery(EDIT_PERIOD, periodEdit)
  yield takeEvery(ADD_RULE_TO_PERIOD, periodRuleAdd)
  yield takeEvery(REMOVE_RULE_TO_PERIOD, periodRuleRemove)
  yield takeEvery(ADD_GROUP_TO_PERIOD, periodGroupAdd)
  yield takeEvery(REMOVE_GROUP_TO_PERIOD, periodGroupRemove)
  yield takeEvery(GET_PERIOD_PARTICIPANTS, participantsGet)
  yield takeEvery(GET_PERIOD_CLIENTS, clientsGet)
  yield takeEvery(GET_PERIODS_PARTICIPANT, periodsParticipantGet)
}

export default gamificationPeriodsSaga
