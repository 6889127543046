/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_REPORT_SURVEY } from "./actionTypes"
import { listReportSurveyError, listReportSurveySuccess } from "./actions"

//Include Both Helper File with needed methods
import { reportSurveyNewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(reportSurveyNewApi, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (response.length === undefined) throw new Error(response)
    yield put(listReportSurveySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(listReportSurveyError(error.message))
  }
}

function* reportSurveyListSaga() {
  yield takeEvery(LIST_REPORT_SURVEY, list)
}

export default reportSurveyListSaga
