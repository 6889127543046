import {
  LIST_QUIZZ_ANSWERS,
  LIST_QUIZZ_ANSWERS_SUCCESS,
  LIST_QUIZZ_ANSWERS_CLEAN,
  LIST_QUIZZ_ANSWERS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  quizzAnswers: [],
}

const QuizzAnswersList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_QUIZZ_ANSWERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_QUIZZ_ANSWERS_SUCCESS:
      state = {
        ...state,
        quizzAnswers: action.payload.quizzAnswers,
        loading: false,
        done: true,
      }
      break
    case LIST_QUIZZ_ANSWERS_CLEAN:
      state = initialState
      break
    case LIST_QUIZZ_ANSWERS_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        quizzAnswers: [],
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuizzAnswersList
