import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"

const ActivationDeactivationModal = props => {
  const handleSubmit = () => {
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {props.action ? "Activar" : "Desactivar"} usuario
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        ¿Confirma que desea {props.action ? "activar" : "desactivar"} al
        usuario?
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            props.onCancel()
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onConfirm()}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  )
}

ActivationDeactivationModal.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ActivationDeactivationModal
