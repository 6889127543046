import {
  GET_COURSES,
  GET_COURSES_SUCCESS,
  API_ERROR,
  GET_COURSES_BY_USER,
  GET_COURSES_BY_USER_SUCCESS,
  GET_COURSES_BY_USER_AND_GROUP,
  GET_COURSES_BY_USER_AND_GROUP_SUCCESS,
  GET_COURSES_BY_USER_AND_GROUP_CLEAN,
  GET_COURSES_BY_USER_AND_GROUP_ERROR,
  SEND_COURSE_TRASH,
  SEND_COURSE_TRASH_SUCCESS,
  SEND_COURSE_TRASH_ERROR,
  SEND_COURSE_TRASH_CLEAN,
} from "./actionTypes"

export const getCourses = trash => ({
  type: GET_COURSES,
  payload: trash === undefined ? {} : { trash: 1 },
})

export const getCoursesSuccess = courses => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUser = idUser => ({
  type: GET_COURSES_BY_USER,
  payload: { idUser },
})

export const getCoursesByUserSuccess = courses => {
  return {
    type: GET_COURSES_BY_USER_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUserAndGroup = (userId, groupId) => ({
  type: GET_COURSES_BY_USER_AND_GROUP,
  payload: { userId, groupId },
})

export const getCoursesByUserAndGroupSuccess = courses => {
  return {
    type: GET_COURSES_BY_USER_AND_GROUP_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUserAndGroupClean = () => {
  return {
    type: GET_COURSES_BY_USER_AND_GROUP_CLEAN,
    payload: {},
  }
}

export const getCoursesByUserAndGroupError = error => {
  return {
    type: GET_COURSES_BY_USER_AND_GROUP_ERROR,
    payload: error,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const sendCourseToTrash = (id, trash) => {
  return {
    type: SEND_COURSE_TRASH,
    payload: { id, trash },
  }
}

export const sendCourseToTrashSuccess = courses => {
  return {
    type: SEND_COURSE_TRASH_SUCCESS,
    payload: {},
  }
}

export const sendCourseToTrashError = error => {
  return {
    type: SEND_COURSE_TRASH_ERROR,
    payload: error,
  }
}

export const sendCourseToTrashClean = () => {
  return {
    type: SEND_COURSE_TRASH_CLEAN,
    payload: {},
  }
}
