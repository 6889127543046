import {
  EDIT_COURSE,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_CLEAN,
  EDIT_COURSE_ERROR,
  EDIT_COURSE_STATUS,
  EDIT_COURSE_STATUS_SUCCESS,
} from "./actionTypes"

export const editCourse = form => ({
  type: EDIT_COURSE,
  payload: { form },
})

export const editCourseSuccess = response => {
  return {
    type: EDIT_COURSE_SUCCESS,
    payload: { response },
  }
}

export const editCourseClean = () => {
  return {
    type: EDIT_COURSE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_COURSE_ERROR,
    payload: error,
  }
}

export const editCourseStatus = (idCourse, status, publishDate) => ({
  type: EDIT_COURSE_STATUS,
  payload: { idCourse, status, publishDate },
})

export const editCourseStatusSuccess = response => {
  return {
    type: EDIT_COURSE_STATUS_SUCCESS,
    payload: { response },
  }
}
