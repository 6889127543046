/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_ABILITIES_BY_USER_AND_GROUP } from "./actionTypes"
import {
  listAbilitiesByUserAndGroupError,
  listAbilitiesByUserAndGroupSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { abilitiesWithGroup } from "../../../helpers/backend_helper"

function* listByUserAndGroup(request) {
  try {
    const response = yield call(abilitiesWithGroup, {
      groupId: request.payload.groupId,
      userId: request.payload.userId,
    })
    if (response == undefined) throw new Error("API error")
    yield put(listAbilitiesByUserAndGroupSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(listAbilitiesByUserAndGroupError(error.message))
  }
}

function* abilitiesListSaga() {
  yield takeEvery(LIST_ABILITIES_BY_USER_AND_GROUP, listByUserAndGroup)
}

export default abilitiesListSaga
