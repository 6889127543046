/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_BUSINESS } from "./actionTypes"
import { apiError, editBusinessSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateBusiness } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateBusiness, action.payload.form)
    console.log(response)
    if(response === undefined) yield put(apiError("Error al editar negocio"))
    if(!response.success) yield put(apiError(`Error al editar negocio: ${response.error}`))
    else yield put(editBusinessSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* businessEditSaga() {
  yield takeEvery(EDIT_BUSINESS, edit)
}

export default businessEditSaga
