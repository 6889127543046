import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import SweetAlert from "react-bootstrap-sweetalert"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import moment from "moment"

import {
  getGroups,
  sendGroupsToTrash,
  sendGroupsToTrashClean,
  me,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const GroupsListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)
  const [trash, setTrash] = useState(undefined)
  const [group, setGroup] = useState({
    idGrupo: 0,
  })

  const { error, loading, done, groups, trashDone, whoIAm } = useSelector(
    state => ({
      error: state.GroupsList.error,
      loading: state.GroupsList.loading,
      groups: state.GroupsList.groups,
      done: state.GroupsList.done,
      trashDone: state.GroupsTrash.trashDone,
      whoIAm: state.Profile.user,
    })
  )

  const profileId = whoIAm?.profileId ?? 0

  useEffect(() => {
    dispatch(getGroups())
  }, [])

  useEffect(() => {
    if (!whoIAm?.profileId) {
      dispatch(me())
    }
  }, [whoIAm])

  if (trashDone) {
    dispatch(getGroups())
    dispatch(sendGroupsToTrashClean())
  }

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.idGrupo}`}
        isOpen={row.idGrupo === active}
        toggle={() => {
          setDropDown(row.idGrupo === active ? 0 : row.idGrupo)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem
            onClick={() => props.history.push(`groups/detail/${row.idGrupo}`)}
          >
            Ingresar a grupo
          </DropdownItem>
          {(profileId === 1 || profileId === 2) && (
            <DropdownItem
              onClick={() => {
                setTrash(row.idGrupo)
              }}
            >
              Enviar a papelera
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "idGrupo",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "nombreGrupo",
      text: "Nombre del grupo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => (
        <Link to={`groups/detail/${row.idGrupo}`} draggable="false">
          {row.nombreGrupo}
        </Link>
      ),
    },
    {
      dataField: "participantes",
      text: "Participantes / Cursos",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div>
            <span>
              {row.participantesConteo} participante
              {row.participantesConteo === 1 ? "" : "s"}
            </span>
            <br />
            <span>
              {row.cursosConteo} curso{row.cursosConteo === 1 ? "" : "s"}
            </span>
          </div>
        )
      },
    },
    {
      dataField: "completados",
      text: "% de cursos completados",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        const valor = Math.round(row.porcentajeCompleto, 2)
        return (
          <div>
            <Progress multi>
              <Progress
                bar
                value={valor}
                color="secondary"
                style={{ width: `${valor}%` }}
                animated
              ></Progress>
              <Progress
                bar
                value={100 - valor}
                color="light"
                style={{ width: `${100 - valor}%` }}
              ></Progress>
            </Progress>
            <div className="mt-1">{`${valor}%`}</div>
          </div>
        )
      },
    },
    {
      dataField: "estatusGrupo",
      text: "Estatus",
      sort: true,
      align: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.idGrupo}`}>
            <span
              className={`pt-2 badge bg-${
                row.estatusGrupo ? "success" : "warning"
              }`}
            >
              {row.estatusGrupo ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: groups?.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const { ExportCSVButton } = CSVExport

  const defaultSorted = [{ dataField: "nombreGrupo", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  return loading || groups === undefined ? (
    <LoadingIndicator />
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="idGrupo"
        columns={columns}
        data={groups}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="idGrupo"
            columns={columns}
            data={groups}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={6}>
                    <div className="d-grid">
                      {(profileId === 1 || profileId === 2) && (
                        <Link
                          draggable="false"
                          to="/groups/add"
                          className="btn btn-primary btn-rounded"
                        >
                          <i className="fa fa-plus"></i> Agregar grupo
                        </Link>
                      )}
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 2, offset: 8 }}
                    md={{ size: 3, offset: 6 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"idGrupo"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {groups?.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {trash !== undefined && (
        <SweetAlert
          title="Enviar a papelera"
          warning
          confirmBtnBsStyle="danger"
          confirmBtnText="Sí, enviar"
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No, cancelar"
          onConfirm={() => {
            dispatch(sendGroupsToTrash(trash, true))
            setTrash(undefined)
          }}
          onCancel={() => {
            setTrash(undefined)
          }}
        >
          <p>¿Confirmas que deseas enviar este grupo a papelera?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

GroupsListTable.propTypes = {
  groups: PropTypes.array,
  history: PropTypes.object,
}

export default withRouter(GroupsListTable)
