import {
  GET_QUESTION,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_CLEAN,
  GET_QUESTION_ERROR,
} from "./actionTypes"

export const getQuestion = idQuestion => ({
  type: GET_QUESTION,
  payload: { idQuestion },
})

export const getQuestionSuccess = question => {
  return {
    type: GET_QUESTION_SUCCESS,
    payload: { question },
  }
}

export const getQuestionClean = () => {
  return {
    type: GET_QUESTION_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_QUESTION_ERROR,
    payload: error,
  }
}
