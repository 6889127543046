/* CAROUSEL */
export const LIST_CAROUSELS = "LIST_CAROUSELS"
export const LIST_CAROUSELS_SUCCESS = "LIST_CAROUSELS_SUCCESS"
export const LIST_CAROUSELS_CLEAN = "LIST_CAROUSELS_CLEAN"
export const LIST_CAROUSELS_ERROR = "LIST_CAROUSELS_ERROR"

export const GET_SINGLE_CAROUSEL = "GET_SINGLE_CAROUSEL"
export const GET_SINGLE_CAROUSEL_SUCCESS = "GET_SINGLE_CAROUSEL_SUCCESS"
export const GET_SINGLE_CAROUSEL_CLEAN = "GET_SINGLE_CAROUSEL_CLEAN"
export const GET_SINGLE_CAROUSEL_ERROR = "GET_SINGLE_CAROUSEL_ERROR"

export const CREATE_CAROUSEL = "CREATE_CAROUSEL"
export const CREATE_CAROUSEL_SUCCESS = "CREATE_CAROUSEL_SUCCESS"
export const CREATE_CAROUSEL_CLEAN = "CREATE_CAROUSEL_CLEAN"
export const CREATE_CAROUSEL_ERROR = "CREATE_CAROUSEL_ERROR"

export const EDIT_CAROUSEL = "EDIT_CAROUSEL"
export const EDIT_CAROUSEL_SUCCESS = "EDIT_CAROUSEL_SUCCESS"
export const EDIT_CAROUSEL_CLEAN = "EDIT_CAROUSEL_CLEAN"
export const EDIT_CAROUSEL_ERROR = "EDIT_CAROUSEL_ERROR"

export const DELETE_CAROUSEL = "DELETE_CAROUSEL"
export const DELETE_CAROUSEL_SUCCESS = "DELETE_CAROUSEL_SUCCESS"
export const DELETE_CAROUSEL_CLEAN = "DELETE_CAROUSEL_CLEAN"
export const DELETE_CAROUSEL_ERROR = "DELETE_CAROUSEL_ERROR"

export const ORDER_CAROUSEL = "ORDER_CAROUSEL"
export const ORDER_CAROUSEL_SUCCESS = "ORDER_CAROUSEL_SUCCESS"
export const ORDER_CAROUSEL_CLEAN = "ORDER_CAROUSEL_CLEAN"
export const ORDER_CAROUSEL_ERROR = "ORDER_CAROUSEL_ERROR"
