import {
  EXTEND_SESSION,
  EXTEND_SESSION_SUCCESS,
  EXTEND_SESSION_CLEAN,
  EXTEND_SESSION_ERROR,
} from "./actionTypes"

export const extendSession = () => {
  return {
    type: EXTEND_SESSION,
    payload: {},
  }
}

export const extendSessionSuccess = token => {
  return {
    type: EXTEND_SESSION_SUCCESS,
    payload: { token },
  }
}

export const extendSessionError = error => {
  return {
    type: EXTEND_SESSION_ERROR,
    payload: { error },
  }
}

export const extendSessionClean = () => {
  return {
    type: EXTEND_SESSION_CLEAN,
    payload: {},
  }
}
