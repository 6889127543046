import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  CardTitle,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, Redirect } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import {
  acceptPrivacyTyc,
  me,
  acceptPrivacyTycReset,
} from "../../store/actions"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"
import AprendeYGanaTyC from "components/Common/AprendeYGanaTyC"

const AcceptPrivacyTyC = props => {
  const dispatch = useDispatch()

  const [formError, setFormError] = useState(false)

  const { user, loading, error, success } = useSelector(state => ({
    user: state.Profile.user,
    loading: state.Profile.privacyTycLoading,
    error: state.Profile.privacyTycError,
    success: state.Profile.privacyTycSuccess,
  }))

  useEffect(() => {
    document.body.className = "main-celf-background"
    return () => {
      document.body.className = ""
    }
  }, [])

  useEffect(() => {
    dispatch(me())
    return () => dispatch(acceptPrivacyTycReset())
  }, [success])

  // handleValidSubmit
  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    dispatch(acceptPrivacyTyc(user.userId))
  }

  if (success) {
    return <Redirect to="/dashboard" />
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Aceptar Política de Privacidad, Términos y Condiciones de Uso |
          Professional&apos;s Academy E-Learning
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md={10} lg={8} xl={12}>
              <Card className="overflow-hidden">
                <div className="row">
                  <div className="col-sm-4 offset-sm-4 col-xs-8 offset-xs-2">
                    <div className="academy-logo-login"></div>
                  </div>
                </div>
                <CardTitle className="p-4">
                  <h3 className="text-center">
                    Aceptar la Política de Privacidad y los Términos y
                    Condiciones de Uso
                  </h3>
                </CardTitle>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">{error.toString()}</Alert>
                      )}
                      {formError && (
                        <Alert color="danger">
                          Por favor verifica los errores que se indican en el
                          formulario.
                        </Alert>
                      )}
                      <div
                        style={{
                          height: "320px",
                          width: "100%",
                          backgroundColor: "#F6F6F6",
                          padding: "16px",
                          overflow: "scroll",
                          marginBottom: "24px",
                        }}
                      >
                        <p>
                          Debido a las innovaciones técnicas y los cambios del
                          marco jurídico pertinente a las Condiciones de Uso
                          necesitan ser cambiadas y/o modificadas por nosotros
                          en algunas ocasiones. Por lo tanto, pedimos al usuario
                          revisar estas Condiciones de Uso antes de cada visita
                          a este sitio web y de tener en cuenta los cambios y/o
                          modificaciones.
                        </p>
                        <h4>Introducción</h4>
                        <p>Henkel Capital, S.A. de C.V.</p>
                        <p>
                          Henkel Capital, S.A. de C.V. (&quot;Henkel&quot;) es
                          una sociedad mexicana cuyo objeto social principal es
                          el comercio y la industria en general, incluyendo la
                          manufactura, industrialización, ensamble, adquisición,
                          venta, importación, exportación, uso y distribución de
                          toda clase de mercancías y productos, por cuenta
                          propia o ajena, en la República Mexicana o en el
                          extranjero, especialmente productos químicos,
                          petroquímicos e industriales en general.
                        </p>
                        <p>
                          Específicamente, Henkel fabrica y/o vende en México
                          adhesivos de diferentes clases para consumo,
                          profesionales o la construcción; detergentes en polvo
                          y líquidos, productos de limpieza para el hogar, así
                          como jabones de lavandería; también jabones de
                          tocador, cosmética, higiene y cuidado corporal; y
                          finalmente, productos químicos para tratamiento de
                          superficies, lubricantes y limpiadores.
                        </p>
                        <p>
                          Henkel cuenta actualmente con una oficina corporativa
                          en Interlomas, además cuenta con 5 Plantas productivas
                          ubicadas en Ecatepec, dos en Toluca, Salamanca y
                          Monterrey. Además cuenta con un nivel avanzado de
                          tecnología que es altamente competitiva en el ámbito
                          nacional e internacional, ya que cuenta con equipos de
                          vanguardia.
                        </p>
                        <h4>II. Términos y Condiciones Generales de Uso</h4>
                        <p>
                          Todos los visitantes al sitio de Internet de Henkel se
                          obligan a cumplir con los siguientes términos y
                          condiciones de uso por el solo hecho de accesar y
                          hacer uso del mismo. El acceso y utilización de este
                          sitio de Internet se encuentra sujeto a las
                          disposiciones aplicables de la legislación mexicana de
                          la materia.
                        </p>
                        <h4>III. Información Legal</h4>
                        <p>
                          <strong>a) Derechos Reservados</strong>
                        </p>
                        <p>
                          El diseño y contenido de este sitio de Internet, así
                          como cualquier programa de cómputo que aparezca en el
                          mismo, tienen todos los derechos reservados conforme a
                          lo dispuesto por la Ley Federal del Derecho de Autor y
                          a las demás disposiciones legales aplicables, por lo
                          que queda estrictamente prohibido, de manera
                          enunciativa más no limitativa: modificar, copiar,
                          distribuir, transmitir, desplegar, publicar, editar,
                          vender o de cualquier forma explotar el diseño y
                          contenido de este sitio de Internet con fines
                          comerciales.
                        </p>
                        <p>
                          Todas las fotografías, diseños, dibujos y demás
                          material de imagen que se muestran en este sitio de
                          Internet están protegidos por la ley aplicable y no
                          podrán ser utilizados por terceros para usos
                          diferentes a editoriales. Henkel tiene todos los
                          derechos reservados © sobre ese material.
                        </p>
                        <p>
                          La reproducción para fines privados, parcial o total
                          del contenido del sitio de Internet, deberá hacerse
                          con el previo consentimiento otorgado por escrito por
                          Henkel.
                        </p>
                        <p>
                          <strong>b) Marcas</strong>
                        </p>
                        <p>
                          Las marcas, nombres comerciales y demás propiedades
                          intelectuales e industriales que aparecen en este
                          sitio de Internet en relación con los productos que
                          produce o fabrica Henkel, son propiedad exclusiva de
                          Henkel AG & Co. KGaA, Henkelstrasse 67, D-40191
                          Düsseldorf, Alemania, dadas en licencia a Henkel, o
                          propiedad de Henkel. Queda estrictamente prohibido
                          cualquier uso o explotación de las marcas, nombres
                          comerciales y demás propiedades intelectuales e
                          industriales sin el consentimiento previo y por
                          escrito de Henkel. Las marcas de los productos Henkel
                          son: &quot;Henkel&quot;, &quot;Pritt&quot;,
                          &quot;Sista&quot;, &quot;Resistol&quot;,
                          &quot;Fester&quot;, &quot;Tangit&quot;, “Thomsit”,
                          &quot;Bully&quot;, &quot;Viva&quot;, &quot;MAS&quot;,
                          “Persil”, &quot;123&quot;, &quot;Bref&quot;,
                          “Renuzit”, “Vernel”, &quot;Palette&quot;,
                          &quot;DIAL&quot;, “Smooth’N Shine”, “Citré Shine”,
                          “PeRT”, “Xtreme”, “Men Express”, “Loctie”,
                          “Technomelt”, “Teroson”, “Acquence”, “Bonderite”,
                          “Osis”, “Bonacure”, “Igora”,
                          &quot;Henkel-Schwarzkopf&quot;.
                        </p>
                        <p>
                          <strong>c) Responsabilidad</strong>
                        </p>
                        <p>
                          Henkel no asume responsabilidad alguna por los daños o
                          perjuicios que se pudieren llegar a ocasionar en
                          virtud de la utilización de la información contenida
                          en este sitio de Internet. Es responsabilidad
                          exclusiva del usuario el evaluar la exactitud,
                          contenido y utilidad de la información contenida en el
                          mismo.
                        </p>
                        <p>
                          Henkel no asume responsabilidad alguna que se derive
                          de cualquier daño o perjuicio, directo o indirecto,
                          generado o de cualquier forma relacionado con el
                          acceso o utilización de este sitio de Internet (aún
                          cuando Henkel Capital haya tenido conocimiento de la
                          posibilidad del daño), incluyendo la responsabilidad
                          relacionada con daños ocasionados por virus que
                          infecten el/los sistema(s) de cómputo del usuario.{" "}
                        </p>
                        <p>
                          <strong>d) Ligas a otros Sitios</strong>
                        </p>
                        <p>
                          Se encuentra estrictamente prohibido cualquier tipo de
                          captación y/o vinculación no autorizada a este sitio.
                          Henkel se reserva el derecho de desactivar cualquier
                          liga (link) o marco (frame) no autorizado, y no asume
                          responsabilidad alguna respecto del contenido de
                          cualquier otro sitio de Internet ligado o vinculado a
                          este sitio.
                        </p>
                        <p>
                          El acceso a cualquier sitio ligado o vinculado será
                          bajo el riesgo exclusivo del usuario. Asimismo, el
                          usuario reconoce y acepta que los derechos de
                          propiedad intelectual e industrial de cualquier sitio
                          ligado o vinculado, están protegidos por la
                          legislación vigente y son propiedad exclusiva de
                          terceros.
                        </p>
                        <p>
                          <strong>e) Productos</strong>
                        </p>
                        <p>
                          Los productos que se muestran o enumeran en este sitio
                          de Internet son un ejemplo de los productos que Henkel
                          fabrica y/o vende en México.
                        </p>
                      </div>
                      <div className="pt-4"></div>
                      <AprendeYGanaTyC />
                      <AvGroup check>
                        <AvInput name="terminos" type="checkbox" required />
                        <Label for="terminos">
                          Confirmo que leí y acepto los{" "}
                          <a
                            href="https://www.henkel.mx/terminos-de-uso#pageID=560528"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Términos y Condiciones de Uso
                          </a>{" "}
                          de la Plataforma.
                        </Label>
                        <AvFeedback>Este campo es requerido.</AvFeedback>
                      </AvGroup>
                      <AvGroup check className="mt-4 mb-4">
                        <AvInput name="privacidad" type="checkbox" required />
                        <Label for="privacidad">
                          Confirmo que leí y acepto la{" "}
                          <a
                            href="https://www.henkel.mx/declaracion-de-proteccion-de-datos#pageID=560414"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Política de Privacidad
                          </a>{" "}
                          de la Plataforma.
                        </Label>
                        <AvFeedback>Este campo es requerido.</AvFeedback>
                      </AvGroup>
                      <AvGroup check className="mt-4 mb-4">
                        <AvInput name="aprendeYGana" type="checkbox" required />
                        <Label for="aprendeYGana">
                          Confirmo que leí y acepto los{" "}
                          <a
                            href="/aprende_y_gana_con_adhesivos_henkel_vf.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Términos y Condiciones de la promoción Aprende y
                            Gana
                          </a>
                        </Label>
                        <AvFeedback>Este campo es requerido.</AvFeedback>
                      </AvGroup>
                      <div className="mt-3 d-grid">
                        <Row>
                          <Col xs={6} style={{ textAlign: "right" }}>
                            <button
                              className={`btn btn-secondary ${
                                loading ? "disabled" : ""
                              }`}
                              type="submit"
                            >
                              {!loading && <span>Confirmar</span>}
                              {loading && <span>Confirmando...</span>}
                            </button>
                          </Col>
                          <Col xs={6}>
                            <Link to="/logout" className="btn btn-light">
                              No confirmar y salir
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AcceptPrivacyTyC
