import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report6Chart = ({ data }) => {
  const series = [data.internal, data.external]

  const options = {
    title: {
      text: "Gráfica 6: Split por tipo de usuario",
    },
    chart: {
      type: "donut",
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },

    labels: ["Usuarios internos", "Usuarios externos"],
    colors: ["#34c38f", "#f46a6a"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="donut"
        height="420"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report6Chart.propTypes = {
  data: PropTypes.array,
}
export default Report6Chart
