import {
  GET_QUIZZ_ANSWER_USER,
  GET_QUIZZ_ANSWER_USER_SUCCESS,
  GET_QUIZZ_ANSWER_USER_CLEAN,
  GET_QUIZZ_ANSWER_USER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  quizzAnswerUser: undefined,
}

const QuizzAnswerUserGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUIZZ_ANSWER_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_QUIZZ_ANSWER_USER_SUCCESS:
      state = {
        ...state,
        quizzAnswerUser: action.payload.quizzAnswerUser,
        loading: false,
        done: true,
      }
      break
    case GET_QUIZZ_ANSWER_USER_CLEAN:
      state = initialState
      break
    case GET_QUIZZ_ANSWER_USER_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        quizzAnswerUser: undefined,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuizzAnswerUserGet
