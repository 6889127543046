import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"

import avatar1 from "../../assets/images/user_without_pic.png"
import { useSelector, useDispatch } from "react-redux"
import { getGroups, me } from "../../store/actions"

const WelcomeN2to7 = props => {
  const dispatch = useDispatch()

  const [groupName, setGroupName] = useState("")

  const { whoIAm, groups } = useSelector(state => ({
    whoIAm: state.Profile.user,
    groups: state.GroupsList.groups,
  }))

  const user =
    whoIAm === undefined
      ? {
          userId: 0,
          firstName: "",
          lastName: "",
          profileId: 0,
          profile: "",
          groupId: 0,
        }
      : whoIAm

  const avg = props.completedAvg

  useEffect(() => {
    dispatch(getGroups())
  }, [])

  useEffect(() => {
    const listGroups = groups ?? []
    try {
      const myGroup = listGroups.find(group => group.idGrupo === user.groupId)
      if (myGroup !== undefined) {
        setGroupName(myGroup.nombreGrupo)
      }
    } catch (e) {
      setGroupName("")
    }
  }, [groups])

  return (
    <React.Fragment>
      <Row>
        <Col lg={9} xl={8}>
          <div className="p-2 pt-4">
            <h2>
              <strong>
                {user.firstName} {user.lastName}
              </strong>
            </h2>
            <h4 className="text-secondary">{user.profile}</h4>
            <h5>{groupName}</h5>
          </div>
        </Col>
        <Col
          className="mb-4"
          xl={{ size: 4, offset: 0 }}
          lg={{ size: 3, offset: 0 }}
          xs={{ size: 8, offset: 2 }}
          sm={{ size: 12, offset: 0 }}
          md={{ size: 8, offset: 2 }}
        >
          <div
            className="mt-4"
            style={{
              position: "relative",
              overflow: "hidden",
              paddingBottom: "100%",
            }}
          >
            <div
              style={{
                backgroundImage:
                  user.avatar == null
                    ? `url(${avatar1})`
                    : `url(${user.avatar})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "50%",
              }}
            >
              &nbsp;
            </div>
          </div>
        </Col>
        <hr />
        <Col xs={6}>
          <Link
            to={"/profile"}
            className="btn btn-primary btn-block"
            draggable="false"
          >
            Mi cuenta
          </Link>
        </Col>
        <Col xs={6}>
          <Link
            to={"/my-courses"}
            className="btn btn-secondary btn-block"
            draggable="false"
          >
            Mis cursos
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  )
}

WelcomeN2to7.propTypes = {
  completedAvg: PropTypes.number,
}

export default WelcomeN2to7
