import {
  LIST_REPORTB,
  LIST_REPORTB_SUCCESS,
  LIST_REPORTB_CLEAN,
  LIST_REPORTB_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  report: [],
}

const ReportBList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_REPORTB:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_REPORTB_SUCCESS:
      state = {
        ...state,
        report: action.payload.report,
        loading: false,
        done: true,
      }
      break
    case LIST_REPORTB_CLEAN:
      state = initialState
      break
    case LIST_REPORTB_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ReportBList
