import { Fragment, useState, useEffect } from "react"
import { PropTypes } from "prop-types"
import {
  Col,
  Row,
  Media,
  Input,
  Modal,
  Table,
  Card,
  CardBody,
  CardImg,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getCoursesByUser } from "store/actions"
import CourseCard from "./CourseCard"

const CourseLessonIntro = props => {
  const [modal, setModal] = useState(true)

  const course = props.course
  const sugCourses = course.suggestedCourses

  const dispatch = useDispatch()
  const { courses, user } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.CoursesList.courses,
  }))

  const idUser = user?.userId ?? 0

  useEffect(() => {
    dispatch(getCoursesByUser(idUser))
  }, [course, idUser])

  const getCourse = id => {
    if (courses != undefined)
      return courses.find(course => course.courseId === id)
    return undefined
  }

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Table className="table table-striped">
                <tbody>
                  <tr>
                    <td align="right">Categorías</td>
                    <td>
                      {props.knowledgeCategories === undefined
                        ? ""
                        : props.knowledgeCategories
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">Clave curso</td>
                    <td>{props.course?.courseCode}</td>
                  </tr>
                  <tr>
                    <td align="right">Curso previo</td>
                    <td>
                      {props.previousCourses === undefined
                        ? ""
                        : props.previousCourses.length <= 0
                        ? "Ninguno"
                        : props.previousCourses
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">Tiempo estimado</td>
                    <td>{props.course?.tiempoDedicacion} minutos</td>
                  </tr>
                  <tr>
                    <td align="right">Nivel</td>
                    <td>{props.level}</td>
                  </tr>
                  <tr>
                    <td align="right">Mínimo aprobatorio</td>
                    <td>{props.course?.minApproval}</td>
                  </tr>
                  {/*<tr>
                    <td align="right">Valoración usuarios</td>
                    <td>
                      <div>
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                      </div>
                    </td>
                  </tr>*/}
                </tbody>
              </Table>
              <br />
              {sugCourses !== undefined && sugCourses.length > 0 && (
                <div>
                  <h3>Cursos sugeridos</h3>
                  <Row>
                    {sugCourses.map((suggested, index) => {
                      const course = getCourse(suggested.courseId)
                      return (
                        course !== undefined && (
                          <div
                            key={`suggested-course-${index}`}
                            className="col-lg-4 col-md-6 col-sm-12"
                          >
                            <CourseCard course={course} />
                          </div>
                        )
                      )
                    })}
                  </Row>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <button
                  onClick={props.goNext}
                  className="btn btn-secondary ml-2"
                >
                  Siguiente <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

CourseLessonIntro.propTypes = {
  knowledgeCategories: PropTypes.array,
  course: PropTypes.object,
  previousCourses: PropTypes.array,
  responsibles: PropTypes.array,
  level: PropTypes.string,
  goNext: PropTypes.func,
}

export default CourseLessonIntro
