import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap"
import ChartistGraph from "react-chartist"

import "chartist/dist/scss/chartist.scss"
import "../../assets/scss/chartist.scss"

const ProgressGroup = () => {
  var barChartData = {
    labels: [
      "Atzin Rogel \n 25 terminados de 30",
      "Jesús Martínez Rangel \n 30 terminados de 30",
      "Anderson Ruíz Díaz \n 27 terminados de 30",
      "Viridiana Vélez Frías \n 0 terminados de 30",
    ],
    series: [
      [25, 30, 27, 0],
      [5, 0, 3, 30],
    ],
  }
  var barChartOptions = {
    stackBars: true,
    axisY: {
      labelInterpolationFnc: function (value) {
        return value
      },
    },
  }

  return (
    <Row>
      <Col xs="12">
        <div className="mb-3">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Progreso por usuario</CardTitle>
              <Row className="justify-content-center">
                <Col sm={4}>
                  <div className="text-center">
                    <h5 className="mb-0 font-size-20">30</h5>
                    <p className="text-muted">Cursos</p>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="text-center">
                    <h5 className="mb-0 font-size-20">4</h5>
                    <p className="text-muted">Usuarios</p>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="text-center">
                    <h5 className="mb-0 font-size-20">38</h5>
                    <p className="text-muted">Sin finalizar</p>
                  </div>
                </Col>
              </Row>
              <ChartistGraph
                data={barChartData}
                style={{ height: "300px" }}
                options={barChartOptions}
                type={"Bar"}
              />
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  )
}

export default ProgressGroup
