import {
  RESET_GROUP_COURSE,
  RESET_GROUP_COURSE_SUCCESS,
  RESET_GROUP_COURSE_CLEAN,
  RESET_GROUP_COURSE_ERROR,
} from "./actionTypes"

export const resetGroupCourse = form => ({
  type: RESET_GROUP_COURSE,
  payload: { form },
})

export const resetGroupCourseSuccess = group => {
  return {
    type: RESET_GROUP_COURSE_SUCCESS,
    payload: { group },
  }
}

export const resetGroupCourseClean = () => {
  return {
    type: RESET_GROUP_COURSE_CLEAN,
    payload: {},
  }
}

export const resetGroupCourseError = error => {
  return {
    type: RESET_GROUP_COURSE_ERROR,
    payload: error,
  }
}
