const AprendeYGanaTyC = () => {
  return (
    <div
      style={{
        height: "320px",
        width: "100%",
        backgroundColor: "#F6F6F6",
        padding: "16px",
        overflow: "scroll",
        marginBottom: "24px",
      }}
    >
      <p>&nbsp;</p>
      <p>
        <strong>T&Eacute;RMINOS Y CONDICIONES DE LA PROMOCI&Oacute;N</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Al margen de lo que dispone la Ley Federal de Protecci&oacute;n al
        Consumidor y de la NOM-028-SCFI-2007, se hace p&uacute;blica la presente
        (en lo sucesivo la &ldquo;Promoci&oacute;n&rdquo;). Para poder
        participar en la misma se deber&aacute; dar lectura integra de los
        siguientes t&eacute;rminos y condiciones (en adelante los
        &ldquo;T&eacute;rminos y Condiciones&rdquo;), adem&aacute;s de cumplir
        totalmente con los requisitos aqu&iacute; establecidos, lo cual implica
        su total comprensi&oacute;n y aceptaci&oacute;n. Cabe se&ntilde;alar que
        la suerte o el azar no son objeto de la presente Promoci&oacute;n.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> NOMBRE DE LA PROMOCI&Oacute;N: </strong>Aprende &amp; Gana
          con Adhesivos Henkel.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <strong> RESPONSABLE DE LA PROMOCI&Oacute;N: </strong>el responsable
          de la Promoci&oacute;n (en lo sucesivo el &ldquo;Responsable de la
          Promoci&oacute;n&rdquo;) ser&aacute; Henkel Capital, S.A. de C.V., con
          domicilio en: Boulevard Magnocentro, N&uacute;mero 8, Piso 2, Centro
          Urbano San Fernando, Huixquilucan, Estado de M&eacute;xico,
          M&eacute;xico, C.P. 52760.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <strong> ZONA GEOGR&Aacute;FICA: </strong>Rep&uacute;blica Mexicana.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="4">
        <li>
          <strong> VIGENCIA DE LA PROMOCI&Oacute;N: </strong>la vigencia para
          participar en la Promoci&oacute;n ser&aacute; del 2 de abril de 2024
          al 15 de julio de 2024.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="5">
        <li>
          <strong> CONSULTA LOS T&Eacute;RMINOS Y CONDICIONES EN: </strong>se
          podr&aacute;n consultar los T&eacute;rminos y Condiciones de la
          presente Promoci&oacute;n a trav&eacute;s de Internet en la
          p&aacute;gina: www.professionals-academy.com
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="6">
        <li>
          <strong>
            {" "}
            ESTABLECIMIENTO DONDE SE REALIZA LA PROMOCI&Oacute;N:{" "}
          </strong>
          la promoci&oacute;n ser&aacute; realizada de manera digital dentro de
          la plataforma digital &ldquo;<em>Professional&rsquo;s AcadeMy</em>
          &rdquo;, mediante la p&aacute;gina www.professionals-academy.com.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="7">
        <li>
          <strong> REQUISITOS PARA PARTICIPAR: </strong>podr&aacute;n participar
          el p&uacute;blico consumidor o usuario de la Rep&uacute;blica Mexicana
          que est&eacute; interesado en la Promoci&oacute;n y que re&uacute;na
          todos y cada uno de los siguientes requisitos (en lo sucesivo el
          &ldquo;Participante&rdquo; o los &ldquo;Participantes&rdquo;):
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          Ser residente legal de la Rep&uacute;blica Mexicana acreditado con
          comprobante de domicilio.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          Mayores de 18 (dieciocho) a&ntilde;os cumplidos antes de la fecha de
          inicio de la Promoci&oacute;n que cuenten con identificaci&oacute;n
          oficial vigente con fotograf&iacute;a.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          Realizar cada uno de los pasos descritos en la mec&aacute;nica de
          participaci&oacute;n como se describe en el punto 9 (nueve) de los
          presentes T&eacute;rminos y Condiciones.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="4">
        <li>
          Contar con su debido registro dentro de la p&aacute;gina:
          professionals-academy.com.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          Formar parte de alguno de los siguientes equipos de fuerza de venta,
          el cual ser&aacute; asignado por el RSM (Jefe Regional de Ventas) y/o
          el AE (ingeniero de Aplicaci&oacute;n o Application Engineering)
          designado por Henkel Capital, S.A. de C.V., dependiendo del perfil y
          &aacute;rea de especialidad de cada individuo:
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          Interno, que incluye:
          <ul>
            <li>Marketin,</li>
            <li>PD (Product Development o Desarrollo de Producto),</li>
            <li>
              KAM&rsquo;s (Key Account Manager o Ejecutivo de Cuentas Clave), y
            </li>
            <li>Becarios;</li>
          </ul>
        </li>
        <li>RSM&rsquo;s (Jefe Regional de Ventas);</li>
        <li>Fuerza de ventas de los clientes (ESF); y</li>
        <li></li>
      </ul>
      <p>&nbsp;</p>
      <ol start="8">
        <li>
          <strong> INCENTIVOS OFRECIDOS: </strong>
        </li>
      </ol>
      <ul>
        <li>
          1 (una) tarjeta de regalo de VANILLA VISA con valor total de $4,000.00
          MXN (cuatro mil pesos 00/100 M.N.), que incluye el Impuesto al Valor
          Agregado (IVA).
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          9 (nueve) Smart TV marca Hisense, LED 40H4030F 39.6&quot;&quot;, Full
          HD, Negro, con valor total de $3,584.02 MXN (tres mil quinientos
          ochenta y cuatro pesos 02/100 M.N.), que incluye el Impuesto al Valor
          Agregado (IVA) cada una.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          2 (dos) tarjetas de regalo de Amazon con valor total de $1,500.00 MXN
          (mil quinientos pesos 00/100 M.N.), que incluye el Impuesto al Valor
          Agregado (IVA) cada una.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          12 (doce) bocinas bloetooth marca JBL con valor total de $673.06 MXN
          (seiscientos setenta y tres pesos 06/100 M.N.), que incluye el
          Impuesto al Valor Agregado (IVA) cada una.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          10 (diez) Xiaomi Smartwatch Smart Band 8, Touch, Bluetooth 5.1,
          Android/iOS, Negro, Resistente al Agua, con valor total de $555.01 MXN
          (quinientos cincuenta y cinco pesos 01/100 M.N.), que incluye el
          Impuesto al Valor Agregado (IVA) cada una.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          13 (trece) tarjetas de regalo de Amazon con valor total de $500.00 MXN
          (quinientos pesos 00/100 M.N.), que incluye el Impuesto al Valor
          Agregado (IVA) cada una<strong>. </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="9">
        <li>
          <strong> MEC&Aacute;NICA DE PARTICIPACI&Oacute;N: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          El Participante deber&aacute; de realizar su registro en la plataforma
          &ldquo;<em>Professional&rsquo;s AcadeMy</em>&rdquo;, a la cual se
          accede mediante la p&aacute;gina professionals-academy.com, por medio
          del RSM (Jefe Regional de Ventas) y/o el AE (ingeniero de
          Aplicaci&oacute;n o Application Engineering) designado por Henkel
          Capital, S.A. de C.V.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          El Participante tendr&aacute; un diverso cat&aacute;logo de cursos, a
          los cuales se asignar&aacute;n por marca, tecnolog&iacute;a y
          producto.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          Durante la vigencia de la Promoci&oacute;n, una vez que el
          Participante finalice cualquiera de los cursos elegidos y realice la
          evaluaci&oacute;n correspondiente, &eacute;ste podr&aacute; sumar
          puntos.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          Cada curso s&oacute;lo generar&aacute; puntos la primera vez que este
          sea realizado.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          Para la evaluaci&oacute;n de los cursos, la calificaci&oacute;n
          m&iacute;nima para aprobar es de 8 (ocho), pudiendo obtener una
          calificaci&oacute;n de 10 (diez) respondiendo todo correctamente. Los
          puntos se obtendr&aacute;n conforme a la siguiente tabla de
          equivalencias:
        </li>
      </ol>
      <p>&nbsp;</p>
      <table className="table">
        <thead>
          <tr>
            <th>Cursos</th>
            <th>Abril</th>
            <th>Mayo</th>
            <th>Junio</th>
            <th>Julio</th>
            <th>Total 1S</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>4 c/semestre</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>4</td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div className="d-flex justify-content-center">
                <strong>Puntuación</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td>Puntos por curso completado</td>
            <td>8-10</td>
            <td>8-10</td>
            <td>8-10</td>
            <td>8-10</td>
            <td>32-40</td>
          </tr>
          <tr>
            <td>Puntos por &ldquo;Early bird (3 días)</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Quizzes</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Examenes aprobados al primer intento</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Encuestas</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>8</td>
          </tr>
          <tr>
            <td colSpan={5}></td>
            <td>
              <span className="text-danger" style={{ fontSize: "16px" }}>
                100
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <ol>
        <li>
          Adicionalmente, adem&aacute;s de la obtenci&oacute;n de puntos, el
          Participante recibir&aacute; 1 (una) constancia de
          participaci&oacute;n del curso aprobado.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="10">
        <li>
          <strong> SELECCI&Oacute;N DE LOS ACREEDORES AL INCENTIVO: </strong>la
          posibilidad de los Participantes para ser acreedores al incentivo
          depender&aacute; del grupo en el que participan. Conforme se muestra a
          continuaci&oacute;n, los Participantes que completen los cursos y
          obtengan la mayor cantidad de puntos durante la vigencia de la
          Promoci&oacute;n se har&aacute;n acreedores a alguno de los incentivos
          especificados en el punto 8 (ocho) de los presentes T&eacute;rminos y
          Condiciones:
        </li>
      </ol>
      <p>&nbsp;</p>
      <table className="table">
        <thead>
          <tr>
            <th>Concursos</th>
            <th>Participaci&oacute;n</th>
            <th>1er</th>
            <th>2do</th>
            <th>3er</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Interno</td>
            <td>Cursos completados</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Concursos para DNA</td>
            <td>Cursos completados</td>
            <td>9</td>
            <td>9</td>
            <td>9</td>
            <td>27</td>
          </tr>
          <tr>
            <td>Concursos para ESF&quot;s</td>
            <td>Cusos completados y participación de usuarios</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>3</td>
          </tr>
          <tr>
            <td>RSM&quot;s</td>
            <td>Cusos completados y participación de usuarios</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>3</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        Los incentivos ser&aacute;n repartidos como se muestra a
        continuaci&oacute;n:
      </p>
      <ol>
        <li>Interno</li>
      </ol>
      <p>&nbsp;</p>
      <ol start="500">
        <li>
          Primer lugar: 1 (una) tarjeta de regalo de Amazon con valor total de
          $1,500.00 MXN (mil quinientos pesos 00/100 M.N.), que incluye el
          Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="673">
        <li>
          Segundo lugar: 1 (una) bocina bloetooth marca JBL con valor total de
          $673.06 MXN (seiscientos setenta y tres pesos 06/100 M.N.), que
          incluye el Impuesto al Valor Agregado (IVA), m&aacute;s 1 (una)
          tarjeta de regalo de Amazon con valor total de $500.00 MXN (quinientos
          pesos 00/100 M.N.), que incluye el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="500">
        <li>
          Tercer lugar: 1 (una) tarjeta de regalo de Amazon con valor total de
          $500.00 MXN (quinientos pesos 00/100 M.N.), que incluye el Impuesto al
          Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="2">
        <li>DNA</li>
      </ol>
      <p>&nbsp;</p>
      <ol start="39">
        <li>
          Primer lugar: 1 (una) Smart TV marca Hisense, LED 40H4030F
          39.6&quot;&quot;, Full HD, Negro, con valor total de $3,584.02 MXN
          (tres mil quinientos ochenta y cuatro pesos 02/100 M.N.), que incluye
          el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Habr&aacute; 9 (nueve) participantes dentro de esta categor&iacute;a que
        podr&aacute;n ser acreedores al incentivo se&ntilde;alado para el primer
        lugar.
      </p>
      <p>&nbsp;</p>
      <ol start="673">
        <li>
          Segundo lugar: 1 (una) bocina bloetooth marca JBL con valor total de
          $673.06 MXN (seiscientos setenta y tres pesos 06/100 M.N.), que
          incluye el Impuesto al Valor Agregado (IVA), m&aacute;s 1 (una)
          tarjeta de regalo de Amazon con valor total de $500.00 MXN (quinientos
          pesos 00/100 M.N.), que incluye el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Habr&aacute; 9 (nueve) participantes dentro de esta categor&iacute;a que
        podr&aacute;n ser acreedores al incentivo se&ntilde;alado para el
        segundo lugar.
      </p>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          Tercer lugar: 1 (un) Xiaomi Smartwatch Smart Band 8, Touch, Bluetooth
          5.1, Android/iOS, Negro, Resistente al Agua, con valor total de
          $555.01 MXN (quinientos cincuenta y cinco pesos 01/100 M.N.), que
          incluye el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Habr&aacute; 9 (nueve) participantes dentro de esta categor&iacute;a que
        podr&aacute;n ser acreedores al incentivo se&ntilde;alado para el tercer
        lugar.
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>ESF:</li>
      </ol>
      <p>&nbsp;</p>
      <ol start="500">
        <li>
          Primer lugar: 1(una) tarjeta de regalo de Amazon con valor total de
          $1,500.00 MXN (mil quinientos pesos 00/100 M.N.), que incluye el
          Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          Segundo lugar: 1 (un) Xiaomi Smartwatch Smart Band 8, Touch, Bluetooth
          5.1, Android/iOS, Negro, Resistente al Agua, con valor total de
          $555.01 MXN (quinientos cincuenta y cinco pesos 01/100 M.N.), que
          incluye el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="500">
        <li>
          Tercer lugar: 1 (una) tarjeta de regalo de Amazon con valor total de
          $500.00 MXN (quinientos pesos 00/100 M.N.), que incluye el Impuesto al
          Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="4">
        <li>RSM</li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          Primer lugar: 1 (una) tarjeta de regalo de VANILLA VISA con valor
          total de $4,000.00 MXN (cuatro mil pesos 00/100 M.N.), que incluye el
          Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="673">
        <li>
          Segundo lugar: 1 (una) bocina bloetooth marca JBL con valor total de
          $673.06 MXN (seiscientos setenta y tres pesos 06/100 M.N.), que
          incluye el Impuesto al Valor Agregado (IVA), m&aacute;s 1 (una)
          tarjeta de regalo de Amazon con valor total de $500.00 MXN (quinientos
          pesos 00/100 M.N.), que incluye el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="673">
        <li>
          Tercer lugar: 1 (una) bocina bloetooth marca JBL con valor total de
          $673.06 MXN (seiscientos setenta y tres pesos 06/100 M.N.), que
          incluye el Impuesto al Valor Agregado (IVA).
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="11">
        <li>
          <strong> PUBLICACI&Oacute;N DE ACREEDORES DEL INCENTIVO: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        A trav&eacute;s de correo electr&oacute;nico y por medio del cuadro de
        mando de la plataforma &ldquo;<em>Professional&rsquo;s AcadeMy</em>
        &rdquo; se publicar&aacute; mensualmente al listado de los Participantes
        con mayor cantidad de puntos por cada categor&iacute;a. La lista final
        de los acreedores al incentivo ser&aacute; publicada por medio de la
        plataforma &ldquo;<em>Professional&rsquo;s AcadeMy</em>&rdquo; y enviada
        por correo electr&oacute;nico a los Participantes el 18 de julio de 2024
        a las 12:00 pm.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="12">
        <li>
          <strong> ENTREGA DE INCENTIVOS: </strong>el Responsable de la
          Promoci&oacute;n contactar&aacute; a los acreedores al incentivo
          v&iacute;a correo electr&oacute;nico y/o v&iacute;a telef&oacute;nica
          a fin de confirmar los datos para el env&iacute;o de los incentivos.
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        El incentivo ser&aacute; enviado por medio de DHL o Estafeta a la
        direcci&oacute;n se&ntilde;alada por el acreedor al incentivo, el cual
        llegar&aacute; en un aproximado de 45 (cuarenta y cinco) a 60 (sesenta)
        d&iacute;as h&aacute;biles contados a partir de la fecha de
        env&iacute;o.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="13">
        <li>
          <strong> RESTRICCIONES: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          No podr&aacute;n canjearse los incentivos por dinero en efectivo.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Los Participantes se obligan a no distribuir ninguna parte de la
          p&aacute;gina web promocional a trav&eacute;s de ning&uacute;n medio
          sin la previa autorizaci&oacute;n por escrito de Henkel Capital, S.A.
          de C.V., incluyendo, a t&iacute;tulo enunciativo, mas no limitativo,
          los archivos y dem&aacute;s informaci&oacute;n ingresada en la
          p&aacute;gina promocional, as&iacute; como a no alterar o modificar
          ninguna parte de la p&aacute;gina web promocional y sus
          tecnolog&iacute;as relacionadas, no eludir, desactivar o manipular de
          cualquier otra forma (o tratar de eludir, desactivar o manipular) las
          funciones de seguridad de la p&aacute;gina web promocional u otras
          funciones que impidan o restrinjan su utilizaci&oacute;n, as&iacute;
          como tampoco utilizar la p&aacute;gina web promocional para
          ning&uacute;n fin comercial, de publicidad, para realizar alguna
          actividad comercial, art&iacute;stica, t&eacute;cnico o profesional
          y/o con el fin principal de obtener ingresos, ni utilizar o activar
          ning&uacute;n sistema automatizado tendiente a modificar o copiar el
          contenido del sitio web promocional.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          En caso de imposibilidad para hacer efectivo el incentivo por
          cualquier motivo no aplica compensaci&oacute;n o remuneraci&oacute;n
          alguna.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Los Participantes deber&aacute;n evitar en cualquier momento conductas
          difamatorias dentro de su participaci&oacute;n en la presente
          Promoci&oacute;n.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Los incentivos ser&aacute;n entregados siempre y cuando se haya
          validado la credencial que acredite su mayor&iacute;a de edad.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          La fecha de participaci&oacute;n deber&aacute; coincidir con la
          vigencia de la presente Promoci&oacute;n.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          La participaci&oacute;n en la presente Promoci&oacute;n no
          tendr&aacute; costo alguno.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Henkel Capital, S.A. de C.V. podr&aacute;n, en cualquier momento,
          suspender la Promoci&oacute;n o la entrega de incentivos sin
          responsabilidad alguna si dicha suspensi&oacute;n es en acato de
          cualquier orden administrativa o judicial y/o si se dieren
          circunstancias de caso fortuito o fuerza mayor o eventos que afecten
          los intereses de Henkel Capital, S.A. de C.V. Si por causas ajenas a
          Henkel Capital, S.A. de C.V. se suspendiera, modificara, alterara o de
          cualquier forma se vieran imposibilitados los acreedores para recibir
          el incentivo, Henkel Capital, S.A. de C.V. se reserva el derecho de
          otorgar opcionalmente alg&uacute;n incentivo igual o similar al
          incentivo al cual el Participante se hizo acreedor; se
          proceder&aacute; de igual manera en los casos que existieran actos
          pol&iacute;ticos, sociales o de salud que hicieran inejecutables las
          entregas respectivas de incentivos. En todos estos casos, el
          Responsable de la Promoci&oacute;n publicar&aacute; a trav&eacute;s
          del sitio web promocional los motivos en que se fundamenta la
          realizaci&oacute;n de cualquiera de los actos establecidos en el
          presente p&aacute;rrafo.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          En el caso de que se presente un empate entre Participantes para
          determinar a los acreedores al incentivo, se tomar&aacute;n en cuenta
          los siguientes criterios de desempate: el tiempo que tardaron en
          completar los cursos y el n&uacute;mero de intentos que les tomo
          completar las evaluaciones.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="14">
        <li>
          <strong> CAUSAS QUE ANULAN LA PARTICIPACI&Oacute;N: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          La falta de entrega de cualquiera de los documentos que solicite el
          Responsable de la Promoci&oacute;n conforme al punto 7 (siete) de los
          presentes T&eacute;rminos y Condiciones.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          En caso de haber resultado acreedor a alg&uacute;n incentivo
          violentando las T&eacute;rminos y Condiciones, se deber&aacute; de
          restituir el mismo a Henkel Capital, S.A. de C.V. y responder por los
          da&ntilde;os y perjuicios ocasionados.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          No ser&aacute;n v&aacute;lidos los tickets fuera de los plazos
          especificados de la Promoci&oacute;n, siendo descalificadas sin
          derecho a incentivos.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Los Participantes que se dediquen a perpetuar en las promociones para
          fines il&iacute;citos y desventajosos que delimiten la
          participaci&oacute;n de las dem&aacute;s interesadas.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          Quienes realicen cualquiera de las conductas se&ntilde;aladas en este
          apartado responder&aacute; por da&ntilde;os o perjuicios de forma
          incondicional ante quienes reclamen acci&oacute;n de cualquier
          &iacute;ndole por tal situaci&oacute;n.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="15">
        <li>
          <strong> CASO FORTUITO O FUERZA MAYOR: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        El Responsable de la Promoci&oacute;n podr&aacute;, en cualquier
        momento, suspender la Promoci&oacute;n o la entrega de incentivos sin
        responsabilidad alguna si dicha suspensi&oacute;n es en acato de
        cualquier orden administrativa o judicial y/o si se dieren
        circunstancias de caso fortuito o fuerza mayor o eventos que afecten los
        intereses de Henkel Capital, S.A. de C.V.
      </p>
      <p>&nbsp;</p>
      <p>
        Si por causas ajenas a Henkel Capital, S.A. de C.V. se suspendiera,
        modificara, alterara o de cualquier forma se vieran imposibilitados los
        acreedores para recibir el incentivo, Henkel Capital, S.A. de C.V. se
        reserva el derecho de otorgar opcionalmente alg&uacute;n incentivo igual
        o similar al incentivo al cual el Participante se hizo acreedor; se
        proceder&aacute; de igual manera en los casos que existieran actos
        pol&iacute;ticos, sociales o de salud que hicieran inejecutables las
        entregas respectivas de incentivos. En todos estos casos, el Responsable
        de la Promoci&oacute;n publicar&aacute; a trav&eacute;s del sitio web
        promocional los motivos en que se fundamenta la realizaci&oacute;n de
        cualquiera de los actos establecidos en el presente p&aacute;rrafo. En
        ning&uacute;n momento Henkel Capital, S.A. de C.V. ser&aacute;
        responsables por los acontecimientos que de esta devengan.
      </p>
      <p>&nbsp;</p>
      <ol start="16">
        <li>
          <strong> AUTORIZACI&Oacute;N DE USO DE DATOS, OBRA E IMAGEN:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Al momento de aceptar participar en la presente Promoci&oacute;n, el
        Participante cede los derechos de uso de imagen correspondientes a
        Henkel Capital, S.A. de C.V. y para que &eacute;sta pueda utilizarlos
        para los fines que decida, adem&aacute;s de su libre utilizaci&oacute;n,
        publicaci&oacute;n y difusi&oacute;n posterior. De igual forma, el
        Participante se obliga a realizar la suscripci&oacute;n de los
        documentos pertinentes para alcanzar tal fin.
      </p>
      <p>
        De as&iacute; requerirse, los acreedores al incentivo se obligan a
        prestar su imagen y/o voz para la elaboraci&oacute;n de material
        fotogr&aacute;fico, as&iacute; como grabaciones de audio y video o
        informaci&oacute;n y recopilaci&oacute;n de datos a utilizarse en los
        medios de comunicaci&oacute;n que Henkel Capital, S.A. de C.V. juzgue
        convenientes.
      </p>
      <p>
        Los testimoniales a que se hacen referencia en el p&aacute;rrafo
        anterior ser&aacute;n propiedad exclusiva de Henkel Capital, S.A. de
        C.V., los cuales podr&aacute; difundir y utilizar de la manera que
        m&aacute;s convenga a sus intereses, sin que esto implique pago alguno a
        dichos Participantes por su aparici&oacute;n en cualquier medio de
        comunicaci&oacute;n.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="17">
        <li>
          <strong> MEDIOS DE DIFUSI&Oacute;N: </strong>los T&eacute;rminos y
          Condiciones de la presente Promoci&oacute;n se difundir&aacute;n a
          trav&eacute;s de la p&aacute;gina web
        </li>
      </ol>
      <p>
        promocional{" "}
        <a href="http://www.professionals-academy.com">
          www.professionals-academy.com
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        Por lo antes mencionado, los Participantes y acreedores est&aacute;n
        conformes en dar su autorizaci&oacute;n de manera gratuita para que se
        use su(s) imagen(es), testimonios y datos personales por parte de Henkel
        Capital, S.A. de C.V. por el t&eacute;rmino de 5 (cinco) a&ntilde;os,
        tiempo durante el cual podr&aacute; &eacute;ste &uacute;ltimo usar las
        im&aacute;genes de manera libre para su edici&oacute;n,
        distribuci&oacute;n, difusi&oacute;n, explotaci&oacute;n comercial y
        distribuci&oacute;n de dichas im&aacute;genes tomadas durante la
        vigencia de la Promoci&oacute;n para explotarlas por cualquier forma o
        medio, incluyendo, de manera enunciativa mas no limitativa,
        televisi&oacute;n, internet, radio o cualquier medio electr&oacute;nico,
        medios externos de publicidad tales como parabuses, vallas,
        espectaculares, entre otros, por el plazo de 5 (cinco) a&ntilde;os
        contados a partir de la fecha en que se inscriba a la Promoci&oacute;n
        el Participante; la transmisi&oacute;n de derechos patrimoniales se da
        de conformidad con los art&iacute;culos 30 y 31 de la Ley Federal del
        Derecho de Autor, siempre y cuando el uso de las im&aacute;genes
        est&eacute;n relacionadas con la presente Promoci&oacute;n.
      </p>
      <p>&nbsp;</p>
      <ol start="18">
        <li>
          <strong> RESPONSABILIDAD: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        La participaci&oacute;n en &eacute;sta Promoci&oacute;n implica la
        aceptaci&oacute;n total y sin restricciones de los presentes
        T&eacute;rminos y Condiciones, por lo que el Participante acepta
        expresamente liberar a Henkel Capital, S. A. de C.V., as&iacute; como a
        sus respectivas compa&ntilde;&iacute;as afiliadas, subsidiarias y
        matrices, funcionarios, directores, empleados y agentes, de toda y
        cualquier demanda o acci&oacute;n de responsabilidad legal de cualquier
        naturaleza en relaci&oacute;n con la Promoci&oacute;n y derivado de
        cualquier da&ntilde;o o perjuicios personales, corporales o
        patrimoniales que puedan sufrir los Participantes con motivo de haber
        participado en la Promoci&oacute;n o haber obtenido cualquiera de los
        incentivos se&ntilde;alados en el punto 8 (ocho) de los presentes
        T&eacute;rminos y Condiciones.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="19">
        <li>
          <strong> JURISDICCI&Oacute;N Y LEYES APLICABLES:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        En caso de cualquier controversia, el Participante se somete a la
        competencia de la Procuradur&iacute;a Federal del Consumidor y/o la
        jurisdicci&oacute;n de los Tribunales Federales con residencia en la
        Ciudad de M&eacute;xico, renunciando a cualquier otro fuero por
        raz&oacute;n de su domicilio presente o futuro le pudiera corresponder y
        renuncian expresamente en este acto a cualquier otro fuero que en
        raz&oacute;n de sus domicilios presentes o futuros o por cualquier otra
        causa les pudiera corresponder.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>AVISO DE PRIVACIDAD</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        HENKEL CAPITAL, S.A. DE C.V. (en lo sucesivo &ldquo;HENKEL&rdquo;), con
        domicilio en Boulevard Magnocentro, N&uacute;mero 8, Centro Urbano
        Interlomas, Huixquilucan, Estado de M&eacute;xico, M&eacute;xico, C.P.
        52760, en cumplimiento a la Ley Federal de Protecci&oacute;n de Datos
        Personales en Posesi&oacute;n de los Particulares, su Reglamento y los
        lineamientos y est&aacute;ndares nacionales e internacionales en materia
        de protecci&oacute;n de datos personales, asimismo, en estricto apego al
        cumplimento de los principios establecidos en la ley y los deberes de
        seguridad y confidencialidad, aplica las medidas de seguridad
        f&iacute;sicas, administrativas y tecnol&oacute;gicas pertinentes
        durante todo el tratamiento y ciclo de vida de sus datos personales, en
        t&eacute;rminos del presente Aviso de Privacidad:
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> Datos Personales </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Para efecto de llevar a cabo la promoci&oacute;n &ldquo;Aprende &amp;
        Gana con Adhesivos Henkel&rdquo;, y con fines de fines de
        identificaci&oacute;n y autenticaci&oacute;n, HENKEL recaba la siguiente
        informaci&oacute;n:
      </p>
      <ul>
        <li>Nombre completo;</li>
        <li>Correo electr&oacute;nico;</li>
        <li>Fecha de nacimiento;</li>
        <li>Fotograf&iacute;a y/o imagen; y</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Informaci&oacute;n relativa a sus redes sociales Facebook o Twitter,
        &uacute;nicamente para las personas que interact&uacute;an con HENKEL a
        trav&eacute;s de dichos medios. La informaci&oacute;n que recaba HENKEL
        de estos medios es &uacute;nicamente el usuario y/o nombre de contacto y
        HENKEL tiene acceso a su fotograf&iacute;a de perfil.
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <strong> Datos financieros y Datos sensibles </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>HENKEL no recaba datos sensibles.</p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="3">
        <li>
          <strong> Finalidades de Tratamiento: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        A continuaci&oacute;n, se citan las finalidades para las cuales HENKEL
        efect&uacute;a el tratamiento de sus datos personales:
      </p>
      <p>&nbsp;</p>
      <p>Primarias:</p>
      <ul>
        <li>Registro;</li>
        <li>Contacto;</li>
        <li>Publicitarios y de prospecci&oacute;n comercial;</li>
        <li>Llevar a cabo estad&iacute;sticas de preferencias de consumo;</li>
        <li>
          Entrega de los incentivos motivo de la promoci&oacute;n publicitaria;
          y
        </li>
        <li>
          En su caso, la celebraci&oacute;n de contratos y/o autorizaciones a
          los que haya lugar con los acreedores a incentivos.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Si usted no desea que sus datos sean tratados para cualquiera de las
        finalidades anteriormente descritas, puede oponerse a ello en cualquier
        momento a trav&eacute;s del correo electr&oacute;nico:
        aviso_privacidad@henkel.com.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="4">
        <li>
          <strong> Transferencias: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Informamos a los participantes que no efectuamos transferencias de sus
        datos personales, a excepci&oacute;n de aquellas que sean expresamente
        requeridas o autorizadas por ley o por orden de autoridad competente.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="5">
        <li>
          <strong>
            {" "}
            Medios y/o procedimiento para el ejercicio de Derechos ARCO:{" "}
          </strong>
        </li>
      </ol>
      <p>
        Usted podr&aacute; limitar u oponerse al tratamiento de sus datos, el
        uso o divulgaci&oacute;n de los mismos, o bien, revocar su
        consentimiento, a trav&eacute;s del correo electr&oacute;nico{" "}
        <a href="mailto:aviso_privacidad@henkel.com">
          aviso_privacidad@henkel.com
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        Para el ejercicio de sus derechos de Acceso, Rectificaci&oacute;n,
        Cancelaci&oacute;n y Oposici&oacute;n, podr&aacute; enviar su solicitud
        al correo electr&oacute;nico aviso_privacidad@henkel.com.
      </p>
      <p>&nbsp;</p>
      <p>Su solicitud de derechos ARCO deber&aacute; contener:</p>
      <ul>
        <li>Nombre completo.</li>
        <li>
          Copia simple o copia digital de identificaci&oacute;n oficial para
          acreditar la identidad (INE, pasaporte, c&eacute;dula profesional).
        </li>
        <li>
          Representaci&oacute;n: en caso de actuar en representaci&oacute;n de
          un menor, se deber&aacute; presentar acta de nacimiento o documento
          oficial donde se acredite la personalidad del padre o tutor, en caso
          de actuar en representaci&oacute;n de un tercero mayor de edad,
          deber&aacute; adjuntar el poder donde se acrediten sus facultades para
          actuar en representaci&oacute;n del mismo.
        </li>
        <li>
          Descripci&oacute;n breve de lo que solicita, ya sea el acceso a sus
          datos, rectificaci&oacute;n o cancelaci&oacute;n de los mismos,
          oposici&oacute;n al tratamiento o algunas finalidades del tratamiento
          o revocaci&oacute;n del consentimiento.
        </li>
        <li>
          Para rectificar su nombre, anexar copia de justificativo en caso de
          ser distinto a la identificaci&oacute;n oficial presentada para
          acreditar la identidad.
        </li>
        <li>
          El plazo de respuesta ser&aacute; de 20 (veinte) d&iacute;as
          h&aacute;biles con base en el calendario oficial publicado en el
          Diario Oficial de la Federaci&oacute;n.
        </li>
        <li>
          La respuesta a su solicitud ser&aacute; enviada a trav&eacute;s del
          mismo medio por el cual fue recibida, es decir, v&iacute;a correo
          electr&oacute;nico.
        </li>
      </ul>
      <p>
        En caso de no recibir respuesta de parte de HENKEL o si ella le
        resultara insatisfactoria, usted cuenta con un plazo de 15 (quince)
        d&iacute;as h&aacute;biles para acudir al INAI y solicitar que se inicie
        un procedimiento de protecci&oacute;n de derechos.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="6">
        <li>
          <strong> Cambios al Aviso de Privacidad: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Los cambios a este Aviso de Privacidad le ser&aacute;n notificados
        v&iacute;a correo electr&oacute;nico, directamente en nuestras
        instalaciones o a trav&eacute;s de esta p&aacute;gina web.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="7">
        <li>
          <strong> Cookies o web Beacons: </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Nuestro sitio web recaba autom&aacute;ticamente determinada
        informaci&oacute;n no personal sobre el uso de nuestro sitio, la cual se
        almacena en nuestros servidores para fines exclusivamente internos, como
        pueden ser facilitar su visita a nuestro sitio, mejorar su experiencia
        on-line o para finalidades estad&iacute;sticas de acceso.
      </p>
      <p>
        Para los fines antes mencionados, tambi&eacute;n podemos utilizar
        &ldquo;<em>cookies</em>&rdquo;, &ldquo;<em>web beacons</em>&rdquo; y
        otras tecnolog&iacute;as. Las &ldquo;<em>cookies</em>&rdquo; son
        archivos de texto que son descargados autom&aacute;ticamente y
        almacenados en el disco duro del equipo de c&oacute;mputo del usuario al
        navegar en una p&aacute;gina de Internet espec&iacute;fica, permitiendo
        recordar al servidor de Internet algunos datos sobre este usuario, entre
        ellos, sus preferencias para la visualizaci&oacute;n de las
        p&aacute;ginas en ese servidor, nombre y contrase&ntilde;a. Los &ldquo;
        <em>web beacons</em>&rdquo; son im&aacute;genes insertadas en una
        p&aacute;gina de Internet o correo electr&oacute;nico, que pueden ser
        utilizados para monitorear el comportamiento de un visitante, para
        almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario,
        duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina
        y el tipo de navegador utilizado, entre otros.
      </p>
      <p>&nbsp;</p>
      <p>
        Las &ldquo;<em>cookies</em>&rdquo; y &ldquo;<em>web beacons</em>&rdquo;
        nos permitir&aacute;n adecuar una p&aacute;gina web o anuncio a sus
        intereses y preferencias. En la mayor&iacute;a de los navegadores de
        Internet usted puede eliminar las &ldquo;<em>cookies</em>&rdquo; del
        disco duro de su ordenador, bloquearlas o recibir un aviso antes de que
        se instale una &ldquo;<em>cookie</em>&rdquo;. Por favor, consulte las
        instrucciones de su navegador o la pantalla de ayuda para saber
        m&aacute;s sobre el funcionamiento de estas tecnolog&iacute;as y como
        deshabilitarlas.
      </p>
      <p>&nbsp;</p>
      <ol start="8">
        <li>
          <strong> Medidas de Seguridad: </strong>
        </li>
      </ol>
      <p>
        HENKEL ha establecido y mantiene medidas de seguridad, administrativas,
        t&eacute;cnicas y f&iacute;sicas que le permiten proteger sus datos
        personales contra da&ntilde;o, p&eacute;rdida, alteraci&oacute;n,
        destrucci&oacute;n o contra el uso, acceso o tratamiento no autorizado,
        en t&eacute;rminos de la legislaci&oacute;n aplicable.
      </p>
      <style jsx>{`
        p {
          margin: 0cm;
          font-size: 16px;
          font-family: "Arial", sans-serif;
          color: black;
        }
        span {
          font-size: 11px;
        }
        p.footer {
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 8pt;
          margin-left: 0cm;
          font-size: 11pt;
          font-family: "Aptos", sans-serif;
        }
        p.footer span {
          font-size: 11px;
          line-height: 107%;
        }
        .blue {
          color: blue;
        }
      `}</style>
    </div>
  )
}

export default AprendeYGanaTyC
