import {
  GET_SUPPORT_MATERIAL_TYPE,
  GET_SUPPORT_MATERIAL_TYPE_SUCCESS,
  GET_SUPPORT_MATERIAL_TYPE_ERROR,
  GET_SUPPORT_MATERIAL_TYPE_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  supportMaterialType: undefined,
}

const SupportMaterialTypeGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORT_MATERIAL_TYPE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SUPPORT_MATERIAL_TYPE_SUCCESS:
      state = {
        ...state,
        supportMaterialType: action.payload.supportMaterialType,
        loading: false,
        done: true,
      }
      break
    case GET_SUPPORT_MATERIAL_TYPE_CLEAN:
      state = initialState
      break
    case GET_SUPPORT_MATERIAL_TYPE_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SupportMaterialTypeGet
