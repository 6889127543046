import {
  GET_LESSON,
  GET_LESSON_SUCCESS,
  GET_LESSON_CLEAN,
  GET_LESSON_ERROR,
} from "./actionTypes"

export const getLesson = id => {
  return {
    type: GET_LESSON,
    payload: { id },
  }
}

export const getLessonSuccess = lesson => {
  return {
    type: GET_LESSON_SUCCESS,
    payload: { lesson },
  }
}

export const getLessonClean = () => {
  return {
    type: GET_LESSON_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_LESSON_ERROR,
    payload: error,
  }
}
