/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_EXAM } from "./actionTypes"
import { apiError, getExamSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getExam } from "../../../helpers/backend_helper"

function* get(request) {
  try {
    const response = yield call(getExam, request.payload.idCourse)
    if (response.examId === undefined) throw new Error("API error")
    yield put(getExamSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* examGetSaga() {
  yield takeEvery(GET_EXAM, get)
}

export default examGetSaga
