import { call, put, takeEvery } from "redux-saga/effects"
import {
  CREATE_CAROUSEL,
  DELETE_CAROUSEL,
  EDIT_CAROUSEL,
  GET_SINGLE_CAROUSEL,
  LIST_CAROUSELS,
  ORDER_CAROUSEL,
} from "./actionTypes"
import {
  getActiveCarousels,
  getCarousels,
  getCarousel,
  addCarousel,
  updateCarousel,
  removeCarousel,
  changeOrderCarousel,
} from "helpers/backend_helper"
import {
  createCarouselError,
  createCarouselSuccess,
  deleteCarouselError,
  deleteCarouselSuccess,
  editCarouselError,
  editCarouselSuccess,
  getSingleCarouselError,
  getSingleCarouselSuccess,
  listCarouselsError,
  listCarouselsSuccess,
  orderCarouselError,
  orderCarouselSuccess,
} from "./actions"

function* carouselsList({ payload }) {
  try {
    const functionToCall =
      payload.onlyActive === true ? getActiveCarousels : getCarousels
    const response = yield call(functionToCall, {})
    if (response.statusCode) throw new Error(response.message)
    yield put(listCarouselsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(listCarouselsError(error))
  }
}

function* carouselGet({ payload }) {
  try {
    const response = yield call(getCarousel, payload.carouselId)
    if (response.statusCode) throw new Error(response.message)
    yield put(getSingleCarouselSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getSingleCarouselError(error))
  }
}

function* carouselAdd({ payload }) {
  try {
    const response = yield call(addCarousel, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(createCarouselSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(createCarouselError(error))
  }
}

function* carouselEdit({ payload }) {
  try {
    const response = yield call(updateCarousel, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(editCarouselSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(editCarouselError(error))
  }
}

function* carouselDelete({ payload }) {
  console.log(payload)
  try {
    const response = yield call(removeCarousel, { id: payload.id })
    if (response.statusCode) throw new Error(response.message)
    yield put(deleteCarouselSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(deleteCarouselError(error))
  }
}

function* carouselOrder({ payload }) {
  try {
    const response = yield call(changeOrderCarousel, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(orderCarouselSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(orderCarouselError(error))
  }
}

function* carouselSaga() {
  yield takeEvery(LIST_CAROUSELS, carouselsList)
  yield takeEvery(GET_SINGLE_CAROUSEL, carouselGet)
  yield takeEvery(CREATE_CAROUSEL, carouselAdd)
  yield takeEvery(EDIT_CAROUSEL, carouselEdit)
  yield takeEvery(DELETE_CAROUSEL, carouselDelete)
  yield takeEvery(ORDER_CAROUSEL, carouselOrder)
}

export default carouselSaga
