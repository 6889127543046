import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Button, Label } from "reactstrap"
import Switch from "react-switch"
import {
  addKnowledgeCategory,
  addKnowledgeCategoryClean,
  editKnowledgeCategory,
  editKnowledgeCategoryClean,
} from "../../store/actions"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Sí
    </div>
  )
}

const KnowledgeCategoryForm = props => {
  const knowledgeCategory = props.knowledgeCategory
  const [formError, setFormError] = useState(false)
  const [status, setStatus] = useState(knowledgeCategory.status)

  const insert = props.insert ?? true

  const dispatch = useDispatch()

  const {
    loadingAdd,
    doneAdd,
    errorAdd,
    loadingUpdate,
    errorUpdate,
    doneUpdate,
  } = useSelector(state => ({
    loadingAdd: state.KnowledgeCategoryAdd.loading,
    errorAdd: state.KnowledgeCategoryAdd.error,
    doneAdd: state.KnowledgeCategoryAdd.done,
    loadingUpdate: state.KnowledgeCategoryEdit.loading,
    errorUpdate: state.KnowledgeCategoryEdit.error,
    doneUpdate: state.KnowledgeCategoryEdit.done,
  }))

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }

    const intStatus = status ? 1 : 0

    if (insert) {
      const request = {
        name: values.name,
        status: intStatus,
      }
      dispatch(addKnowledgeCategory(request))
    } else {
      const request = {
        knowledgeCategoryId: knowledgeCategory.knowledgeCategoryId,
        name: values.name,
        status: intStatus,
      }
      dispatch(editKnowledgeCategory(request))
    }
  }

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {errorAdd !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar la categoría de conocimiento: {errorAdd}
        </div>
      )}
      {errorUpdate !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al editar la categoría de conocimiento: {errorUpdate}
        </div>
      )}
      {formError && (
        <div className="alert alert-danger">
          Por favor corrija los errores en el formulario antes de continuar.
        </div>
      )}
      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Categoría de conocimiento:
          </Label>
        </Col>
        <Col md={9}>
          <AvField
            name="name"
            value={knowledgeCategory.knowledgeCategoryName}
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Activado:
          </Label>
        </Col>
        <Col md={9}>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            className="me-1 mb-sm-8 mb-2"
            onColor={"#33BE00"}
            offColor={"#ed1c2e"}
            onChange={() => {
              setStatus(!status)
            }}
            checked={status}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Button className={`float-right  btn-block`} type="submit">
            {!loadingAdd && !loadingUpdate && <span>Guardar</span>}
            {(loadingAdd || loadingUpdate) && <span>Guardando...</span>}
          </Button>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <button
            type="button"
            onClick={() => props.onCancel()}
            className="btn btn-light btn-block"
          >
            Cancelar
          </button>
        </Col>
      </Row>
      {doneAdd && insert && errorAdd === "" && (
        <SweetAlert
          title="Categoría de conocimiento registrada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.onDone()
          }}
        >
          <p>La categoría de conocimiento fue registrada exitosamente.</p>
        </SweetAlert>
      )}
      {doneUpdate && !insert && errorUpdate === "" && (
        <SweetAlert
          title="Categoría de conocimiento actualizada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.onDone()
          }}
        >
          <p>La categoría de conocimiento fue actualizada exitosamente.</p>
        </SweetAlert>
      )}
      <style jsx="true">{`
        .radio-sublabel {
          color: gray;
          padding-left: 2rem;
        }
        .users-group {
          padding-left: 3rem;
        }
      `}</style>
    </AvForm>
  )
}

KnowledgeCategoryForm.propTypes = {
  insert: PropTypes.bool,
  knowledgeCategory: PropTypes.object,
  history: PropTypes.object,
  onDone: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(KnowledgeCategoryForm)
