import {
  ADD_SKILL,
  ADD_SKILL_SUCCESS,
  ADD_SKILL_CLEAN,
  ADD_SKILL_ERROR,
} from "./actionTypes"

export const addSkill = form => ({
  type: ADD_SKILL,
  payload: { form },
})

export const addSkillSuccess = skill => {
  return {
    type: ADD_SKILL_SUCCESS,
    payload: { skill },
  }
}

export const addSkillClean = () => {
  return {
    type: ADD_SKILL_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_SKILL_ERROR,
    payload: error,
  }
}
