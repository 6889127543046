/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_COURSE_KNOWLEDGES } from "./actionTypes"
import { apiError, getCourseKnowledgesSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getCourseKnowledges } from "../../../helpers/backend_helper"

function* courseKnowledges() {
  try {
    const response = yield call(getCourseKnowledges)
    yield put(getCourseKnowledgesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* courseKnowledgesListSaga() {
  yield takeEvery(GET_COURSE_KNOWLEDGES, courseKnowledges)
}

export default courseKnowledgesListSaga
