import React from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  CardTitle,
  Label,
} from "reactstrap"

const BlockNewTab = props => {
  return (
    <React.Fragment>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md={10} lg={8} xl={12}>
              <Card className="overflow-hidden">
                <div className="row">
                  <div className="col-sm-4 offset-sm-4 col-xs-8 offset-xs-2">
                    <div className="academy-logo-login"></div>
                  </div>
                </div>
                <CardTitle className="p-4">
                  <h3 className="text-center">
                    Por razones de seguridad, solo se permite tener abierta una
                    pestaña de esta aplicación.
                  </h3>
                </CardTitle>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlockNewTab
