import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "common/moment_i18n"

import {
  listGroupCourses,
  addGroupCourse,
  addGroupCourseClean,
  removeGroupCourse,
  removeGroupCourseClean,
  getCourses,
  resetGroupCourse,
  resetGroupCourseClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import AddCourseToGroupModal from "./AddCourseToGroupModal"
import DeleteCourseFromGroupModal from "./DeleteCourseFromGroupModal"
import CoursePreviewModal from "./CoursePreviewModal"

const CoursesGroupListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [showAddCourse, setShowAddCourse] = useState(false)
  const [showDeleteCourse, setShowDeleteCourse] = useState(undefined)
  const [coursePreview, setCoursePreview] = useState(undefined)
  const [courseToReset, setCourseToReset] = useState(undefined)

  const idGroup = props.group.idGrupo ?? 0

  useEffect(() => {
    dispatch(listGroupCourses(idGroup))
    dispatch(getCourses())
  }, [idGroup])

  const {
    groupCourses,
    addCourseLoading,
    addCourseError,
    addCourseDone,
    removeCourseLoading,
    removeCourseError,
    removeCourseDone,
    resetCourseLoading,
    resetCourseError,
    resetCourseDone,
    coursesList,
  } = useSelector(store => ({
    groupCourses: store.GroupCoursesList.groupCourses,
    addCourseLoading: store.GroupCourseAdd.loading,
    addCourseError: store.GroupCourseAdd.error,
    addCourseDone: store.GroupCourseAdd.done,
    removeCourseLoading: store.GroupCourseRemove.loading,
    removeCourseError: store.GroupCourseRemove.error,
    removeCourseDone: store.GroupCourseRemove.done,
    resetCourseLoading: store.GroupCourseReset.loading,
    resetCourseError: store.GroupCourseReset.error,
    resetCourseDone: store.GroupCourseReset.done,
    coursesList: store.CoursesList.coursesForAdmin,
  }))

  let courses = []
  let availableCourses = []

  if (coursesList !== undefined && groupCourses !== undefined) {
    coursesList.forEach(course => {
      if (course.status === 2) {
        const exists = groupCourses.find(
          groupCourse => groupCourse.idCourse === course.courseId
        )
        if (exists !== undefined) {
          course.groupUserPercentage = exists.groupUserPercentage

          courses.push(course)
        } else {
          availableCourses.push(course)
        }
      }
    })
  }

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.courseId}`}
        isOpen={row.courseId === active}
        toggle={() => {
          setDropDown(row.courseId === active ? 0 : row.courseId)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem onClick={() => setCoursePreview(row.courseId)}>
            Ver curso
          </DropdownItem>
          {!props.readonly && (
            <DropdownItem onClick={() => setCourseToReset(row.courseId)}>
              Resetear curso
            </DropdownItem>
          )}
          {!props.readonly && (
            <DropdownItem onClick={() => setShowDeleteCourse(row)}>
              Eliminar del grupo
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "courseId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "courseCode",
      text: "ID Curso Henkel",
      sort: true,
    },
    {
      dataField: "courseName",
      text: "Nombre del curso",
      sort: true,
    },
    {
      dataField: "categoria",
      text: "Categoría de conocimiento",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        let knowledgeCategories = []
        row.knowledgeCategories.forEach(object => {
          knowledgeCategories.push(object.knowledgeCategoryName)
        })
        let string = knowledgeCategories.join(", ")
        return (
          <div key={`knowledge-categories-${row.courseId}`}>
            {string === ""
              ? "-- Sin categorías de conocimiento asignadas --"
              : string}
          </div>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Actualización",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.courseId}`}>
            {moment(row.lastActivity)
              .locale("es")
              .format("D [de] MMM [de] YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "groupUserPercentage",
      text: "Avance grupal",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`progress-course-${row.courseId}`}>
            <div className="mb-1 text-center">{`${Math.round(
              row.groupUserPercentage
            )}%`}</div>
            <Progress multi>
              <Progress
                bar
                value={row.groupUserPercentage}
                color="secondary"
                style={{ width: `${row.groupUserPercentage}%` }}
                animated
              ></Progress>
              <Progress
                bar
                value={100 - row.groupUserPercentage}
                color="light"
                style={{ width: `${100 - row.groupUserPercentage}%` }}
              ></Progress>
            </Progress>
          </div>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: courses?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "courseCode", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  const handleAddCourse = courseId => {
    const request = {
      idGroup: props.group.idGrupo,
      idCourse: courseId,
    }

    dispatch(addGroupCourse(request))
  }

  const handleDeleteCourse = courseId => {
    const request = {
      idGroup: props.group.idGrupo,
      idCourse: courseId,
    }

    dispatch(removeGroupCourse(request))
  }

  const handleResetCourse = () => {
    dispatch(
      resetGroupCourse({
        idGroup: idGroup,
        idCourse: courseToReset,
      })
    )
    setCourseToReset(undefined)
  }

  if (addCourseDone) {
    dispatch(listGroupCourses(props.group.idGrupo))
    dispatch(addGroupCourseClean())
  }

  if (removeCourseDone) {
    setShowDeleteCourse(undefined)
    dispatch(listGroupCourses(props.group.idGrupo))
    dispatch(removeGroupCourseClean())
  }

  return (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="idCurso"
        columns={columns}
        data={courses}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="idCurso"
            columns={columns}
            data={courses}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-link">
                      <i className="fa fa-file-excel"></i> Exportar a Excel
                    </button>
                    {"   "}
                    {!props.readonly && (
                      <Link
                        to="/courses"
                        className="btn btn-link"
                        draggable="false"
                      >
                        <i className="fa fa-users"></i> Ver todos los cursos
                      </Link>
                    )}
                    {"   "}
                    {!props.readonly && (
                      <button className="btn btn-link">
                        <i className="fa fa-sync-alt"></i> Resetear cursos
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mb-2 pt-3">
                  <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                    <div className="d-grid">
                      {!props.readonly && (
                        <button
                          onClick={() => setShowAddCourse(true)}
                          className="btn btn-primary btn-rounded"
                        >
                          <i className="fa fa-plus"></i> Agregar curso
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col xl={8} lg={6} md={4} xs={0} className="text-center pt-2">
                    Total de cursos: <strong>{courses.length}</strong>
                  </Col>
                  <Col
                    xl={{ size: 2 }}
                    lg={{ size: 3 }}
                    md={{ size: 4 }}
                    sm={{ size: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"idCurso"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {courses.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      <AddCourseToGroupModal
        show={showAddCourse}
        loading={addCourseLoading}
        courses={availableCourses}
        onConfirm={courseId => handleAddCourse(courseId)}
        onCancel={() => setShowAddCourse(false)}
        error={addCourseError}
      />
      <DeleteCourseFromGroupModal
        show={showDeleteCourse !== undefined}
        course={showDeleteCourse}
        loading={removeCourseLoading}
        onConfirm={courseId => handleDeleteCourse(courseId)}
        onCancel={() => setShowDeleteCourse(undefined)}
        error={removeCourseError}
      />
      <CoursePreviewModal
        show={coursePreview !== undefined}
        course={coursePreview}
        onClose={() => setCoursePreview(undefined)}
      />
      {courseToReset !== undefined && (
        <SweetAlert
          title="Resetear curso"
          warning
          confirmBtnBsStyle="secondary"
          cancelBtnBsStyle="light"
          showCancel={true}
          cancelBtnText="No, cancelar"
          confirmBtnText="Sí, resetear"
          onCancel={() => {
            setCourseToReset(undefined)
          }}
          onConfirm={handleResetCourse}
        >
          <p>¿Confirma que desea resetear este curso?</p>
        </SweetAlert>
      )}
      {resetCourseDone && (
        <SweetAlert
          title="Curso reseteado"
          success
          confirmBtnBsStyle="secondary"
          confirmBtnText="Aceptar"
          onConfirm={() => {
            dispatch(listGroupCourses(idGroup))
            dispatch(resetGroupCourseClean())
          }}
        >
          <p>Se reseteó el curso para el grupo exitosamente.</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CoursesGroupListTable.propTypes = {
  group: PropTypes.object,
  readonly: PropTypes.bool,
}

export default CoursesGroupListTable
