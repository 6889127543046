import {
  CREATE_CAROUSEL,
  CREATE_CAROUSEL_CLEAN,
  CREATE_CAROUSEL_ERROR,
  CREATE_CAROUSEL_SUCCESS,
  EDIT_CAROUSEL,
  EDIT_CAROUSEL_CLEAN,
  EDIT_CAROUSEL_ERROR,
  EDIT_CAROUSEL_SUCCESS,
  GET_SINGLE_CAROUSEL,
  GET_SINGLE_CAROUSEL_CLEAN,
  GET_SINGLE_CAROUSEL_ERROR,
  GET_SINGLE_CAROUSEL_SUCCESS,
  LIST_CAROUSELS,
  LIST_CAROUSELS_CLEAN,
  LIST_CAROUSELS_ERROR,
  LIST_CAROUSELS_SUCCESS,
  DELETE_CAROUSEL,
  DELETE_CAROUSEL_CLEAN,
  DELETE_CAROUSEL_ERROR,
  DELETE_CAROUSEL_SUCCESS,
  ORDER_CAROUSEL,
  ORDER_CAROUSEL_CLEAN,
  ORDER_CAROUSEL_ERROR,
  ORDER_CAROUSEL_SUCCESS,
} from "./actionTypes"

export const listCarousels = onlyActive => {
  return {
    type: LIST_CAROUSELS,
    payload: {
      onlyActive,
    },
  }
}

export const listCarouselsSuccess = carousels => ({
  type: LIST_CAROUSELS_SUCCESS,
  payload: { carousels },
})

export const listCarouselsError = error => ({
  type: LIST_CAROUSELS_ERROR,
  payload: { error },
})

export const listCarouselsClean = () => ({
  type: LIST_CAROUSELS_CLEAN,
  payload: {},
})

export const getSingleCarousel = carouselId => {
  return {
    type: GET_SINGLE_CAROUSEL,
    payload: { ...{ carouselId: carouselId } },
  }
}

export const getSingleCarouselSuccess = carousel => ({
  type: GET_SINGLE_CAROUSEL_SUCCESS,
  payload: { carousel },
})

export const getSingleCarouselError = error => ({
  type: GET_SINGLE_CAROUSEL_ERROR,
  payload: { error },
})

export const getSingleCarouselClean = () => ({
  type: GET_SINGLE_CAROUSEL_CLEAN,
  payload: {},
})

export const createCarousel = form => ({
  type: CREATE_CAROUSEL,
  payload: { ...form },
})

export const createCarouselSuccess = () => ({
  type: CREATE_CAROUSEL_SUCCESS,
  payload: {},
})

export const createCarouselError = error => ({
  type: CREATE_CAROUSEL_ERROR,
  payload: { error },
})

export const createCarouselClean = () => ({
  type: CREATE_CAROUSEL_CLEAN,
  payload: {},
})

export const editCarousel = form => ({
  type: EDIT_CAROUSEL,
  payload: { ...form },
})

export const editCarouselSuccess = () => ({
  type: EDIT_CAROUSEL_SUCCESS,
  payload: {},
})

export const editCarouselError = error => ({
  type: EDIT_CAROUSEL_ERROR,
  payload: { error },
})

export const editCarouselClean = () => ({
  type: EDIT_CAROUSEL_CLEAN,
  payload: {},
})

export const deleteCarousel = id => {
  return {
    type: DELETE_CAROUSEL,
    payload: { id },
  }
}

export const deleteCarouselSuccess = () => ({
  type: DELETE_CAROUSEL_SUCCESS,
  payload: {},
})

export const deleteCarouselError = error => ({
  type: DELETE_CAROUSEL_ERROR,
  payload: { error },
})

export const deleteCarouselClean = () => ({
  type: DELETE_CAROUSEL_CLEAN,
  payload: {},
})

export const orderCarousel = form => ({
  type: ORDER_CAROUSEL,
  payload: { ...form },
})

export const orderCarouselSuccess = () => ({
  type: ORDER_CAROUSEL_SUCCESS,
  payload: {},
})

export const orderCarouselError = error => ({
  type: ORDER_CAROUSEL_ERROR,
  payload: { error },
})

export const orderCarouselClean = () => ({
  type: ORDER_CAROUSEL_CLEAN,
  payload: {},
})
