import {
  ADD_QUIZZ,
  ADD_QUIZZ_SUCCESS,
  ADD_QUIZZ_CLEAN,
  ADD_QUIZZ_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  quizz: undefined,
}

const QuizzAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUIZZ:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_QUIZZ_SUCCESS:
      state = {
        ...state,
        quizz: action.payload.quizz,
        loading: false,
        done: true,
      }
      break
    case ADD_QUIZZ_CLEAN:
      state = initialState
      break
    case ADD_QUIZZ_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuizzAdd
