import {
  GET_SUPPORT_MATERIAL_TYPE,
  GET_SUPPORT_MATERIAL_TYPE_SUCCESS,
  GET_SUPPORT_MATERIAL_TYPE_ERROR,
  GET_SUPPORT_MATERIAL_TYPE_CLEAN,
} from "./actionTypes"

export const getSupportMaterialType = () => ({
  type: GET_SUPPORT_MATERIAL_TYPE,
  payload: {},
})

export const getSupportMaterialTypeSuccess = idSupportMaterialType => {
  return {
    type: GET_SUPPORT_MATERIAL_TYPE_SUCCESS,
    payload: { idSupportMaterialType },
  }
}

export const getSupportMaterialTypeClean = () => {
  return {
    type: GET_SUPPORT_MATERIAL_TYPE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_SUPPORT_MATERIAL_TYPE_ERROR,
    payload: error,
  }
}
