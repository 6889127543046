import {
  REMOVE_QUIZZ,
  REMOVE_QUIZZ_SUCCESS,
  REMOVE_QUIZZ_CLEAN,
  REMOVE_QUIZZ_ERROR,
} from "./actionTypes"

export const removeQuizz = form => ({
  type: REMOVE_QUIZZ,
  payload: { form },
})

export const removeQuizzSuccess = quizz => {
  return {
    type: REMOVE_QUIZZ_SUCCESS,
    payload: { quizz },
  }
}

export const removeQuizzClean = () => {
  return {
    type: REMOVE_QUIZZ_CLEAN,
    payload: {},
  }
}

export const removeQuizzError = error => {
  return {
    type: REMOVE_QUIZZ_ERROR,
    payload: error,
  }
}
