import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import avatar1 from "../../assets/images/user_without_pic.png"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import {
  userForgetPassword,
  userForgetPasswordClean,
  changePassword,
  changePasswordClean,
  activateDeactivate,
  activateDeactivateClean,
  getUsers,
  resetGroupUser,
  resetGroupUserClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import RestorePasswordModal from "./RestorePasswordModal"
import { useDispatch, useSelector } from "react-redux"
import ActivationDeactivationModal from "./ActivationDeactivationModal"
import XlsExporter from "components/Common/XlsExporter"
import { numberWithCommas } from "common/strings"

const UsersListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [user, setUser] = useState({
    idUsuario: 0,
    email: "",
  })
  const [userForResetCourses, setUserForResetCourses] = useState(undefined)
  const [forgetPasswordSent, setForgetPasswordSent] = useState(false)
  const [changePasswordSent, setChangePasswordSent] = useState(false)
  const [activationType, setActivationType] = useState(undefined)
  const [page, setPage] = useState(undefined)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [sizePerPage, setSizePerPage] = useState(undefined)
  const [passwordView, setPasswordView] = useState(false)
  const [activationDeactivationUser, setActivationDeactivationUser] =
    useState(0)

  const {
    error,
    loading,
    done,
    users,
    forgetSuccess,
    forgetError,
    changePasswordError,
    changePasswordLoading,
    changePasswordSuccess,
    activateDeactivateLoading,
    activateDeactivateSuccess,
    resetDone,
    whoIAm,
  } = useSelector(state => ({
    forgetSuccess: state.ForgetPassword.forgetSuccessMsg,
    forgetError: state.ForgetPassword.forgetError,
    changePasswordError: state.UsersList.changePasswordError,
    changePasswordLoading: state.UsersList.changePasswordLoading,
    changePasswordSuccess: state.UsersList.changePasswordSuccess,
    activateDeactivateLoading: state.UserEdit.activateDeactivateLoading,
    activateDeactivateSuccess: state.UserEdit.activateDeactivateSuccess,
    error: state.UsersList.error,
    loading: state.UsersList.loading,
    users: state.UsersList.users,
    done: state.UsersList.done,
    resetDone: state.GroupUserReset.done,
    whoIAm: state.Profile.user,
  }))

  const userId = whoIAm?.userId ?? 0

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getUsers(userId))
    }
  }, [userId])

  useEffect(() => {
    setFilteredUsers(users)
  }, [users])

  if (forgetSuccess) {
    setForgetPasswordSent(true)
    dispatch(userForgetPasswordClean())
  }

  if (changePasswordSuccess) {
    setChangePasswordSent(true)
    dispatch(changePasswordClean())
  }

  if (resetDone) {
    dispatch(resetGroupUserClean())
  }

  if (activateDeactivateSuccess && activationDeactivationUser !== 0) {
    setActivationDeactivationUser(0)
    dispatch(getUsers(userId))
  }

  const getPassword = () => {
    return passwordView ? user.password : "************"
  }

  const [modalPasswordResetBackdrop, setModalPasswordResetBackdrop] =
    useState(false)

  const [
    modalActivationDeactivationBackdrop,
    setModalActivationDeactivationBackdrop,
  ] = useState(false)

  const handleResetCourses = () => {
    dispatch(
      resetGroupUser({
        id: userForResetCourses,
      })
    )
    setUserForResetCourses(undefined)
  }

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.userId}`}
        isOpen={row.userId === active}
        toggle={() => {
          setDropDown(row.userId === active ? 0 : row.userId)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem onClick={() => resetPassword(row)}>
            Resetear contraseña
          </DropdownItem>
          <DropdownItem>
            <Link to={`/users/edit/${row.userId}`} draggable="false">
              Editar info
            </Link>
          </DropdownItem>
          <DropdownItem onClick={() => setUserForResetCourses(row.userId)}>
            Resetear cursos
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setActivationType(!row.status)
              setActivationDeactivationUser(row.userId)
              setModalActivationDeactivationBackdrop(true)
            }}
          >
            {row.status ? "Desactivar" : "Activar"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "userId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      csvExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <img
            key={`image-${row.userId}`}
            src={avatar1}
            alt="Avatar"
            className="img-responsive rounded-circle"
            width={50}
          />
        )
      },
    },
    {
      dataField: "firstName",
      text: "Nombre completo y correo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`nombre-${row.userId}`}>
            <strong>
              <Link to={`/users/edit/${row.userId}`} draggable="false">
                {row.firstName} {row.lastName}
              </Link>
            </strong>
            <br />
            <span>{row.email}</span>
          </div>
        )
      },
      csvFormatter: (column, row) => `${row.firstName} ${row.lastName}`,
    },
    {
      dataField: "lastName",
      text: "Apellido",
      sort: true,
      csvExport: false,
      hidden: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      csvExport: true,
      hidden: true,
    },
    {
      dataField: "profileId",
      text: "Perfil",
      sort: true,
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return <span>N{row.profileId}</span>
      },
      csvFormatter: (column, row) => `N${row.profileId}`,
    },
    {
      dataField: "groupName",
      text: "Grupo",
      sort: true,
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return row.groupId === null ? (
          <span>Ninguno</span>
        ) : (
          <span>{row.groupName}</span>
        )
      },
      csvFormatter: (column, row) =>
        row.groupId === null ? "Ninguno" : row.groupName,
    },
    {
      dataField: "clientName",
      text: "Cliente",
      sort: true,
      csvExport: true,
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return row.clientName === null ? (
          <span>-</span>
        ) : (
          <span>{row.clientName}</span>
        )
      },
      csvFormatter: (column, row) =>
        row.groupId === null ? "-" : row.clientName,
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      csvText: "Último ingreso",
      sort: true,
      align: "center",
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return row.aceptotyc !== null && row.aceptoPrivacidadDatos !== null ? (
          <span key={`time-${row.idUsuario}`}>
            {moment(row.lastActivity)
              .locale("es")
              .format("DD/MM/YYYY HH:mm [hrs.]")}
          </span>
        ) : (
          <span>-</span>
        )
      },
      csvFormatter: (column, row) =>
        moment(row.lastActivity).format("D/M/YYYY HH:mm [hrs.]"),
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.status}`}>
            <span
              className={`pt-2 badge bg-${row.status ? "success" : "warning"}`}
            >
              {row.status ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
      csvFormatter: (column, row) => (row.status ? "Activo" : "Inactivo"),
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      csvExport: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: filteredUsers.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const getXlsData = users => {
    let data = users.map((user, index) => {
      return {
        ID: user.userId,
        "Nombre completo": `${user.firstName} ${user.lastName}`,
        Correo: user.email,
        Perfil: `N${user.profileId}`,
        Grupo: user.groupId === null ? "Ninguno" : user.groupName,
        Cliente: user.clientName === null ? "-" : user.clientName,
        "Último ingreso":
          user.aceptotyc !== null && user.aceptoPrivacidadDatos !== null
            ? moment(user.lastActivity).format("D/M/YYYY HH:mm [hrs.]")
            : "-",
        Estatus: user.status ? "Activo" : "Inactivo",
      }
    })

    return {
      Usuarios: data,
    }
  }

  const defaultSorted = [{ dataField: "profileId", order: "asc" }]

  const resetPassword = user => {
    setModalPasswordResetBackdrop(true)
    setUser(user)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const afterFilter = (newResult, newFilters) => {}

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="userId"
        columns={columns}
        data={users}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="userId"
            columns={columns}
            data={users}
            search={{
              afterSearch: newData => {
                setFilteredUsers(newData)
              },
            }}
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={4}>
                    <div className="d-grid">
                      <Link
                        draggable="false"
                        to="/users/add"
                        className="btn btn-primary btn-rounded"
                      >
                        <i className="fa fa-plus"></i> Agregar usuario
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={8}
                    md={6}
                    sm={4}
                    xs={4}
                    className="text-center"
                  ></Col>
                  <Col
                    lg={{ size: 2 }}
                    md={{ size: 3 }}
                    sm={{ size: 4 }}
                    xs={4}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          onSearch={value => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(value)
                          }}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                {users !== undefined && filteredUsers !== undefined && (
                  <Row>
                    <Col xs={8}>
                      <p>
                        {filteredUsers.length === users.length ? (
                          <span>
                            Mostrando{" "}
                            <strong>{numberWithCommas(users.length)}</strong>{" "}
                            usuarios
                          </span>
                        ) : (
                          <span>
                            Mostrando{" "}
                            <strong>
                              {numberWithCommas(filteredUsers.length)}
                            </strong>{" "}
                            usuarios de un total de{" "}
                            <strong>{numberWithCommas(users.length)}</strong>
                          </span>
                        )}
                      </p>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end pr-4">
                      <XlsExporter
                        data={getXlsData(filteredUsers)}
                        dataName={"Usuarios"}
                        name="Usuariosx"
                        label="Exportar lista"
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"userId"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {users?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                  <RestorePasswordModal
                    show={modalPasswordResetBackdrop}
                    error={
                      changePasswordError != ""
                        ? changePasswordError
                        : forgetError
                    }
                    onCancel={() => {
                      setModalPasswordResetBackdrop(false)
                      dispatch(userForgetPasswordClean())
                    }}
                    onConfirmWithLink={() => {
                      dispatch(userForgetPassword(user.email, true))
                    }}
                    onConfirmWithPassword={password => {
                      setUser({
                        ...user,
                        password: password,
                      })
                      dispatch(changePassword(user.userId, password, userId))
                    }}
                  />
                  <ActivationDeactivationModal
                    onCancel={() =>
                      setModalActivationDeactivationBackdrop(false)
                    }
                    onConfirm={() => {
                      dispatch(
                        activateDeactivate(
                          activationDeactivationUser,
                          activationType,
                          userId
                        )
                      )
                      setModalActivationDeactivationBackdrop(false)
                    }}
                    action={activationType}
                    show={modalActivationDeactivationBackdrop}
                  />
                </Row>
                {activateDeactivateSuccess && (
                  <SweetAlert
                    title={`Usuario ${
                      activationType ? "activado" : "inactivado"
                    }`}
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                      dispatch(activateDeactivateClean())
                      setActivationType(undefined)
                    }}
                  >
                    Se le ha enviado un correo al usuario indicándole que ha
                    sido {activationType ? "activado" : "inactivado"} en la
                    plataforma.
                  </SweetAlert>
                )}
                {forgetPasswordSent && (
                  <SweetAlert
                    title="Correo enviado"
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                      setModalPasswordResetBackdrop(false)
                      setForgetPasswordSent(false)
                      setUser({})
                    }}
                  >
                    Enviamos a {user.firstName} un correo con las instrucciones
                    para restaurar su contraseña.
                  </SweetAlert>
                )}
                {changePasswordSent && (
                  <SweetAlert
                    title="Contraseña modificada"
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                      setModalPasswordResetBackdrop(false)
                      setChangePasswordSent(false)
                      setUser({})
                    }}
                  >
                    <p>
                      La contraseña de {user.firstName} ha sido modificada
                      exitosamente.
                    </p>
                    {/*<p>
                    Contraseña:
                    {getPassword()}
                    <button
                      className="btn btn-link"
                      onClick={setPasswordView(!passwordView)}
                    >
                      <i
                        className={`fa fa-${
                          passwordView ? "eye-show" : "eye-hide"
                        }`}
                      ></i>
                    </button>
                      </p>*/}
                  </SweetAlert>
                )}

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {userForResetCourses !== undefined && (
        <SweetAlert
          title="Resetear cursos"
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={handleResetCourses}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => setUserForResetCourses(undefined)}
        >
          <p>¿Confirmas el reseteo de todos los cursos del participante?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

UsersListTable.propTypes = {
  users: PropTypes.array,
  onUpdateStatus: PropTypes.func,
}

export default UsersListTable
