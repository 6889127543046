import {
  EDIT_RULE,
  EDIT_RULE_CLEAN,
  EDIT_RULE_ERROR,
  EDIT_RULE_SUCCESS,
  LIST_RULES,
  LIST_RULES_CLEAN,
  LIST_RULES_ERROR,
  LIST_RULES_SUCCESS,
} from "./actionTypes"

export const listRules = () => ({
  type: LIST_RULES,
  payload: {},
})

export const listRulesSuccess = rules => ({
  type: LIST_RULES_SUCCESS,
  payload: { rules },
})

export const listRulesError = error => ({
  type: LIST_RULES_ERROR,
  payload: { error },
})

export const listRulesClean = () => ({
  type: LIST_RULES_CLEAN,
  payload: {},
})

export const editRule = form => ({
  type: EDIT_RULE,
  payload: { ...form },
})

export const editRuleSuccess = () => ({
  type: EDIT_RULE_SUCCESS,
  payload: {},
})

export const editRuleError = error => ({
  type: EDIT_RULE_ERROR,
  payload: { error },
})

export const editRuleClean = () => ({
  type: EDIT_RULE_CLEAN,
  payload: {},
})
