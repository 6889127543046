import {
  ADD_IMAGE,
  ADD_IMAGE_SUCCESS,
  ADD_IMAGE_CLEAN,
  ADD_IMAGE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  image: "",
}

const ImageAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_IMAGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_IMAGE_SUCCESS:
      state = {
        ...state,
        image: action.payload.image,
        loading: false,
        done: true,
      }
      break
    case ADD_IMAGE_CLEAN:
      state = initialState
      break
    case ADD_IMAGE_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ImageAdd
