import {
  LIST_REPORT_SURVEY,
  LIST_REPORT_SURVEY_SUCCESS,
  LIST_REPORT_SURVEY_CLEAN,
  LIST_REPORT_SURVEY_ERROR,
} from "./actionTypes"

export const listReportSurvey = form => ({
  type: LIST_REPORT_SURVEY,
  payload: { form },
})

export const listReportSurveySuccess = report => {
  return {
    type: LIST_REPORT_SURVEY_SUCCESS,
    payload: { report },
  }
}

export const listReportSurveyClean = () => {
  return {
    type: LIST_REPORT_SURVEY_CLEAN,
    payload: {},
  }
}

export const listReportSurveyError = error => {
  return {
    type: LIST_REPORT_SURVEY_ERROR,
    payload: error,
  }
}
