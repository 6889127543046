import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report2Chart = ({ data }) => {
  const categs = data.map(item => item.nombre_curso)

  const categories = [
    "En 0%",
    "Mayor a 0% y hasta 50%",
    "Mayor a 50% y menor a 100%",
    "Al 100%",
  ]

  let avance0 = 0
  let avance50 = 0
  let avance99 = 0
  let avance100 = 0

  if (data !== undefined)
    data.forEach(element => {
      avance0 += element.num_usuarios_pctavance_0
      avance50 += element.num_usuarios_pctavance_0_50
      avance99 += element.total_usuarios_porcentaje_avance_50_100
      avance100 += element.total_usuarios_porcentaje_avance_100
    })

  const series = [
    {
      type: "column",
      name: "En 0%",
      data: data.map(item => item.num_usuarios_pctavance_0),
    },
    {
      type: "column",
      name: "Mayor a 0% y hasta 50%",
      data: data.map(item => item.num_usuarios_pctavance_0_50),
    },
    {
      type: "column",
      name: "Mayor a 50% y menor a 100%",
      data: data.map(item => item.total_usuarios_porcentaje_avance_50_100),
    },
    {
      type: "column",
      name: "Al 100%",
      data: data.map(item => item.total_usuarios_porcentaje_avance_100),
    },
  ]

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      show: true,
      categories: categs,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#f46a6a", "#f1734f", "#f1b44c", "#34c38f"],
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="bar"
        height="420"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report2Chart.propTypes = {
  data: PropTypes.array,
}
export default Report2Chart
