import React from "react"
import ReactApexChart from "react-apexcharts"

const barchart = () => {
  const series = [
    {
      data: [25, 29, 45, 71, 78, 65, 61, 27, 36, 25],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f"],
    grid: {
      borderColor: "#f1f1f1",
    },
    width: "300px",
    xaxis: {
      categories: [
        "100%",
        "90%",
        "80%",
        "70%",
        "60%",
        "50%",
        "40%",
        "30%",
        "20%",
        "10%",
        "0%",
      ],
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="500"
      width="100%"
    />
  )
}

export default barchart
