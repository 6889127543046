import { PropTypes } from "prop-types"
import { useState } from "react"
import { AvForm, AvInput } from "availity-reactstrap-validation"
import { Row, Col, Label, Card, CardBody } from "reactstrap"
import Select from "react-select"

const DashboardFilters = props => {
  const [coursesSelectedMulti, setCoursesSelectedMulti] = useState([])
  const [customersSelectedMulti, setCustomersSelectedMulti] = useState([])
  const [groupsSelectedMulti, setGroupsSelectedMulti] = useState([])

  const handleSubmit = (event, errors, values) => {
    props.onSubmit({
      courses: coursesSelectedMulti.map(course => course.value),
      customers: customersSelectedMulti.map(customer => customer.value),
      groups: groupsSelectedMulti.map(group => group.value),
      status: 1,
    })
  }

  const handleCoursesMulti = coursesSelectedMulti =>
    setCoursesSelectedMulti(coursesSelectedMulti)

  const handleCustomersMulti = customersSelectedMulti =>
    setCustomersSelectedMulti(customersSelectedMulti)

  const handleGroupsMulti = groupsSelectedMulti =>
    setGroupsSelectedMulti(groupsSelectedMulti)

  const coursesOptions =
    props.courses !== undefined
      ? props.courses
          .filter(course => course.status === 2)
          .sort((a, b) => parseInt(a.courseCode) - parseInt(b.courseCode))
          .map(course => {
            return {
              label: `${course.courseCode} - ${course.courseName}`,
              value: course.courseId,
            }
          })
      : []

  const filteredCustomers =
    props.customers !== undefined
      ? props.customers
          .filter(customer => customer.statusId)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      : []

  const customersOptions = filteredCustomers.map(customer => {
    return {
      label: `${customer.idh} - ${customer.name}`,
      value: customer.idh,
    }
  })

  let groupsOptions = []
  try {
    groupsOptions = props.groups
      .sort((a, b) => (a.nombreGrupo > b.nombreGrupo ? 1 : -1))
      .map(group => {
        return {
          label: `${group.nombreGrupo}`,
          value: group.idGrupo,
        }
      })
  } catch (error) {}

  const coursesOptionGroup = [
    {
      label: "Cursos",
      options: coursesOptions,
    },
  ]

  const customersOptionGroup = [
    {
      label: "Clientes",
      options: customersOptions,
    },
  ]

  const groupsOptionGroup = [
    {
      label: "Grupos",
      options: groupsOptions,
    },
  ]

  return (
    <Card>
      <CardBody>
        <AvForm onSubmit={handleSubmit}>
          <Row>
            {/*<Col xs={12} className="mb-3">
              <Label for="">Filtrar por cursos</Label>
              <Select
                value={coursesSelectedMulti}
                isMulti={true}
                onChange={event => {
                  handleCoursesMulti(event)
                }}
                options={coursesOptionGroup}
                classNamePrefix="select2-selection"
              />
            </Col>*/}
            <Col xs={12} className="mb-3">
              <Label for="">Filtrar por cliente</Label>
              <Select
                value={customersSelectedMulti}
                isMulti={true}
                onChange={event => {
                  handleCustomersMulti(event)
                }}
                options={customersOptionGroup}
                classNamePrefix="select2-selection"
              />
            </Col>
            <Col xs={12} className="mb-3">
              <Label for="">Filtrar por grupo</Label>
              <Select
                value={groupsSelectedMulti}
                isMulti={true}
                onChange={event => {
                  handleGroupsMulti(event)
                }}
                options={groupsOptionGroup}
                classNamePrefix="select2-selection"
              />
            </Col>
            <Col xs={12} className="mb-2">
              <button
                className="btn btn-secondary mt-2 btn-block"
                type="submit"
              >
                <i className="fa fa-table"></i> Filtrar
              </button>
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
  )
}

DashboardFilters.propTypes = {
  onSubmit: PropTypes.func,
  courses: PropTypes.array,
  customers: PropTypes.array,
  groups: PropTypes.array,
}

export default DashboardFilters
