/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_COURSE } from "./actionTypes"
import { apiError, addCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addCourse } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addCourse, action.payload.form)
    if (response.statusCode !== undefined) throw new Error(response.message)
    yield put(addCourseSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* courseAddSaga() {
  yield takeEvery(ADD_COURSE, add)
}

export default courseAddSaga
