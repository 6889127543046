/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_QUIZZ_ANSWER_USER } from "./actionTypes"
import { addQuizzAnswerUserError, addQuizzAnswerUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addQuizzAnswerUserNewApi } from "../../../helpers/backend_helper"

function* add(request) {
  try {
    const response = yield call(addQuizzAnswerUserNewApi, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(addQuizzAnswerUserSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(addQuizzAnswerUserError(error.message))
  }
}

function* quizzAnswerUserAddSaga() {
  yield takeEvery(ADD_QUIZZ_ANSWER_USER, add)
}

export default quizzAnswerUserAddSaga
