import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report4Chart = ({ data }) => {
  const categories =
    data !== undefined
      ? data.map(
          element => `${element.nombre_usuario} ${element.apellido_usuario}`
        )
      : []

  let porcentajeAvance = []
  let porceentajeAprobacion = []

  if (data !== undefined)
    data.forEach(element => {
      porcentajeAvance.push(
        Math.round((element.porcentaje_avance + Number.EPSILON) * 100) / 100
      )
      porceentajeAprobacion.push(
        Math.round((element.porcentaje_aprobacion + Number.EPSILON) * 100) / 100
      )
    })

  const series = [
    {
      type: "column",
      name: "Porcentaje de aprobación",
      data: porceentajeAprobacion,
    },
    {
      type: "line",
      name: "Porcentaje de avance",
      data: porcentajeAvance,
    },
  ]

  const options = {
    title: {
      text: "Gráfica 4: Porcentaje de avance / porcentaje de aprobación",
    },
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: false,
        style: {
          fontSize: "14px",
        },
      },
    },
    labels: ["Participante"],
    yaxis: [
      {
        title: {
          text: "Porcentaje de aprobación",
          style: {
            fontSize: "14px",
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Porcentaje de avance",
          style: {
            fontSize: "14px",
          },
        },
      },
    ],
    colors: ["#34c38f", "#f46a6a"],
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="line"
        height="420"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report4Chart.propTypes = {
  data: PropTypes.array,
}
export default Report4Chart
