import {
  LIST_REPORT4,
  LIST_REPORT4_SUCCESS,
  LIST_REPORT4_CLEAN,
  LIST_REPORT4_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  report: [],
}

const Report4List = (state = initialState, action) => {
  switch (action.type) {
    case LIST_REPORT4:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_REPORT4_SUCCESS:
      state = {
        ...state,
        report: action.payload.report,
        loading: false,
        done: true,
      }
      break
    case LIST_REPORT4_CLEAN:
      state = initialState
      break
    case LIST_REPORT4_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Report4List
