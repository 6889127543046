import {
  LIST_REPORTA,
  LIST_REPORTA_SUCCESS,
  LIST_REPORTA_CLEAN,
  LIST_REPORTA_ERROR,
} from "./actionTypes"

export const listReportA = form => ({
  type: LIST_REPORTA,
  payload: { form },
})

export const listReportASuccess = report => {
  return {
    type: LIST_REPORTA_SUCCESS,
    payload: { report },
  }
}

export const listReportAClean = () => {
  return {
    type: LIST_REPORTA_CLEAN,
    payload: {},
  }
}

export const listReportAError = error => {
  return {
    type: LIST_REPORTA_ERROR,
    payload: error,
  }
}
