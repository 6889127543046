import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"

const RestorePasswordModal = props => {
  const [option, setOption] = useState("")
  const [password, setPassword] = useState("")
  const [passwordValidation, setPasswordValidation] = useState(undefined)
  const pattern = /()^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}).*$/

  const changeOption = event => {
    setOption(event.target.value)
    if (event.target.value === "link") {
      document.getElementById("password").value = ""
    }
  }
  const changePassword = event => {
    setPassword(event.target.value)
    setPasswordValidation(
      pattern.test(password) && password.length >= 12 ? true : undefined
    )
  }

  const handleSubmit = () => {
    if (!pattern.test(password) && password.length < 12) {
      setPasswordValidation(false)
      return
    }
    setPasswordValidation(true)
    props.onConfirmWithPassword(password)
  }

  const beforeClose = () => {
    setOption("")
    setPassword("")
  }
  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Restaurar contraseña
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            beforeClose()
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        {props.error != null && (
          <div className="alert alert-danger">{props.error}</div>
        )}
        <FormGroup check>
          <Row className="mb-2">
            <Col xs={12}>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="link"
                  onChange={changeOption}
                />
                Se enviará un link para que el usuario genere su propia
                contraseña
              </Label>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12}>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="password"
                  onChange={changeOption}
                />
                Crear una contraseña
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <Input
                name="password"
                id="password"
                type="password"
                placeholder="Escriba aquí la contraseña"
                disabled={option === "link"}
                onChange={changePassword}
              />
              {passwordValidation !== undefined && (
                <div
                  className={
                    passwordValidation === true
                      ? "valid text-success mt-1"
                      : "invalid text-danger mt-1"
                  }
                  name="validate"
                  id="validate1"
                >
                  {passwordValidation === true
                    ? "¡Esa contraseña luce genial!"
                    : "La contraseña debe tener al menos 12 caracteres, al menos una minúscula, una mayúscula y un número"}
                </div>
              )}
            </Col>
            <Col xs={12}>
              <p>
                Debe tener al menos 12 caracteres, al menos una minúscula, una
                mayúscula y un número
              </p>
              <p>Se le invitará al usuario a cambiar su contraseña</p>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            beforeClose()
            props.onCancel()
          }}
        >
          Cancelar
        </button>
        <button
          disabled={option === ""}
          type="button"
          className="btn btn-secondary"
          onClick={() =>
            option === "link" ? props.onConfirmWithLink() : handleSubmit()
          }
        >
          Resetear
        </button>
      </div>
    </Modal>
  )
}

RestorePasswordModal.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirmWithLink: PropTypes.func,
  onConfirmWithPassword: PropTypes.func,
}

export default RestorePasswordModal
