import {
  LIST_REPORT9,
  LIST_REPORT9_SUCCESS,
  LIST_REPORT9_CLEAN,
  LIST_REPORT9_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  report: {},
}

const Report9List = (state = initialState, action) => {
  switch (action.type) {
    case LIST_REPORT9:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_REPORT9_SUCCESS:
      state = {
        ...state,
        report: action.payload.report,
        loading: false,
        done: true,
      }
      break
    case LIST_REPORT9_CLEAN:
      state = initialState
      break
    case LIST_REPORT9_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Report9List
