/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_BLOCK } from "./actionTypes"
import { apiError, editBlockSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateBlock } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateBlock, action.payload.form)
    if (response === undefined) yield put(apiError("Error al editar lección"))
    if (response.statusCode !== undefined) throw new Error(response.message)
    else yield put(editBlockSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* blockEditSaga() {
  yield takeEvery(EDIT_BLOCK, edit)
}

export default blockEditSaga
