import {
  REMOVE_SUPPORT_MATERIAL,
  REMOVE_SUPPORT_MATERIAL_CLEAN,
  REMOVE_SUPPORT_MATERIAL_SUCCESS,
  REMOVE_SUPPORT_MATERIAL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  supportMaterial: undefined,
}

const SupportMaterialRemove = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_SUPPORT_MATERIAL:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_SUPPORT_MATERIAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        supportMaterial: action.payload.supportMaterial,
      }
      break
    case REMOVE_SUPPORT_MATERIAL_CLEAN:
      state = initialState
      break
    case REMOVE_SUPPORT_MATERIAL_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SupportMaterialRemove
