import { Fragment, useEffect, useState } from "react"
import { Col, Row, Label, Button, Input, Modal } from "reactstrap"
import PropTypes from "prop-types"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import {
  getLessons,
  addLesson,
  addLessonClean,
  editLesson,
  editLessonClean,
  getBlocks,
  addBlock,
  addBlockClean,
  editBlock,
  editBlockText,
  editBlockClean,
  removeBlock,
  removeBlockClean,
  listQuizz,
  addQuizz,
  addQuizzClean,
  getBlocksClean,
  listQuizzClean,
  removeQuizz,
  removeQuizzClean,
} from "../../store/actions"
import CoursesLesonnsFormWysiwyg from "./CoursesLessonsFormWysiwyg"
import CoursesLesonnsFormWysiwyg2 from "./CoursesLessonsFormWysiwyg2"
import QuizzFormModal from "./QuizzFormModal"

const CoursesLessonsForm = props => {
  const dispatch = useDispatch()

  const lessonId = props.lesson.lessonId
  const courseId = props.course.courseId

  const [insert, setInsert] = useState(props.insert)
  const [formError, setFormError] = useState(false)
  const [refreshQuizz, setRefreshQuizz] = useState(false)
  const [blockToRemove, setBlockToRemove] = useState(undefined)
  const [showQuizzForm, setShowQuizzForm] = useState(undefined)

  useEffect(() => {
    if (!insert) {
      dispatch(getBlocks(lessonId))
      dispatch(listQuizz(lessonId, whoIAm.userId))
      setRefreshQuizz(true)
    }
    return () => {
      setFormError(false)
      dispatch(addLessonClean())
      dispatch(getBlocksClean())
    }
  }, [insert, lessonId, courseId])

  const {
    lessonAddLoading,
    lessonAddDone,
    lessonAddError,
    lessonEditDone,
    lessonEditError,
    blocks,
    blocksLoading,
    blockAddLoading,
    blockAddDone,
    blockAddError,
    blockEditDone,
    blockEditError,
    blockEditText,
    blockRemoveLoading,
    blockRemoveDone,
    blockRemoveError,
    quizz,
    quizzDone,
    quizzAddLoading,
    quizzAddDone,
    quizzAddError,
    quizzRemoveLoading,
    quizzRemoveDone,
    quizzRemoveError,
    whoIAm,
  } = useSelector(store => ({
    lessonAddLoading: store.LessonAdd.loading,
    lessonAddDone: store.LessonAdd.done,
    lessonAddError: store.LessonAdd.error,
    lessonEditDone: store.LessonEdit.done,
    lessonEditError: store.LessonEdit.error,
    blocks: store.BlocksList.blocks,
    blocksLoading: store.BlocksList.loading,
    blockAddLoading: store.BlockAdd.loading,
    blockAddDone: store.BlockAdd.done,
    blockAddError: store.BlockAdd.error,
    blockEditLoading: store.BlockEdit.loading,
    blockEditDone: store.BlockEdit.done,
    blockEditError: store.BlockEdit.error,
    blockRemoveLoading: store.BlockRemove.loading,
    blockRemoveDone: store.BlockRemove.done,
    blockRemoveError: store.BlockRemove.error,
    quizz: store.QuizzList.quizz,
    quizzDone: store.QuizzList.done,
    quizzAddLoading: store.QuizzAdd.loading,
    quizzAddDone: store.QuizzAdd.done,
    quizzAddError: store.QuizzAdd.error,
    quizzRemoveLoading: store.QuizzRemove.loading,
    quizzRemoveDone: store.QuizzRemove.done,
    quizzRemoveError: store.QuizzRemove.error,
    whoIAm: store.Profile.user,
  }))

  /*useEffect(() => {
    const singleQuizz = quizz.find(element => element.question === "")
    setShowQuizzForm(singleQuizz)
    return () => {}
  }, [quizz])*/

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    if (insert) {
      const request = {
        idCourse: props.course.courseId,
        title: values.name,
        minutes: parseInt(values.minutes),
        order: props.lessonCount + 1,
      }

      dispatch(addLesson(request))
    } else {
      const request = {
        idLesson: props.lesson.lessonId,
        idCourse: props.course.courseId,
        title: values.name,
        minutes: parseInt(values.minutes),
      }

      dispatch(editLesson(request))
    }
  }

  const handleRemoveBlock = block => {
    setBlockToRemove(block)
  }

  const handleConfirmRemoveBlock = id => {
    setBlockToRemove(undefined)
    dispatch(removeBlock(id))
  }

  const addText = () => {
    dispatch(
      addBlock({
        idLesson: props.lesson.lessonId,
        idCourse: props.course.courseId,
        type: "text",
        value: "",
      })
    )
  }

  const handleAddQuizz = () => {
    const request = {
      courseId: props.course.courseId,
      lessonId: props.lesson.lessonId,
      question: "Nueva pregunta",
      userId: whoIAm.userId,
    }
    dispatch(addQuizz(request))
  }

  if (blockAddDone) {
    dispatch(addBlockClean())
    dispatch(getBlocks(props.lesson.lessonId))
  }

  if (quizzAddDone) {
    dispatch(addQuizzClean())
    setRefreshQuizz(true)
    dispatch(listQuizz(props.lesson.lessonId, whoIAm.userId))
  }

  if (quizzRemoveDone) {
    dispatch(removeQuizzClean())
    dispatch(listQuizz(props.lesson.lessonId, whoIAm.userId))
  }

  if (quizzDone && refreshQuizz) {
    if (showQuizzForm !== undefined) {
      setRefreshQuizz(false)
    }
  }

  return (
    <Fragment>
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        {lessonAddError !== "" && (
          <div className="alert alert-danger">{lessonAddError}</div>
        )}
        {formError && (
          <div className="alert alert-danger">
            Por favor verifica los errores en el formulario.
          </div>
        )}
        <Row className="mb-3">
          <Col md={3} className="d-flex justify-content-end align-items-center">
            <Label for="name">* Nombre de la lección:</Label>
          </Col>
          <Col md={7}>
            <AvField
              name="name"
              value={props.lesson.title}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3} className="d-flex justify-content-end align-items-center">
            <Label for="name">* Minutos aproximados:</Label>
          </Col>
          <Col lg={1} md={3} sm={6}>
            <AvField
              name="minutes"
              value={props.lesson.relatedMinutes}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
                number: {
                  value: true,
                  errorMessage: "Este campo debe ser un número",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <Button className={`float-right  btn-block`} type="submit">
              <i className="fa fa-save"></i> Guardar
            </Button>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <button
              onClick={props.onCancel}
              className="btn btn-light btn-block"
            >
              <i className="fa fa-undo"></i> Regresar
            </button>
          </Col>
        </Row>
      </AvForm>
      {!insert && (
        <Row className="mt-4">
          <Col xl={1} lg={2} md={2} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={4} xs={4} className="mb-3">
                <button
                  className={`${
                    blocks.filter(block => block.type === "text").length <= 0
                      ? ""
                      : "disabled"
                  } btn btn-block mb-3 btn-secondary`}
                  onClick={() => addText()}
                >
                  <i className="fa fa-font"></i>
                  <br />
                  Agregar texto
                </button>
              </Col>
              <Col xl={12} lg={12} md={12} sm={4} xs={4} className="mb-3">
                <button
                  className={`btn btn-block mb-3 btn-warning`}
                  onClick={() => handleAddQuizz()}
                >
                  <i className="fa fa-tasks"></i>
                  <br />
                  Agregar quizz
                </button>
              </Col>
            </Row>
          </Col>
          <Col xl={11} lg={10} md={10} sm={12} className="border">
            {blocks?.length < 1 && (
              <h4 className="text-center mt-4 mb-4">-- Sin bloques --</h4>
            )}
            {blocks?.map(block => (
              <div key={`text-${block.idBlock}`} className="mt-4 mb-4">
                {block.type === "text" && (
                  <CoursesLesonnsFormWysiwyg2
                    block={block}
                    removeBlockButton={
                      <button
                        className="btn btn-dark"
                        onClick={() => handleRemoveBlock(block)}
                      >
                        <i className="fa fa-trash" /> Remover bloque
                      </button>
                    }
                  />
                )}
                <hr />
              </div>
            ))}
            <hr />
            {quizz?.length < 1 && (
              <h4 className="text-center mt-4 mb-4">-- Sin quizzes --</h4>
            )}
            {quizz !== undefined &&
              quizz !== null &&
              quizz?.map(singleQuizz => (
                <div key={`quizz-${singleQuizz.quizzId}`} className="mt-4 mb-4">
                  <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                      <h4>{singleQuizz.question}</h4>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-xs-12">
                      <button
                        className="btn btn-secondary btn-block"
                        onClick={() => setShowQuizzForm(singleQuizz)}
                      >
                        <i className="fa fa-edit" /> Editar quizz
                      </button>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-xs-12">
                      <button
                        className="btn btn-dark btn-block"
                        onClick={() =>
                          dispatch(
                            removeQuizz({ quizzId: singleQuizz.quizzId })
                          )
                        }
                      >
                        <i className="fa fa-trash" /> Remover quizz
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
          </Col>
        </Row>
      )}
      <Modal
        isOpen={blockToRemove !== undefined}
        backdrop={"static"}
        id="removeBlock"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="removeBlockLabel">
            Remover bloque
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setBlockToRemove(undefined)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <p>¿Confirmas que deseas remover este bloque?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setBlockToRemove(undefined)
            }}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => handleConfirmRemoveBlock(blockToRemove.blockId)}
          >
            Remover
          </button>
        </div>
      </Modal>
      <QuizzFormModal
        onClose={() => {
          setShowQuizzForm(undefined)
          dispatch(listQuizz(props.lesson.lessonId, whoIAm.userId))
          setRefreshQuizz(true)
        }}
        quizz={showQuizzForm}
        course={props.course}
        reload={() => dispatch(listQuizz(props.lesson.lessonId))}
      />
      {lessonAddDone && insert && (
        <SweetAlert
          title="Lección registrada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(addLessonClean())
            props.onCancel()
          }}
        >
          <p>La lección fue registrada de forma exitosa.</p>
        </SweetAlert>
      )}
      {lessonEditDone && !insert && (
        <SweetAlert
          title="Lección actualizada exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editLessonClean())
          }}
        >
          <p>La lección fue actualizada de forma exitosa.</p>
        </SweetAlert>
      )}
      {blockRemoveDone && (
        <SweetAlert
          title="Bloque removido exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(removeBlockClean())
            dispatch(getBlocks(props.lesson.lessonId))
          }}
        >
          <p>El bloque fue removido de forma exitosa.</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CoursesLessonsForm.propTypes = {
  insert: PropTypes.bool,
  lesson: PropTypes.object,
  lessonCount: PropTypes.number,
  course: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirmInsert: PropTypes.func,
}

export default CoursesLessonsForm
