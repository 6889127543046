import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  getAllShoppingAreas,
  editShoppingAreaClean,
  addShoppingAreaClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import ShoppingAreaForm from "./ShoppingAreaForm"

const ShoppingAreasListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [insertModal, setInsertModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [shoppingArea, setShoppingArea] = useState({
    shoppingAreaId: 0,
  })

  const { error, loading, done, shoppingAreas } = useSelector(state => ({
    error: state.ShoppingAreasList.error,
    loading: state.ShoppingAreasList.loading,
    shoppingAreas: state.ShoppingAreasList.shoppingAreas,
    done: state.ShoppingAreasList.done,
  }))

  useEffect(() => {
    dispatch(getAllShoppingAreas())
  }, [])

  const handleEdit = shoppingArea => {
    setShoppingArea(shoppingArea)
    setUpdateModal(true)
  }

  const buttonActions = (cell, row, rowIndex, extraData) => {
    return (
      <button
        onClick={() => handleEdit(row)}
        key={`button-${row.shoppingAreaId}`}
        className="btn btn-primary"
      >
        Editar
      </button>
    )
  }

  const columns = [
    {
      dataField: "shoppingAreaId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "shoppingArea",
      text: "Zona comercial",
      sort: true,
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.shoppingAreaId}`}>
            <span
              className={`pt-2 badge bg-${row.status ? "success" : "warning"}`}
            >
              {row.status ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: buttonActions,
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: shoppingAreas?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "zonaComercial", order: "asc" }]

  return loading || shoppingAreas === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="shoppingAreaId"
        columns={columns}
        data={shoppingAreas}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="shoppingAreaId"
            columns={columns}
            data={shoppingAreas}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={6}>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-rounded"
                        onClick={() => setInsertModal(true)}
                      >
                        <i className="fa fa-plus"></i> Agregar zona comercial
                      </button>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 2, offset: 8 }}
                    md={{ size: 3, offset: 6 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"idNegocio"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {shoppingAreas?.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <Modal
        isOpen={insertModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Registrar zona comercial</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setInsertModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ShoppingAreaForm
            onDone={() => {
              dispatch(addShoppingAreaClean())
              dispatch(getAllShoppingAreas())
              setInsertModal(false)
            }}
            onCancel={() => {
              dispatch(addShoppingAreaClean())
              setInsertModal(false)
            }}
            insert={true}
            shoppingArea={{
              shoppingAreaId: 0,
              shoppingAra: "",
              status: true,
            }}
          />
        </div>
      </Modal>
      <Modal isOpen={updateModal} backdrop={"static"} size="lg">
        <div className="modal-header">
          <h5 className="modal-title">Editar zona comercial</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setUpdateModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ShoppingAreaForm
            onDone={() => {
              dispatch(editShoppingAreaClean())
              dispatch(getAllShoppingAreas())
              setUpdateModal(false)
            }}
            onCancel={() => {
              dispatch(editShoppingAreaClean())
              setUpdateModal(false)
            }}
            insert={false}
            shoppingArea={shoppingArea}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

ShoppingAreasListTable.propTypes = {
  shoppingAreas: PropTypes.array,
}

export default ShoppingAreasListTable
