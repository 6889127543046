import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { Row, Col, Modal } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"
import { getPeriodClients } from "store/actions"
import XlsExporter from "components/Common/XlsExporter"

const GammificationClientsListTable = props => {
  const limit = props.limit ?? false
  const dispatch = useDispatch()
  const [finalClients, setFinalClients] = useState([])

  const { clients, loading } = useSelector(state => ({
    clients: state.GamificationPeriods.clients,
    loading: state.GamificationPeriods.loading,
  }))

  useEffect(() => {
    dispatch(getPeriodClients(props.periodId))
  }, [props.periodId])

  useEffect(() => {
    const dummyClients = clients
    dummyClients.sort((a, b) => {
      return b.puntos_totales - a.puntos_totales
    })

    for (let i = 0; i < dummyClients.length; i++) {
      dummyClients[i].ranking = i + 1
    }

    const dummyClients2 = limit
      ? dummyClients.slice(0, 5).map((client, index) => client)
      : dummyClients

    setFinalClients(dummyClients2)
  }, [clients])

  const { SearchBar } = Search
  const columns = [
    {
      dataField: "ranking",
      text: "Ranking",
      sort: true,
    },
    {
      dataField: "nombre_distribuidor",
      text: "Cliente",
      sort: true,
    },
    {
      dataField: "zona_comercial",
      text: "Zona Venta",
      sort: true,
    },
    {
      dataField: "puntos_totales",
      text: "Puntos",
      sort: true,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nombre_periodo",
      text: "Periodo",
      sort: true,
      align: "center",
      headerAlign: "center",
    },
  ]

  const getXlsData = clients => {
    let data = clients.map((row, index) => {
      return {
        Ranking: row.ranking,
        Cliente: row.nombre_distribuidor,
        "Zona comercial": row.zona_comercial,
        Puntos: row.puntos_totales ?? 0,
        Periodo: row.nombre_periodo,
      }
    })

    if (limit) {
      return {
        Clientes_ganadores: data,
      }
    }

    return {
      Clientes: data,
    }
  }

  const pageOptions = {
    sizePerPage: 25,
    totalSize: finalClients?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "ranking", order: "asc" }]

  return loading || finalClients === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="Idh_cliente"
        columns={columns}
        data={finalClients}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="Idh_cliente"
            columns={columns}
            data={finalClients}
            search
          >
            {toolkitProps => (
              <Fragment>
                <Row className="mb-2 pt-3">
                  <Col>
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                {finalClients !== undefined && (
                  <Row>
                    <Col className="d-flex justify-content-end pr-4">
                      <XlsExporter
                        data={getXlsData(finalClients)}
                        dataName={limit ? "Clientes_ganadores" : "Clientes"}
                        name={limit ? "Clientes_ganadores" : "Clientes"}
                        label="Exportar lista"
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"Idh_cliente"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {finalClients?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Fragment>
  )
}

GammificationClientsListTable.propTypes = {
  loading: PropTypes.bool,
  periodId: PropTypes.number,
  limit: PropTypes.bool,
}

export default GammificationClientsListTable
