import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESTORE_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userRestorePasswordSuccess,
  userRestorePasswordError,
} from "./actions"

import {
  lostPassword,
  lostPasswordNewApi,
  recoverPassword,
  recoverPasswordNewApi,
} from "../../../helpers/backend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { email, byAdmin } }) {
  try {
    const response = yield call(lostPasswordNewApi, {
      email: email,
      byAdmin: byAdmin,
    })
    if (response) yield put(userForgetPasswordSuccess(response))
    else
      yield put(
        userForgetPasswordError(
          "El correo electrónico ingresado no se encuentra registrado."
        )
      )
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* restorePassword({ payload: { key, password } }) {
  try {
    const response = yield call(recoverPasswordNewApi, {
      token: key,
      password: password,
    })
    if (response) yield put(userRestorePasswordSuccess(response))
    else
      yield put(
        userRestorePasswordError("Ocurrió un error al restaurar tu contraseña.")
      )
  } catch (error) {
    yield put(userRestorePasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESTORE_PASSWORD, restorePassword)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
