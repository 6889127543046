import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_CLEAN,
  ADD_CUSTOMER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  customer: {},
}

const CustomerAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customer: action.payload.customer,
        loading: false,
        done: true,
      }
      break
    case ADD_CUSTOMER_CLEAN:
      state = initialState
      break
    case ADD_CUSTOMER_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CustomerAdd
