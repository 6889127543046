/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { RESET_GROUP_USER } from "./actionTypes"
import { resetGroupUserError, resetGroupUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { resetGroupUserNewApi } from "../../../helpers/backend_helper"

function* reset(action) {
  try {
    const response = yield call(resetGroupUserNewApi, action.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(resetGroupUserSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(resetGroupUserError(error.message))
  }
}

function* groupUserResetSaga() {
  yield takeEvery(RESET_GROUP_USER, reset)
}

export default groupUserResetSaga
