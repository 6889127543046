import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { useParams } from "react-router-dom"
import UsersForm from "components/Users/UsersForm"

const EditUser = props => {
  const { id } = useParams()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Edit user | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Editar usuario</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Home", link: "/dashboard" },
                  { key: "users", title: "Usuarios", link: "/users" },
                  { key: "edit_user", title: "Editar usuario" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <UsersForm id={parseInt(id)} insert={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditUser
