import React, { useEffect, useState, Fragment } from "react"
import classnames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import { getChartsData as onGetChartsData } from "../../store/actions"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane,
} from "reactstrap"

import {
  getCourseKnowledges,
  getSkills,
  getCoursesByUser,
  getCustomers,
  getGroups,
  listReport9,
  listReport2,
  listReport3,
  listReport5,
} from "../../store/actions"
import WelcomeN2to7 from "pages/Dashboard/WelcomeN2to7"
import CoursesByStatus from "components/Dashboard/CoursesByStatus"
import GuageN8 from "components/Charts/GuageN8"
import DashboardFilters from "components/Dashboard/DashboardFilters"
import Report5Chart from "components/Reports/Report5Chart"
import MetaTags from "react-meta-tags"
import ProfileBox from "pages/Dashboard/ProfileBox"
import GammificationPeriodsParticipantListTable from "components/Gammification/GammificationPeriodsParticipantListTable"

const MyProfile = props => {
  const [completedAvg, setCompletedAvg] = useState(0)
  const [activeTabHab, setActiveTabHab] = useState("1")

  const dispatch = useDispatch()

  const {
    knowledges,
    skills,
    user,
    courses,
    loading,
    customers,
    groups,
    report9,
    report2,
    report3,
  } = useSelector(store => ({
    knowledges: store.CourseKnowledgesList.courseKnowledges,
    skills: store.SkillsList.skills,
    user: store.Profile.user,
    courses: store.CoursesList.courses,
    loading: store.CoursesList.loading,
    customers: store.CustomersList.customers,
    groups: store.GroupsList.groups,
    report9: store.Report9List.report,
    report2: store.Report2List.report,
    report3: store.Report3List.report,
  }))

  const idUser = user?.userId ?? 0

  useEffect(() => {
    dispatch(getCourseKnowledges())
    dispatch(getSkills())
    dispatch(getCoursesByUser(idUser))
    dispatch(getCustomers())
    dispatch(getGroups())
  }, [idUser])

  const toggleIconCustomHab = tab => {
    if (activeTabHab !== tab) {
      setActiveTabHab(tab)
    }
  }

  const getColor = index => {
    if (index % 4 == 0) return "success"
    if (index % 4 == 1) return "warning"
    if (index % 4 == 2) return "dark"
    if (index % 4 == 3) return "info"
  }

  const getCompleteCourses = () => {
    const completed =
      courses !== undefined
        ? courses.filter(
            course => course.status === 2 && course.courseParticipant?.approved
          )
        : []

    return completed.length
  }

  useEffect(() => {
    const allCourses =
      courses !== undefined ? courses.filter(course => course.status === 2) : []
    const completedCourses = getCompleteCourses()
    if (allCourses.length <= 0 || completedCourses <= 0) {
      setCompletedAvg(0)
    }
    setCompletedAvg((completedCourses / allCourses.length) * 100)

    return () => {
      setCompletedAvg(0)
    }
  }, [courses])
  console.log(knowledges)

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mi cuenta | Professional&apos;s Academy E-Learning</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <Card className="overflow-hidden">
                <CardBody
                  className="pt-0 pl-0 card-small m-3"
                  style={{ backgroundColor: "rgb(255,255,255)" }}
                >
                  <ProfileBox />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <Card>
                <CardBody className="card-small">
                  <GuageN8 value={completedAvg} />
                </CardBody>
              </Card>
              <Card>
                <CardBody className="card-small">
                  <h3 className="text-center">Detalle de puntos conseguidos</h3>
                  <GammificationPeriodsParticipantListTable userId={idUser} />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabHab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustomHab("1")
                        }}
                      >
                        <span className="d-sm-block">Habilidades</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabHab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustomHab("2")
                        }}
                      >
                        <span className="d-sm-block">Conocimientos</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={activeTabHab}
                    className="text-muted mt-4"
                  >
                    <TabPane tabId="1">
                      <div className="">
                        {skills.length == 0 && (
                          <div>-- No se han generado conocimientos --</div>
                        )}
                        {skills.map((skill, index) =>
                          index % 3 == 0 ? (
                            <span
                              key={`skill-${skill.abilityId}`}
                              className={`badge bg-pill bg-${getColor(
                                index
                              )} pt-2 pb-2 pl-1 pr-1 m-1`}
                              style={{ fontSize: "16px", borderRadius: "14px" }}
                            >
                              {skill.abilityName}
                            </span>
                          ) : (
                            <span key={`skill-${index}`}></span>
                          )
                        )}
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="">
                        {knowledges.length == 0 && (
                          <div>-- No se han generado conocimientos --</div>
                        )}
                        {knowledges.map((knowledge, index) =>
                          index % 3 == 0 ? (
                            <span
                              key={`knowledge-${knowledge.knowledgeId}`}
                              className={`badge bg-pill bg-${getColor(
                                index
                              )} pt-2 pb-2 pl-1 m-1`}
                              style={{ fontSize: "16px", borderRadius: "14px" }}
                            >
                              {knowledge.knowledgeName}
                            </span>
                          ) : (
                            <span key={`knowledge-${index}`}></span>
                          )
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <style jsx>{`
        .card-small {
          min-height: 300px;
        }
        .card-medium {
          min-height: 450px;
        }
      `}</style>
    </Fragment>
  )
}

export default MyProfile
