import {
  EDIT_BLOCK,
  EDIT_BLOCK_CLEAN,
  EDIT_BLOCK_SUCCESS,
  EDIT_BLOCK_CHANGE_TEXT,
  EDIT_BLOCK_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  block: undefined,
  text: [],
}

Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true
  }
  return false
}

Array.prototype.replace = function (key, value) {
  for (var i = 0; i < this.length; i++) {
    if (this[i].key == key) {
      this[i].value = value
      break //Stop this loop, we found it!
    }
  }
}

Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element)
  } else {
    this.replace(element.key, element.value)
  }
}

const BlockEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_BLOCK:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_BLOCK_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        block: action.payload.block,
      }
      break
    case EDIT_BLOCK_CHANGE_TEXT:
      state.text.pushIfNotExist(
        { key: action.payload.idBlock, value: action.payload.text },
        e => e.key === action.payload.idBlock
      )
      state = {
        ...state,
      }
      break
    case EDIT_BLOCK_CLEAN:
      state = initialState
      break
    case EDIT_BLOCK_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default BlockEdit
