import { Fragment, useState, useEffect } from "react"
import { PropTypes } from "prop-types"
import { Card, CardBody, Row, Col } from "reactstrap"
import {
  AvForm,
  AvRadio,
  AvRadioGroup,
  AvField,
} from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { getPoll, getPollClean, tryPoll, tryPollClean } from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"

const CoursePollForm = props => {
  const courseId = props.course.courseId ?? 0
  const dispatch = useDispatch()

  const { done, polls, donePolls, loading } = useSelector(store => ({
    done: store.PollTry.done,
    polls: store.PollTry.polls,
    loading: store.PollTry.loading,
    donePolls: store.PollTry.donePolls,
  }))

  const [answered, setAnswered] = useState(false)
  const [formError, setFormError] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [pollRequests, setPollRequests] = useState(0)
  const [answer1, setAnswer1] = useState(0)
  const [answer2, setAnswer2] = useState(0)
  const [answer3, setAnswer3] = useState(0)
  const [answer4, setAnswer4] = useState(0)
  const [answer5, setAnswer5] = useState(0)
  const [answer6, setAnswer6] = useState(0)
  const [answer7, setAnswer7] = useState(0)
  const [answer8, setAnswer8] = useState(0)
  const [answer9, setAnswer9] = useState("")

  useEffect(() => {
    if (courseId !== 0) {
      dispatch(getPoll({ courseId }))
    }
  }, [courseId])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      courseId: courseId,
      answers: [
        {
          questionId: 1,
          answer: answer1.toString(),
        },
        {
          questionId: 2,
          answer: answer2.toString(),
        },
        {
          questionId: 3,
          answer: answer3.toString(),
        },
        {
          questionId: 4,
          answer: answer4.toString(),
        },
        {
          questionId: 5,
          answer: answer5.toString(),
        },
        {
          questionId: 6,
          answer: answer6.toString(),
        },
        {
          questionId: 7,
          answer: answer7.toString(),
        },
        {
          questionId: 8,
          answer: answer8.toString(),
        },
        {
          questionId: 9,
          answer: answer9.toString(),
        },
      ],
    }

    if (pollRequests === 0) {
      setPollRequests(1)
      dispatch(tryPoll(request))
    }
  }

  if (done) {
    setAnswered(true)
    setShowConfirmation(true)
    setPollRequests(0)
    dispatch(tryPollClean())
  }

  if (donePolls) {
    dispatch(getPollClean())
    if (polls.length > 0) {
      setAnswered(true)
      polls.forEach(poll => {
        if (poll.questionId === 1) setAnswer1(parseInt(poll.answer))
        if (poll.questionId === 2) setAnswer2(parseInt(poll.answer))
        if (poll.questionId === 3) setAnswer3(parseInt(poll.answer))
        if (poll.questionId === 4) setAnswer4(parseInt(poll.answer))
        if (poll.questionId === 5) setAnswer5(parseInt(poll.answer))
        if (poll.questionId === 6) setAnswer6(parseInt(poll.answer))
        if (poll.questionId === 7) setAnswer7(parseInt(poll.answer))
        if (poll.questionId === 8) setAnswer8(parseInt(poll.answer))
        if (poll.questionId === 9) setAnswer9(poll.answer)
      })
    }
  }

  const getQuestionForm = (question, number, value, onChange) => {
    return (
      <Fragment>
        <p className="mt-3">
          {number}. {question}
        </p>
        {number < 9 ? (
          <AvRadioGroup
            name={`answer${number}`}
            className="form-control"
            onChange={event => {
              if (answered) return
              onChange(parseInt(event.target.value))
            }}
            value={value}
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          >
            <Row className="mb-2 mt-2">
              <Col md={1}>
                <AvRadio value={1} label="1" disabled={answered} />
              </Col>
              <Col md={1}>
                <AvRadio value={2} label="2" disabled={answered} />
              </Col>
              <Col md={1}>
                <AvRadio value={3} label="3" disabled={answered} />
              </Col>
              <Col md={1}>
                <AvRadio value={4} label="4" disabled={answered} />
              </Col>
              <Col md={1}>
                <AvRadio value={5} label="5" disabled={answered} />
              </Col>
              <Col md={7}>
                * Siendo 1 la calificación más baja y 5 la más alta
              </Col>
            </Row>
          </AvRadioGroup>
        ) : (
          <AvField
            name={`answer${number}`}
            onChange={event => onChange(event.target.value)}
            value={value}
            className="form-control"
            placeholder="Ingrese aquí sus comentarios"
            type="text"
            disabled={answered}
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        )}
        <div className="pb-2"></div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <h2 className="alt-celf-color">Encuesta de satisfacción</h2>

          {answered && (
            <div className="alert alert-success">
              <h3 className="text-center pt-2">
                <i className="fa fa-check"></i> Recibimos sus respuestas
                exitosamente.
              </h3>
            </div>
          )}
          <AvForm className="form-horizontal" onSubmit={handleSubmit}>
            {formError && (
              <div className="alert alert-danger">
                Existen errores en el formulario.
              </div>
            )}
            <h4 className="mt-4 mb-3">Contenido</h4>
            {getQuestionForm(
              "¿Consideras que la organización del temario fue adecuada?",
              1,
              answer1,
              value => setAnswer1(value)
            )}
            {getQuestionForm(
              "¿La explicación de los temas fue correcta?",
              2,
              answer2,
              value => setAnswer2(value)
            )}
            {getQuestionForm(
              "¿Qué te pareció el material presentado en el curso?",
              3,
              answer3,
              value => setAnswer3(value)
            )}
            {getQuestionForm(
              "¿Las actividades dentro del curso fueron útiles para reforzar el conocimiento?",
              4,
              answer4,
              value => setAnswer4(value)
            )}
            <h4 className="mt-4 mb-3">Plataforma</h4>
            {getQuestionForm(
              "En cuanto a las herramientas mostradas dentro del curso, ¿Te fueron útiles?",
              5,
              answer5,
              value => setAnswer5(value)
            )}
            {getQuestionForm(
              "¿Como consideras que fue la experiencia dentro de la plataforma (conexión, navegación dentro de la misma)?",
              6,
              answer6,
              value => setAnswer6(value)
            )}
            {getQuestionForm(
              "¿La estructura y diseño de la plataforma le parecieron adecuados?",
              7,
              answer7,
              value => setAnswer7(value)
            )}
            {getQuestionForm(
              "¿En general, como valorarías la experiencia del curso? ",
              8,
              answer8,
              value => setAnswer8(value)
            )}
            {getQuestionForm("Comentarios", 9, answer9, value =>
              setAnswer9(value)
            )}
            {!answered && (
              <Row className="mb-3 mt-3">
                <Col xl={10} lg={10} md={10} sm={10} xs={0}></Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={6}>
                  <button
                    className={`btn btn-secondary float-right  btn-block ${
                      !loading ? "" : "disabled"
                    }`}
                    type="submit"
                  >
                    {!loading ? "Guardar" : "Guardando..."}
                  </button>
                </Col>
              </Row>
            )}
          </AvForm>
        </CardBody>
      </Card>
      {showConfirmation && (
        <SweetAlert
          title="Encuesta enviada"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowConfirmation(false)
          }}
        >
          <p>Sus respuestas han sido guardadas exitosamente.</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CoursePollForm.propTypes = {
  course: PropTypes.object,
}

export default CoursePollForm
