import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import Report1ListTable from "components/Reports/Report1ListTable"
import {
  getCourses,
  listReport1,
  listReport1Clean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import Report1Chart from "components/Reports/Report1Chart"
import Report1ChartNew from "components/Reports/Report1ChartNew"

const Report1 = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.Report1List.error,
    loading: state.Report1List.loading,
    report: state.Report1List.report,
    done: state.Report1List.done,
    courses: state.CoursesList.coursesForAdmin,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReport1({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    let data = report.map((element, index) => {
      return {
        Grupo: element.nombre_grupo,
        "Nombre del N6": `${element.nombre_usuario_n6} ${element.apellido_usuario_n6}`,
        "Nombre distribuidor": element.nombre_distribuidor,
        "Número de personas del distribuidor": element.personas,
        "Porcentaje de aprobación": (
          Math.round((element.porcentaje_aprobacion + Number.EPSILON) * 100) /
          100
        ).toFixed(2),
      }
    })

    return {
      Reporte1: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReport1Clean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 1 - Avance por cliente | Professional&apos;s Academy
            E-Learning
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 1 - Avance por cliente</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Home",
                    link: "/dashboard",
                  },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report1", title: "Avance por cliente" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 1"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte1"}
                        name="Reporte 1"
                        label="Exportar"
                      />
                    }
                    users={users.filter(user => user.profileId === 6)}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                    report1={true}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <Report1ChartNew data={reportInfo} />
                      <br />
                      <Report1ListTable loading={loading} report={reportInfo} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Report1
