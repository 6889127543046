import {
  GET_QUIZZ_ANSWER_USER,
  GET_QUIZZ_ANSWER_USER_SUCCESS,
  GET_QUIZZ_ANSWER_USER_CLEAN,
  GET_QUIZZ_ANSWER_USER_ERROR,
} from "./actionTypes"

export const getQuizzAnswerUser = form => ({
  type: GET_QUIZZ_ANSWER_USER,
  payload: { form },
})

export const getQuizzAnswerUserSuccess = quizzAnswerUser => {
  return {
    type: GET_QUIZZ_ANSWER_USER_SUCCESS,
    payload: { quizzAnswerUser },
  }
}

export const getQuizzAnswerUserClean = () => {
  return {
    type: GET_QUIZZ_ANSWER_USER_CLEAN,
    payload: {},
  }
}

export const getQuizzAnswerUserError = error => {
  return {
    type: GET_QUIZZ_ANSWER_USER_ERROR,
    payload: error,
  }
}
