/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_REPORT5 } from "./actionTypes"
import { listReport5Error, listReport5Success } from "./actions"

//Include Both Helper File with needed methods
import { report5NewApi } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(report5NewApi, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (response.length === undefined) throw new Error(response)
    yield put(listReport5Success(response))
  } catch (error) {
    console.log(error.message)
    yield put(listReport5Error(error.message))
  }
}

function* report5ListSaga() {
  yield takeEvery(LIST_REPORT5, list)
}

export default report5ListSaga
