import CustomersListTable from "components/Customers/CustomersListTable"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"

const CustomersList = props => (
  <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Clientes | Professional&apos;s Academy E-Learning</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col sm={4}>
            <h4>Clientes</h4>
          </Col>
          <Col sm={8}>
            <Breadcrumbs
              breadcrumbItems={[
                { key: "dashboard", title: "Home", link: "/dashboard" },
                { key: "customers", title: "Clientes" },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CustomersListTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
)

export default CustomersList
