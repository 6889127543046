/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SHOPPING_AREAS, GET_SHOPPING_AREAS_ALL } from "./actionTypes"
import { apiError, getShoppingAreasSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getShoppingAreasNewApi,
  getAllShoppingAreasNewApi,
} from "../../../helpers/backend_helper"

function* shoppingAreas() {
  try {
    const response = yield call(getShoppingAreasNewApi)
    if (response.error) {
      throw new Error(response.message)
    }
    yield put(getShoppingAreasSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* shoppingAreasAll() {
  try {
    const response = yield call(getAllShoppingAreasNewApi)
    if (response.error) {
      throw new Error(response.message)
    }
    yield put(getShoppingAreasSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* shoppingAreasListSaga() {
  yield takeEvery(GET_SHOPPING_AREAS, shoppingAreas)
  yield takeEvery(GET_SHOPPING_AREAS_ALL, shoppingAreasAll)
}

export default shoppingAreasListSaga
