import {
  REMOVE_SUPPORT_MATERIAL,
  REMOVE_SUPPORT_MATERIAL_SUCCESS,
  REMOVE_SUPPORT_MATERIAL_CLEAN,
  REMOVE_SUPPORT_MATERIAL_ERROR,
} from "./actionTypes"

export const removeSupportMaterial = id => ({
  type: REMOVE_SUPPORT_MATERIAL,
  payload: { id },
})

export const removeSupportMaterialSuccess = supportMaterial => {
  return {
    type: REMOVE_SUPPORT_MATERIAL_SUCCESS,
    payload: { supportMaterial },
  }
}

export const removeSupportMaterialClean = () => {
  return {
    type: REMOVE_SUPPORT_MATERIAL_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_SUPPORT_MATERIAL_ERROR,
    payload: error,
  }
}
