import {
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const addGroup = form => ({
  type: ADD_GROUP,
  payload: { form },
})

export const addGroupSuccess = group => {
  return {
    type: ADD_GROUP_SUCCESS,
    payload: { group },
  }
}

export const addGroupClean = group => {
  return {
    type: ADD_GROUP_CLEAN,
    payload: { group },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
