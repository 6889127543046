import { call, put, takeEvery } from "redux-saga/effects"
import {
  editRuleError,
  editRuleSuccess,
  listRulesError,
  listRulesSuccess,
} from "./actions"
import { EDIT_RULE, LIST_RULES } from "./actionTypes"
import { getAllRules, updateRule } from "helpers/backend_helper"

function* rulesList() {
  try {
    const response = yield call(getAllRules, {})
    if (response.statusCode) throw new Error(response.message)
    yield put(listRulesSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(listRulesError(error))
  }
}

function* ruleEdit({ payload }) {
  try {
    const response = yield call(updateRule, { ...payload })
    if (response.statusCode) throw new Error(response.message)
    yield put(editRuleSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(editRuleError(error))
  }
}

function* gamificationRulesSaga() {
  yield takeEvery(LIST_RULES, rulesList)
  yield takeEvery(EDIT_RULE, ruleEdit)
}

export default gamificationRulesSaga
